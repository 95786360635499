import './api';
import './mapbox';

export { useTranslation } from './i18n/useTranslate';

import './dayjs';
import { initEcharts } from './echarts';
import { initAgGrid } from './ag-grid';
export { echarts } from './echarts';

export function initLibs() {
  initEcharts();
  initAgGrid();
}
