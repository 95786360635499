import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent } from 'react';
import { assetService } from '@/modules/assets/api/assets/asset.service';
import { AssetModel } from '@/modules/assets/types/AssetModel';
import { LocationAssetsGrid } from './LocationAssetsGrid';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import { PagePaginationResultDto, PageSortOrder } from '@/lib/api/pagination.page.dto';

interface LocationAssetsTabProps {
  locationId: number;
}

export const LocationAssetsTab: FunctionComponent<LocationAssetsTabProps> = ({ locationId }) => {
  const { data, isLoading, isError, fetchData } = useApiCall<PagePaginationResultDto<AssetModel>>(() =>
    assetService.get(
      { locationId },
      {
        page: 1,
        limit: 1000,
        order: PageSortOrder.ASC,
      },
    ),
  );
  return (
    <div className="mt-6 flex h-full flex-1 flex-grow flex-col">
      {isLoading ? <LoadingBlock /> : <LocationAssetsGrid data={data?.data} isError={isError}></LocationAssetsGrid>}
    </div>
  );
};
