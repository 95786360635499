import { LinkRounded, RssFeedRounded } from '@mui/icons-material';
import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import MobileMainMenu from '../components/MobileMainMenu';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';

interface MobileLayoutProps {}

const MobileLayout: FunctionComponent<MobileLayoutProps> = () => {
  return (
    <div
      className="flex flex-col h-full overflow-scroll pb-14 w-full"
      style={{
        width: '100dvw',
        height: '100dvh',
      }}
    >
      <Outlet />

      <MobileMainMenu
        menuItems={[
          {
            label: 'link',
            url: '/mobile/connect-asset',
            icon: <LinkRounded />,
            activeRouteIds: ['/mobile/connect-asset*'],
            permission: Permission.MOBILE_CONNECT,
          },
          {
            label: 'events',
            url: '/mobile/events',
            icon: <RssFeedRounded />,
            activeRouteIds: ['/mobile/events*'],
            permission: Permission.MOBILE_EVENTS,
          },
        ]}
      />
    </div>
  );
};

export default MobileLayout;
