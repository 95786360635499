import { FunctionComponent, useContext } from 'react';
import { FilterBarContext } from './FilterBarContext';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from '@/lib';

interface FilterBarSearchButtonProps {
  isLoading?: boolean;
}

const FilterBarSearchButton: FunctionComponent<FilterBarSearchButtonProps> = ({ isLoading }) => {
  const { triggerSearch } = useContext(FilterBarContext);
  const { t } = useTranslation();

  return (
    <LoadingButton loading={isLoading} variant="contained" color="primary" size="medium" onClick={triggerSearch} className="mt-2.5">
      {t('search')}
    </LoadingButton>
  );
};

export default FilterBarSearchButton;
