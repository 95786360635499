export enum Permission {
  // **************
  // Application
  // **************

  // Insights
  DASHBOARDS_VIEW = 'dashboards:view',
  DASHBOARDS_EDIT = 'dashboards:edit',
  MAP_VIEW = 'map:view',
  BALANCES_VIEW = 'balances:view',

  // Operations
  EVENTS_VIEW = 'events:view',

  // Setup
  LOCATIONS_VIEW = 'locations:view',
  LOCATIONS_EDIT = 'locations:edit',
  ASSETS_VIEW = 'assets:view',
  ASSETS_EDIT = 'assets:edit',

  // Mobile Connect
  MOBILE_CONNECT = 'mobile:connect',
  MOBILE_EVENTS = 'mobile:events',

  // **************
  // Configuration
  // **************

  // Base Data
  ASSET_TYPES_VIEW = 'asset_types:view',
  ASSET_TYPES_EDIT = 'asset_types:edit',
  TRACKERS_VIEW = 'trackers:view',
  TRACKERS_EDIT = 'trackers:edit',
  DEVICES_VIEW = 'devices:view',
  DEVICES_EDIT = 'devices:edit',
  LABELS_VIEW = 'labels:view',
  LABELS_EDIT = 'labels:edit',

  // Settings
  EVENT_SETTINGS_VIEW = 'event_settings:view',
  EVENT_SETTINGS_EDIT = 'event_settings:edit',
  CONNECTORS_VIEW = 'connectors:view',
  API_SETTINGS_VIEW = 'api_settings:view',
  API_SETTINGS_EDIT = 'api_settings:edit',

  // Users
  USER_VIEW = 'user:view',
  USER_EDIT = 'user:edit',
  USERS_ROLES_VIEW = 'users_roles:view',
  USERS_ROLES_EDIT = 'users_roles:edit',

  // Import
  FILE_IMPORTER_USE = 'file_importer:use',

  // Application Log
  APPLICATION_LOG_VIEW = 'application_log:view',

  // **************
  // Development
  // **************

  // Debug permission that should never be assigned to a user
  NEVER = 'never',
}

export interface PermissionDTO {
  id: number;
  name: Permission;
}

export const CONFIG_PERMISSIONS: Permission[] = [
  Permission.ASSET_TYPES_VIEW,
  Permission.ASSET_TYPES_EDIT,
  Permission.TRACKERS_VIEW,
  Permission.TRACKERS_EDIT,
  Permission.DEVICES_VIEW,
  Permission.DEVICES_EDIT,
  Permission.LABELS_VIEW,
  Permission.LABELS_EDIT,
  Permission.EVENT_SETTINGS_VIEW,
  Permission.EVENT_SETTINGS_EDIT,
  Permission.CONNECTORS_VIEW,
  Permission.API_SETTINGS_VIEW,
  Permission.API_SETTINGS_EDIT,
  Permission.USER_VIEW,
  Permission.USER_EDIT,
  Permission.USERS_ROLES_VIEW,
  Permission.USERS_ROLES_EDIT,
  Permission.FILE_IMPORTER_USE,
  Permission.APPLICATION_LOG_VIEW,
];
