import { Environment } from '@/config/Environment';
import { ColorModeToggleButton } from './ColorModeToggleButton';
import { ConfigurationButton } from './ConfigurationButton';
import { LogOutButton } from './LogOutButton';
import { useUser } from '@/modules/users/contexts/UserContext';
import { CONFIG_PERMISSIONS } from '@/modules/users/submodules/roles/api/permissions.contracts';

export function MainMenuBottom() {
  const { hasAnyPermission } = useUser();

  const hasAtLeastOneConfigurationPermission = hasAnyPermission(CONFIG_PERMISSIONS);

  return (
    <div className="flex w-full flex-col  gap-y-2">
      {Environment.isDevelopment() && <ColorModeToggleButton />}

      {hasAtLeastOneConfigurationPermission && <ConfigurationButton />}
      <LogOutButton />
    </div>
  );
}
