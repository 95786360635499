import TextLink from '@/components/TextLink';
import { ICellRendererParams } from 'ag-grid-community';
import { FunctionComponent } from 'react';

export interface LinkCellRendererParams {
  pathname?: string;
  search?: string;
  rightSlotRenderer?: FunctionComponent<LinkCellRendererParams> | null;
}

interface LinkCellRendererProps extends ICellRendererParams<unknown>, LinkCellRendererParams {}

const LinkCellRenderer: FunctionComponent<LinkCellRendererProps> = (props: LinkCellRendererProps) => {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  return (
    <div className="">
      {props.pathname ? (
        <div className="flex">
          <TextLink to={{ pathname: props.pathname, search: props.search }}>{cellValue}</TextLink>{' '}
          {props.rightSlotRenderer && <props.rightSlotRenderer {...props} />}
        </div>
      ) : (
        cellValue
      )}
    </div>
  );
};

export default LinkCellRenderer;
