import { z } from 'zod';

export const baseLabelProps = z.object({
  name: z.string().min(1),
  color: z.number(),
  values: z.array(
    z.object({
      id: z.number().optional(),
      value: z.string().min(1),
    }),
  ),
});

export const LabelFormSchema = baseLabelProps;
export const LabelCreateFormSchema = baseLabelProps;

export type LabelFormModel = z.infer<typeof LabelFormSchema>;
