import { FunctionComponent, ReactNode } from 'react';
import { TrackerType } from '../api/trackers/tracker.contracts';
import { trackerTypeToString } from '../lib/tracker.utils';
import { Bluetooth, FormatAlignCenter, RssFeed } from '@mui/icons-material';
import { useTranslation } from '@/lib';
interface TrackerTypeChipProps {
  trackerType: TrackerType;
}

export const TrackerTypeChip: FunctionComponent<TrackerTypeChipProps> = ({ trackerType }) => {
  const { t } = useTranslation();
  interface TrackerTypeStyling {
    icon: ReactNode;
    iconClasses: string;
    textClasses: string;
  }

  const trackerTypeStylingMap: Record<TrackerType, TrackerTypeStyling> = {
    [TrackerType.Barcode]: {
      icon: <FormatAlignCenter className="h-4 w-4 rotate-90" />,
      iconClasses: 'bg-green-400 text-green-950',
      textClasses: 'bg-green-200 text-green-950',
    },
    [TrackerType.RFID]: {
      icon: <RssFeed className="h-4 w-4" />,
      iconClasses: 'bg-purple-400 text-purple-950',
      textClasses: 'bg-purple-200 text-purple-950',
    },
    [TrackerType.UnaTag]: {
      icon: <RssFeed className="h-4 w-4" />,
      iconClasses: 'bg-yellow-700 text-yellow-950',
      textClasses: 'bg-yellow-400 text-yellow-950',
    },
    [TrackerType.BLE]: {
      icon: <Bluetooth className="h-4 w-4" />,
      iconClasses: 'bg-blue-400 text-blue-950',
      textClasses: 'bg-blue-200 text-blue-950',
    },
  };

  const { icon, iconClasses, textClasses } = trackerTypeStylingMap[trackerType];

  return (
    <div className="flex h-6 items-center ">
      <div className={`flex  h-full items-center justify-center rounded-l px-1 ${iconClasses}`}>{icon}</div>
      <div className={`flex h-full items-center rounded-r pl-1  pr-2 leading-none ${textClasses}`}>
        <div>{trackerTypeToString(trackerType, t)}</div>
      </div>
    </div>
  );
};
