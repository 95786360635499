import React, { useContext } from 'react';
import { FilterBarContext, FilterFieldName } from '../FilterBarContext';
import { SelectChangeEvent } from '@mui/material';
import PoSelect from '@/components/inputs/PoSelect';

interface ListSelectFilterProps<T, V> {
  filterFieldName: FilterFieldName;
  label?: string;
  options: T[];
  renderOption: (item: T) => React.ReactNode;
  valueExtractor: (item: T) => V;
  disabled?: boolean;
}

function ListSelectFilter<T, V>({
  filterFieldName,
  label,
  options,
  renderOption,
  valueExtractor,
  disabled,
}: ListSelectFilterProps<T, V>): JSX.Element {
  const { setFilterValue, filterValues } = useContext(FilterBarContext);

  function onValueChanged(event: SelectChangeEvent<V>) {
    setFilterValue(filterFieldName, event.target.value);
  }

  return (
    <PoSelect
      label={label}
      value={filterValues[filterFieldName] as V}
      options={options}
      onChange={onValueChanged}
      renderOption={renderOption}
      valueExtractor={valueExtractor}
      disabled={disabled}
    />
  );
}

export default ListSelectFilter;
