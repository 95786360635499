import { UserDTO } from '../api/users/users.contracts';
import { RoleDTO } from '../submodules/roles/api/roles.contracts';
import { RoleModel } from '../submodules/roles/types/RoleModel';

export class UserModel {
  public id: number;
  public firebaseId: string;
  public name: string;
  public email: string;
  public roles: RoleModel[];
  public password: string;

  constructor(args: UserCreateArguments) {
    this.id = args.id;
    this.firebaseId = args.firebaseId;
    this.name = args.name;
    this.name = args.name;
    this.email = args.email;
    this.roles = args.roles;
    this.password = args.password;
  }

  public static fromDto(dto: UserDTO) {
    return new UserModel({
      id: dto.id,
      email: dto.email,
      firebaseId: dto.firebaseId,
      name: dto.name,
      roles: dto.roles.map((role: RoleDTO) => new RoleModel(role)),
      password: dto.password,
    });
  }
}

interface UserCreateArguments {
  id: number;
  firebaseId: string;
  name: string;
  email: string;
  roles: RoleModel[];
  password: string;
}
