export interface BalanceLocationDTO {
  assetTypeId: number;
  assetTypeName: string;
  balances: BalanceOnDateDTO[];
}

export interface BalanceOnDateDTO {
  balance: number;
  date: string;
}

export interface CurrentBalanceLocationDTO {
  assetTypeId: number;
  assetTypeName: string;
  balance: number;
}

export interface BalancesAtLocationDTO {
  locationId: number;
  locationName: string;
  locationCode: string;
  level: number;
  isSystem: boolean;
  parentLocationCode1?: string;
  parentLocationCode2?: string;
  totalBalance: number;
  totalIncoming: number;
  totalOutgoing: number;
  totalStaleAssets: number;
  totalStaleAssets60Days: number;
  returnRate: number;
  individualBalance?: number;
  individualIncoming?: number;
  individualOutgoing?: number;
  individualStaleAssets?: number;
  individualStaleAssets60Days?: number;
  children?: BalancesAtLocationDTO[];
  assetTypeBalances: AssetTypeBalanceDTO[];
}

export interface AssetTypeBalanceDTO {
  assetTypeId: number;
  assetTypeName: string;
  balance: number;
  incoming: number;
  outgoing: number;
  numberOfStaleAssets: number;
  numberOfStaleAssets60Days: number;
  individualBalance?: number;
  individualIncoming?: number;
  individualOutgoing?: number;
  individualStaleAssets?: number;
  individualStaleAssets60Days?: number;
}

export enum BalanceSortOption {
  LOCATION_NAME = 'location-name',
  TOTAL_BALANCE = 'total-balance',
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
  RETURN_RATE = 'return-rate',
  STAYTIME_30 = 'staytime-30',
  STAYTIME_60 = 'staytime-60',
}
