import { LngLat, LngLatBounds, LngLatBoundsLike, Map } from 'mapbox-gl';

export function getBoundingBox(coordinates: number[][]): LngLatBoundsLike | undefined {
  const bounds: {
    xMin?: number;
    xMax?: number;
    yMin?: number;
    yMax?: number;
  } = {};

  for (let i = 0; i < coordinates.length; i++) {
    const longitude = coordinates[i][0];
    const latitude = coordinates[i][1];
    if (bounds.xMin && bounds.xMax && bounds.yMin && bounds.yMax) {
      bounds.xMin = bounds.xMin < longitude ? bounds.xMin : longitude;
      bounds.xMax = bounds.xMax > longitude ? bounds.xMax : longitude;
      bounds.yMin = bounds.yMin < latitude ? bounds.yMin : latitude;
      bounds.yMax = bounds.yMax > latitude ? bounds.yMax : latitude;
    } else {
      bounds.xMin = longitude;
      bounds.xMax = longitude;
      bounds.yMin = latitude;
      bounds.yMax = latitude;
    }
  }
  if (bounds.xMin && bounds.xMax && bounds.yMin && bounds.yMax) {
    return new LngLatBounds(new LngLat(bounds.xMin, bounds.yMin), new LngLat(bounds.xMax, bounds.yMax));
  }
  return undefined;
}

export function fitToPolygon(map: Map, coordinates: number[][]) {
  const bounds = getBoundingBox(coordinates);
  if (bounds) {
    map.fitBounds(bounds, {
      padding: 20,
    });
  }
}

/**
 * Function to create a hexagonal polygon around a certain latitude, longitude point
 * @param latitude - Latitude of the center point
 * @param longitude - Longitude of the center point
 * @param radiusInKm - Radius of the hexagon in kilometers
 * @returns - Polygon's coordinates value, of type number[][]
 */
export function createHexagon(latitude: number, longitude: number, radiusInMeters: number): number[][] {
  const radiusInKm = radiusInMeters / 1000;

  // Convert the radius from kilometers to degrees, approximately
  const radiusInDegrees = radiusInKm / 111.19;

  // Initialize an empty array for coordinates
  const coordinates: number[][] = [];

  // Loop to create six points, one for each side of the hexagon
  for (let i = 0; i < 6; i++) {
    // Calculate the angle in radians for this point
    const angle = (Math.PI / 3) * i;

    // Calculate the change in latitude for this point
    const pointLatitude = latitude + radiusInDegrees * Math.cos(angle);

    // Calculate the change in longitude for this point, adjusting for the change in distance per degree of longitude as you move away from the equator
    const pointLongitude = longitude + (radiusInDegrees * Math.sin(angle)) / Math.cos((latitude * Math.PI) / 180);

    // Add this point to the array of coordinates
    coordinates.push([pointLongitude, pointLatitude]);
  }

  // Close the polygon by adding the first point again at the end
  coordinates.push(coordinates[0]);

  // Return the coordinates of the hexagonal polygon
  return coordinates;
}
