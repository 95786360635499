import { IDashboardWidgetCatalogItem } from '../../../types/IDashboardWidgetCatalogItem';
import { BalanceOverTimeWidgetConfiguration } from './BalanceOverTimeWidgetConfiguration';
import { DashboardWidgetType } from '@/modules/dashboards/types/DashboardWidgetType';
import { v4 } from 'uuid';
import { MultilineChartRounded } from '@mui/icons-material';
import { TFunction } from 'i18next';
import { DashboardWidgetCatalogItemImageColorType } from '../../widget-catalog/DashboardWidgetCatalogItemImage';
import { Layout } from 'react-grid-layout';
export class BalanceOverTimeWidgetCatalogItem implements IDashboardWidgetCatalogItem<BalanceOverTimeWidgetConfiguration> {
  title: string;
  description: string;
  icon: JSX.Element;
  iconColor: DashboardWidgetCatalogItemImageColorType;
  id: string;
  type: DashboardWidgetType;
  configuration: BalanceOverTimeWidgetConfiguration;
  layout: Layout;

  constructor(t: TFunction) {
    this.title = t('dashboard.balanceOverTimeWidget.title');
    this.description = t('dashboard.balanceOverTimeWidget.description');
    this.icon = <MultilineChartRounded />;
    this.iconColor = 'green';
    this.id = v4();
    this.type = DashboardWidgetType.BalanceOverTimeWidget;
    this.configuration = new BalanceOverTimeWidgetConfiguration(t);
    this.layout = {
      h: 2,
      w: 3,
      i: v4(),
      x: 0,
      y: 0,
      maxH: 4,
      maxW: 6,
      minH: 2,
      minW: 2,
    };
  }
}
