import { SettingValue } from '@/modules/settings/api/settings/setting.contracts';
import React, { createContext, useState, ReactNode, useContext } from 'react';
import { UserSettingModel } from '../types/UserSettingModel';
import { userSettingService } from '../api/user-settings/user-setting.service';
import { UserSettingKey } from '../api/user-settings/user-setting.contracts';
import { ApiResponse } from '@/lib/api/api-response';
import { toast } from 'react-toastify';
import { useUser } from './UserContext';
import { isEqual } from 'lodash-es';

interface UserSettingsContextProps {
  userSettings: UserSettingModel[];
  setUserSettings: React.Dispatch<React.SetStateAction<UserSettingModel[]>>;
  loadUserSettings: (userId: number) => Promise<ApiResponse<UserSettingModel[]>>;
  getUserSettingByKey: (key: UserSettingKey) => UserSettingModel | undefined;
  getUserSettingValueByKey: <T = SettingValue>(key: UserSettingKey) => T | undefined;
  setUserSettingByKey(key: UserSettingKey, value: SettingValue, showToast: boolean): Promise<boolean>;
}

export const UserSettingsContext = createContext<UserSettingsContextProps | null>(null);

interface UserSettingsProviderProps {
  initialSettings: UserSettingModel[];
  children: ReactNode;
}

/**
 * UserSettingsProvider is used to load and store user settings.
 */
export const UserSettingsProvider: React.FC<UserSettingsProviderProps> = ({ children, initialSettings }) => {
  const [userSettings, setUserSettings] = useState<UserSettingModel[]>(initialSettings);
  const { userId } = useUser();

  async function loadUserSettings(userId: number) {
    const settings = await userSettingService.getAll(userId);
    if (settings.isSuccess) {
      setUserSettings(settings.payload);
    }
    return settings;
  }

  function getUserSettingByKey(key: UserSettingKey) {
    return userSettings.find((setting) => setting.key === key);
  }

  function getUserSettingValueByKey<T = SettingValue>(key: UserSettingKey) {
    const setting = userSettings.find((setting) => setting.key === key);
    if (setting) {
      return setting.value as T;
    }
    return undefined;
  }

  async function setUserSettingByKey(key: UserSettingKey, value: SettingValue, showToast = true): Promise<boolean> {
    if (userId) {
      const updateValueResponse = await userSettingService.updateValue(userId, key, value);
      if (updateValueResponse.isSuccess) {
        if (showToast) {
          toast.success(`Updated setting`, { position: 'top-right' });
        }

        const updatedUserSettings = userSettings.map((setting) => {
          if (setting.key === key) {
            setting.value = value;
            return setting;
          }
          return setting;
        });

        if (isEqual(updatedUserSettings, userSettings)) {
          return updateValueResponse.isSuccess; // Don't change anything if the same value
        }

        setUserSettings(updatedUserSettings);
      } else {
        if (showToast) {
          toast.error(`Error updating setting`, { position: 'top-right' });
        }
        return updateValueResponse.isSuccess;
      }
    }
    return false;
  }

  return (
    <UserSettingsContext.Provider
      value={{ userSettings, setUserSettings, loadUserSettings, getUserSettingByKey, getUserSettingValueByKey, setUserSettingByKey }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

/**
 * useUserSettings is used to access user settings
 *
 * It can only be used inside UserSettingsProvider
 */
export const useUserSettings = (): UserSettingsContextProps => {
  const context = useContext(UserSettingsContext);
  if (!context) {
    throw new Error('useUserSettings must be used within a UserSettingsProvider');
  }
  return context;
};
