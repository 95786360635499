import { useState } from 'react';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';

export const useDeleteConfirmation = ({
  questionText,
  onDelete,
  questionTitle,
}: {
  questionText: string;
  questionTitle: string;
  onDelete: (id?: number) => void;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<number | undefined>(undefined);

  const handleOpenModal = (id?: number) => {
    setIsModalOpen(true);
    setDeleteId(id);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setDeleteId(undefined);
  };

  const handleConfirmDelete = () => {
    onDelete(deleteId);
    setIsModalOpen(false);
    setDeleteId(undefined);
  };

  const ModalComponent = (
    <DeleteConfirmationModal
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      onConfirmDelete={handleConfirmDelete}
      questionText={questionText}
      questionTitle={questionTitle}
    />
  );

  return { ModalComponent, handleOpenModal };
};
