const useString = () => {
  function stringToHex(input: string): string {
    let hexResult = '';

    for (let i = 0; i < input.length; i++) {
      // Convert char to char code
      const charCode = input.charCodeAt(i);

      // Convert char code to hex and append to the result
      hexResult += charCode.toString(16).padStart(2, '0');
    }

    return hexResult;
  }

  return { stringToHex };
};

export default useString;
