import { createContext, useContext } from 'react';
import { User } from 'firebase/auth';

interface AuthUserContextType {
  authUser: User | null;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<User>;
  signUp: (email: string, password: string) => void;
  logOut: () => void;
  signInWithGoogle: () => void;
  autoLogin: () => Promise<User | null>;
  authError: string | null;
  tenantId: string | null;
}

export const AuthUserContext = createContext<AuthUserContextType>({
  authUser: null,
  loading: true,
  signIn: () => new Promise(() => null),
  signUp: () => null,
  logOut: () => null,
  signInWithGoogle: () => null,
  autoLogin: () => new Promise(() => null),
  authError: null,
  tenantId: null,
});

export const useAuthUserContext = () => useContext(AuthUserContext);
