import { IDashboardWidgetBaseConfiguration } from '../../../types/IDashboardWidgetBaseConfiguration';
import { TFunction } from 'i18next';

export class EventsOverTimeByTypeWidgetConfiguration implements IDashboardWidgetBaseConfiguration {
  title: string;

  constructor(t: TFunction) {
    this.title = t('dashboard.eventsOverTimeByTypeWidget.title');
  }
}
