import React from 'react';
import ReactDOM from 'react-dom/client';

// CSS Imports
import '@fontsource-variable/open-sans';

// AG Grid CSS
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional theme CSS
import './index.css';

// React Grid Layout
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

// Router
import { RouterProvider } from 'react-router-dom';
import { router } from './router/router';

// Libs (Internationalization, Axios, etc..)
import { initLibs } from './lib';
initLibs();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
