import { useEffect, useContext } from 'react';
import { TimerContextProps, TimerContext } from './TimerProvider';

export function useTimerEndListener(onLoopEnded: () => void) {
  const { loopCount }: TimerContextProps = useContext(TimerContext);

  useEffect(() => {
    if (loopCount > 0) {
      onLoopEnded();
    }
  }, [loopCount]);
}
