import { createContext, FunctionComponent, ReactNode, useContext, useState } from 'react';

interface TimelineItemContextProps {
  isFirst?: boolean;
  isLast?: boolean;
}

export const useTimelineItem = (initialState: TimelineItemContextProps = {}): TimelineItemContextProps => {
  const [isFirst, setIsFirst] = useState<boolean | undefined>(initialState.isFirst);
  const [isLast, setIsLast] = useState<boolean | undefined>(initialState.isLast);

  // Additional logic to manipulate isFirst and isLast can be added here

  return { isFirst, isLast };
};

const TimelineItemContext = createContext<TimelineItemContextProps | null>(null);

interface TimelineItemProviderProps {
  isFirst?: boolean;
  isLast?: boolean;
  children: ReactNode;
}

export const TimelineItemProvider: FunctionComponent<TimelineItemProviderProps> = ({ isFirst, isLast, children }) => {
  return <TimelineItemContext.Provider value={{ isFirst, isLast }}>{children}</TimelineItemContext.Provider>;
};

export const useTimelineItemContext = (): TimelineItemContextProps => {
  const context = useContext(TimelineItemContext);
  if (!context) {
    throw new Error('useTimelineItemContext must be used within a TimelineItemProvider');
  }
  return context;
};
