import { TrackerType } from '@/modules/trackers';

export enum EventType {
  Observation = 'observation',
  Incoming = 'in',
  Outgoing = 'out',
  Inventory = 'inventory',
  ADMINISTRATIVE = 'administrative',
}

export interface EventFilterOptionsDTO {
  assetId?: number;
  locationId?: number;
  dateFrom?: string;
  dateTo?: string;
  eventType?: EventType;
}

export enum GpsSourceType {
  GPS = 1,
  SIGFOX_ATLAS_NATIVE = 2,
  CERTIFIED_LOCATION_WIFI = 3,
  WIFI = 4,
  CERTIFIED_LOCATION_BLE = 5,
  CELLULAR = 6,
  LOCATION_CODE = 7,
}

export interface EventReadDTO {
  id: number;
  uuid: string;
  type: EventType;
  occurredAt: Date;
  asset: {
    id: number;
    code: string;
    location?: {
      id: number;
      name: string;
      code: string;
    };
  };
  device?: {
    id: number;
    name: string;
    code: string;
  };
  tracker: {
    type: TrackerType;
    epc?: string;
    barcode?: string;
    deviceId?: string;
  };
  location: {
    id: number;
    name: string;
    code: string;
  };
  latitude: number;
  longitude: number;
  gpsAccuracy: number;
  gpsSourceType?: GpsSourceType;
  eventSource: EventSourceType;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export enum EventSourceType {
  IMPORTER = 'IMPORTER',
  TRACKER = 'TRACKER', // Tracker - Unatag
  MANUAL = 'MANUAL',
  GATE = 'GATE',
  HANDHELD = 'HANDHELD',
  // GATE // FIXED READER - Nedap
  // HANDHELD - Posito handheld
}

export interface EventCreateDTO {
  name: string;
  code: string;
  status: EventStatusType;
  eventTypeId: number;
  trackers: number[];
}
export interface EventUpdateDTO {
  name: string;
  code: string;
  status: EventStatusType;
  eventTypeId: number;
  trackers: number[];
}

export enum EventStatusType {
  Healthy,
  Dirty,
  Damaged,
  Full,
  Empty,
}

export interface EventGroupListDto {
  uuid: string;
  occurredAt: Date;
  location: {
    id: number;
    name: string;
    code: string;
  };
  device: {
    id: number;
    name: string;
    code: string;
  };
  totalEpcScans: number;
  totalBarcodeScans: number;
  totalMutations: number;
}

export interface EventGroupAssetDto {
  id: number;
  code: string;
  assetType: {
    id: number;
    name: string;
    code: string;
  };
}

export interface EventGroupTrackerDto {
  id: number;
  type: number;
  barcode: string;
  epc: string;
  deviceId: string;
}

export interface EventGroupDto {
  uuid: string;
  occurredAt: Date;
  location: {
    id: number;
    name: string;
    code: string;
  };
  device: {
    id: number;
    name: string;
    code: string;
  };
  assets: EventGroupAssetDto[];
  trackers: EventGroupTrackerDto[];
}

export interface EventCountOverTimeDTO {
  date: string;
  count: number;
}

export interface EventCountOverTimeByTypeDTO {
  date: string;
  counts: {
    type: EventType;
    count: number;
  }[];
}

export enum EventSortOption {
  ASSET_CODE = 'asset-code',
  LOCATION_NAME = 'location-name',
  EVENT_TYPE = 'type',
  OCCURRED_AT = 'occurred-at',
  GPS_ACCURACY = 'gps-accuracy',
  GPS_SOURCE_TYPE = 'gps-source-type',
  EVENT_SOURCE = 'event-source',
  TRACKER_TYPE = 'tracker-type',
}
