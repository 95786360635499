import React, { createContext, useState, useContext, ReactNode } from 'react';
import { UserModel } from '../types/UserModel';
import { Permission } from '../submodules/roles/api/permissions.contracts';

interface UserContextProps {
  user: UserModel | undefined;
  setUser: React.Dispatch<React.SetStateAction<UserModel | undefined>>;
  firebaseId: string | undefined;
  userId: number | undefined;
  hasPermission: (permission: Permission) => boolean;
  hasAllPermissions: (permissions: Permission[]) => boolean;
  hasAnyPermission: (permissions: Permission[]) => boolean;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

interface UserProviderProps {
  initialUser: UserModel | undefined;
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children, initialUser }) => {
  const [user, setUser] = useState<UserModel | undefined>(initialUser);

  const firebaseId = user?.firebaseId;
  const userId = user?.id;

  function hasPermission(permission: Permission): boolean {
    return user?.roles.some((role) => role.hasPermission(permission)) ?? false;
  }

  function hasAllPermissions(permissions: Permission[]): boolean {
    return permissions.every((permission) => hasPermission(permission));
  }

  function hasAnyPermission(permissions: Permission[]): boolean {
    return permissions.some((permission) => hasPermission(permission));
  }

  return (
    <UserContext.Provider value={{ user, setUser, firebaseId, userId, hasPermission, hasAllPermissions, hasAnyPermission }}>
      {children}
    </UserContext.Provider>
  );
};

export function useUser(): UserContextProps {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}
