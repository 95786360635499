import { FC, useEffect, useRef, useState } from 'react';
import mapboxgl, { LngLatLike } from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { FeatureCollection } from 'geojson';
import { getBoundingBox } from '@/modules/map/lib/geo.utils';

interface LocationGeofenceEditorProps {
  polygon?: number[][];
  onPolygonDrawn: (polygon: number[][]) => void;
}

export const LocationGeofenceEditor: FC<LocationGeofenceEditorProps> = ({ polygon, onPolygonDrawn }) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [initialPaintCompleted, setInitialPaintCompleted] = useState(false);

  const draw = useRef(
    new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
      // Set mapbox-gl-draw to draw by default.
      // The user does not have to click the polygon control button first.
      // defaultMode: 'draw_polygon',
    }),
  );

  useEffect(() => {
    if (map.current) return; // Initialize map only once
    // mapboxgl.accessToken = 'YOUR_MAPBOX_ACCESS_TOKEN';

    map.current = new mapboxgl.Map({
      container: mapContainer.current!,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: !polygon ? ([4.5833, 52.0167] as LngLatLike) : undefined,

      bounds: polygon ? getBoundingBox(polygon) : undefined,

      fitBoundsOptions: {
        padding: 50,
        maxZoom: 14,
      },
    });

    map.current.on('load', function () {
      map.current!.addControl(draw.current, 'top-left');

      map.current!.on('draw.create', updateArea);

      map.current!.on('draw.update', updateArea);

      function updateArea(e: any) {
        const data = draw.current.getAll();
        console.log({ polydata: data, e });

        if (data.features.length > 0) {
          for (let i = data.features.length - 1; i >= 1; i--) {
            if (data.features[i].id !== e.features[0].id) {
              const id = data.features[i].id;
              if (id !== undefined && (typeof id === 'string' || typeof id === 'object')) {
                draw.current.delete(id);
              }
            }
          }

          if (data.features[0].geometry.type === 'Polygon') {
            const coordinates: number[][] = data.features[0].geometry.coordinates[0];
            onPolygonDrawn(coordinates);
          }
        }
      }

      drawPolygon();
      setInitialPaintCompleted(true);
    });

    map.current!.on('draw.delete', (e: any) => {
      draw.current.deleteAll();
      onPolygonDrawn([]);
    });

    map.current!.on('draw.modechange', (e) => {
      const data = draw.current.getAll();
      if (draw.current.getMode() == 'draw_polygon') {
        const pids: string[] = [];
        const lid = data.features[data.features.length - 1].id;
        data.features.forEach((f) => {
          if (f.geometry.type === 'Polygon' && f.id !== lid) {
            pids.push(f.id as string);
          }
        });
        draw.current.delete(pids);
        onPolygonDrawn([]);
      }
    });
  }, []);

  function drawPolygon() {
    console.log({ drawingPolygin: true, map: map.current, draw });

    if (!map.current) return; // Wait for map to initialize
    if (!draw.current) return;
    if (polygon && polygon.length > 0) {
      const geofenceFeature: FeatureCollection = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            id: 'example-id',
            geometry: { type: 'Polygon', coordinates: [polygon] },
          },
        ],
      };
      draw.current.set(geofenceFeature);
      zoomToPolygon();
    }
  }

  function zoomToPolygon() {
    if (!map.current) return;
    if (!polygon || polygon.length === 0) {
      return;
    }
    // Geographic coordinates of the LineString
    const bounds = getBoundingBox(polygon);
    if (!bounds) return;
    map.current.fitBounds(bounds, {
      padding: 20,
    });
  }

  useEffect(() => {
    if (!map.current) return; // Wait for map to initialize
    if (!draw.current) return;
    if (!initialPaintCompleted) return;
    console.log({ draw });
    drawPolygon();
  }, [polygon]);

  return <div ref={mapContainer} style={{ width: '100%', height: '350px' }} className="rounded-lg" />;
};
