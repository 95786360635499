import React, { createContext, useState, useContext, ReactNode } from 'react';
import LocationInfoDrawer from '../components/location-drawer/LocationInfoDrawer';

interface ILocationDrawerContext {
  locationId: number | null;
  setLocationId: (locationId: number) => void;
  openDrawer: () => void;
}

const LocationDrawerContext = createContext<ILocationDrawerContext>({
  locationId: null,
  openDrawer: () => null,
  setLocationId: () => null,
});

export const useLocationDrawerContext = () => {
  const context = useContext(LocationDrawerContext);
  //   if (!context) {
  // alert('test');
  // throw new Error('useLocationDrawerContext must be used within a LocationDrawerProvider');
  //   }
  return context;
};

export const LocationDrawerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [locationId, setLocationId] = useState<number | null>(null);
  const [isLocationDrawerOpen, setIsLocationDrawerOpen] = useState(false);

  const openDrawer = () => {
    setIsLocationDrawerOpen(true);
  };

  return (
    <LocationDrawerContext.Provider value={{ locationId, setLocationId, openDrawer }}>
      {children}
      {locationId !== null && (
        <LocationInfoDrawer locationId={locationId} isOpen={isLocationDrawerOpen} onClose={() => setIsLocationDrawerOpen(false)} />
      )}
    </LocationDrawerContext.Provider>
  );
};
