import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@mui/material';
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { dashboardService } from '../../api/dashboard/dashboard.service';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { DashboardModel } from '../../types/DashboardModel';
import { useTranslation } from '@/lib'; // Import useTranslation

interface RenameDashboardDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onDashboardRenamed: () => void;
  dashboard: DashboardModel;
}

const RenameDashboardDialog: FunctionComponent<RenameDashboardDialogProps> = ({ isOpen, onClose, onDashboardRenamed, dashboard }) => {
  const [dashboardName, setDashboardName] = useState('');
  const [isRenamingDashboard, setIsRenamingDashboard] = useState(false);
  const { t } = useTranslation(); // Use useTranslation hook

  function onDashboardNameChange(e: ChangeEvent<HTMLInputElement>) {
    setDashboardName(e.target.value);
  }

  useEffect(() => {
    setDashboardName(dashboard.name);
  }, [dashboard]);

  async function onRenameDashboardClicked() {
    setIsRenamingDashboard(true);
    const renamedDashboard = await dashboardService.update(dashboard.id, { ...dashboard, name: dashboardName });
    if (renamedDashboard.isSuccess) {
      toast.success(t('dashboard.rename.success')); // Use translation for success message
      onDashboardRenamed();
    }
    setIsRenamingDashboard(false);
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t('dashboard.rename.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('dashboard.rename.description')}</DialogContentText>
        <TextField
          className="mt-4"
          autoFocus
          margin="dense"
          id="name"
          label={t('dashboard.rename.newNameLabel')}
          type="text"
          fullWidth
          variant="standard"
          helperText={t('dashboard.rename.helperText')}
          value={dashboardName}
          onChange={onDashboardNameChange}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {t('dashboard.rename.cancel')}
        </Button>
        <LoadingButton
          loading={isRenamingDashboard}
          variant="contained"
          onClick={onRenameDashboardClicked}
          disabled={dashboardName.length < 3}
        >
          {t('dashboard.rename.renameButton')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RenameDashboardDialog;
