import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { LocationCreateFormSchema, LocationFormModel, LocationFormSchema } from './LocationFormModel';
import { zodResolver } from '@hookform/resolvers/zod';
import LocationFormDisplay from './LocationFormDisplay';
import { useConfirmationModal } from '@/components/modals/confirmation-modal/useConfirmationModal';
import { locationService } from '../../api/locations/location.service';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '@/lib';

interface LocationFormLogicProps {
  initialValues: LocationFormModel;
  onSubmit: (data: LocationFormModel) => Promise<boolean>;
  isCreate?: boolean;
  locationId?: number;
}

const LocationFormLogic: FunctionComponent<LocationFormLogicProps> = ({ initialValues, onSubmit, isCreate, locationId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const form = useForm<LocationFormModel>({
    mode: 'onSubmit',
    defaultValues: initialValues,
    resolver: zodResolver(isCreate ? LocationCreateFormSchema : LocationFormSchema),
  });

  async function onUnarchiveConfirmed(id?: number) {
    if (id) {
      setIsLoading(true);
      const response = await locationService.unarchive(id);
      if (response.isSuccess) {
        navigate(`/app/locations/${id}`);
        setIsModalOpen(false);
      } else {
        setIsLoading(false);
      }
    }
  }

  const { ModalComponent, handleOpenModal, setIsLoading, setIsModalOpen } = useConfirmationModal({
    onConfirm: onUnarchiveConfirmed,
    questionTitle: t('unarchive_location_title'),
    questionText: t('unarchive_location_text'),
    confirmButtonText: t('unarchive'),
    cancelButtonText: t('cancel'),
    closeOnConfirm: true,
  });

  const handleSubmit = async (data: LocationFormModel) => {
    if (isCreate || data.code != initialValues.code) {
      const existingLocationResponse = await locationService.getByCode(data.code);

      if (existingLocationResponse.isSuccess && existingLocationResponse.payload) {
        if (existingLocationResponse.payload.dto.deletedAt) {
          handleOpenModal(existingLocationResponse.payload.dto.id);
          return;
        } else {
          form.setError('code', {
            type: 'manual',
            message: t('location_with_code_already_exists'),
          });
          return;
        }
      }
    }

    await onSubmit(data)
      .then(() => form.reset(data))
      .catch((err) => console.error(err));
  };

  return (
    <>
      <LocationFormDisplay form={form} onSubmit={handleSubmit} isCreate={isCreate} locationId={locationId} />
      {ModalComponent}
    </>
  );
};

export default LocationFormLogic;
