import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { TrackerCreateFormSchema, TrackerFormModel, TrackerFormSchema } from './TrackerFormModel';
import { zodResolver } from '@hookform/resolvers/zod';
import TrackerFormDisplay from './TrackerFormDisplay';

interface TrackerFormLogicProps {
  initialValues: TrackerFormModel;
  onSubmit: (data: TrackerFormModel) => Promise<boolean>;
  isCreate?: boolean;
  trackerId?: number;
}

const TrackerFormLogic: FunctionComponent<TrackerFormLogicProps> = ({ initialValues, onSubmit, isCreate, trackerId }) => {
  const form = useForm<TrackerFormModel>({
    mode: 'onSubmit',
    defaultValues: initialValues,
    resolver: zodResolver(isCreate ? TrackerCreateFormSchema : TrackerFormSchema),
  });

  const handleSubmit = async (data: TrackerFormModel) => {
    await onSubmit(data)
      .then(() => form.reset(data))
      .catch((err) => console.error(err));
  };

  return <TrackerFormDisplay form={form} onSubmit={handleSubmit} isCreate={isCreate} trackerId={trackerId} />;
};

export default TrackerFormLogic;
