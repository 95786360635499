import { useDeleteConfirmation } from '@/components/modals/delete-confirmation-modal/useDeleteConfirmationModal';
import { DARK_THEME } from '@/config/theme/dark-theme';
import { BASE_THEME } from '@/config/theme/light-theme';
import { Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { FunctionComponent, PropsWithChildren, useContext } from 'react';
import { ColorModeContext } from '../context/ColorModeContext';
import { ThemeProvider } from '@mui/material';
import { useTranslation } from '@/lib';

interface ActionBarDeleteButtonProps extends PropsWithChildren {
  isVisible?: boolean;
  isLoading?: boolean;
  onDeleteConfirmed: () => void;
  deleteConfirmationQuestion: string;
}

export const ActionBarDeleteButton: FunctionComponent<ActionBarDeleteButtonProps> = ({
  isVisible,
  isLoading,
  onDeleteConfirmed,
  deleteConfirmationQuestion,
}) => {
  const colorMode = useContext(ColorModeContext);
  const theme = colorMode.isDarkMode ? DARK_THEME : BASE_THEME;
  const { t } = useTranslation();

  const { ModalComponent, handleOpenModal: openDeleteConfirmationModal } = useDeleteConfirmation({
    questionText: deleteConfirmationQuestion,
    questionTitle: t('delete'),
    onDelete: onDeleteConfirmed,
  });
  return (
    isVisible && (
      <>
        <LoadingButton
          variant="contained"
          color="error"
          type="button"
          loading={isLoading}
          startIcon={<Delete />}
          onClick={() => openDeleteConfirmationModal()}
        >
          {t('delete')}
        </LoadingButton>
        <ThemeProvider theme={theme}>{ModalComponent}</ThemeProvider>
      </>
    )
  );
};
