import { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Skeleton, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { AssetTypeModel } from '../../types/AssetTypeModel';
import { assetTypeService } from '../../api/asset-types/asset-type.service';
import { BackButton } from '@/components/BackButton';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { TabbedLayoutTopBarVerticalSeperator } from '@/modules/application/components/TabbedLayoutTopBarVerticalSeperator';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';

import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import AssetTypeFormApi from '../../components/asset-type-form/AssetTypeFormApi';
import { AssetTypeBalanceTab } from '../../components/asset-type-balance-tab/AssetTypeBalanceTab';
import { useTranslation } from '@/lib';

interface AssetTypeDetailPageProps {
  isCreate?: boolean;
}
export const AssetTypeDetailPage: FunctionComponent<AssetTypeDetailPageProps> = ({ isCreate }) => {
  const { assetTypeId } = useParams();
  const { t } = useTranslation();

  const [value, setValue] = useState('1');
  const [assetType, setAssetType] = useState<AssetTypeModel | undefined>(undefined);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (assetTypeId) {
      assetTypeService.getById(Number.parseInt(assetTypeId)).then((assetType) => {
        if (assetType.isSuccess) {
          setAssetType(assetType.payload);
        }
      });
    }
  }, [assetTypeId]);

  return (
    <TabContext value={value}>
      <TabbedLayout
        topBar={
          <TabbedLayoutTopBar>
            <BackButton pathname="../" />
            <TabbedLayoutTopBarHeader icon={null}>
              {isCreate ? t('new_asset_type') : assetType?.name ? `${t('asset_type')} - ${assetType.name}` : <Skeleton width={200} />}
            </TabbedLayoutTopBarHeader>
            <TabbedLayoutTopBarVerticalSeperator />
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={t('details')} value="1" />
              {!isCreate && <Tab label={t('balance')} value="2" />}
            </TabList>
          </TabbedLayoutTopBar>
        }
      >
        <TabbedPageLayoutBody>
          <div className="flex h-full flex-grow flex-col ">
            {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}></Box> */}
            <TabPanel className="p-0" value="1" style={{ flexGrow: 1, height: '100%' }}>
              <AssetTypeFormApi isCreate={isCreate} assetTypeId={assetTypeId ? Number.parseInt(assetTypeId) : undefined} />
            </TabPanel>
            {!isCreate && (
              <TabPanel className="p-0" value="2" style={{ flexGrow: 1, position: 'relative' }}>
                {assetTypeId && <AssetTypeBalanceTab assetTypeId={Number.parseInt(assetTypeId)}></AssetTypeBalanceTab>}
              </TabPanel>
            )}
          </div>
        </TabbedPageLayoutBody>
      </TabbedLayout>
    </TabContext>
  );
};

// interface AssetTypeDetailPageProps {
//   isCreate?: boolean;
// }

// export const AssetTypeDetailPage: FunctionComponent<AssetTypeDetailPageProps> = ({ isCreate }) => {
//   const { assetTypeId } = useParams();

//   const [value, setValue] = useState('1');
//   const [assetType, setAssetType] = useState<AssetTypeModel | undefined>(undefined);

//   const handleChange = (event: SyntheticEvent, newValue: string) => {
//     setValue(newValue);
//   };

//   useEffect(() => {
//     if (assetTypeId) {
//       assetTypeService.getById(Number.parseInt(assetTypeId)).then((assetType) => {
//         if (assetType.isSuccess) {
//           setAssetType(assetType.payload);
//         }
//       });
//     }
//   }, []);

//   return (
//     <TabContext value={value}>
//       <TabbedLayout
//         topBar={
//           <TabbedLayoutTopBar>
//             <BackButton pathname="../" />
//             <TabbedLayoutTopBarHeader icon={null}>
//               {isCreate ? 'New AssetType' : assetType?.name ? `AssetType - ${assetType.name}` : <Skeleton width={200} />}
//             </TabbedLayoutTopBarHeader>
//             <TabbedLayoutTopBarVerticalSeperator />
//             <TabList onChange={handleChange} aria-label="lab API tabs example">
//               <Tab label="Details" value="1" />
//               <Tab label="Events" value="2" />
//             </TabList>
//           </TabbedLayoutTopBar>
//         }
//       >
//         {/* <PageLayoutTopBar header={isCreate ? 'New AssetType' : `AssetType`} navigateBackPathname={'../'} /> */}
//         <TabbedPageLayoutBody>
//           <div className="flex h-full flex-grow flex-col ">
//             {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}></Box> */}
//             <TabPanel className="p-0" value="1" style={{ flexGrow: 1, height: '100%' }}>
//               <AssetTypeFormApi isCreate={isCreate} assetTypeId={assetTypeId ? Number.parseInt(assetTypeId) : undefined} />
//             </TabPanel>
//             <TabPanel className="p-0" value="2" style={{ flexGrow: 1, position: 'relative' }}>
//               {assetTypeId && <AssetTypeEventsTab assetTypeId={Number.parseInt(assetTypeId)}></AssetTypeEventsTab>}
//             </TabPanel>
//           </div>
//         </TabbedPageLayoutBody>
//       </TabbedLayout>
//     </TabContext>
//   );
// };
