import { TrackerType } from '@/modules/trackers';
import { EventReadDTO, EventSourceType, EventType, GpsSourceType } from '../api/events/event.contracts';

export interface EventArguments {
  id: number;
  uuid: string;
  type: EventType;
  occurredAt: Date;
  asset: {
    id: number;
    code: string;
    location?: {
      id: number;
      name: string;
      code: string;
    };
  };
  device?: {
    id: number;
    name: string;
    code: string;
  };
  tracker: {
    type: TrackerType;
    epc?: string;
    barcode?: string;
    deviceId?: string;
  };
  location: {
    id: number;
    name: string;
    code: string;
  };
  latitude: number;
  longitude: number;
  gpsAccuracy: number;
  gpsSourceType?: GpsSourceType;
  eventSource: EventSourceType;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export enum EventModelFieldNames {
  Id = 'id',
  Uuid = 'uuid',
  Type = 'type',
  OccurredAt = 'occurredAt',
  Asset = 'asset',
  AssetId = 'asset.id',
  AssetCode = 'asset.code',
  AssetLocationId = 'asset.location.id',
  AssetLocationName = 'asset.location.name',
  AssetLocationCode = 'asset.location.code',
  Device = 'device',
  DeviceId = 'device.id',
  DeviceName = 'device.name',
  DeviceCode = 'device.code',
  Tracker = 'tracker',
  TrackerType = 'tracker.type',
  TrackerEpc = 'tracker.epc',
  TrackerBarcode = 'tracker.barcode',
  TrackerDeviceId = 'tracker.deviceId',
  Location = 'location',
  LocationId = 'location.id',
  LocationName = 'location.name',
  LocationCode = 'location.code',
  Latitude = 'latitude',
  Longitude = 'longitude',
  GpsAccuracy = 'gpsAccuracy',
  GpsSourceType = 'gpsSourceType',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
  GpsAccuracyLevel = 'gpsAccuracyLevel',
  TrackerUniqueIdentifier = 'trackerUniqueIdentifier',
  EventSource = 'eventSource',
}

export enum GpsAccuracyLevel {
  UNKNOWN = 1,
  LOW = 2,
  MEDIUM = 3,
  HIGH = 4,
}

export class EventModel {
  id: number;
  uuid: string;
  type: EventType;
  occurredAt: Date;
  asset: {
    id: number;
    code: string;
    location?: {
      id: number;
      name: string;
      code: string;
    };
  };
  device?: {
    id: number;
    name: string;
    code: string;
  };
  tracker: {
    type: TrackerType;
    epc?: string;
    barcode?: string;
    deviceId?: string;
  };
  location: {
    id: number;
    name: string;
    code: string;
  };
  latitude: number;
  longitude: number;
  gpsAccuracy: number;
  gpsSourceType?: GpsSourceType;
  eventSource: EventSourceType;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;

  constructor(args: EventArguments) {
    this.id = args.id;
    this.uuid = args.uuid;
    this.type = args.type;
    this.occurredAt = args.occurredAt;
    this.asset = {
      id: args.asset.id,
      code: args.asset.code,
      location: args.asset.location && {
        id: args.asset.location.id,
        name: args.asset.location.name,
        code: args.asset.location.code,
      },
    };
    this.device = args.device && {
      id: args.device.id,
      name: args.device.name,
      code: args.device.code,
    };
    this.tracker = {
      type: args.tracker.type,
      epc: args.tracker.epc,
      barcode: args.tracker.barcode,
      deviceId: args.tracker.deviceId,
    };
    this.location = {
      id: args.location.id,
      name: args.location.name,
      code: args.location.code,
    };
    this.latitude = args.latitude;
    this.longitude = args.longitude;
    this.gpsAccuracy = args.gpsAccuracy;
    this.gpsSourceType = args.gpsSourceType;
    this.eventSource = args.eventSource;
    this.createdAt = args.createdAt;
    this.updatedAt = args.updatedAt;
    this.deletedAt = args.deletedAt;
  }

  get trackerUniqueIdentifier(): string | null | undefined {
    switch (this.tracker.type) {
      case TrackerType.Barcode:
        return this.tracker.barcode;

      case TrackerType.RFID:
        return this.tracker.epc;

      case TrackerType.UnaTag:
      case TrackerType.BLE:
        return this.tracker.deviceId;

      default:
        return undefined;
    }
  }

  get gpsAccuracyLevel(): GpsAccuracyLevel {
    if (!this.gpsAccuracy) {
      return GpsAccuracyLevel.UNKNOWN;
    }

    if (this.gpsSourceType === GpsSourceType.LOCATION_CODE) {
      return GpsAccuracyLevel.HIGH;
    }

    if (this.gpsAccuracy < 100) {
      return GpsAccuracyLevel.HIGH;
    } else if (this.gpsAccuracy < 1000) {
      return GpsAccuracyLevel.MEDIUM;
    } else {
      return GpsAccuracyLevel.LOW;
    }
  }

  toDTO(): EventReadDTO {
    // replace "any" with the correct type of EventReadDTO, if available
    return {
      id: this.id,
      uuid: this.uuid,
      type: this.type,
      occurredAt: this.occurredAt,
      asset: {
        id: this.asset.id,
        code: this.asset.code,
        location: this.asset.location && {
          id: this.asset.location.id,
          name: this.asset.location.name,
          code: this.asset.location.code,
        },
      },
      device: this.device && {
        id: this.device.id,
        name: this.device.name,
        code: this.device.code,
      },
      tracker: {
        type: this.tracker.type,
        epc: this.tracker.epc,
        barcode: this.tracker.barcode,
        deviceId: this.tracker.deviceId,
      },
      location: {
        id: this.location.id,
        name: this.location.name,
        code: this.location.code,
      },
      latitude: this.latitude,
      longitude: this.longitude,
      gpsAccuracy: this.gpsAccuracy,
      gpsSourceType: this.gpsSourceType,
      eventSource: this.eventSource,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt ? this.deletedAt : undefined,
    };
  }

  static fromDTO(dto: EventReadDTO): EventModel {
    // replace "any" with the correct type of EventReadDTO, if available
    return new EventModel({
      id: dto.id,
      uuid: dto.uuid,
      type: dto.type,
      occurredAt: new Date(dto.occurredAt),
      asset: {
        id: dto.asset.id,
        code: dto.asset.code,
        location: dto.asset.location && {
          id: dto.asset.location.id,
          name: dto.asset.location.name,
          code: dto.asset.location.code,
        },
      },
      device: dto.device && {
        id: dto.device.id,
        name: dto.device.name,
        code: dto.device.code,
      },
      tracker: {
        type: dto.tracker.type,
        epc: dto.tracker.epc,
        barcode: dto.tracker.barcode,
        deviceId: dto.tracker.deviceId,
      },
      location: {
        id: dto.location.id,
        name: dto.location.name,
        code: dto.location.code,
      },
      latitude: dto.latitude,
      longitude: dto.longitude,
      gpsAccuracy: dto.gpsAccuracy,
      gpsSourceType: dto.gpsSourceType,
      eventSource: dto.eventSource,
      createdAt: new Date(dto.createdAt),
      updatedAt: dto.updatedAt ? new Date(dto.updatedAt) : undefined,
      deletedAt: dto.deletedAt ? new Date(dto.deletedAt) : undefined,
    });
  }
}
