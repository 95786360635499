import { FunctionComponent, useState } from 'react';
// Assuming you're using MUI for buttons
import { CameraAltRounded } from '@mui/icons-material'; // Assuming MUI icons
import BarcodeScanModal from './BarcodeScanModal'; // Adjust the import path as needed
import { IconButton } from '@mui/material';

type ScanBarcodeButtonProps = {
  onScan: (barcode: string) => void;
};

const ScanBarcodeButton: FunctionComponent<ScanBarcodeButtonProps> = ({ onScan }) => {
  const [showBarcodeScanModal, setShowBarcodeScanModal] = useState(false);

  const handleBarcodeScan = (barcode: string) => {
    onScan(barcode); // Trigger the provided handler with the scanned barcode
    setShowBarcodeScanModal(false); // Close the modal
  };

  const handleCloseBarcodeScanModal = () => {
    setShowBarcodeScanModal(false); // Close the modal
  };

  return (
    <>
      <IconButton size="medium" color="primary" onClick={() => setShowBarcodeScanModal(true)}>
        <CameraAltRounded />
      </IconButton>
      <BarcodeScanModal onCancel={handleCloseBarcodeScanModal} onScan={handleBarcodeScan} open={showBarcodeScanModal} />
    </>
  );
};

export default ScanBarcodeButton;
