import { useEffect, useState } from 'react';
import { ImportDefinition } from '../definitions/import-definition';
import { AxiosProgressEvent } from 'axios';
import { WizardContextProps } from './ImportWizardContext';
import { round } from 'lodash-es';
import { ApiResponse } from '@/lib/api/api-response';
import { ImportPreviewResponse } from '../api/importer.contracts';
import { useTranslation } from '@/lib';

export enum ImportWizardStep {
  Upload,
  Preview,
  Import,
}

const useImportWizard = (impdef: ImportDefinition): WizardContextProps => {
  const { t } = useTranslation();
  const [importDefinition, setImportDefinition] = useState<ImportDefinition>(impdef);

  const [currentStep, setCurrentStep] = useState<ImportWizardStep>(ImportWizardStep.Upload);
  const [steps] = useState<ImportWizardStep[]>([ImportWizardStep.Upload, ImportWizardStep.Preview, ImportWizardStep.Import]);
  const [currentStepTitle, setCurrentStepTitle] = useState<string>(titleForStep(currentStep));

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);

  const [previewResponse, setPreviewResponse] = useState<ApiResponse<ImportPreviewResponse<any, any>> | null>(null);

  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [importResponse, setImportResponse] = useState<ApiResponse<boolean> | null>(null);

  const moveForward = () => {
    switch (currentStep) {
      case ImportWizardStep.Upload:
        setCurrentStep(ImportWizardStep.Preview);
        uploadFileForPreview();
        break;
      case ImportWizardStep.Preview:
        setCurrentStep(ImportWizardStep.Import);
        // Fake API call for complete step
        // setApiResponse({ data: 'Fake complete data' });
        break;
      default:
        break;
    }
  };

  const moveBackward = () => {
    switch (currentStep) {
      case ImportWizardStep.Preview:
        setCurrentStep(ImportWizardStep.Upload);
        break;
      case ImportWizardStep.Import:
        setCurrentStep(ImportWizardStep.Preview);
        break;
      default:
        break;
    }
  };

  const handleFileUpload = (file: File) => {
    setUploadedFile(file);
  };

  function titleForStep(step: ImportWizardStep) {
    switch (step) {
      case ImportWizardStep.Upload:
        return t('importer.upload.select_file');
      case ImportWizardStep.Preview:
        return t('importer.preview.preview');
      case ImportWizardStep.Import:
        return t('importer.import.import');
      default:
        return 'Unknown step';
    }
  }

  function onUploadProgress(progressEvent: AxiosProgressEvent) {
    if (progressEvent.progress) {
      setUploadProgress(round(progressEvent.progress * 100, 2));
    }

    console.log(progressEvent);
  }

  async function uploadFileForPreview() {
    if (uploadedFile) {
      setIsUploadingFile(true);
      const response = await importDefinition.preview({
        file: uploadedFile,
        onUploadProgress: onUploadProgress,
      });

      setPreviewResponse(response);

      setIsUploadingFile(false);
    }
  }

  async function startImport() {
    console.log({ previewResponse });

    if (previewResponse && previewResponse.payload.summary) {
      setIsImporting(true);
      const response = await importDefinition.import({
        summary: previewResponse.payload.summary,
      });

      setImportResponse(response);

      setIsImporting(false);
    }
  }

  useEffect(() => {
    setCurrentStepTitle(titleForStep(currentStep));
  }, [currentStep]);

  return {
    steps,
    currentStep,
    moveForward,
    moveBackward,
    uploadedFile,
    previewResponse,
    handleFileUpload,
    isUploadingFile,
    titleForStep,
    currentStepTitle,
    importDefinition,
    uploadFileForPreview,
    uploadProgress,
    isImporting,
    importResponse,
    startImport,
  };
};

export default useImportWizard;
