export enum LabelColor {
  Gray = 1,
  Yellow = 2,
  Green = 3,
  Blue = 4,
  Indigo = 5,
  Purple = 6,
  Pink = 7,
  Red = 8,
}

export interface CreateLabelDTO {
  name: string;
  values: string[];
  color: LabelColor;
}

export interface UpdateLabelDTO {
  name: string;
  color: LabelColor;
  values: UpdateLabelValueDTO[];
}

export interface UpdateLabelValueDTO {
  id?: number;
  value: string;
}

export interface ReadLabelDTO {
  id: number;
  name: string;
  color: LabelColor;
  values: ReadLabelValueDTO[];
  assignments: LabelAssignmentDTO[];
}

export interface ReadLabelValueDTO {
  id: number;
  value: string;
}

export interface LabelAssignmentDTO {
  id: number;
  entityId: string;
  entityType: string;
  value: ReadLabelValueDTO;
}

export interface LabelSearchDTO {
  searchText?: string;
  limit?: number;
}

export interface UpdateAssignmentsDTO {
  setAssignments: SetAssignmentsDTO[];
  removeAssignments: RemoveAssignmentsDTO[];
}

export interface SetAssignmentsDTO {
  labelValueId: number;
  entityType: string;
  entityIds: number[];
}

export interface RemoveAssignmentsDTO {
  labelId: number;
  entityType: string;
  entityIds: number[];
}

export enum LabelAssignmentEntityType {
  LOCATION = 'LOCATION',
}

export interface AssignedLabelDTO {
  id: number;
  name: string;
  color: LabelColor;
  value: {
    id: number;
    value: string;
  };
}
