import { FunctionComponent, ReactNode } from 'react';

interface MobilePageHeaderProps {
  title: ReactNode;
}

const MobilePageHeader: FunctionComponent<MobilePageHeaderProps> = ({ title }) => {
  return (
    <div className="sticky top-0 bg-gray-100 dark:bg-gray-900 py-1 border-b border-gray-300 dark:border-gray-700 z-10 px-3">
      <div className="text-lg font-bold">{title}</div>
    </div>
  );
};

export default MobilePageHeader;
