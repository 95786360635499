import { FunctionComponent } from 'react';

type SettingEditorProps = {
  title: string;
  description: string;
  prefixSlot?: React.ReactNode;
  children?: React.ReactNode;
};

export const SettingEditor: FunctionComponent<SettingEditorProps> = ({ title, description, prefixSlot, children }) => (
  <div className="flex items-start">
    <div className="flex w-14 shrink-0">{prefixSlot || <div>&nbsp;</div>}</div>
    <div className="flex w-full flex-col">
      <div className="font-bold">{title}</div>
      <div className="mt-1 text-gray-600 dark:text-gray-400">{description}</div>
      <div className="pt-4">{children}</div>
    </div>
  </div>
);
