import { IDashboardWidget } from '../../../types/IDashboardWidget';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { TopLocationsMetric, TopLocationsWidgetConfiguration } from './TopLocationsWidgetConfiguration';

import TopLocationsWidgetConfigurationForm from './TopLocationsWidgetConfigurationForm';
import DashboardWidgetConfigurationButton from '../../dashboard-widget/DashboardWidgetConfigurationButton';
import DashboardWidgetContent from '../../dashboard-widget/DashboardWidgetContent';
import { DashboardWidgetProvider } from '../../dashboard-widget/DashboardWidgetContextProvider';
import DashboardWidgetConfigurationDrawer from '../../dashboard-widget/DasshboardWidgetConfigurationDrawer';
import { locationService } from '@/modules/locations/api/locations/location.service';
import { useTimerEndListener } from '@/providers/timer-provider/useTimerEndListener';
import { LocationByAssetCountDTO, LocationByAssetMonetaryValueDTO } from '@/modules/locations/api/locations/location.contracts';
import DashboardWidgetTitle from '../../dashboard-widget/DashboardWidgetTitle';
import { useFormatting } from '@/hooks/useFormatting';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from '@/lib';

type TopLocationsWidgetProps = IDashboardWidget<TopLocationsWidgetConfiguration>;

const TopLocationsWidget: FunctionComponent<TopLocationsWidgetProps> = ({ configuration, id, layout }) => {
  const { formatNumber } = useFormatting();
  const [topLocationsByAssetCount, setTopLocationsByAssetCount] = useState<LocationByAssetCountDTO[]>([]);
  const [topLocationsByAssetMonetaryValue, setTopLocationsByAssetMonetaryValue] = useState<LocationByAssetMonetaryValueDTO[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();

  // Listens to the timer context
  useTimerEndListener(handleTimerEnd);

  async function fetchData() {
    switch (configuration.metric) {
      case TopLocationsMetric.assetCount: {
        const total = await locationService.getTopLocationsByAssetCount({
          assetTypeId: configuration.assetTypeId,
          labelValueIds: configuration.labelValueIDs,
        });
        if (total.isSuccess) {
          setTopLocationsByAssetCount(total.payload);
          setIsError(false);
        } else {
          setIsError(true);
        }
        setIsLoading(false);
        break;
      }

      case TopLocationsMetric.assetTotalMonetaryValue: {
        const locationsByAssetMonetaryValue = await locationService.getTopLocationsByAssetMonetaryValue({
          assetTypeId: configuration.assetTypeId,
          labelValueIds: configuration.labelValueIDs,
        });
        if (locationsByAssetMonetaryValue.isSuccess) {
          setTopLocationsByAssetMonetaryValue(locationsByAssetMonetaryValue.payload);
          setIsError(false);
        } else {
          setIsError(true);
        }
        setIsLoading(false);
        break;
      }
    }
  }
  useEffect(() => {
    fetchData();
  }, [configuration.assetTypeId, configuration.metric, configuration.labelValueIDs]);

  function handleTimerEnd() {
    fetchData();
  }

  return (
    <DashboardWidgetProvider
      widgetId={id ?? 'unknown'}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      isError={isError}
      setIsError={setIsError}
    >
      <DashboardWidgetContent>
        <DashboardWidgetTitle>{configuration.title}</DashboardWidgetTitle>
        <DashboardWidgetConfigurationButton></DashboardWidgetConfigurationButton>

        {configuration.metric === TopLocationsMetric.assetCount && topLocationsByAssetCount.length > 0 && (
          <div className="relative h-full overflow-y-auto">
            <div className="grid grid-cols-2 justify-between ">
              <div className="bg-gray-200 px-2 py-2 text-xs font-bold uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
                {t('location')}
              </div>
              <div className="bg-gray-200 px-2 py-2 text-right text-xs font-bold uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
                {t('assets')}
              </div>
              {topLocationsByAssetCount.map((location) => (
                <Fragment key={location.id}>
                  <Link
                    component={RouterLink}
                    to={`/app/locations/${location.id}`}
                    underline="hover"
                    className="whitespace-nowrap px-2 py-2 font-medium text-gray-900 dark:text-white"
                  >
                    {location.name}
                  </Link>
                  <div className=" px-2 py-2 text-right">{formatNumber(location.assetCount)}</div>
                  <div className="col-span-2 h-px bg-gray-300 dark:bg-gray-700"></div>
                </Fragment>
              ))}
            </div>
          </div>
        )}
        {configuration.metric === TopLocationsMetric.assetCount && topLocationsByAssetCount.length === 0 && (
          <div className=" flex h-full items-center justify-center ">No locations found.</div>
        )}
        {configuration.metric === TopLocationsMetric.assetTotalMonetaryValue && topLocationsByAssetMonetaryValue.length > 0 && (
          <div className="relative h-full overflow-y-auto">
            <div className="grid grid-cols-2 justify-between ">
              <div className="bg-gray-200 px-2 py-2 text-xs font-bold uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
                {t('location')}
              </div>
              <div className="bg-gray-200 px-2 py-2 text-right text-xs font-bold uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
                {t('total_value')}
              </div>
              {topLocationsByAssetMonetaryValue.map((location) => (
                <Fragment key={location.id}>
                  <Link
                    component={RouterLink}
                    to={`/app/locations/${location.id}`}
                    underline="hover"
                    className="whitespace-nowrap px-2 py-2 font-medium text-gray-900 dark:text-white"
                  >
                    {location.name}
                  </Link>
                  <div className=" px-2 py-2 text-right">{formatNumber(location.totalMonetaryValue)}</div>
                  <div className="col-span-2 h-px bg-gray-300 dark:bg-gray-700"></div>
                </Fragment>
              ))}
            </div>
          </div>
        )}
        {configuration.metric === TopLocationsMetric.assetTotalMonetaryValue && topLocationsByAssetMonetaryValue.length === 0 && (
          <div className=" flex h-full items-center justify-center ">{t('no_locations_found')}</div>
        )}
      </DashboardWidgetContent>

      <DashboardWidgetConfigurationDrawer widgetName={configuration.title}>
        <TopLocationsWidgetConfigurationForm configuration={configuration} id={id} />
      </DashboardWidgetConfigurationDrawer>
    </DashboardWidgetProvider>
  );
};

export default TopLocationsWidget;
