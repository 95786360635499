import { FunctionComponent } from 'react';

const BetaLabel: FunctionComponent = () => {
  return (
    <div className=" rounded-full border border-fuchsia-600 bg-fuchsia-500 bg-opacity-20 px-2 py-0.5 text-xs text-fuchsia-800 dark:text-fuchsia-200">
      beta
    </div>
  );
};

export default BetaLabel;
