import { Button } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { userService } from '../api/users/users.service';
import { UserModel } from '../types/UserModel';
import { useApiCall } from '@/hooks/useApiCall';
import { UserList } from '../components/UserList';
import AddOutlined from '@mui/icons-material/AddOutlined';
import { useNavigate } from 'react-router-dom';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import RequirePermissionComponent from '@/components/permissions/RequirePermissionComponent';
import { Permission } from '../submodules/roles/api/permissions.contracts';
import { useUser } from '../contexts/UserContext';
import { useTranslation } from '@/lib';

export const UsersPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<UserModel[]>([]);
  const { data, isLoading, isError, fetchData } = useApiCall<UserModel[]>(() => userService.getUsers());
  const navigate = useNavigate();
  const hasUserEditPermission = useUser().hasPermission(Permission.USER_EDIT);
  return (
    <TabbedLayout
      topBar={
        <TabbedLayoutTopBar
          actions={
            <RequirePermissionComponent permission={Permission.USER_EDIT}>
              <Button startIcon={<AddOutlined />} onClick={() => navigate('create')}>
                {t('add_user')}
              </Button>
            </RequirePermissionComponent>
          }
        >
          <TabbedLayoutTopBarHeader icon={null}>{t('users')}</TabbedLayoutTopBarHeader>
        </TabbedLayoutTopBar>
      }
    >
      <TabbedPageLayoutBody>
        {isError ? <ErrorLoadingDataAlert /> : <UserList users={data} isLoading={isLoading} isReadOnly={!hasUserEditPermission}></UserList>}
      </TabbedPageLayoutBody>
    </TabbedLayout>
  );
};
