import { useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';

interface CheckboxListProps<T> {
  items: T[];
  selectedItems: T[];
  onChange: (selectedItems: T[]) => void;
  maxSelectedItems?: number;
  getLabel: (item: T) => string; // Closure for getting the label from an item
  getValue: (item: T) => string; // Closure for getting the value (identifier) from an item
}

function CheckboxList<T>({ items, selectedItems, onChange, maxSelectedItems, getLabel, getValue }: CheckboxListProps<T>): JSX.Element {
  const [showHelperText, setShowHelperText] = useState(false);

  const handleCheckboxChange = (item: T) => {
    const itemValue = getValue(item);
    const isSelected = selectedItems.some((selectedItem) => getValue(selectedItem) === itemValue);
    let updatedSelectedItems: T[];

    if (isSelected) {
      updatedSelectedItems = selectedItems.filter((selectedItem) => getValue(selectedItem) !== itemValue);
    } else {
      if (!maxSelectedItems || selectedItems.length < maxSelectedItems) {
        updatedSelectedItems = [...selectedItems, item];
      } else {
        setShowHelperText(true);
        return;
      }
    }

    setShowHelperText(false);
    onChange(updatedSelectedItems);
  };

  return (
    <FormGroup>
      <div className="grid grid-cols-2">
        {items.map((item) => (
          <FormControlLabel
            key={getValue(item)}
            control={
              <Checkbox
                checked={selectedItems.some((selectedItem) => getValue(selectedItem) === getValue(item))}
                onChange={() => handleCheckboxChange(item)}
              />
            }
            label={getLabel(item)}
          />
        ))}
      </div>
      {showHelperText && maxSelectedItems && <FormHelperText error>You can only select up to {maxSelectedItems} items.</FormHelperText>}
    </FormGroup>
  );
}

export default CheckboxList;
