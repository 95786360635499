import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { RoleModel } from '../types/RoleModel';
import { Switch, Tooltip } from '@mui/material';
import { Permission } from '../api/permissions.contracts';
import { Lock } from '@mui/icons-material';
import { useUser } from '@/modules/users/contexts/UserContext';
import { useTranslation } from '@/lib';

type RoleEditorProps = {
  roles?: RoleModel[];
  onPermissionChanged: (role: RoleModel, permissions: Permission[], value: boolean) => void;
};

type PermissionEditorGroup = {
  name: string;
  permissions: PermissionEditItem[];
};

type PermissionEditItem = {
  name: string;
  description: string;
  permissions: Permission[];
};

const RoleEditor: FunctionComponent<RoleEditorProps> = ({ roles, onPermissionChanged }) => {
  const { t } = useTranslation();
  const hasRolesEditPermission = useUser().hasPermission(Permission.USERS_ROLES_EDIT);

  const permissionEditorGroups: PermissionEditorGroup[] = [
    {
      name: t('dashboards'),
      permissions: [
        {
          name: t('view_dashboards'),
          description: t('allows_user_to_view_dashboards'),
          permissions: [Permission.DASHBOARDS_VIEW],
        },
        {
          name: t('edit_dashboards'),
          description: t('allows_user_to_create_edit_delete_dashboards'),
          permissions: [Permission.DASHBOARDS_EDIT],
        },
      ],
    },
    {
      name: t('map'),
      permissions: [
        {
          name: t('view_map'),
          description: t('allows_user_to_view_map'),
          permissions: [Permission.MAP_VIEW],
        },
      ],
    },
    {
      name: t('balances'),
      permissions: [
        {
          name: t('view_balances'),
          description: t('allows_user_to_view_balances'),
          permissions: [Permission.BALANCES_VIEW],
        },
      ],
    },
    {
      name: t('events'),
      permissions: [
        {
          name: t('view_events'),
          description: t('allows_user_to_view_events'),
          permissions: [Permission.EVENTS_VIEW],
        },
      ],
    },
    {
      name: t('locations'),
      permissions: [
        {
          name: t('view_locations'),
          description: t('allows_user_to_view_locations'),
          permissions: [Permission.LOCATIONS_VIEW],
        },
        {
          name: t('edit_locations'),
          description: t('allows_user_to_create_edit_delete_locations'),
          permissions: [Permission.LOCATIONS_EDIT],
        },
      ],
    },
    {
      name: t('assets'),
      permissions: [
        {
          name: t('view_assets'),
          description: t('allows_user_to_view_assets'),
          permissions: [Permission.ASSETS_VIEW],
        },
        {
          name: t('edit_assets'),
          description: t('allows_user_to_create_edit_delete_assets'),
          permissions: [Permission.ASSETS_EDIT],
        },
      ],
    },
    {
      name: t('asset_types'),
      permissions: [
        {
          name: t('view_asset_types'),
          description: t('allows_user_to_view_asset_types'),
          permissions: [Permission.ASSET_TYPES_VIEW],
        },
        {
          name: t('edit_asset_types'),
          description: t('allows_user_to_create_edit_delete_asset_types'),
          permissions: [Permission.ASSET_TYPES_EDIT],
        },
      ],
    },
    {
      name: t('trackers'),
      permissions: [
        {
          name: t('view_trackers'),
          description: t('allows_user_to_view_trackers'),
          permissions: [Permission.TRACKERS_VIEW],
        },
        {
          name: t('edit_trackers'),
          description: t('allows_user_to_create_edit_delete_trackers'),
          permissions: [Permission.TRACKERS_EDIT],
        },
      ],
    },
    {
      name: t('devices'),
      permissions: [
        {
          name: t('view_devices'),
          description: t('allows_user_to_view_devices'),
          permissions: [Permission.DEVICES_VIEW],
        },
        {
          name: t('edit_devices'),
          description: t('allows_user_to_create_edit_delete_devices'),
          permissions: [Permission.DEVICES_EDIT],
        },
      ],
    },
    {
      name: t('labels'),
      permissions: [
        {
          name: t('view_labels'),
          description: t('allows_user_to_view_labels'),
          permissions: [Permission.LABELS_VIEW],
        },
        {
          name: t('edit_labels'),
          description: t('allows_user_to_create_edit_delete_labels'),
          permissions: [Permission.LABELS_EDIT],
        },
      ],
    },
    {
      name: t('event_settings'),
      permissions: [
        {
          name: t('view_event_settings'),
          description: t('allows_user_to_view_event_settings'),
          permissions: [Permission.EVENT_SETTINGS_VIEW],
        },
        {
          name: t('edit_event_settings'),
          description: t('allows_user_to_create_edit_delete_event_settings'),
          permissions: [Permission.EVENT_SETTINGS_EDIT],
        },
      ],
    },
    {
      name: t('connectors'),
      permissions: [
        {
          name: t('view_connectors'),
          description: t('allows_user_to_view_connectors'),
          permissions: [Permission.CONNECTORS_VIEW],
        },
      ],
    },
    {
      name: t('api_settings'),
      permissions: [
        {
          name: t('view_api_settings'),
          description: t('allows_user_to_view_api_settings'),
          permissions: [Permission.API_SETTINGS_VIEW],
        },
        {
          name: t('edit_api_settings'),
          description: t('allows_user_to_edit_api_settings'),
          permissions: [Permission.API_SETTINGS_EDIT],
        },
      ],
    },
    {
      name: t('users'),
      permissions: [
        {
          name: t('view_users'),
          description: t('allows_user_to_view_users'),
          permissions: [Permission.USER_VIEW],
        },
        {
          name: t('edit_users'),
          description: t('allows_user_to_edit_users'),
          permissions: [Permission.USER_EDIT],
        },
      ],
    },
    {
      name: t('roles'),
      permissions: [
        {
          name: t('view_roles'),
          description: t('allows_user_to_view_roles'),
          permissions: [Permission.USERS_ROLES_VIEW],
        },
        {
          name: t('edit_roles'),
          description: t('allows_user_to_create_edit_delete_roles'),
          permissions: [Permission.USERS_ROLES_EDIT],
        },
      ],
    },
    {
      name: t('file_importer'),
      permissions: [
        {
          name: t('use_file_importer'),
          description: t('allows_user_to_use_file_importer'),
          permissions: [Permission.FILE_IMPORTER_USE],
        },
      ],
    },
    {
      name: t('application_log'),
      permissions: [
        {
          name: t('view_application_log'),
          description: t('allows_user_to_view_application_log'),
          permissions: [Permission.APPLICATION_LOG_VIEW],
        },
      ],
    },
    {
      name: t('mobile'),
      permissions: [
        {
          name: t('mobile_connect'),
          description: t('allows_user_to_connect_trackers_to_assets_in_smartphone_view'),
          permissions: [Permission.MOBILE_CONNECT],
        },
        {
          name: t('mobile_events'),
          description: t('allows_user_to_view_events_in_smartphone_view'),
          permissions: [Permission.MOBILE_EVENTS],
        },
      ],
    },
  ];

  async function onPermissionChange(role: RoleModel, permissionGroup: PermissionEditItem, value: boolean) {
    let permissions = role.dto.permissions.map((p) => p.name);
    if (value) {
      permissions.push(...permissionGroup.permissions);
    } else {
      permissions = permissions.filter((p) => !permissionGroup.permissions.includes(p));
    }

    onPermissionChanged(role, permissions, value);

    // update localRoles
    const updatedRole = new RoleModel({
      ...role.dto,
      permissions: permissions.map((p) => ({ id: 0, name: p })),
    });

    const updatedRoles = localRoles.map((r) => (r.dto.id === role.dto.id ? updatedRole : r));
    setLocalRoles(updatedRoles);
  }

  const orderedRoles = roles?.sort((a, b) => a.dto.id - b.dto.id) ?? [];

  // keep localRoles copy in state of the roles prop
  const [localRoles, setLocalRoles] = useState<RoleModel[]>(orderedRoles ?? []);

  useEffect(() => {
    setLocalRoles(orderedRoles ?? []);
  }, [orderedRoles]);

  return (
    <div
      className="grid  rounded-md border overflow-hidden border-gray-200      dark:border-gray-600"
      style={{
        gridTemplateColumns: `1fr repeat(${localRoles?.length}, 165px)`,
      }}
    >
      {/* Header row */}
      <div className="uppercase text-xs font-semibold border-gray-200 bg-gray-100  border-b    dark:border-gray-600 dark:bg-gray-800 h-9 items-center justify-center flex">
        {t('actions')}
      </div>
      {localRoles?.map((role) => {
        return (
          <Tooltip key={role.dto.id} title={role.dto.isReadOnly ? t('read_only_role_cannot_be_edited') : null} placement="top">
            <div
              key={role.dto.id}
              className="uppercase text-xs font-semibold border-gray-200 bg-gray-100  border-b border-l  px-1 flex-nowrap text-nowrap dark:border-gray-600 dark:bg-gray-800 h-9 items-center justify-center flex"
            >
              {role.description}
              {role.dto.isReadOnly && <Lock className="ml-1" fontSize="inherit" />}
            </div>
          </Tooltip>
        );
      })}
      {permissionEditorGroups.map((group, groupIndex) => (
        <Fragment key={group.name}>
          {/* Header row */}
          <div className="uppercase text-xs font-semibold border-gray-200  dark:border-gray-600  pt-3 tracking-wider  items-end px-3 pb-2 flex dark:text-gray-400">
            {group.name}
          </div>
          {localRoles?.map((role) => (
            <Fragment key={role.dto.id}>
              <div className={`border-l border-gray-200 dark:border-gray-600  ${role.dto.isReadOnly && 'bg-gray-850 bg-opacity-50'}  `}></div>
            </Fragment>
          ))}

          {/* Data rows */}
          {group.permissions.map((permission, index) => (
            <Fragment key={permission.name}>
              <div
                className={`px-3 py-1
                ${
                  index === group.permissions.length - 1 && groupIndex < permissionEditorGroups.length - 1
                    ? 'border-b border-gray-200 dark:border-gray-600 '
                    : ''
                }
                ${index === group.permissions.length - 1 ? 'pb-3' : ''}
                
                `}
              >
                <div className="text-base font-medium">{permission.name}</div>
                <div className="text-gray-700 text-xs dark:text-gray-400 pt-0.5">{permission.description}</div>
              </div>
              {localRoles?.map((role) => (
                <Tooltip key={role.dto.id} title={role.dto.isReadOnly ? t('read_only_role_cannot_be_edited') : null} placement="top">
                  <div
                    className={`p-2 border-l flex justify-center items-center border-gray-200 dark:border-gray-600   ${
                      index === group.permissions.length - 1 && groupIndex < permissionEditorGroups.length - 1 ? 'border-b  ' : ''
                    }
                    ${role.dto.isReadOnly && 'bg-gray-850 bg-opacity-50'} 
                    `}
                  >
                    <Switch
                      color="primary"
                      disabled={role.dto.isReadOnly || !hasRolesEditPermission}
                      checked={role.hasAllPermissions(permission.permissions)}
                      onChange={() => onPermissionChange(role, permission, !role.hasAllPermissions(permission.permissions))}
                    />
                  </div>
                </Tooltip>
              ))}
            </Fragment>
          ))}
        </Fragment>
      ))}
    </div>
  );
};

export default RoleEditor;
