import LoadingBlock from '@/components/feedback/LoadingBlock';
import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import LabelFormLogic from './LabelFormLogic';
import { LabelFormModel } from './LabelFormModel';
import { useNavigate } from 'react-router-dom';
import { isNumber } from 'lodash-es';

import { ApiResponseCode } from '@/lib/api/api-response-code';
import { labelService } from '../../api/label.service';
import { LabelModel } from '../../models/LabelModel';
import { LabelColor } from '../../api/label.contracts';
import { useUser } from '@/modules/users/contexts/UserContext';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useTranslation } from '@/lib';

interface LabelFormApiProps {
  isCreate?: boolean;
  labelId?: number;
}

const LabelFormApi: FunctionComponent<LabelFormApiProps> = ({ isCreate, labelId }) => {
  const navigate = useNavigate();
  const hasLabelEditPermission = useUser().hasPermission(Permission.LABELS_EDIT);
  const { t } = useTranslation();

  const handleSubmit = async (data: LabelFormModel) => {
    if (!hasLabelEditPermission) {
      toast.error(t('label_error_permission'));
      return false;
    }
    // Process data
    if (isCreate) {
      const response = await labelService.create({
        name: data.name,
        values: data.values.map((v) => v.value),
        color: data.color,
      });

      if (!response.isSuccess) {
        if (response.responseCode === ApiResponseCode.DUPLICATE_ENTITY_NAME) {
          toast(t('label_error_duplicate'));
        } else {
          toast(t('label_errors_creating'), {
            type: 'error',
          });
        }
      } else {
        toast.success(t('label_created'));
        navigate('/app/configuration/labels/overview');
      }
    } else if (isNumber(labelId)) {
      const response = await labelService.update(labelId, {
        name: data.name,
        color: data.color,
        values: data.values.map((v) => {
          if (isNumber(v.id)) {
            return {
              id: v.id,
              value: v.value,
            };
          } else {
            return {
              value: v.value,
            };
          }
        }),
      });

      if (!response.isSuccess) {
        if (response.responseCode === ApiResponseCode.DUPLICATE_ENTITY_NAME) {
          toast(t('label_error_duplicate'));
        } else {
          toast(t('label_error_updating'), {
            type: 'error',
          });
        }
      } else {
        toast.success(t('label_updated'));
        navigate('/app/configuration/labels/overview/');
      }
    }
    return true;
  };

  if (isCreate || !labelId) {
    const newLabel: LabelFormModel = {
      name: '',
      values: [],
      color: LabelColor.Gray,
    };
    return <LabelFormLogic initialValues={newLabel} onSubmit={handleSubmit} isCreate labelId={labelId} />;
  } else {
    const { data, isLoading, isError, fetchData } = useApiCall<LabelModel>(() => labelService.getById(labelId));

    // return early if initial form data isn't loaded
    if (isLoading) return <LoadingBlock />;
    if (isError || !data) return <div>{t('no_data')}</div>;

    return (
      <LabelFormLogic
        initialValues={{
          name: data.dto.name,
          color: data.dto.color,
          values: data.dto.values.map((v) => ({
            id: v.id,
            value: v.value,
          })),
        }}
        onSubmit={handleSubmit}
        isCreate={isCreate}
        labelId={labelId}
      />
    );
  }
};

export default LabelFormApi;
