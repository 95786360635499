import { useState, useCallback } from 'react';
import { FilterValues } from './FilterBarContext';

const useFilterBar = ({
  onSearch,
  initialFilterValues = {},
}: { onSearch?: (filterValues: FilterValues) => void; initialFilterValues?: FilterValues } = {}) => {
  const [filterValues, setFilterValues] = useState<FilterValues>(initialFilterValues);

  const setFilterValue = (field: string, value: unknown) => {
    setFilterValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const triggerSearch = useCallback(() => {
    if (onSearch) {
      onSearch(filterValues);
    }
  }, [filterValues, onSearch]);

  return { filterValues, setFilterValue, triggerSearch };
};

export default useFilterBar;
