import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { FunctionComponent } from 'react';
import { SystemTotalWidgetConfiguration } from './SystemTotalWidgetConfiguration';
import { useDashboardContext } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { isNumber, startCase } from 'lodash-es';
import { SystemTotalType } from './system-total-type.enum';

interface SystemTotalWidgetConfigurationFormProps {
  configuration: SystemTotalWidgetConfiguration;
  id: string;
}

const SystemTotalWidgetConfigurationForm: FunctionComponent<SystemTotalWidgetConfigurationFormProps> = ({ configuration, id }) => {
  const { widgets, updateWidget } = useDashboardContext();

  return (
    <div className="flex flex-col gap-y-3">
      <TextField
        label="Name"
        value={configuration.title}
        onChange={(e) => {
          updateWidget<SystemTotalWidgetConfiguration>(id, {
            configuration: {
              title: e.currentTarget.value,
              systemTotalType: configuration.systemTotalType,
            },
          });
        }}
      />

      <FormControl>
        <InputLabel id="demo-simple-select-helper-label">Total</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label={'Total'}
          defaultValue={configuration.systemTotalType}
          onChange={(e) => {
            updateWidget<SystemTotalWidgetConfiguration>(id, {
              configuration: {
                title: '# ' + startCase(e.target.value as SystemTotalType),
                systemTotalType: e.target.value as SystemTotalType,
              },
            });
          }}
        >
          {Object.values(SystemTotalType).map((totalType, i) => {
            if (isNumber(totalType)) {
              return;
            }
            return (
              <MenuItem key={totalType} value={totalType} className="first-letter:uppercase">
                {startCase(totalType)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      {/* <LocationSelect
        // exludedLocationIds={[configuration.locationId]}
        selectedLocationId={configuration.type}
        onSelected={(selectedLocation) => {
          if (selectedLocation) {
            updateWidget<SystemTotalWidgetConfiguration>(id, {
              configuration: {
                title: configuration.title,
                type: selectedLocation?.type,
              },
            });
          }
        }}
        helperText={'Select a Location to filter on.'}
      /> */}
      {/* {configuration.locationId}
      <div className="whitespace-pre">{JSON.stringify(widgets)}</div> */}
    </div>
  );
};

export default SystemTotalWidgetConfigurationForm;
