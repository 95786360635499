import React, { FunctionComponent, KeyboardEvent, useContext } from 'react';
import { TextField } from '@mui/material';
import { FilterBarContext, FilterFieldName } from '../FilterBarContext';
import { Key } from '@/types/key.enum';

interface NumberFilterProps {
  label: string;
  /** The name of the filter field. Defaults to FilterFieldName `searchNumber` */
  filterFieldName?: FilterFieldName;
}

const NumberFilter: FunctionComponent<NumberFilterProps> = ({ label, filterFieldName = FilterFieldName.searchNumber }) => {
  const { setFilterValue, filterValues, triggerSearch } = useContext(FilterBarContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Allow only numeric values and empty string
    if (/^\d*$/.test(value)) {
      setFilterValue(filterFieldName, value);
    }
  };

  function onKeyDown(e: KeyboardEvent) {
    if (e.key === Key.Enter) {
      triggerSearch();
    }
  }

  return (
    <TextField
      autoComplete=""
      variant="outlined"
      type="number"
      label={label}
      value={filterValues[filterFieldName] || ''}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      fullWidth
    />
  );
};

export default NumberFilter;
