import React, { useEffect, useState } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material';

import { LabelValueModel } from '@/modules/labels/models/LabelModel';
import { labelService } from '../api/label.service';
import { InfoOutlined } from '@mui/icons-material';

interface LabelValueSelectProps {
  labelId: number;
  value: number | '' | undefined;
  onChange: (event: SelectChangeEvent<number>) => void;
  hasMixedValues?: boolean;
}

const LabelValueSelect: React.FC<LabelValueSelectProps> = ({ labelId, value, onChange, hasMixedValues }) => {
  const [labelValues, setLabelValues] = useState<LabelValueModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchLabelValues = async () => {
      setIsLoading(true);
      const response = await labelService.getById(labelId);
      if (response.isSuccess && response.payload) {
        setLabelValues(response.payload.values);
      } else {
        console.error('Failed to fetch label values:', response.message);
        setLabelValues([]);
      }
      setIsLoading(false);
    };

    if (labelId) {
      fetchLabelValues();
    }
  }, [labelId]);

  return (
    <FormControl fullWidth disabled={isLoading}>
      <InputLabel>Label Value</InputLabel>
      <Select value={value && labelValues.length > 0 ? value : ''} onChange={onChange} label="Label Value" displayEmpty={true}>
        {labelValues.map((labelValue) => (
          <MenuItem key={labelValue.dto.id} value={labelValue.dto.id}>
            {labelValue.dto.value}
          </MenuItem>
        ))}
      </Select>
      {hasMixedValues && (
        <Tooltip title="This Label has different Values assigned across the selection. Select a Value to set the same Values for the complete selection">
          <FormHelperText>
            <span className=" flex items-center">
              <span>Mixed Values</span>
              <span className="text-sm flex items-center">
                <InfoOutlined fontSize="inherit" className="ml-1" />
              </span>
            </span>
          </FormHelperText>
        </Tooltip>
      )}
    </FormControl>
  );
};

export default LabelValueSelect;
