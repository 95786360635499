import { MainMenuItemGroup } from '../types/MenuItem';
import LeaderboardRounded from '@mui/icons-material/LeaderboardRounded';
import { MapRounded, PlaceRounded, RssFeedRounded, WidgetsRounded } from '@mui/icons-material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';

export const MAIN_MENU_ITEM_GROUPS: MainMenuItemGroup[] = [
  {
    label: 'insights',
    children: [
      {
        activeRouteIds: ['/app/dashboards*'],
        label: 'dashboards',
        url: '/app/dashboards',
        icon: <LeaderboardRounded fontSize={'inherit'} />,
        permission: Permission.DASHBOARDS_VIEW,
      },
      {
        activeRouteIds: ['/app/map*'],
        label: 'map',
        url: '/app/map',
        icon: <MapRounded fontSize={'inherit'} />,
        permission: Permission.MAP_VIEW,
      },
      {
        activeRouteIds: ['/app/balances*'],
        label: 'balances',
        url: '/app/balances',
        icon: <ShowChartIcon fontSize={'inherit'} />,
        permission: Permission.BALANCES_VIEW,
      },
    ],
  },
  {
    label: 'operations',
    children: [
      {
        activeRouteIds: ['/app/events*'],
        label: 'events',
        url: '/app/events',
        icon: <RssFeedRounded fontSize={'inherit'} />,
        permission: Permission.EVENTS_VIEW,
      },
      // {
      //   activeRouteIds: ['/app/transactions*'],
      //   label: 'transactions',
      //   url: '/app/transactions',
      //   icon: <MoveDownRounded fontSize={'inherit'} />,
      // },
    ],
  },
  {
    label: 'setup',
    children: [
      {
        activeRouteIds: ['/app/locations*'],
        label: 'locations',
        url: '/app/locations',
        icon: <PlaceRounded fontSize={'inherit'} />,
        permission: Permission.LOCATIONS_VIEW,
      },
      {
        activeRouteIds: ['/app/assets*'],
        label: 'assets',
        url: '/app/assets',
        icon: <WidgetsRounded fontSize={'inherit'} />,
        permission: Permission.ASSETS_VIEW,
      },
    ],
  },
];
