import { FunctionComponent } from 'react';
import { FilterFieldName } from '../FilterBarContext';
import { useTranslation } from '@/lib';
import { TrackerType } from '@/modules/trackers';
import { TrackerTypeChip } from '@/modules/trackers/components/TrackerTypeChip';
import ListSelectFilter from './ListSelectFilter';
import { TrackerTypeSearchParam } from '@/modules/trackers/api/trackers/tracker.contracts';

const TrackerTypeFilter: FunctionComponent = () => {
  const filterFieldName = FilterFieldName.trackerType;
  const { t } = useTranslation();

  return (
    <ListSelectFilter
      label={t('tracker_type')}
      filterFieldName={filterFieldName}
      options={[TrackerTypeSearchParam.BARCODE, TrackerTypeSearchParam.BLE, TrackerTypeSearchParam.UNATAG, TrackerTypeSearchParam.RFID, null]}
      renderOption={(item) => {
        switch (item) {
          case TrackerTypeSearchParam.BARCODE:
            return <TrackerTypeChip trackerType={TrackerType.Barcode} />;
          case TrackerTypeSearchParam.BLE:
            return <TrackerTypeChip trackerType={TrackerType.BLE} />;
          case TrackerTypeSearchParam.UNATAG:
            return <TrackerTypeChip trackerType={TrackerType.UnaTag} />;
          case TrackerTypeSearchParam.RFID:
            return <TrackerTypeChip trackerType={TrackerType.RFID} />;
          default:
            return <div className="italic">{t('all')}</div>;
        }
      }}
      valueExtractor={(item) => item}
    />
  );
};

export default TrackerTypeFilter;
