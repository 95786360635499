import { FunctionComponent, ReactNode } from 'react';
import { TrackerType } from '../api/trackers/tracker.contracts';
import { FormatAlignCenter, RssFeed, Bluetooth } from '@mui/icons-material';

interface TrackerUniqueIdentifierChipProps {
  trackerType: TrackerType;
  uniqueIdentifier: string | null | undefined;
}

export const TrackerUniqueIdentifierChip: FunctionComponent<TrackerUniqueIdentifierChipProps> = ({ trackerType, uniqueIdentifier }) => {
  let icon: ReactNode;
  let iconClasses = '';
  let textClasses = '';

  switch (trackerType) {
    case TrackerType.Barcode:
      icon = <FormatAlignCenter className="h-4 w-4 rotate-90" />;
      iconClasses = 'bg-green-400 text-green-950';
      textClasses = 'bg-green-200  text-green-950';
      break;

    case TrackerType.RFID:
      icon = <RssFeed className="h-4 w-4" />;
      iconClasses = 'bg-purple-400 text-purple-950';
      textClasses = 'bg-purple-200  text-purple-950';
      break;

    case TrackerType.UnaTag:
      icon = <RssFeed className="h-4 w-4" />;
      iconClasses = 'bg-yellow-700 text-yellow-950';
      textClasses = 'bg-yellow-400 text-yellow-950';
      break;
    case TrackerType.BLE:
      icon = <Bluetooth className="h-4 w-4" />;
      iconClasses = 'bg-blue-400 text-blue-950';
      textClasses = 'bg-blue-200 text-blue-950';
  }

  return (
    <div className="flex items-stretch">
      <div className={`flex items-center justify-center rounded-l px-1 ${iconClasses}`}>{icon}</div>
      <div className={`flex items-center rounded-r  pl-1 pr-2 ${textClasses}`}>{uniqueIdentifier}</div>
    </div>
  );
};
