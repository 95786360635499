import { DTO } from '@/types/dto-wrapper';
import { MovementAsset, MovementAssetType, MovementEvent, MovementLocation, MovementReadDTO, MovementType } from '../api/movements.contracts';
import { EventSourceType, EventType } from '@/modules/events/api/events/event.contracts';

// MovementAssetTypeModel
export class MovementAssetTypeModel extends DTO<MovementAssetType> {
  constructor(dto: MovementAssetType) {
    super(dto);
  }

  get id(): number {
    return this.dto.id;
  }

  get code(): string {
    return this.dto.code;
  }

  static fromDTO(dto: MovementAssetType): MovementAssetTypeModel {
    return new MovementAssetTypeModel(dto);
  }

  toDTO(): MovementAssetType {
    return {
      id: this.id,
      code: this.code,
    };
  }
}

// MovementAssetModel
export class MovementAssetModel extends DTO<MovementAsset> {
  constructor(dto: MovementAsset) {
    super(dto);
  }

  get id(): number {
    return this.dto.id;
  }

  get code(): string {
    return this.dto.code;
  }

  get assetType(): MovementAssetTypeModel {
    return MovementAssetTypeModel.fromDTO(this.dto.assetType);
  }

  static fromDTO(dto: MovementAsset): MovementAssetModel {
    return new MovementAssetModel(dto);
  }

  toDTO(): MovementAsset {
    return {
      id: this.id,
      code: this.code,
      assetType: this.assetType.toDTO(),
    };
  }
}

// MovementLocationModel
export class MovementLocationModel extends DTO<MovementLocation> {
  constructor(dto: MovementLocation) {
    super(dto);
  }

  get id(): number {
    return this.dto.id;
  }

  get name(): string {
    return this.dto.name;
  }

  get code(): string {
    return this.dto.code;
  }

  static fromDTO(dto: MovementLocation): MovementLocationModel {
    return new MovementLocationModel(dto);
  }

  toDTO(): MovementLocation {
    return {
      id: this.id,
      name: this.name,
      code: this.code,
    };
  }
}

// MovementEventModel
export class MovementEventModel extends DTO<MovementEvent> {
  constructor(dto: MovementEvent) {
    super(dto);
  }

  get id(): number {
    return this.dto.id;
  }

  get type(): EventType {
    return this.dto.type;
  }

  get source(): EventSourceType {
    return this.dto.source;
  }

  static fromDTO(dto: MovementEvent): MovementEventModel {
    return new MovementEventModel(dto);
  }

  toDTO(): MovementEvent {
    return {
      id: this.id,
      type: this.type,
      source: this.source,
    };
  }
}

// MovementModel
export class MovementModel extends DTO<MovementReadDTO> {
  constructor(dto: MovementReadDTO) {
    super(dto);
  }

  get id(): number {
    return this.dto.id;
  }

  get occurredAt(): Date {
    return this.dto.occurredAt;
  }

  get type(): MovementType {
    return this.dto.type;
  }

  get asset(): MovementAssetModel {
    return MovementAssetModel.fromDTO(this.dto.asset);
  }

  get eventLocation(): MovementLocationModel {
    return MovementLocationModel.fromDTO(this.dto.eventLocation);
  }

  get fromLocation(): MovementLocationModel | undefined {
    return this.dto.fromLocation ? MovementLocationModel.fromDTO(this.dto.fromLocation) : undefined;
  }

  get toLocation(): MovementLocationModel | undefined {
    return this.dto.toLocation ? MovementLocationModel.fromDTO(this.dto.toLocation) : undefined;
  }

  get event(): MovementEventModel {
    return MovementEventModel.fromDTO(this.dto.event);
  }

  static fromDTO(dto: MovementReadDTO): MovementModel {
    return new MovementModel(dto);
  }

  toDTO(): MovementReadDTO {
    return {
      id: this.id,
      occurredAt: this.occurredAt,
      type: this.type,
      asset: this.asset.toDTO(),
      eventLocation: this.eventLocation.toDTO(),
      fromLocation: this.fromLocation ? this.fromLocation.toDTO() : undefined,
      toLocation: this.toLocation ? this.toLocation.toDTO() : undefined,
      event: this.event.toDTO(),
    };
  }
}
