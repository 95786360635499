import { ApiResponse } from '@/lib/api/api-response';

import { Dayjs } from 'dayjs';
import { TransactionModel } from '../../types/TransactionModel';
import { TransactionCreateDTO, TransactionReadDTO, TransactionSourceType, TransactionType } from './transaction.contracts';
import { apiClient } from '@/lib/api/base-client';

export class TransactionService {
  public basePath = 'transaction';

  async get(
    query: {
      assetId?: number;
      locationId?: number;
      dateFrom?: Dayjs;
      dateTo?: Dayjs;
      limit?: number;
    } = { limit: 1000 },
  ): Promise<ApiResponse<TransactionModel[]>> {
    try {
      console.log({ query });

      const transactions: TransactionReadDTO[] = [
        {
          id: 1,
          source: TransactionSourceType.Event,
          number: '123',
          date: new Date(),
          fromLocation: {
            id: 1,
            name: 'Hoek',
            code: 'A',
          },
          toLocation: {
            id: 2,
            name: 'UK123',
            code: 'B',
          },
          type: TransactionType.GiveOut,
          eventId: 123,
          internalNotes: 'Sample internal notes',
          mutations: [
            {
              id: 1,
              assetType: {
                id: 1,
                code: 'CC+',
              },

              quantity: 10,
              createdAt: new Date(),
            },
            {
              id: 2,
              assetType: {
                id: 2,
                code: 'Sustainable Box',
              },
              quantity: 20,
              createdAt: new Date(),
            },
            {
              id: 3,
              assetType: {
                id: 3,
                code: 'Dolly',
              },

              quantity: 30,
              createdAt: new Date(),
            },
          ],
          createdAt: new Date(),
        },
        {
          id: 2,
          source: TransactionSourceType.Event,
          number: '123',
          date: new Date(),
          fromLocation: {
            id: 1,
            name: 'Hoek',
            code: 'A',
          },
          toLocation: {
            id: 2,
            name: 'DK532',
            code: 'B',
          },
          type: TransactionType.GiveOut,
          eventId: 123,
          internalNotes: 'Sample internal notes',
          mutations: [
            {
              id: 1,
              assetType: {
                id: 1,
                code: 'CC+',
              },

              quantity: 10,
              createdAt: new Date(),
            },
            {
              id: 2,
              assetType: {
                id: 2,
                code: 'Sustainable Box',
              },
              quantity: 20,
              createdAt: new Date(),
            },
            {
              id: 3,
              assetType: {
                id: 3,
                code: 'Dolly',
              },

              quantity: 30,
              createdAt: new Date(),
            },
          ],
          createdAt: new Date(),
        },
        {
          id: 3,
          source: TransactionSourceType.Event,
          number: '123',
          date: new Date(),
          fromLocation: {
            id: 1,
            name: 'UK123',
            code: 'A',
          },
          toLocation: {
            id: 2,
            name: 'Hoek',
            code: 'B',
          },
          type: TransactionType.TakeIn,
          eventId: 123,
          internalNotes: 'Sample internal notes',
          mutations: [
            {
              id: 1,
              assetType: {
                id: 1,
                code: 'CC+',
              },

              quantity: 10,
              createdAt: new Date(),
            },
            {
              id: 2,
              assetType: {
                id: 2,
                code: 'Sustainable Box',
              },
              quantity: 20,
              createdAt: new Date(),
            },
            {
              id: 3,
              assetType: {
                id: 3,
                code: 'Dolly',
              },

              quantity: 30,
              createdAt: new Date(),
            },
          ],
          createdAt: new Date(),
        },
        ...generateTransactions(50),
        // Add more TransactionReadDTO objects here...
      ];

      return ApiResponse.responseWithPayload(
        transactions.map((transactionReadDto) => new TransactionModel(transactionReadDto)),
        200,
      );

      // const applicationResponse = await apiClient.get<TransactionReadDTO[]>(this.basePath, {
      //   ...query,
      //   dateFrom: query.dateFrom?.toISOString(),
      //   dateTo: query.dateTo?.toISOString(),
      // });
      // return applicationResponse.processPayload((payload) => {
      //   return payload.map((transactionReadDto) => new TransactionModel(transactionReadDto));
      // });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async create(transactionCreateDTO: TransactionCreateDTO): Promise<ApiResponse<TransactionModel>> {
    try {
      const applicationResponse = await apiClient.post<TransactionReadDTO>(this.basePath, transactionCreateDTO);
      return applicationResponse.processPayload((payload) => new TransactionModel(payload));
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async update(transactionId: number, transactionCreateDTO: TransactionCreateDTO): Promise<ApiResponse<TransactionModel>> {
    try {
      const applicationResponse = await apiClient.put<TransactionReadDTO>(`${this.basePath}/${transactionId}`, transactionCreateDTO);
      return applicationResponse.processPayload((payload) => new TransactionModel(payload));
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async delete(transactionId: number): Promise<ApiResponse<void>> {
    try {
      const applicationResponse = await apiClient.delete<void>(`${this.basePath}/${transactionId}`);
      return applicationResponse.processPayload(() => undefined);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getById(transactionId: number): Promise<ApiResponse<TransactionModel>> {
    try {
      const applicationResponse = await apiClient.get<TransactionReadDTO>(`${this.basePath}/${transactionId}`);
      return applicationResponse.processPayload((payload) => new TransactionModel(payload));
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const transactionService = new TransactionService();

function generateTransactions(count: number): TransactionReadDTO[] {
  const transactions: TransactionReadDTO[] = [];

  const transactionTypes = [TransactionType.Transfer, TransactionType.TakeIn, TransactionType.GiveOut];

  for (let i = 0; i < count; i++) {
    const randomTransactionType = transactionTypes[Math.floor(Math.random() * transactionTypes.length)];

    const transaction: TransactionReadDTO = {
      id: 3 + i + 1,
      source: TransactionSourceType.Event,
      number: Math.floor(Math.random() * 1000).toString(),
      date: new Date(),
      fromLocation: {
        id: Math.floor(Math.random() * 1000) + 1,
        name: `Location ${i + 1}`,
        code: String.fromCharCode(65 + (i % 26)),
      },
      toLocation: {
        id: Math.floor(Math.random() * 1000) + 1,
        name: `Location ${i + 2}`,
        code: String.fromCharCode(65 + ((i + 1) % 26)),
      },
      type: randomTransactionType,
      eventId: Math.floor(Math.random() * 1000) + 1,
      internalNotes: 'Sample internal notes',
      mutations: [
        {
          id: 1,
          assetType: {
            id: 1,
            code: 'CC+',
          },
          quantity: Math.floor(Math.random() * 100),
          createdAt: new Date(),
        },
        {
          id: 2,
          assetType: {
            id: 2,
            code: 'Sustainable Box',
          },
          quantity: Math.floor(Math.random() * 100),
          createdAt: new Date(),
        },
        {
          id: 3,
          assetType: {
            id: 3,
            code: 'Dolly',
          },
          quantity: Math.floor(Math.random() * 100),
          createdAt: new Date(),
        },
      ],
      createdAt: new Date(),
    };

    transactions.push(transaction);
  }

  return transactions;
}
