import { IDashboardWidgetCatalogItem } from '../../../types/IDashboardWidgetCatalogItem';
import { MapWidgetConfiguration } from './MapWidgetConfiguration';
import { DashboardWidgetType } from '@/modules/dashboards/types/DashboardWidgetType';
import { v4 } from 'uuid';
import { MapRounded } from '@mui/icons-material';
import { TFunction } from 'i18next'; // Make sure you import TFunction
import { DashboardWidgetCatalogItemImageColorType } from '../../widget-catalog/DashboardWidgetCatalogItemImage';
import { DASHBOARD_ROW_COUNT, DASHBOARD_COLUMN_COUNT } from '@/modules/dashboards/configuration/configuration';
import { Layout } from 'react-grid-layout';

export class MapWidgetCatalogItem implements IDashboardWidgetCatalogItem<MapWidgetConfiguration> {
  title: string;
  description: string;
  icon: JSX.Element;
  iconColor: DashboardWidgetCatalogItemImageColorType;
  id: string;
  type: DashboardWidgetType;
  configuration: MapWidgetConfiguration;
  layout: Layout;

  constructor(t: TFunction) {
    this.title = t('dashboard.mapWidget.title');
    this.description = t('dashboard.mapWidget.description');
    this.icon = <MapRounded />;
    this.iconColor = 'red';
    this.id = v4();
    this.type = DashboardWidgetType.MapWidget;
    this.configuration = new MapWidgetConfiguration(t);
    this.layout = {
      h: 7,
      w: 8,
      i: v4(),
      x: 0,
      y: 0,
      maxH: DASHBOARD_ROW_COUNT,
      maxW: DASHBOARD_COLUMN_COUNT,
      minH: 2,
      minW: 2,
    };
  }
}
