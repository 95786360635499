import React, { FunctionComponent, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Skeleton } from '@mui/material';
import { useApiCall } from '@/hooks/useApiCall';

import { PagePaginationResultDto, PageSortOption, PageSortOrder } from '@/lib/api/pagination.page.dto';
import PaginationControls from '@/components/grid/PaginationControls';
import { useExportToExcel } from '@/hooks/useExportToExcel';
import { useTranslation } from '@/lib';
import { GridApi } from 'ag-grid-community';
import { AssetsAtLocationGrid } from './AssetsAtLocationGrid';
import { locationService } from '@/modules/locations/api/locations/location.service';
import { LocationModel } from '@/modules/locations/types/LocationModel';
import { DisplayMode } from '@/types/display-mode.enum';
import { LocationAssetModel } from '@/modules/locations/types/LocationAssetModel';

interface AssetsAtLocationModalProps {
  isOpen: boolean;
  onClose: () => void;
  locationId: number;
  assetTypeId?: number;
  balanceDisplayMode?: DisplayMode;
}

const ASSET_FETCH_LIMIT = 1000; // Adjust the limit as needed

export const AssetsAtLocationModal: FunctionComponent<AssetsAtLocationModalProps> = ({
  isOpen,
  onClose,
  locationId,
  assetTypeId,
  balanceDisplayMode,
}) => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState<LocationAssetModel[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOption, setSortOption] = useState<PageSortOption | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<PageSortOrder | undefined>(undefined);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const {
    data: locationData,
    isLoading: locationIsLoading,
    isError: locationIsError,
    fetchData: fetchLocations,
  } = useApiCall<LocationModel>(() => locationService.getById(locationId));

  const getAssetsHierarchical = (page?: number) => {
    return locationService.getAssetsHierarchical(
      {
        locationId: locationId,
      },
      {
        page: currentPage,
        limit: ASSET_FETCH_LIMIT,
        order: sortDirection,
        sort: sortOption,
      },
      {
        assetTypeIds: assetTypeId ? [assetTypeId] : [],
      },
    );
  };

  const getAssetsIndividual = (page?: number) => {
    return locationService.getAssets(
      {
        locationId: locationId,
      },
      {
        page: currentPage,
        limit: ASSET_FETCH_LIMIT,
        order: sortDirection,
        sort: sortOption,
      },
      {
        assetTypeIds: assetTypeId ? [assetTypeId] : [],
      },
    );
  };

  function getAssets(displayMode: DisplayMode, page?: number) {
    if (displayMode === DisplayMode.HIERARCHICAL) {
      return getAssetsHierarchical(page);
    } else {
      return getAssetsIndividual(page);
    }
  }

  const {
    data: assetData,
    isLoading: assetIsLoading,
    isError: assetIsError,
    fetchData: fetchAssets,
    setApiCallArg,
  } = useApiCall<PagePaginationResultDto<LocationAssetModel>>(() => getAssets(balanceDisplayMode ?? DisplayMode.INDIVIDUAL));

  const { exportToExcel } = useExportToExcel();

  const onAssetsMoved = () => {
    fetchAssets();
  };

  useEffect(() => {
    setApiCallArg(() => getAssets(balanceDisplayMode ?? DisplayMode.INDIVIDUAL));
  }, [locationId, assetTypeId, currentPage, sortOption, sortDirection, balanceDisplayMode]);

  const handleExportToExcel = async () => {
    if (gridApi) {
      const allAssets: LocationAssetModel[] = [];
      let page = 1;
      let totalPages = 1;

      do {
        const response = await getAssets(balanceDisplayMode ?? DisplayMode.INDIVIDUAL, page);

        if (response.isSuccess && response.payload) {
          allAssets.push(...response.payload.data);
          totalPages = response.payload.totalPages;
          page += 1;
        } else {
          break;
        }
      } while (page <= totalPages);

      if (allAssets.length > 0) {
        exportToExcel(
          allAssets.map((asset) => ({
            code: asset.dto.assetCode,
            type: asset.dto.assetTypeName,
            arrivedAt: asset.dto.dateArrivedAtLocation,
          })),
          `assets_${locationId}_${new Date().toISOString()}.xlsx`,
        );
      }
    }
  };

  const onPageChanged = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        <div className="flex items-center">
          {t('assets_at_location')}:{' '}
          <span className="pl-1">
            {locationIsLoading ? (
              <Skeleton width={100} />
            ) : locationIsError ? (
              <span className="text-red-500 pl-1">{t('Error loading location.')}</span>
            ) : (
              <span className="font-bold"> {locationData?.dto.name}</span>
            )}
          </span>
        </div>
      </DialogTitle>
      <DialogContent dividers className="h-[70vh] flex">
        {assetIsLoading ? (
          <div className="flex justify-center p-4">
            <CircularProgress />
          </div>
        ) : assetIsError ? (
          <div className="text-red-500">{t('error_loading_assets')}</div>
        ) : (
          <div className="flex-1 flex flex-col">
            <AssetsAtLocationGrid
              data={assetData?.data}
              isError={assetIsError}
              isLoading={assetIsLoading}
              onAssetsMoved={onAssetsMoved}
              onSortChange={(sortOption, sortDirection) => {
                setSortOption(sortOption);
                setSortDirection(sortDirection);
                setCurrentPage(1); // Reset to first page on sort change
              }}
              sortOption={sortOption}
              sortDirection={sortDirection}
              selectedRows={selectedRows}
              onSelectionChanged={setSelectedRows}
              noBorder={false}
              setGridApi={setGridApi}
            />
            <div className="flex items-center justify-between mt-2">
              <PaginationControls
                isLoading={assetIsLoading}
                totalPageCount={assetData?.totalPages ?? 1}
                currentPage={currentPage}
                totalElements={assetData?.totalElements ?? 0}
                onChange={onPageChanged}
              />
              <Button variant="outlined" onClick={handleExportToExcel}>
                {t('export_to_excel')}
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  );
};
