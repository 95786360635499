import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { TrackerCreateFormSchema, TrackerModalFormModel, TrackerFormSchema } from './TrackerModalFormModel';
import { zodResolver } from '@hookform/resolvers/zod';
import TrackerModalFormDisplay from './TrackerModalFormDisplay';

interface TrackerFormLogicProps {
  initialValues: TrackerModalFormModel;
  onSubmit: (data: TrackerModalFormModel) => Promise<boolean>;
  isCreate?: boolean;
  isOpen: boolean;
  onClose: () => void;
  trackerId?: number;
}

const TrackerFormLogic: FunctionComponent<TrackerFormLogicProps> = ({ initialValues, onSubmit, isCreate, isOpen, onClose, trackerId }) => {
  const form = useForm<TrackerModalFormModel>({
    mode: 'onSubmit',
    defaultValues: initialValues,
    resolver: zodResolver(isCreate ? TrackerCreateFormSchema : TrackerFormSchema),
  });

  const handleSubmit = async (data: TrackerModalFormModel) => {
    await onSubmit(data)
      .then(() => form.reset(data))
      .catch((err) => console.error(err));
  };

  return (
    <TrackerModalFormDisplay
      isOpen={isOpen}
      onClose={onClose}
      form={form}
      onSubmit={handleSubmit}
      isCreate={isCreate}
      trackerId={trackerId}
    />
  );
};

export default TrackerFormLogic;
