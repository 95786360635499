import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { BASE_GRID_OPTIONS, PoGrid } from '@/components/grid/PoGrid';
import {
  ColumnState,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  ICellRendererParams,
  IDetailCellRendererParams,
  IGroupCellRendererParams,
  MenuItemDef,
  RowDoubleClickedEvent,
  SelectionChangedEvent,
  SortChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { BalancesAtLocationModel, BalanceAtLocationAssetTypeModel } from '../models/BalanceAtLocationModel';
import { useExportToExcel } from '@/hooks/useExportToExcel';
import dayjs from 'dayjs';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { UserSettingKey } from '@/modules/users/api/user-settings/user-setting.contracts';
import { ColumnID } from '@/components/grid/column-ids';
import { Place } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { ITooltipCellRendererParams, TooltipCellRenderer } from '@/components/grid/cells/TooltipCellRenderer';
import { DisplayMode } from '@/types/display-mode.enum';
import LocationBalanceDrawer from './LocationBalanceDrawer';
import LinkCallbackCellRenderer, { ILinkCallbackCellRendererParams } from '@/components/grid/cells/LinkCallbackCellRenderer';
import { useTranslation } from '@/lib';
import { PageSortOption, PageSortOrder } from '@/lib/api/pagination.page.dto';
import { BalanceSortOption } from '../api/balances/balance.contracts';

interface LocationsBalanceisHierarchicalGridProps {
  data?: BalancesAtLocationModel[];
  isError: boolean;
  noBorder?: boolean;
  isLoading?: boolean;
  initialSortOption?: PageSortOption;
  initialSortOrder?: PageSortOrder;
  onSortChanged: (sortOrder: SortChangedEvent<BalancesAtLocationModel>) => void;
}

export const LocationsBalanceisHierarchicalGrid: FunctionComponent<LocationsBalanceisHierarchicalGridProps> = ({
  data,
  isError,
  noBorder,
  isLoading,
  initialSortOption,
  initialSortOrder,
  onSortChanged,
}) => {
  const {
    setColumnStateGridApi,
    handleColumnStateChange,
    columnState,
    applyStateToDefinitions,
    setIsAutoSaveEnabled,
    setDefaultColumnState,
  } = useGridColumnState(UserSettingKey.BALANCE_OVERVIEW_HIERARCHICAL_COLUMN_STATE);
  const { t } = useTranslation();

  const [isLocationInfoDrawerOpen, setIsLocationInfoDrawerOpen] = useState(false);
  const [locationDrawerLocationId, setLocationDrawerLocationId] = useState<number>();
  const [gridApi, setGridApi] = useState<GridApi<BalancesAtLocationModel>>();

  const { exportToExcel } = useExportToExcel();
  const columnDefs: ColDefOrGroup<BalancesAtLocationModel>[] = useMemo(() => {
    const baseColumns: ColDefOrGroup<BalancesAtLocationModel>[] = [
      {
        cellRenderer: 'agGroupCellRenderer',
        colId: ColumnID.LOCATION_NAME,
        field: 'dto.locationName',
        headerName: t('location'),
        cellClass: 'ag-full-width-group-cell',
        cellRendererParams: (params: ICellRendererParams<BalancesAtLocationModel>): IGroupCellRendererParams => ({
          innerRenderer: LinkCallbackCellRenderer,

          innerRendererParams: {
            buttonText: params.data?.dto.locationName || '',
            callback: (rowData) => {
              setLocationDrawerLocationId(rowData.dto.locationId);
              setIsLocationInfoDrawerOpen(true);
            },
            rightSlotRenderer: () => {
              if (!params.data?.totalNumberOfChildren) return null;
              return (
                <Tooltip title={t('location_balance_helper_text', { count: params.data?.totalNumberOfChildren ?? 0 })}>
                  <div className="pl-2 text-gray-500 flex items-center gap-x-0.5">
                    <Place fontSize="inherit" />
                    {params.data?.totalNumberOfChildren}
                  </div>
                </Tooltip>
              );
            },
          } as ILinkCallbackCellRendererParams<BalancesAtLocationModel>,
        }),
        pinned: 'left',
        headerClass: 'font-bold',
        lockVisible: true,
        lockPinned: true,
        lockPosition: true,
      },
      {
        colId: ColumnID.TOTAL_BALANCE,
        field: 'totalBalance',
        headerName: t('total_balance'),
        headerClass: 'font-bold ag-right-aligned-header',
        type: 'numericColumn',
        cellRenderer: TooltipCellRenderer,
        cellRendererParams: (params: ICellRendererParams<BalancesAtLocationModel>): ITooltipCellRendererParams => ({
          tooltip: t('total_balance_helper_text', {
            balance: params.data?.dto.individualBalance ?? 0,
            children: (params.data?.totalBalance ?? 0) - (params.data?.dto.individualBalance ?? 0),
          }),
          showDashedUnderline: params.data?.isTotalBalanceDifferentFromIndividualBalances,
        }),
        lockVisible: true,
      },
      {
        colId: ColumnID.TOTAL_INCOMING,
        field: 'totalIncoming',
        headerName: `${t('incoming')} (30d)`,
        headerClass: 'font-bold ag-right-aligned-header',
        // If it's 0, show a dash instead
        valueGetter: (params: ValueGetterParams<BalancesAtLocationModel>) => params.data?.totalIncoming || '-',
        // Fix sorting so it's not sorting as string and taking '-' as the lowest value
        comparator: (a: number | string, b: number | string) => {
          if (a === '-' && b === '-') return 0;
          if (a === '-') return -1;
          if (b === '-') return 1;
          return (a as number) - (b as number);
        },
        type: 'numericColumn',
        cellRenderer: TooltipCellRenderer,
        cellRendererParams: (params: ICellRendererParams<BalancesAtLocationModel>): ITooltipCellRendererParams => ({
          tooltip: t('incoming_helper_text', {
            balance: params.data?.dto.individualIncoming ?? 0,
            children: (params.data?.totalIncoming ?? 0) - (params.data?.dto.individualIncoming ?? 0),
          }),
          showDashedUnderline: params.data?.isTotalIncomingDifferentFromIndividualIncoming,
        }),
        lockVisible: true,
      },
      {
        colId: ColumnID.TOTAL_OUTGOING,
        field: 'totalOutgoing',
        headerName: `${t('outgoing')} (30d)`,
        headerClass: 'font-bold ag-right-aligned-header',
        // If it's 0, show a dash instead
        valueGetter: (params: ValueGetterParams<BalancesAtLocationModel>) => params.data?.totalOutgoing || '-',
        // Fix sorting so it's not sorting as string and taking '-' as lowest value
        comparator: (a: number | string, b: number | string) => {
          if (a === '-' && b === '-') return 0;
          if (a === '-') return -1;
          if (b === '-') return 1;
          return (a as number) - (b as number);
        },
        type: 'numericColumn',
        cellRenderer: TooltipCellRenderer,
        cellRendererParams: (params: ICellRendererParams<BalancesAtLocationModel>): ITooltipCellRendererParams => ({
          tooltip: t('outgoing_helper_text', {
            balance: params.data?.dto.individualOutgoing ?? 0,
            children: (params.data?.totalOutgoing ?? 0) - (params.data?.dto.individualOutgoing ?? 0),
          }),
          showDashedUnderline: params.data?.isTotalOutgoingDifferentFromIndividualOutgoing,
        }),
        lockVisible: true,
      },
      // Calculate the percentage of returned items
      {
        colId: ColumnID.RETURN_RATE,
        field: 'returnRate',
        headerTooltip: t('return_rate_tooltip'),

        headerName: `${t('return_rate')} (30d)`,
        valueFormatter: (params: ValueFormatterParams<BalancesAtLocationModel>) => {
          if (params.value === 0 && !params.data?.totalIncoming) return '-';
          return `${params.value.toFixed(0)}%`;
        },

        headerClass: 'font-bold ag-right-aligned-header',
        type: 'numericColumn',
        lockVisible: true,
      },
      {
        colId: ColumnID.STAYTIME,
        field: 'totalNumberOfStaleAssets',
        headerName: `30d+ ${t('staytime')}`,
        headerClass: 'font-bold ag-right-aligned-header',
        type: 'numericColumn',
        cellRenderer: TooltipCellRenderer,
        cellRendererParams: (params: ICellRendererParams<BalancesAtLocationModel>): ITooltipCellRendererParams => ({
          tooltip: t('staytime_helper_text', {
            balance: params.data?.dto.individualStaleAssets ?? 0,
            children: (params.data?.totalNumberOfStaleAssets ?? 0) - (params.data?.dto.individualStaleAssets ?? 0),
          }),
          showDashedUnderline: params.data?.isTotalNumberOfStaleAssetsDifferentFromIndividualStaleAssets,
        }),
        lockVisible: true,
      },
      {
        colId: ColumnID.STAYTIME_60_DAYS,
        field: 'totalNumberOfStaleAssets60Days',
        headerName: `60d+ ${t('staytime')}`,
        headerClass: 'font-bold ag-right-aligned-header',
        type: 'numericColumn',
        cellRenderer: TooltipCellRenderer,
        cellRendererParams: (params: ICellRendererParams<BalancesAtLocationModel>): ITooltipCellRendererParams => ({
          tooltip: t('staytime_helper_text', {
            balance: params.data?.dto.individualStaleAssets60Days ?? 0,
            children: (params.data?.totalNumberOfStaleAssets60Days ?? 0) - (params.data?.dto.individualStaleAssets60Days ?? 0),
          }),
          showDashedUnderline: params.data?.isTotalNumberOfStaleAssets60DaysDifferentFromIndividualStaleAssets60Days,
        }),
        lockVisible: true,
      },
    ];

    if (!data) {
      return baseColumns;
    }

    const distinctAssetTypes: BalanceAtLocationAssetTypeModel[] = BalancesAtLocationModel.distinctAssetTypes(data);

    const assetTypeColumns = distinctAssetTypes.map<ColDefOrGroup<BalancesAtLocationModel>>((assetType, index) => ({
      colId: `ASSET_TYPE_BALANCE_ID_${assetType.assetTypeId}`,
      headerName: assetType.assetTypeName,
      valueGetter: (params: ValueGetterParams<BalancesAtLocationModel>) => {
        if (!params.data?.balances) return 0;
        return params.data.balances.find((balance) => balance.dto.assetTypeId === assetType.assetTypeId)?.dto.balance || 0;
      },
      flex: index === distinctAssetTypes.length - 1 ? 1 : undefined,
      minWidth: index === distinctAssetTypes.length - 1 ? 100 : undefined,
      type: 'numericColumn',
      cellRenderer: TooltipCellRenderer,
      cellRendererParams: (params: ICellRendererParams<BalancesAtLocationModel>): ITooltipCellRendererParams => {
        const assetTypeBalance = params.data?.balances.find((balance) => balance.dto.assetTypeId === assetType.assetTypeId);
        if (!assetTypeBalance || !assetTypeBalance.dto.individualBalance) return { tooltip: undefined };
        return {
          tooltip: t('total_balance_helper_text', {
            balance: assetTypeBalance.dto.individualBalance,
            children: assetTypeBalance.dto.balance - assetTypeBalance.dto.individualBalance,
          }),
          showDashedUnderline: assetTypeBalance?.isIndividualBalanceDifferentFromTotal,
        };
      },
      lockVisible: true,
    }));

    setDefaultColumnState(generateLocationBalanceTableColumnState(distinctAssetTypes));
    const columnsWithAssetTypes = [...baseColumns, ...assetTypeColumns];

    applyStateToDefinitions(columnsWithAssetTypes);

    return columnsWithAssetTypes;
  }, [data, columnState]);

  // Handle sorting changes
  const handleSortChange = (event: SortChangedEvent<BalancesAtLocationModel>) => {
    handleColumnStateChange(event); // Handle the state change for columns

    // Call the onSortChanged callback to update the external state
    onSortChanged(event);

    // Apply the sort state to the grid
    const column = event.columns && event.columns[0];
    if (column) {
      gridApi?.applyColumnState({
        state: [
          {
            colId: column.getColId(),
            sort: column.getSort(),
          },
        ],
        defaultState: { sort: null },
      });
    }
  };

  const detailCellRendererParams = useMemo<IDetailCellRendererParams<BalancesAtLocationModel>>(() => {
    return {
      // level 2 grid options
      detailGridOptions: {
        columnDefs: columnDefs,
        defaultColDef: {
          // flex: 1,
        },
        // groupDefaultExpanded: 1,

        masterDetail: true,
        detailRowHeight: 240,
        detailRowAutoHeight: true,
        onRowDoubleClicked(_: RowDoubleClickedEvent<BalancesAtLocationModel, unknown>) {
          // navigate(`../${event.data?.dto.id}`);
        },
        isRowMaster: (loc) => {
          if (!loc.children || loc.children.length < 1) {
            return false;
          } else {
            return true;
          }
        },
        rowSelection: 'multiple',
        onGridReady: (params) => {
          if (!params.api) {
            return;
          }

          // setLevel2GridApi(params.api);
        },
        onSelectionChanged: (event: SelectionChangedEvent<BalancesAtLocationModel, unknown>) => {
          console.log({
            rows: event.api.getSelectedRows(),
            EventSource: event.source,
          });

          // setLevel2SelectedRows([...event.api.getSelectedRows()]);
        },
        detailCellRendererParams: {
          // level 3 grid options
          detailGridOptions: {
            columnDefs: columnDefs,
            defaultColDef: {
              flex: 1,
            },
            domLayout: 'autoHeight',
            onRowDoubleClicked(_: RowDoubleClickedEvent<BalancesAtLocationModel, unknown>) {
              // navigate(`../${event.data?.dto.id}`);
            },
            rowSelection: 'multiple',
            onSelectionChanged: (event: SelectionChangedEvent<BalancesAtLocationModel, unknown>) => {
              console.log(event.api.getSelectedRows());
              // setLevel3SelectedRows(event.api.getSelectedRows());
            },
            onGridReady: (params) => {
              if (!params.api) {
                return;
              }

              // setLevel3GridApi(params.api);
            },
          },
          getDetailRowData: (params) => {
            params.successCallback(params.data.children ?? []);
          },
        } as IDetailCellRendererParams<BalancesAtLocationModel>,
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.children ?? []);
      },
    } as IDetailCellRendererParams<BalancesAtLocationModel>;
  }, [columnDefs]);

  const customGridOptions: GridOptions<BalancesAtLocationModel> = {
    ...BASE_GRID_OPTIONS,
    alwaysShowHorizontalScroll: true,
    alwaysShowVerticalScroll: true,
    masterDetail: true,
    detailRowHeight: 240,
    detailRowAutoHeight: true,
    detailCellRendererParams: detailCellRendererParams,
    suppressRowClickSelection: true,
    isRowMaster: (locBal) => {
      if (!locBal.children || locBal.children.length < 1) {
        return false;
      } else {
        console.log(locBal);
        return true;
      }
    },
    processUnpinnedColumns: (_) => {
      // simply don't change the order of the columns
      return [];
    },
    getContextMenuItems: (params) => {
      const result: (string | MenuItemDef)[] = [
        'copy',
        'separator',
        {
          name: t('export_excel'),
          action: () => {
            console.log('Export to Excel clicked');

            onExportToExcelClicked(params.api);
          },
        },
      ];
      return result;
    },

    // Grid column state changes
    onColumnMoved: handleColumnStateChange,
    onColumnVisible: handleColumnStateChange,
    onColumnResized: handleColumnStateChange,
    onColumnRowGroupChanged: handleColumnStateChange,
    onSortChanged: handleSortChange,
    onColumnPinned: handleColumnStateChange,
    onFirstDataRendered: onFirstDataRendered,

    onGridReady(event) {
      setGridApi(event.api);
      setColumnStateGridApi(event.api);
    },
  };

  function onFirstDataRendered(event: FirstDataRenderedEvent) {
    // Apply initial sorting if provided
    if (initialSortOption && initialSortOrder) {
      let colId: ColumnID | string | undefined;

      switch (initialSortOption) {
        case BalanceSortOption.LOCATION_NAME:
          colId = ColumnID.LOCATION_NAME;
          break;
        case BalanceSortOption.TOTAL_BALANCE:
          colId = ColumnID.TOTAL_BALANCE;
          break;
        case BalanceSortOption.INCOMING:
          colId = ColumnID.TOTAL_INCOMING;
          break;
        case BalanceSortOption.OUTGOING:
          colId = ColumnID.TOTAL_OUTGOING;
          break;
        case BalanceSortOption.RETURN_RATE:
          colId = ColumnID.RETURN_RATE;
          break;
        case BalanceSortOption.STAYTIME_30:
          colId = ColumnID.STAYTIME;
          break;
        case BalanceSortOption.STAYTIME_60:
          colId = ColumnID.STAYTIME_60_DAYS;
          break;
        default:
          colId = initialSortOption; // Should only be used for the dynamic column ids
          break;
      }

      console.log('colId', colId);

      if (colId) {
        event.api.applyColumnState({
          state: [
            {
              colId,
              sort: initialSortOrder === PageSortOrder.ASC ? 'asc' : 'desc',
            },
          ],
          defaultState: { sort: null },
        });
      }
    }
  }

  function onExportToExcelClicked(gridApi: GridApi<BalancesAtLocationModel> | undefined) {
    if (gridApi) {
      const data: BalancesAtLocationModel[] = [];
      gridApi.forEachNode((node) => {
        if (node.data) {
          data.push(node.data);
        }
      });
      if (data.length > 0) {
        exportToExcel(
          data.map((balance) => ({
            locationName: balance.dto.locationName,
            locationCode: balance.dto.locationCode,
            parentLocationCodeLevel1: balance.dto.parentLocationCode1,
            parentLocationCodeLevel2: balance.dto.parentLocationCode2,
            totalBalance: balance.totalBalance,
            totalIncoming: balance.totalIncoming,
            totalOutgoing: balance.totalOutgoing,
            returnRate: balance.returnRate,
            totalNumberOfStaleAssets: balance.totalNumberOfStaleAssets,
            totalNumberOfStaleAssets60Days: balance.totalNumberOfStaleAssets60Days,
            ...balance.balances.reduce((acc: Record<string, unknown>, balance) => {
              acc[`${balance.dto.assetTypeName}`] = balance.dto.balance;
              return acc;
            }, {}),
          })),
          `balances_${dayjs().format('YYYY_MM_DD_HHmmss')}.xlsx`,
        );
      }
    }
  }

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  return (
    <div className="flex h-full flex-1 flex-grow flex-col">
      {isError ? (
        <ErrorLoadingDataAlert />
      ) : (
        <PoGrid
          noBorder={noBorder}
          isLoading={isLoading}
          colDefs={columnDefs}
          rowData={data}
          gridOptions={customGridOptions}
          disableResizeColumnsToFit
          disableDefaultGridOptions
        />
      )}
      {locationDrawerLocationId !== undefined && (
        <LocationBalanceDrawer
          displayMode={DisplayMode.HIERARCHICAL}
          locationId={locationDrawerLocationId}
          isOpen={isLocationInfoDrawerOpen}
          onClose={() => setIsLocationInfoDrawerOpen(false)}
        />
      )}
    </div>
  );
};

function generateLocationBalanceTableColumnState(distinctAssetTypes: BalanceAtLocationAssetTypeModel[]): ColumnState[] {
  const assetTypeColumns = distinctAssetTypes.map<ColumnState>((assetType) => ({
    colId: `ASSET_TYPE_BALANCE_ID_${assetType.assetTypeId}`,
    hide: false,
  }));

  return [
    { colId: ColumnID.LOCATION_NAME, hide: false, pinned: true },
    { colId: ColumnID.TOTAL_BALANCE, hide: false },
    { colId: ColumnID.TOTAL_INCOMING, hide: false },
    { colId: ColumnID.TOTAL_OUTGOING, hide: false },
    { colId: ColumnID.RETURN_RATE, hide: false },
    { colId: ColumnID.STAYTIME, hide: false },
    { colId: ColumnID.STAYTIME_60_DAYS, hide: false },
    ...assetTypeColumns,
  ];
}
