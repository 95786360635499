export enum DashboardWidgetType {
  CountWidget = 'CountWidget',
  MapWidget = 'MapWidget',
  SystemTotalWidget = 'SystemTotalWidget',
  TopLocationsWidget = 'TopLocationsWidget',
  TopAssetTypesWidget = 'TopAssetTypesWidget',
  EventsOverTimeWidget = 'EventsOverTimeWidget',
  BalanceOverTimeWidget = 'BalancesOverTimeWidget',
  EventsOverTimeByTypeWidget = 'EventsOverTimeByTypeWidget',
}
