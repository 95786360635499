import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';
import { RoleModel } from '../types/RoleModel';
import { RoleDTO } from './roles.contracts';
import { Permission } from './permissions.contracts';

export class RoleService {
  public basePath = 'roles';

  async getAllRoles(): Promise<ApiResponse<RoleModel[]>> {
    try {
      const applicationResponse = await apiClient.get<RoleDTO[]>(this.basePath + '/');
      return applicationResponse.processPayload((payload) => {
        return payload.map((roleDto) => {
          return new RoleModel(roleDto);
        });
      });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async updateRolePermissions({ roleId, permissions }: { roleId: number; permissions: Permission[] }): Promise<ApiResponse<RoleModel>> {
    try {
      const applicationResponse = await apiClient.put<RoleDTO>(`${this.basePath}/${roleId}/permissions`, {
        permissions: permissions,
      });
      return applicationResponse.processPayload((roleDto) => {
        return new RoleModel(roleDto);
      });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const roleService = new RoleService();
