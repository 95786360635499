export interface AssetTypeReadDTO {
  id: number;
  name: string;
  code: string;
  description: string;
  widthInMm: number;
  heightInMm: number;
  depthInMm: number;
  volumeInCubicMm: number;
  modelYear: number;
  monetaryValue?: number;
  count: number;
  imageUrl?: string;
  imageThumbnailUrl?: string;
  deletedAt: string | null;
}
export interface AssetTypeUpdateDTO {
  name: string;
  code: string;
  description?: string;
  widthInMm?: number;
  heightInMm?: number;
  depthInMm?: number;
  volumeInCubicMm?: number;
  modelYear?: number;
  monetaryValue?: number;
}
export interface AssetTypeCreateDTO {
  name: string;
  code: string;
  description?: string;
  widthInMm?: number;
  heightInMm?: number;
  depthInMm?: number;
  volumeInCubicMm?: number;
  modelYear?: number;
  monetaryValue?: number;
}

export interface AssetByAssetCountDTO {
  id: number;
  name: string;
  code: string;
  count: number;
  totalAssetValue: number;
}

export interface AssetByAssetMonetaryValueDTO {
  id: number;
  name: string;
  code: string;
  count: number;
  totalAssetValue: number;
}

export interface ReadAssetTypeBalanceDTO {
  locationId: number;
  locationName: string;
  locationCode: string;
  currentBalance: number;
}

export enum AssetMetricType {
  MONETARY_VALUE = 'MONETARY_VALUE',
  COUNT = 'COUNT',
}
