import { FunctionComponent, PropsWithChildren, useContext } from 'react';
import { DashboardWidgetContext } from './DashboardWidgetContextProvider';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import ErrorBlock from '@/components/feedback/ErrorBlock';

interface DashboardWidgetContentProps extends PropsWithChildren {}

const DashboardWidgetContent: FunctionComponent<DashboardWidgetContentProps> = ({ children }) => {
  const { isLoading, isError } = useContext(DashboardWidgetContext);

  if (isLoading) {
    return <LoadingBlock />;
  }

  if (isError) {
    return <ErrorBlock />;
  }

  return <div className="relative flex  h-full w-full flex-col">{children}</div>;
};

export default DashboardWidgetContent;
