import { Button } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useContext } from 'react';
import { useDashboardContext } from '../../contexts/DashboardContextProvider';
import { DashboardWidgetContext } from './DashboardWidgetContextProvider';
import { useTranslation } from '@/lib';

export function DashboardWidgetConfigurationDrawerDeleteButton() {
  const { widgetId } = useContext(DashboardWidgetContext);
  const { removeWidget } = useDashboardContext();
  const { t } = useTranslation();

  function onDeleteWidgetClicked() {
    if (widgetId) {
      removeWidget(widgetId);
    }
  }

  return (
    <Button color="error" className="w-full" size={'large'} startIcon={<Delete></Delete>} onClick={onDeleteWidgetClicked}>
      {t('dashboard.widget.deleteButton')}
    </Button>
  );
}
