import { CloseRounded } from '@mui/icons-material';
import { FunctionComponent, useState, useEffect } from 'react';

export const ErrorCircle: FunctionComponent = () => {
  const [showBorder, setShowBorder] = useState(true);

  useEffect(() => {
    // Fade in the border when the component is mounted
    setShowBorder(true);

    // Fade out the border after a few seconds (e.g., 3 seconds)
    const timeout = setTimeout(() => {
      setShowBorder(false);
    }, 2000);

    // Cleanup the timeout when the component is unmounted
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="relative float-left m-2.5 h-24 w-24">
      <div
        className={`absolute h-full w-full animate-spin rounded-full  bg-red-500 bg-opacity-30 bg-clip-content p-1 transition-opacity duration-500`}
      ></div>
      <div
        className={`block h-full w-full animate-spin rounded-full border-2 border-dashed border-red-500 bg-red-500 bg-opacity-30 bg-clip-content p-1 transition-opacity duration-1000 ${
          showBorder ? 'opacity-100' : 'opacity-0'
        }`}
      ></div>
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-red-300">
        <CloseRounded className="h-12 w-12" />
      </div>
    </div>
  );
};
