import { FunctionComponent, PropsWithChildren } from 'react';

type TabbedPageLayoutBodyProps = PropsWithChildren;

export const TabbedPageLayoutBody: FunctionComponent<TabbedPageLayoutBodyProps> = ({ children }) => {
  return (
    <div className="relative flex w-full flex-1 flex-grow flex-col items-stretch  ">
      <div className="absolute inset-0 overflow-y-auto  p-4 ">{children}</div>
    </div>
  );
};
