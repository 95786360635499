import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent, useState } from 'react';
import { AssetTypeTotalModel } from '@/modules/asset-types';
import { DisplayMode } from '@/types/display-mode.enum';
import LocationHierarchyToggleButton from '../../LocationHierarchyToggleButton';
import { locationService } from '@/modules/locations/api/locations/location.service';
import { LocationBalanceGrid } from './LocationBalanceGrid';

interface LocationBalanceTabProps {
  locationId: number;
}

export const LocationBalanceTab: FunctionComponent<LocationBalanceTabProps> = ({ locationId }) => {
  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.INDIVIDUAL);
  const { data, isLoading, isError } = useApiCall<AssetTypeTotalModel[]>(() => locationService.getBalance({ locationId: locationId }));

  return (
    <div className="flex flex-col h-full flex-1 flex-grow gap-x-4  max-w-[1920px] mt-2 ">
      <div className="flex items-center  justify-end w-full">
        <div className="text-xs flex items-center scale-75 origin-right">
          <LocationHierarchyToggleButton displayMode={displayMode} onChange={setDisplayMode} reverseOrder />
        </div>
      </div>

      <LocationBalanceGrid
        locationId={locationId}
        data={data}
        isError={isError}
        isLoading={isLoading}
        balanceDisplayMode={displayMode}
      ></LocationBalanceGrid>
    </div>
  );
};
