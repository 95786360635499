import { IDashboardWidgetCatalogItem } from '../../../types/IDashboardWidgetCatalogItem';
import { TopLocationsWidgetConfiguration } from './TopLocationsWidgetConfiguration';
import { DashboardWidgetType } from '@/modules/dashboards/types/DashboardWidgetType';
import { v4 } from 'uuid';
import { Place } from '@mui/icons-material';
import { TFunction } from 'i18next';
import { DashboardWidgetCatalogItemImageColorType } from '../../widget-catalog/DashboardWidgetCatalogItemImage';
import { Layout } from 'react-grid-layout';

export class TopLocationsWidgetCatalogItem implements IDashboardWidgetCatalogItem<TopLocationsWidgetConfiguration> {
  title: string;
  description: string;
  icon: JSX.Element;
  iconColor: DashboardWidgetCatalogItemImageColorType;
  id: string;
  type: DashboardWidgetType;
  configuration: TopLocationsWidgetConfiguration;
  layout: Layout;

  constructor(t: TFunction) {
    this.title = t('dashboard.topLocationsWidget.title');
    this.description = t('dashboard.topLocationsWidget.description');
    this.icon = <Place />;
    this.iconColor = 'blue';
    this.id = v4();
    this.type = DashboardWidgetType.TopLocationsWidget;
    this.configuration = new TopLocationsWidgetConfiguration(t);
    this.layout = {
      h: 5,
      w: 3,
      i: v4(),
      x: 0,
      y: 0,
      maxH: 5,
      maxW: 4,
      minH: 3,
      minW: 2,
    };
  }
}
