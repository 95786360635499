import { DashboardReadDTO } from '../api/dashboard/dashboard.contracts';
import { IDashboardWidget } from './IDashboardWidget';
import { IDashboardWidgetBaseConfiguration } from './IDashboardWidgetBaseConfiguration';

// DashboardArguments Interface
export interface DashboardArguments {
  id: number;
  name: string;
  widgets: IDashboardWidget<IDashboardWidgetBaseConfiguration>[];
}

// DashboardFieldNames enum
export enum DashboardModelFieldNames {
  Id = 'id',
  Name = 'name',
  Widgets = 'widgets',
}

// Dashboard Class
export class DashboardModel {
  id: number;
  name: string;
  widgets: IDashboardWidget<IDashboardWidgetBaseConfiguration>[];

  constructor(args: DashboardArguments) {
    this.id = args.id;
    this.name = args.name;
    this.widgets = args.widgets;
  }

  toDTO(): DashboardReadDTO {
    return {
      id: this.id,
      name: this.name,
      widgets: this.widgets,
    };
  }

  static fromDTO(dto: DashboardReadDTO): DashboardModel {
    return new DashboardModel({
      id: dto.id,
      name: dto.name,
      widgets: dto.widgets,
    });
  }
}
