import { FunctionComponent, PropsWithChildren, ReactElement } from 'react';
import { Box } from '@mui/material';

interface LabelProps extends PropsWithChildren {
  color: 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
  variant: 'filled' | 'outlined' | 'ghost' | 'soft';
  startIcon?: ReactElement;
  endIcon?: ReactElement;
}

export const Label: FunctionComponent<LabelProps> = ({ variant = 'soft', endIcon, startIcon, color, children }) => {
  const iconStyle = {
    width: 16,
    height: 16,
    '& svg, img': { width: 1, height: 1, objectFit: 'cover' },
  };

  return (
    <div
      className={`inline-flex h-5 items-center justify-center  rounded-md bg-gray-700 px-2 text-xs font-semibold leading-none tracking-wide
      ${variant === 'soft' ? 'bg-opacity-20' : ''}
      ${color === 'primary' ? 'bg-primary-600 text-primary-600' : ''}
      ${color === 'success' ? 'bg-green-600 text-green-600' : ''}
      ${color === 'info' ? 'bg-blue-600 text-blue-600' : ''}
      ${color === 'error' ? 'bg-red-600 text-red-600' : ''}
      ${color === 'warning' ? 'bg-yellow-600 text-yellow-600' : ''}
      `}
      style={{ minWidth: '22px' }}
    >
      {startIcon && <Box sx={{ mr: 0.75, ...iconStyle }}> {startIcon} </Box>}

      {children}

      {endIcon && <Box sx={{ ml: 0.75, ...iconStyle }}> {endIcon} </Box>}
    </div>
  );
};
