import { Outlet, useNavigate } from 'react-router-dom';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import { MoveDownRounded } from '@mui/icons-material';
import { Button } from '@mui/material';

export default function TransactionsLayout() {
  const navigate = useNavigate();
  const eventTabItems = [
    {
      activeRouteIds: ['/app/events/overview'],
      label: 'Overview',
      url: '/app/events/overview',
    },
  ];

  return (
    <TabbedLayout
      topBar={
        <TabbedLayoutTopBar
          header="Transactions"
          headerIcon={<MoveDownRounded fontSize={'small'} />}
          actions={
            <Button variant="contained" onClick={() => navigate({ pathname: '../create' })}>
              Create
            </Button>
          }
          tabItems={eventTabItems}
        />
      }
    >
      <Outlet />
    </TabbedLayout>
  );
}
