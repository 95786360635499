import { MainMenu } from '../components/MainMenu';
import { Outlet } from 'react-router';
import { MAIN_MENU_ITEM_GROUPS } from '../configuration/main-menu.configuration';
import { FunctionComponent } from 'react';
import { LocationDrawerProvider } from '@/modules/locations/contexts/LocationDrawerContext';

export const ApplicationLayout: FunctionComponent = () => {
  return (
    <LocationDrawerProvider>
      <div className="flex min-h-screen w-full">
        <MainMenu mainMenuItemGroups={MAIN_MENU_ITEM_GROUPS} />

        <div className="flex-grow">
          <Outlet />
        </div>
      </div>
    </LocationDrawerProvider>
  );
};
