import { TFunction } from 'i18next';
import { IDashboardWidgetCatalogItem } from '../../types/IDashboardWidgetCatalogItem';
import { BalanceOverTimeWidgetCatalogItem } from './balance-over-time-widget/BalanceOverTimeWidgetCatalogItem';
import { CountWidgetCatalogItem } from './count-widget/CountWidgetCatalogItem';
import { EventsOverTimeByTypeWidgetCatalogItem } from './events-over-time-by-type-widget /EventsOverTimeByTypeWidgetCatalogItem';
import { MapWidgetCatalogItem } from './map-widget/MapWidgetCatalogItem';
import { SystemTotalWidgetCatalogItem } from './system-total-widget/SystemTotalWidgetCatalogItem';
import { TopAssetTypesWidgetCatalogItem } from './top-assets-widget/TopAssetTypesWidgetCatalogItem';
import { TopLocationsWidgetCatalogItem } from './top-locations-widget/TopLocationsWidgetCatalogItem';
import { EventsOverTimeWidgetCatalogItem } from './events-over-time-widget/EventsOverTimeWidgetCatalogItem';
import { DashboardWidgetType } from '../../types/DashboardWidgetType';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function generateWidgetCatalog(t: TFunction): IDashboardWidgetCatalogItem<any>[] {
  return [
    new CountWidgetCatalogItem(t),
    new MapWidgetCatalogItem(t),
    new SystemTotalWidgetCatalogItem(t),
    new TopLocationsWidgetCatalogItem(t),
    new TopAssetTypesWidgetCatalogItem(t),
    new EventsOverTimeWidgetCatalogItem(t),
    new EventsOverTimeByTypeWidgetCatalogItem(t),
    new BalanceOverTimeWidgetCatalogItem(t),
  ];
}

export class WidgetCatalogFactory {
  public static createWidgetFromType(type: DashboardWidgetType, t: TFunction) {
    switch (type) {
      case DashboardWidgetType.CountWidget:
        return new CountWidgetCatalogItem(t);
      case DashboardWidgetType.MapWidget:
        return new MapWidgetCatalogItem(t);
      case DashboardWidgetType.SystemTotalWidget:
        return new SystemTotalWidgetCatalogItem(t);
      case DashboardWidgetType.TopLocationsWidget:
        return new TopLocationsWidgetCatalogItem(t);
      case DashboardWidgetType.TopAssetTypesWidget:
        return new TopAssetTypesWidgetCatalogItem(t);
      case DashboardWidgetType.EventsOverTimeWidget:
        return new EventsOverTimeWidgetCatalogItem(t);
      case DashboardWidgetType.EventsOverTimeByTypeWidget:
        return new EventsOverTimeByTypeWidgetCatalogItem(t);
      case DashboardWidgetType.BalanceOverTimeWidget:
        return new BalanceOverTimeWidgetCatalogItem(t);
      default:
        throw new Error(`Unknown widget type: ${type}`);
    }
  }
}
