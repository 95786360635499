import { ButtonBase } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';

interface MobileMainMenuButtonProps {
  isActive: boolean;
  label: string;
  icon: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const MobileMainMenuButton: FunctionComponent<MobileMainMenuButtonProps> = ({ onClick, icon, label, isActive }) => {
  return (
    <ButtonBase
      onClick={onClick}
      component="div"
      className={`inline-flex flex-1 flex-col items-center justify-center px-5   group  
      ${
        isActive
          ? 'text-primary-600 dark:text-primary-500'
          : 'bg-transparent text-gray-700 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700'
      }
      `}
    >
      <div className={`px-5 mt-1 mb-0.5 py-0.5 rounded-full  ${isActive ? 'bg-gray-200 dark:bg-gray-700' : 'bg-transparent'}`}>
        <div className="text-base flex items-center justify-center  group-hover:text-primary-600 dark:group-hover:text-primary-500 ">
          {icon}
        </div>
      </div>
      <span className="text-xs  group-hover:text-primary-600 dark:group-hover:text-primary-500">{label}</span>
    </ButtonBase>
  );
};

export default MobileMainMenuButton;
