import { FC } from 'react';

type SettingsGroupProps = {
  title: string;
  subtitle: string;
  children: React.ReactNode;
};

export const SettingsGroup: FC<SettingsGroupProps> = ({ title, subtitle, children }) => (
  <div className="flex justify-start">
    <div className="flex w-1/3 flex-col">
      <div className="font-bold ">{title}</div>
      <div className="mt-1 text-gray-600 dark:text-gray-400">{subtitle}</div>
    </div>
    <div className="flex w-2/3 flex-col gap-y-2">{children}</div>
  </div>
);
