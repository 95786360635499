import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';

import { DeviceCreateDTO, DeviceReadDTO, DeviceUpdateDTO } from './device.contracts';
import { DeviceModel } from '../../types/DeviceModel';

export class DeviceService {
  public basePath = 'device';

  async create(deviceToCreate: DeviceCreateDTO): Promise<ApiResponse<undefined>> {
    try {
      const applicationResponse = await apiClient.post<undefined>(this.basePath + '/', deviceToCreate);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async update(deviceId: number, deviceToUpdate: DeviceUpdateDTO): Promise<ApiResponse<undefined>> {
    try {
      const applicationResponse = await apiClient.patch<undefined>(`${this.basePath}/${deviceId}`, deviceToUpdate);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getAll(): Promise<ApiResponse<DeviceModel[]>> {
    try {
      const applicationResponse = await apiClient.get<DeviceReadDTO[]>(this.basePath);
      return applicationResponse.processPayload((payload) => {
        return payload.map(DeviceModel.fromDTO);
      });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getById(id: number): Promise<ApiResponse<DeviceModel>> {
    try {
      const applicationResponse = await apiClient.get<DeviceReadDTO>(`${this.basePath}/${id}`);
      return applicationResponse.processPayload(DeviceModel.fromDTO);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getByQuery(query: string): Promise<ApiResponse<DeviceModel[]>> {
    try {
      const applicationResponse = await apiClient.get<DeviceReadDTO[]>(`${this.basePath}/search?query=${query}`);
      return applicationResponse.processPayload((p) => p.map(DeviceModel.fromDTO));
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const deviceService = new DeviceService();
