import { FunctionComponent } from 'react';

interface NoDataBlockProps {
  type?: 'light-block' | undefined;
  classNames?: string;
}

const NoDataBlock: FunctionComponent<NoDataBlockProps> = ({ type, classNames }) => {
  let cssClasses = classNames ? classNames : '';

  switch (type) {
    case 'light-block':
      cssClasses += ' dark:bg-gray-800 rounded ';
      break;

    default:
      break;
  }

  return <div className={`flex h-full w-full items-center justify-center ${cssClasses}`}>No data</div>;
};

export default NoDataBlock;
