import { DTO } from '@/types/dto-wrapper';
import { AssetTotalAtLocationDTO } from '../api/locations/location.contracts';

export class AssetTotalAtLocationModel extends DTO<AssetTotalAtLocationDTO> {
  constructor(dto: AssetTotalAtLocationDTO) {
    super(dto);
  }

  get id(): number {
    return this.dto.id;
  }

  get name(): string {
    return this.dto.name;
  }

  get totalAssets(): number {
    return this.dto.totalAssets;
  }

  get coordinates(): number[] {
    return this.dto.coordinates;
  }

  get inTransitAssetId(): number | undefined {
    return this.dto.inTransitAssetId;
  }

  static fromDTO(dto: AssetTotalAtLocationDTO): AssetTotalAtLocationModel {
    return new AssetTotalAtLocationModel(dto);
  }

  toDTO(): AssetTotalAtLocationDTO {
    return {
      id: this.id,
      name: this.name,
      totalAssets: this.totalAssets,
      coordinates: this.coordinates,
      inTransitAssetId: this.inTransitAssetId,
    };
  }
}
