import { Environment } from '@/config/Environment';
import { initializeApp, type FirebaseOptions } from 'firebase/app';

const firebaseConfig: FirebaseOptions = {
  apiKey: Environment.FIREBASE_PUBLIC_API_KEY,
  authDomain: Environment.FIREBASE_AUTH_DOMAIN,
};

//If an firebase app hasn't already been created
export const app = initializeApp(firebaseConfig);
