export enum CacheKey {
  ASSET_FILTERS = 'ASSET_FILTERS',
  EVENT_FILTERS = 'EVENT_FILTERS',
  LOCATION_FILTERS = 'LOCATION_FILTERS',
  BALANCE_FILTERS = 'BALANCE_FILTERS',
  LOCATION_ASSET_FILTERS = 'LOCATION_ASSET_FILTERS',
  ASSETS_MAP_FILTERS = 'ASSETS_MAP_FILTERS',
  LOCATION_MOVEMENT_FILTERS = 'LOCATION_MOVEMENT_FILTERS',
  ASSET_MOVEMENT_FILTERS = 'ASSET_MOVEMENT_FILTERS',
  TRACKER_FILTERS = 'TRACKER_FILTERS',
}
