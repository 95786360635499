import { Drawer, IconButton, Portal } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import { useApiCall } from '@/hooks/useApiCall';
import LoadingBlock from '@/components/feedback/LoadingBlock';

import { useDate } from '@/hooks/useDate';
import { eventService } from '@/modules/events/api/events/event.service';
import { LocationMap } from '@/modules/map/components/LocationMap';
import { locationService } from '@/modules/locations/api/locations/location.service';
import centroid from '@turf/centroid';
import { TrackerTypeChip } from '@/modules/trackers/components/TrackerTypeChip';
import { useTranslation } from '@/lib';

interface EventInfoDrawerProps {
  eventId: number;
  isOpen: boolean;
  onClose: () => void;
}

const EventInfoDrawer: FunctionComponent<EventInfoDrawerProps> = ({ eventId, isOpen, onClose }) => {
  const { data, isLoading, setApiCallArg } = useApiCall(() => eventService.getById(eventId));

  const { formatDateTimestamp, formatRelativeDate } = useDate();
  const [locationMarker, setLocationMarker] = useState<[number, number] | undefined>(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    setApiCallArg(() => eventService.getById(eventId));
  }, [eventId]);

  useEffect(() => {
    if (data) {
      if (data.longitude && data.latitude) {
        setLocationMarker([data.longitude, data.latitude]);
      } else {
        setLocationMarker(undefined);
        getLocationForEvent();
      }
    }
  }, [data]);

  async function getLocationForEvent() {
    if (data && data.location && data.location.id) {
      const locationResponse = await locationService.getById(data.location.id);
      if (locationResponse.isSuccess && locationResponse.payload.dto.geofence) {
        setLocationMarker(
          centroid({
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [locationResponse.payload.dto.geofence],
            },
            properties: {
              name: 'Hi',
            },
          }).geometry?.coordinates as [number, number],
        );
      }
    }
  }

  return (
    <Portal>
      <Drawer anchor={'right'} open={isOpen} hideBackdrop variant="persistent">
        <div className="flex h-full  w-[512px] flex-col  justify-between  px-6 py-4">
          <div className="flex h-full  flex-col ">
            {!data || isLoading ? (
              <LoadingBlock />
            ) : (
              <>
                {/* header */}
                <div className="div flex items-center justify-between">
                  <div className="flex flex-col">
                    <div className="text-xs text-gray-500 dark:text-gray-400 ">{t('event.event_one')}</div>
                    <div className="text-lg font-medium ">
                      {formatDateTimestamp(data.occurredAt)}
                      <span className="pl-1 text-base text-gray-500 dark:text-gray-400 ">({formatRelativeDate(data.occurredAt)})</span>
                    </div>
                  </div>
                  <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                    <Close />
                  </IconButton>
                </div>
                {/* content */}
                <div className="flex  h-full flex-col ">
                  <div className="mt-4 flex flex-col">
                    <div className="mb-1 font-medium">
                      {t('event.location')}: {data.location.name}
                    </div>
                    <LocationMap locationMarker={locationMarker} />
                  </div>
                  <div className="my-2 flex  flex-grow flex-col">
                    <div className="mb-1 text-lg font-medium ">{t('event.tracker')} </div>
                    <div className="flex items-center">
                      <TrackerTypeChip trackerType={data.tracker.type} />
                      <div className="px-1">-</div>
                      <div className="font-semibold">{data.trackerUniqueIdentifier}</div>
                    </div>
                  </div>
                  {/* <div>{JSON.stringify(data)}</div> */}
                </div>
              </>
            )}
          </div>
          <div className="w-full"></div>
        </div>
      </Drawer>
    </Portal>
  );
};

export default EventInfoDrawer;
