import { FunctionComponent } from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import { CheckCircleOutline, FileCopyOutlined } from '@mui/icons-material';
import useClipboard from '@/hooks/useClipboard';

interface CopyableTextBoxProps {
  /** The text to display in the textbox */
  text: string;
  /** The label to display above the textbox (optional) */
  label?: string;
}

/**
 * A textbox that displays text and allows the user to copy it to the clipboard.
 */
const CopyableTextBox: FunctionComponent<CopyableTextBoxProps> = ({ text, label }) => {
  const { isCopied, copyToClipboard } = useClipboard();

  return (
    <TextField
      label={label}
      value={text}
      fullWidth
      InputProps={{
        readOnly: true,
        endAdornment: isCopied ? (
          <InputAdornment position="end" className="mr-1">
            <CheckCircleOutline color="success" />
          </InputAdornment>
        ) : (
          <InputAdornment position="end">
            <IconButton onClick={() => copyToClipboard(text)}>{<FileCopyOutlined />}</IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CopyableTextBox;
