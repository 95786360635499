import { BalanceAtLocationDto } from '@/modules/locations/api/locations/location.contracts';

export interface AssetTypeTotalArguments {
  id: number;
  name: string;
  code: string;
  individualBalance: number;
  hierarchicalBalance: number;
}

export enum AssetTypeTotalModelFieldNames {
  Id = 'id',
  Name = 'name',
  Code = 'code',
  HierarchicalBalance = 'hierarchicalBalance',
  IndividualBalance = 'individualBalance',
}

export class AssetTypeTotalModel {
  id: number;
  name: string;
  code: string;
  individualBalance: number;
  hierarchicalBalance: number;

  // Temp field
  totalAssetsBasedOnMutations?: number;

  constructor(args: AssetTypeTotalArguments) {
    this.id = args.id;
    this.name = args.name;
    this.code = args.code;
    this.individualBalance = args.individualBalance;
    this.hierarchicalBalance = args.hierarchicalBalance;
  }

  static fromDTO(dto: BalanceAtLocationDto): AssetTypeTotalModel {
    return new AssetTypeTotalModel({
      id: dto.id,
      name: dto.name,
      code: dto.code,
      individualBalance: dto.individualBalance,
      hierarchicalBalance: dto.hierarchicalBalance,
    });
  }
}
