export enum SettingType {
  'STRING' = 'STRING',
  'NUMBER' = 'NUMBER',
  'BOOLEAN' = 'BOOLEAN',
  'JSON' = 'JSON',
}

// String can be either JSON or an actual String value
export type SettingValue = number | string | boolean | object;

export enum SettingKey {
  'WHITE_LABEL_ENABLED' = 'WHITE_LABEL_ENABLED',
  'WHITE_LABEL_LOGO_URL' = 'WHITE_LABEL_LOGO_URL',
  'WHITE_LABEL_LOGO_DARK_MODE_URL' = 'WHITE_LABEL_LOGO_DARK_MODE_URL',
  'GENERATE_NEW_TRACKER_WHEN_UNKNOWN' = 'GENERATE_NEW_TRACKER_WHEN_UNKNOWN',
}

export interface SettingReadDTO {
  id: number;
  key: SettingKey;
  type: SettingType;
  value: SettingValue;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface SettingUpdateDTO {
  key: SettingKey;
  type: SettingType;
  value: SettingValue;
}

export interface ApiKeyDTO {
  positoApiKey: string;
}
