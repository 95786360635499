import { Navigate, RouteObject } from 'react-router-dom';
import { AssetsMapPage } from '../pages/AssetsMapPage';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import RequirePermissionRoute from '@/components/permissions/RequirePermissionRoute';

export const MAP_ROUTES: RouteObject[] = [
  {
    path: 'map',
    children: [
      {
        index: true,
        element: <Navigate to="assets" replace />,
      },
      {
        path: 'assets',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.MAP_VIEW}>
            <AssetsMapPage />
          </RequirePermissionRoute>
        ),
      },
    ],
  },
];
