import { DTO } from '@/types/dto-wrapper';
import { AssetReadDTO, ReadAssetTrackerDTO, ReadAssetDtoLocation } from '../api/assets/asset.contracts';

export class AssetModel extends DTO<AssetReadDTO> {
  constructor(dto: AssetReadDTO) {
    dto.trackers = dto.trackers.sort((a, b) => a.type - b.type);
    super(dto);
  }

  get id(): number {
    return this.dto.id;
  }

  get assetTypeId(): number {
    return this.dto.assetTypeId;
  }

  get code(): string {
    return this.dto.code;
  }

  get trackers(): ReadAssetTrackerDTO[] {
    return this.dto.trackers;
  }

  get assetType(): { name: string; code: string } {
    return this.dto.assetType;
  }

  get location(): ReadAssetDtoLocation | undefined {
    return this.dto.location;
  }

  get createdAt(): Date {
    return new Date(this.dto.createdAt);
  }

  get updatedAt(): Date | undefined {
    return this.dto.updatedAt ? new Date(this.dto.updatedAt) : undefined;
  }

  get deletedAt(): Date | undefined {
    return this.dto.deletedAt ? new Date(this.dto.deletedAt) : undefined;
  }

  get firstEventDate(): Date | undefined {
    return this.dto.firstEventDate ? new Date(this.dto.firstEventDate) : undefined;
  }

  get lastEventDate(): Date | undefined {
    return this.dto.lastEventDate ? new Date(this.dto.lastEventDate) : undefined;
  }

  get locationEnteredDate(): Date | undefined {
    return this.dto.locationEnteredDate ? new Date(this.dto.locationEnteredDate) : undefined;
  }

  static fromDTO(dto: AssetReadDTO): AssetModel {
    return new AssetModel(dto);
  }

  toDTO(): AssetReadDTO {
    return {
      id: this.id,
      assetTypeId: this.assetTypeId,
      code: this.code,
      trackers: this.trackers,
      assetType: this.assetType,
      location: this.location,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt ? this.updatedAt : undefined,
      deletedAt: this.deletedAt ? this.deletedAt : undefined,
      lastEventDate: this.lastEventDate ? this.lastEventDate : undefined,
      firstEventDate: this.firstEventDate ? this.firstEventDate : undefined,
      locationEnteredDate: this.locationEnteredDate ? this.locationEnteredDate : undefined,
    };
  }
}
