import { Navigate, RouteObject } from 'react-router-dom';
import { EventSettingsPage } from '../pages/EventSettingsPage';
import { ConnectorSettingsPage } from '../pages/ConnectorSettingsPage';
import { ApiSettingsPage } from '../pages/ApiSettingsPage';
import RequirePermissionRoute from '@/components/permissions/RequirePermissionRoute';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';

export const SETTING_CONFIGURATION_ROUTES: RouteObject[] = [
  {
    path: 'settings',
    children: [
      {
        index: true,
        element: (
          <RequirePermissionRoute requiredPermission={Permission.EVENT_SETTINGS_VIEW}>
            <Navigate to="events" replace />
          </RequirePermissionRoute>
        ),
      },
      {
        path: 'events',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.EVENT_SETTINGS_VIEW}>
            <EventSettingsPage />
          </RequirePermissionRoute>
        ),
      },
      {
        path: 'connectors',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.CONNECTORS_VIEW}>
            <ConnectorSettingsPage />
          </RequirePermissionRoute>
        ),
      },
      {
        path: 'api',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.API_SETTINGS_VIEW}>
            <ApiSettingsPage />
          </RequirePermissionRoute>
        ),
      },
    ],
  },
];
