export enum TrackerType {
  RFID = 0,
  Barcode = 1,
  UnaTag = 2,
  BLE = 3,
}

export enum TrackerTypeSearchParam {
  RFID = 'rfid',
  BARCODE = 'barcode',
  UNATAG = 'unatag',
  BLE = 'ble',
}

export enum LinkedTrackerSearchParam {
  LINKED = 'linked',
  UNLINKED = 'unlinked',
}

export interface TrackerReadDTO extends TrackerCreateDTO {
  id: number;

  batteryVoltage?: number | null | undefined;
  batteryVoltageUpdatedAt?: Date | null | undefined;
  modelType?: string | null | undefined;

  asset?: {
    id: number;
    code: string;
    assetType: {
      id: number;
      code: string;
    };
  };

  location?: {
    id: number;
    code: string;
  };

  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}

export interface TrackerUpdateDTO extends TrackerCreateDTO {}
export interface TrackerCreateDTO {
  type: TrackerType;
  epc?: string | null | undefined;
  barcode?: string | null | undefined;
  deviceId?: string | null | undefined;
}

export enum TrackerSortOption {
  TYPE = 'type',
  UNIQUE_IDENTIFIER = 'unique-identifier',
  ASSET_CODE = 'asset-code',
  LOCATION_CODE = 'location-code',
  BATTERY_VOLTAGE = 'battery-voltage',
  MODEL = 'model',
}

export interface TrackerFilterOptionsDTO {
  searchText?: string;
  trackerType?: TrackerTypeSearchParam | null;
  linkedAsset?: boolean | null;
}
