import { FunctionComponent } from 'react';
import { SettingEditor } from '../components/SettingEditor';
import { SettingsGroup } from '../components/SettingsGroup';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import ApiKeyManager from '../submodules/api-keys/components/ApiKeyManager';
import CopyableTextBox from '@/components/CopyableTextBox';
import { apiClient } from '@/lib/api/base-client';
import { Link } from 'react-router-dom';
import { IosShareRounded } from '@mui/icons-material';
import SettingsGroupList from '../components/SettingsGroupList';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useUser } from '@/modules/users/contexts/UserContext';
import { useTranslation } from '@/lib';

interface ApiSettingsPageProps {}

export const ApiSettingsPage: FunctionComponent<ApiSettingsPageProps> = () => {
  const apiUrl = `${apiClient.baseUrl}public/ `;
  const apiDocsUrl = `${apiClient.baseUrl}public/docs `;
  const hasEditApiSettingsPermission = useUser().hasPermission(Permission.API_SETTINGS_EDIT);
  const { t } = useTranslation();

  return (
    <TabbedLayout
      topBar={
        <TabbedLayoutTopBar>
          <TabbedLayoutTopBarHeader icon={null}>{t('setting.api.title')}</TabbedLayoutTopBarHeader>
        </TabbedLayoutTopBar>
      }
    >
      <TabbedPageLayoutBody>
        <SettingsGroupList>
          <SettingsGroup title={t('general')} subtitle={t('setting.api.general_description')}>
            <SettingEditor title={t('setting.api.url_title')} description={t('setting.api.url_description')}>
              <CopyableTextBox text={apiUrl} />
            </SettingEditor>
            <SettingEditor title={t('setting.api.documentation_title')} description={t('setting.api.documentation_description')}>
              <Link to={apiDocsUrl} target="_blank" rel="noreferrer" className="text-primary-500">
                {apiDocsUrl} <IosShareRounded className="ml-0.5" color="inherit" fontSize="inherit" />
              </Link>
            </SettingEditor>
          </SettingsGroup>
          <SettingsGroup title={t('setting.api.keys_title')} subtitle={t('setting.api.keys_description')}>
            <SettingEditor title={t('setting.api.manage_keys_title')} description={t('setting.api.manage_keys_description')}>
              <ApiKeyManager isReadOnly={!hasEditApiSettingsPermission} />
            </SettingEditor>
          </SettingsGroup>
        </SettingsGroupList>
      </TabbedPageLayoutBody>
    </TabbedLayout>
  );
};
