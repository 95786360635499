import { FunctionComponent, PropsWithChildren } from 'react';

interface PageLayoutProps extends PropsWithChildren {
  showFullscreen?: boolean;
}

export const PageLayout: FunctionComponent<PageLayoutProps> = ({ children, showFullscreen }) => {
  return (
    <div
      className={` flex h-full  flex-grow flex-col      
      bg-gray-50 dark:bg-gray-900
  

     ${showFullscreen ? 'fixed inset-0 z-40' : ''} `}
    >
      {children}
    </div>
  );
};
