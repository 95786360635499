import { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { TabMenuItem } from '../types/MenuItem';
import { Link, useLocation } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';
import BetaLabel from '@/components/BetaLabel';

interface NavigationTabsProps {
  tabItems: TabMenuItem[];
}

const NavigationTabs: FunctionComponent<NavigationTabsProps> = ({ tabItems }) => {
  const location = useLocation();

  const getActiveTab = () => {
    if (tabItems.length === 0) return '';
    return (
      (tabItems.find((item) => item.activeRouteIds.some((route) => new RegExp(`^${route.replace('*', '.*')}$`).test(location.pathname)))
        ?.url ||
        tabItems[0].url) ??
      ''
    );
  };

  const [value, setValue] = useState(getActiveTab);

  useEffect(() => {
    setValue(getActiveTab);
  }, [location.pathname, tabItems.length]); // Re-run the effect whenever the path changes

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Tabs className="flex-grow self-end" value={value} onChange={handleChange}>
      {tabItems.map((item) => (
        <Tab
          key={item.url}
          value={item.url}
          label={
            <div className="flex items-center">
              {item.label}
              {item.isBeta && (
                <div className="ml-2">
                  <BetaLabel />
                </div>
              )}
            </div>
          }
          component={Link}
          to={item.url}
        />
      ))}
    </Tabs>
  );
};

export default NavigationTabs;
