import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { MainMenuButton } from './MainMenuButton';
import { Link } from '@mui/material';

interface MainMenuItemProps {
  pathname: string;
  label: string;
  icon: ReactNode;
  activePathnames: string[];
}

export const MainMenuItem: FunctionComponent<MainMenuItemProps> = ({ activePathnames, icon, label, pathname }) => {
  const [isRouteActive, setIsRouteActive] = useState(false);

  const currentLocation = useLocation();

  useEffect(() => {
    if (currentLocation.pathname) {
      setIsRouteActive(
        activePathnames.some((r) => {
          const regex = new RegExp(`^${r.replace('*', '.*')}$`);
          return regex.test(currentLocation.pathname);
        }),
      );
    }
  }, [activePathnames, currentLocation.pathname]);

  return (
    <Link to={pathname} component={RouterLink} underline="none" className="active:text-inherit">
      <MainMenuButton isActive={isRouteActive} label={label} icon={icon} />
    </Link>
  );
};
