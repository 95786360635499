import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { LocationSelect } from './LocationSelect';
import { LocationModel } from '../types/LocationModel';
import { LoadingButton } from '@mui/lab';
import { assetService } from '@/modules/assets/api/assets/asset.service';
import { toast } from 'react-toastify';
import { useTranslation } from '@/lib';

interface MoveAssetsToLocationModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedAssetIds: number[];
  onAssetsMoved: () => void;
}

export const MoveAssetsToLocationModal: FunctionComponent<MoveAssetsToLocationModalProps> = ({
  isOpen,
  onClose,
  selectedAssetIds,
  onAssetsMoved,
}) => {
  const { t } = useTranslation();
  const [selectedLocation, setSelectedLocation] = useState<LocationModel>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleLocationSelect = (location: LocationModel | null) => {
    setSelectedLocation(location ?? undefined);
  };

  const handleMoveAssets = async () => {
    if (!selectedLocation) return;

    setIsSubmitting(true);
    try {
      // Assuming moveAssets is an async function taking asset IDs and the selected location ID
      const response = await assetService.moveAssetsToLocation({
        assetIds: selectedAssetIds,
        locationId: selectedLocation.dto.id,
      });

      if (response.isSuccess) {
        onAssetsMoved();
        toast.success(t('assets_moved_successfully'));
        onClose();
      } else {
        toast.error(t('failed_to_move_assets'));
      }
    } catch (error) {
      console.error('Error moving assets:', error);
      // Optionally, handle error (e.g., showing a notification to the user)
      toast.error(t('error_moving_assets'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>{t('move_assets_to_location_title', { count: selectedAssetIds.length })}</DialogTitle>
      <DialogContent>
        <LocationSelect onSelected={handleLocationSelect} label={t('location')} />
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={onClose} color="secondary" variant="text" disabled={isSubmitting}>
          {t('cancel')}
        </LoadingButton>
        <LoadingButton onClick={handleMoveAssets} color="primary" disabled={!selectedLocation} loading={isSubmitting} variant="contained">
          {t('move')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
