import React, { useState, useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { v4 } from 'uuid';
import EventInfoDrawer from '@/modules/events/components/info-drawer/EventInfoDrawer';
import { gray } from 'tailwindcss/colors';

export interface MarkerProps {
  eventId?: number;
  title?: string;
  lat: number;
  lng: number;
}

interface MapProps {
  markers: MarkerProps[];
}

// Length of the sides of the arrow
const arrowSideLength = 8;
// The angle of the arrow sides from the tip
const arrowSideAngle = 8;
// How far apart to space multiple arrows.
const minArrowSpacing = 90;

const lineColor = gray[500];

// If a line is shorter than this, omit arrows completely.
const omitArrowThreshold = 40;

const arrowParams = {
  arrowSideLength,
  arrowSideAngle: Math.PI / arrowSideAngle,
  minArrowSpacing,
};

export const EventHistoryMap: React.FC<MapProps> = ({ markers }) => {
  const [map, setMap] = useState<L.Map | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const [mapContainerId, setMapContainerId] = useState(v4());
  const [selectedEventId, setSelectedEventId] = useState<number | undefined>(undefined);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const linesRef = useRef<
    {
      line: L.Polyline;
      arrow: L.Polyline;
      calcLine: (params: typeof arrowParams) => { line: L.LatLngExpression[]; arrow: L.LatLngExpression[][] };
    }[]
  >([]);

  useEffect(() => {
    if (!map && mapContainerRef.current) {
      if (!(mapContainerRef.current as HTMLDivElement & { _leaflet_id: string })._leaflet_id) {
        // This line checks if the map was already initialized on the container
        const initialMap = L.map(mapContainerRef.current, {
          center: [52.0167, 4.5833],
          zoom: 5,
          attributionControl: true,
        });

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(initialMap);

        setMap(initialMap);
      }
    }
  }, [map]);

  useEffect(() => {
    if (map && markers) {
      // Clear map lines
      linesRef.current.forEach((line) => {
        line.line.remove();
        line.arrow.remove();
      });

      const markerInstances: L.Marker[] = [];
      const lineCoordinates: L.LatLngExpression[] = [];
      const markersReversed = markers.slice().reverse();
      markersReversed.forEach((marker, index) => {
        const latLng = new L.LatLng(marker.lat, marker.lng);

        const reverseIndex = markers.length - index;
        let markerClasses = 'bg-blue-700 !h-7 !w-7 !-ml-3.5 !-mt-3.5 ';
        let markerZIndex = 255 - reverseIndex;

        // The first marker is green
        if (index === 0) {
          markerClasses = `bg-green-700 !h-10 !w-10 !-ml-5 !-mt-5 `;
          markerZIndex = 255;
        }
        // The last marker is red
        else if (index === markers.length - 1) {
          markerClasses = `bg-red-700 !h-7 !w-7 !-ml-3.5 !-mt-3.5 `;
          markerZIndex = 255;
        }

        const markerInstance = L.marker(latLng, {
          icon: L.divIcon({
            className: `${markerClasses} text-white rounded-full  font-bold !text-xs flex justify-center items-center`,
            // iconAnchor: [-8, 8],

            html: `${index + 1}`,
          }),
        });
        markerInstance.setZIndexOffset(markerZIndex);

        markerInstance.addTo(map);
        markerInstances.push(markerInstance);
        lineCoordinates.push(latLng);

        markerInstance.on('click', (event: L.LeafletMouseEvent) => {
          // Handle the click event as desired
          console.log('Marker clicked:', { event, marker });
          if (marker?.eventId) {
            setSelectedEventId(marker.eventId);
            setIsDrawerOpen(true);
          }
        });
      });

      // Get the bounds of the market instances
      if (lineCoordinates.length > 0) {
        const bounds = L.latLngBounds(lineCoordinates);
        map.fitBounds(bounds);
      }

      createLines();

      map.on('zoom', recalculateArrows);

      return () => {
        // Clear map lines
        linesRef.current.forEach((line) => {
          line.line.remove();
          line.arrow.remove();
        });
        markerInstances.forEach((marker) => marker.remove());
      };
    }

    return;
  }, [map, markers]);

  function createLines() {
    if (!map) throw new Error('Map not initialized');

    linesRef.current.splice(0, linesRef.current.length);
    for (let i = 1; i < markers.length; ++i) {
      const from = markers[i];
      const to = markers[i - 1];
      // Convert from and to to LatLng objects.

      const calcLine = makeLineCoordinates(map, new L.LatLng(from.lat, from.lng), new L.LatLng(to.lat, to.lng));
      const path = calcLine(arrowParams);
      const line = L.polyline(path.line, { color: '#888', weight: 1 });
      const arrow = L.polyline(path.arrow, { color: lineColor, fill: true, fillOpacity: 1 });

      line.addTo(map);
      arrow.addTo(map);

      linesRef.current.push({ line, arrow, calcLine });
    }
  }

  function recalculateArrows() {
    for (const line of linesRef.current) {
      const path = line.calcLine(arrowParams);
      line.arrow.setLatLngs(path.arrow);
      line.arrow.redraw();
    }
  }

  function makeLineCoordinates(map: L.Map | null, from: L.LatLng, to: L.LatLng) {
    if (!map) throw new Error('Map not initialized');
    const fromPointOrig = map.latLngToLayerPoint(from);
    const toPointOrig = map.latLngToLayerPoint(to);
    const lineAngle = Math.atan2(toPointOrig.y - fromPointOrig.y, toPointOrig.x - fromPointOrig.x) + Math.PI;

    return function calcLine({ arrowSideLength, arrowSideAngle, minArrowSpacing }: typeof arrowParams) {
      // The height of the arrow from base to tip.
      const arrowHeight = arrowSideLength * Math.sin(arrowSideAngle);

      // Calculate how much to bump the arrow, so that it doesn't look off-center
      // on short lines.
      const xBump = Math.cos(lineAngle) * (arrowHeight / 2);
      const yBump = Math.sin(lineAngle) * (arrowHeight / 2);

      // Get the current pixel coordinates of the ends of the line.
      const toPoint = map.latLngToLayerPoint(to);
      const fromPoint = map.latLngToLayerPoint(from);

      const lineLength = Math.sqrt((toPoint.x - fromPoint.x) ** 2 + (toPoint.y - fromPoint.y) ** 2);

      const numArrows = lineLength > omitArrowThreshold ? Math.max(Math.floor(lineLength / minArrowSpacing), 1) : 0;

      // Move the arrow by this much every time to get evenly spaced arrows.
      const delta = L.point((toPoint.x - fromPoint.x) / (numArrows + 1), (toPoint.y - fromPoint.y) / (numArrows + 1));

      // Similar to before, except now we're starting at fromPoint and will
      // add `delta` each time.
      let arrowTipPixels = L.point(fromPoint.x - xBump, fromPoint.y - yBump);

      const calcOffset = (angle: number) => {
        const x = arrowSideLength * Math.cos(angle);
        const y = arrowSideLength * Math.sin(angle);
        return L.point(x, y);
      };

      const leftOffset = calcOffset(lineAngle - arrowSideAngle);
      const rightOffset = calcOffset(lineAngle + arrowSideAngle);

      const arrowPaths = new Array(numArrows);
      for (let i = 0; i < numArrows; ++i) {
        arrowTipPixels = arrowTipPixels.add(delta);

        const arrowTip = map.layerPointToLatLng(arrowTipPixels);
        const leftPoint = map.layerPointToLatLng(arrowTipPixels.add(leftOffset));
        const rightPoint = map.layerPointToLatLng(arrowTipPixels.add(rightOffset));
        arrowPaths[i] = [leftPoint, arrowTip, rightPoint, leftPoint];
      }

      return {
        line: [from, to],
        arrow: arrowPaths,
      };
    };
  }

  return (
    <>
      <div ref={mapContainerRef} id={mapContainerId} style={{ width: '100%', height: '100%' }} className="rounded-lg" />
      {selectedEventId !== undefined && (
        <EventInfoDrawer eventId={selectedEventId} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
      )}
    </>
  );
};
