import { FunctionComponent, useMemo, useCallback, useEffect } from 'react';
import {
  GridApi,
  GridOptions,
  CellClickedEvent,
  SortChangedEvent,
  GridReadyEvent,
  ColDef,
  RowDoubleClickedEvent,
  ValueGetterParams,
  ICellRendererParams,
} from 'ag-grid-community';

import { PoGrid, BASE_GRID_OPTIONS } from '@/components/grid/PoGrid';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { UserSettingKey } from '@/modules/users/api/user-settings/user-setting.contracts';
import { ColumnID } from '@/components/grid/column-ids';
import { TrackerTypeCellRenderer } from '@/components/grid/cells/TrackerTypeCellRenderer';
import { useTranslation } from '@/lib';
import { TrackerModel } from '../types/TrackerModel';
import { ITooltipCellRendererParams, TooltipCellRenderer } from '@/components/grid/cells/TooltipCellRenderer';
import { useDate } from '@/hooks/useDate';

interface TrackersGridProps {
  data: TrackerModel[] | undefined;
  isLoading: boolean;
  onRowDoubleClicked: (trackerId: string) => void;
  onExportToExcelClicked: (gridApi: GridApi<TrackerModel> | undefined) => void;
  onSortChanged: (event: SortChangedEvent<TrackerModel>) => void;
  onGridReady: (event: GridReadyEvent<TrackerModel>) => void;
}

export const TrackersGrid: FunctionComponent<TrackersGridProps> = ({
  data,
  isLoading,
  onRowDoubleClicked,
  onExportToExcelClicked,
  onSortChanged,
  onGridReady,
}) => {
  const { t } = useTranslation();
  const { formatDateTimestampShort } = useDate();

  // Initialize the grid column state hook
  const {
    setColumnStateGridApi,
    handleColumnStateChange,
    columnState,
    applyStateToDefinitions,
    setIsAutoSaveEnabled,
    setDefaultColumnState,
  } = useGridColumnState(UserSettingKey.TRACKER_OVERVIEW_GRID_COLUMN_STATE);

  // Enable auto-saving of column state
  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  const columns: ColDef<TrackerModel>[] = useMemo(() => {
    const baseColumns: ColDef<TrackerModel>[] = [
      {
        colId: ColumnID.TRACKER_TYPE,
        field: 'type',
        cellRenderer: TrackerTypeCellRenderer,
        headerName: t('type'),
        sortable: true,
      },
      {
        colId: ColumnID.TRACKER_NAME,
        valueGetter: (params: ValueGetterParams<TrackerModel>) => {
          if (!params.data) {
            return '';
          }
          return params.data?.uniqueIdentifier;
        },
        headerName: t('tracker_unique_identifier'),
        flex: 1,
        sortable: true,
      },
      {
        colId: ColumnID.ASSET_CODE,
        field: 'asset.code',
        headerName: t('asset_code'),
        sortable: true,
      },
      {
        colId: ColumnID.LOCATION_CODE,
        field: 'location.code',
        headerName: t('location_code'),
        sortable: true,
      },
      {
        colId: ColumnID.TRACKER_BATTERY_VOLTAGE,
        field: 'batteryVoltage',
        headerName: t('battery_voltage'),
        cellRenderer: TooltipCellRenderer,
        cellRendererParams: (params: ICellRendererParams<TrackerModel>): ITooltipCellRendererParams => ({
          tooltip: t('battery_voltage_updated_at', {
            date: params.data?.batteryVoltageUpdatedAt ? formatDateTimestampShort(params.data.batteryVoltageUpdatedAt) : '',
          }),
        }),
        sortable: true,
      },
      {
        colId: ColumnID.TRACKER_MODEL,
        field: 'modelType',
        headerName: t('model'),
        sortable: true,
      },
    ];

    if (!columnState) {
      setDefaultColumnState([
        { colId: ColumnID.TRACKER_TYPE, hide: false },
        { colId: ColumnID.TRACKER_NAME, hide: false },
        { colId: ColumnID.ASSET_CODE, hide: false },
        { colId: ColumnID.LOCATION_CODE, hide: false },
        { colId: ColumnID.TRACKER_BATTERY_VOLTAGE, hide: false },
        { colId: ColumnID.TRACKER_MODEL, hide: false },
      ]);
    }

    // Apply saved column state to the column definitions
    if (data) {
      applyStateToDefinitions(baseColumns);
    }

    return baseColumns;
  }, [data, columnState, t]);

  const onCellClicked = useCallback((params: CellClickedEvent<TrackerModel>) => {
    // Implement any cell click logic if needed
  }, []);

  const customGridOptions: GridOptions<TrackerModel> = useMemo(
    (): GridOptions<TrackerModel> => ({
      ...BASE_GRID_OPTIONS,
      onRowDoubleClicked(event: RowDoubleClickedEvent<TrackerModel, unknown>) {
        const id = event.data?.id;
        if (id) {
          onRowDoubleClicked(id.toString());
        }
      },
      onGridReady: (event) => {
        onGridReady(event);
        setColumnStateGridApi(event.api);
      },
      onSortChanged: (event) => {
        if (event.columns && event.columns[0] && event.source === 'uiColumnSorted') {
          event.api.applyColumnState({
            state: [
              {
                colId: event.columns[0].getColId(),
                sort: event.columns[0].getSort(),
              },
            ],
            defaultState: { sort: null },
          });
        }
        handleColumnStateChange(event);
        onSortChanged(event);
      },
      getContextMenuItems: (params) => [
        'copy',
        'separator',
        {
          name: t('export_excel'),
          action: () => onExportToExcelClicked(params.api),
        },
      ],
      getRowId: (params) => params.data.id.toString(),
      onCellClicked,
      // Grid column state change handlers
      onColumnMoved: handleColumnStateChange,
      onColumnVisible: handleColumnStateChange,
      onColumnResized: handleColumnStateChange,
      suppressMultiSort: true,
    }),
    [t, onExportToExcelClicked, handleColumnStateChange, onRowDoubleClicked, onGridReady, onSortChanged, setColumnStateGridApi],
  );

  return (
    <PoGrid
      isLoading={isLoading}
      colDefs={columns}
      gridOptions={customGridOptions}
      disableResizeColumnsToFit
      rowData={data}
      disableDefaultGridOptions
    />
  );
};
