import { PoGrid } from '@/components/grid/PoGrid';
import { Button } from '@mui/material';
import { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApiCall } from '@/hooks/useApiCall';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { deviceService } from '../../api/device/device.service';
import { DeviceModel } from '../../types/DeviceModel';
import { GridOptions, RowDoubleClickedEvent } from 'ag-grid-community';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { AddOutlined } from '@mui/icons-material';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { RelativeDateCellRenderer } from '@/components/grid/cells/RelativeDateCellRenderer';
import RequirePermissionComponent from '@/components/permissions/RequirePermissionComponent';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useTranslation } from '@/lib';
import { useLayoutActions } from '@/providers/layout-actions-provider/LayoutActionsProvider';
import { TabbedPageLayout } from '@/modules/application/layouts/TabbedPageLayout';
import { DeviceType } from '../../api/device/device.contracts';

export const AllDevicesPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setActions } = useLayoutActions();

  const { data, isLoading, isError, fetchData } = useApiCall<DeviceModel[]>(() => deviceService.getAll());

  const customGridOptions: GridOptions<DeviceModel> = {
    onRowDoubleClicked(event: RowDoubleClickedEvent<DeviceModel, unknown>) {
      navigate(`../${event.data?.id}/details`);
    },
  };

  const colDefs: ColDefOrGroup<DeviceModel>[] = [
    {
      headerName: t('name'),
      field: 'name',
      width: 250,
    },
    {
      headerName: t('type'),
      field: 'deviceType',
      valueFormatter: (params) => {
        switch (params.value) {
          case DeviceType.BLE_GATEWAY:
            return t(`ble_gateway`);
          case DeviceType.MOBILE_SCANNER:
            return t(`mobile_scanner`);
          default:
            return '';
        }
      },
    },
    {
      headerName: t('brand'),
      field: 'brand',
    },
    {
      headerName: t('description'),
      field: 'type',
    },
    {
      headerName: t('serial_number'),
      field: 'serialNumber',
    },
    {
      headerName: t('last_event_received_at'),
      field: 'lastEventReceivedAt',
      cellRenderer: RelativeDateCellRenderer,
      flex: 1,
    },
  ];

  useEffect(() => {
    setActions(
      <RequirePermissionComponent permission={Permission.DEVICES_EDIT}>
        <Button startIcon={<AddOutlined></AddOutlined>} onClick={() => navigate('../create')}>
          {t('create')}
        </Button>
      </RequirePermissionComponent>,
    );

    return () => {
      setActions('');
    };
  }, []);

  return (
    <TabbedPageLayout>
      <TabbedPageLayoutBody>
        <div className="flex h-full flex-grow flex-col">
          {isError ? (
            <ErrorLoadingDataAlert />
          ) : (
            <PoGrid isLoading={isLoading} colDefs={colDefs} rowData={data} gridOptions={customGridOptions} disableResizeColumnsToFit />
          )}
        </div>
      </TabbedPageLayoutBody>
    </TabbedPageLayout>
  );
};
