import { LabelColor } from '@/modules/labels/api/label.contracts';
import { FunctionComponent } from 'react';

export const GridLabel: FunctionComponent<{ name?: string; value: string; color?: LabelColor }> = ({
  name,
  value,
  color = LabelColor.Gray,
}) => {
  let labelColorClass = '';

  switch (color) {
    case LabelColor.Gray:
      labelColorClass = 'bg-gray-300 text-gray-950 dark:bg-opacity-50 dark:bg-gray-700 dark:text-gray-100';
      break;
    case LabelColor.Blue:
      labelColorClass = 'bg-blue-300 text-blue-950 dark:bg-opacity-50 dark:bg-blue-900 dark:text-blue-100';
      break;
    case LabelColor.Green:
      labelColorClass = 'bg-green-300 text-green-950 dark:bg-opacity-50 dark:bg-green-900 dark:text-green-100';
      break;
    case LabelColor.Indigo:
      labelColorClass = 'bg-indigo-300 text-indigo-950 dark:bg-opacity-50 dark:bg-indigo-800 dark:text-indigo-100';
      break;
    case LabelColor.Pink:
      labelColorClass = 'bg-pink-300 text-pink-950 dark:bg-opacity-50 dark:bg-pink-800 dark:text-pink-100';
      break;
    case LabelColor.Purple:
      labelColorClass = 'bg-purple-300 text-purple-950 dark:bg-opacity-50 dark:bg-purple-800 dark:text-purple-100';
      break;
    case LabelColor.Red:
      labelColorClass = 'bg-red-200 text-red-950 dark:bg-opacity-50 dark:bg-red-800 dark:text-red-100';
      break;
    case LabelColor.Yellow:
      labelColorClass = 'bg-yellow-300 text-yellow-950 dark:bg-opacity-50 dark:bg-yellow-500 dark:text-yellow-950';
      break;
  }

  return (
    <div className={`${labelColorClass} flex leading-none mt-2 py-1 lowercase px-2 rounded`}>
      {name}
      {name && <span className="pr-1">:</span>}
      {value}
    </div>
  );
};
