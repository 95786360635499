import { MenuItemGroup } from '@/modules/application';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';

export const APPLICATION_LOG_CONFIGURATION_SUB_MENU_ITEMS: MenuItemGroup[] = [
  {
    label: 'applicationLog.title',
    children: [
      {
        activeRouteIds: ['/app/configuration/application-log/overview'],
        label: 'overview',
        url: '/app/configuration/application-log/overview',
        requiredPermission: Permission.APPLICATION_LOG_VIEW,
      },
    ],
  },
];
