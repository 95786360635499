import { RouteObject } from 'react-router-dom';
import ConnectAssetPage from '../pages/ConnectAssetPage';
import MobileEventsPage from '../pages/MobileEventsPage';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import RequirePermissionRoute from '@/components/permissions/RequirePermissionRoute';

export const MOBILE_ROUTES: RouteObject[] = [
  {
    path: 'connect-asset',
    element: (
      <RequirePermissionRoute requiredPermission={Permission.MOBILE_CONNECT}>
        <ConnectAssetPage />
      </RequirePermissionRoute>
    ),
  },
  {
    path: 'events',
    element: (
      <RequirePermissionRoute requiredPermission={Permission.MOBILE_EVENTS}>
        <MobileEventsPage />
      </RequirePermissionRoute>
    ),
  },
];
