import { ICellRendererParams } from 'ag-grid-community';
import { FunctionComponent } from 'react';
import { ApplicationLogDTO } from '../api/application-log.contracts';

export const ApplicationLogDetailsCellRenderer: FunctionComponent<ICellRendererParams<ApplicationLogDTO>> = (props) => {
  const { data } = props;

  if (!data) {
    return <span></span>;
  }
  return (
    <div className="mx-4 py-3">
      <span className="font-semibold">{data.message}</span>
      <pre>{data.details}</pre>
    </div>
  );
};
