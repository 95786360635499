import { z } from 'zod';

export const UserFormSchema = z.object({
  name: z.string(),
  email: z.string().min(1, { message: 'this_field_has_to_be_filled' }).email('this_is_not_a_valid_email'),
  roleIds: z.array(z.number()).refine((arr) => arr.length > 0, { message: 'you_must_select_a_role' }),
  // Password is optional during edits
  password: z.string().optional(),
});

// Make password a required field during create
export const UserCreateFormSchema = UserFormSchema.extend({
  // Firebase requires at least 6 characters password
  password: z.string().min(6, {
    message: 'password_with_minimum_characters_required',
  }),
});

export type UserFormModel = z.infer<typeof UserFormSchema>;
