/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { labelService } from '@/modules/labels/api/label.service';
import { useTranslation } from '@/lib';

export interface LabelValueOption {
  labelName: string;
  labelId: number;
  labelValueId: number;
  labelValueName: string;
}

interface LabelValuesSelectProps {
  selectedValues: number[];
  onChange: (selectedIds: number[]) => void;
}

const LabelValuesSelect: FunctionComponent<LabelValuesSelectProps> = ({ selectedValues, onChange }) => {
  const { t } = useTranslation();
  const [labelValues, setLabelValues] = useState<LabelValueOption[]>([]);

  useEffect(() => {
    const fetchLabelValues = async () => {
      const labels = await labelService.getAll();
      if (labels.isSuccess) {
        const newLabelValues = labels.payload.flatMap((label) =>
          label.values.map((labelValue) => ({
            labelName: label.dto.name,
            labelId: label.dto.id,
            labelValueId: labelValue.dto.id,
            labelValueName: labelValue.dto.value,
          })),
        );
        setLabelValues(newLabelValues);
      }
    };
    fetchLabelValues();
  }, []);

  const handleSelectionChange = (event: React.SyntheticEvent, value: LabelValueOption[]) => {
    const ids = value.map((v) => v.labelValueId);
    onChange(ids);
  };

  const selectAllLabelValues = (labelName: string) => {
    const allLabelIds = labelValues.filter((lv) => lv.labelName === labelName).map((lv) => lv.labelValueId);
    onChange([...new Set([...selectedValues, ...allLabelIds])]);
  };

  const deselectAllLabelValues = (labelName: string) => {
    const labelIds = labelValues.filter((lv) => lv.labelName === labelName).map((lv) => lv.labelValueId);
    onChange(selectedValues.filter((id) => !labelIds.includes(id)));
  };

  return (
    <Autocomplete
      multiple
      disableClearable
      options={labelValues}
      value={labelValues.filter((option) => selectedValues.includes(option.labelValueId))}
      onChange={handleSelectionChange}
      groupBy={(option) => option.labelName}
      getOptionLabel={(option) => option.labelValueName}
      renderGroup={(params) => (
        <div key={params.key}>
          <div className="flex justify-between px-4 py-0.5 bg-gray-100 dark:bg-gray-700 font-bold dark:text-gray-400 text-sm group">
            <div className="first-letter:uppercase">{params.group}</div>
            <div className="flex gap-x-2 text-primary-400 font-medium items-center">
              <div className="hover:underline cursor-pointer" onClick={() => selectAllLabelValues(params.group)}>
                {t('select_all')}
              </div>
              <div className="text-gray-400 flex items-center pb-px">|</div>
              <div className="hover:underline cursor-pointer" onClick={() => deselectAllLabelValues(params.group)}>
                {t('deselect_all')}
              </div>
            </div>
          </div>
          {params.children}
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={t('label_values')}
          helperText={t('label_helper_text')}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
};

export default LabelValuesSelect;
