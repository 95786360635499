import { SecondaryMenu, SecondaryMenuItemGroup, SecondaryMenuLayout, SecondaryMenuHeader, MenuItemGroup } from '@/modules/application';
import SecondaryMenuContent from '@/modules/application/components/SecondaryMenuContent';
import { Outlet } from 'react-router-dom';
import { ASSET_CONFIGURATION_SUB_MENU_ITEMS } from '@/modules/assets';
import { TRACKER_CONFIGURATION_SUB_MENU_ITEMS } from '@/modules/trackers';
import { USER_CONFIGURATION_SUB_MENU_ITEMS } from '@/modules/users';
import { DEVICE_CONFIGURATION_SUB_MENU_ITEMS } from '@/modules/devices';
import { SETTING_CONFIGURATION_SUB_MENU_ITEMS } from '@/modules/settings';
import { IMPORTER_CONFIGURATION_SUB_MENU_ITEMS } from '@/modules/importer';
import { APPLICATION_LOG_CONFIGURATION_SUB_MENU_ITEMS } from '@/modules/application-log/configuration/application-log-configuration-sub-menu-items';
import { LABEL_CONFIGURATION_SUB_MENU_ITEMS } from '@/modules/labels/configuration/label-configuration-sub-menu-items';
import RequirePermissionsComponent from '@/components/permissions/RequirePermissionsComponent';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useTranslation } from '@/lib';

export function ConfigurationLayout() {
  const { t } = useTranslation();

  function getAllPermissionsForSubMenuItems(subMenuItems: MenuItemGroup[]): Permission[] {
    return subMenuItems
      .flatMap((smi) => smi.children ?? [])
      .map((mi) => mi.requiredPermission)
      .filter((p) => p !== undefined) as Permission[];
  }

  return (
    <SecondaryMenuLayout
      secondaryMenu={
        <>
          <SecondaryMenu>
            <SecondaryMenuHeader>{t('configuration')}</SecondaryMenuHeader>
            <SecondaryMenuContent>
              <RequirePermissionsComponent
                permissions={getAllPermissionsForSubMenuItems([
                  ...ASSET_CONFIGURATION_SUB_MENU_ITEMS,
                  ...TRACKER_CONFIGURATION_SUB_MENU_ITEMS,
                  ...DEVICE_CONFIGURATION_SUB_MENU_ITEMS,
                  ...LABEL_CONFIGURATION_SUB_MENU_ITEMS,
                ])}
                permissionCheckMethod="any"
              >
                <SecondaryMenuItemGroup
                  header={t('base_data')}
                  menuItems={[
                    ...ASSET_CONFIGURATION_SUB_MENU_ITEMS.flatMap((smi) => smi.children ?? []),
                    ...TRACKER_CONFIGURATION_SUB_MENU_ITEMS.flatMap((smi) => smi.children ?? []),
                    ...DEVICE_CONFIGURATION_SUB_MENU_ITEMS.flatMap((smi) => smi.children ?? []),
                    ...LABEL_CONFIGURATION_SUB_MENU_ITEMS.flatMap((smi) => smi.children ?? []),
                  ]}
                />
              </RequirePermissionsComponent>

              <RequirePermissionsComponent
                permissions={getAllPermissionsForSubMenuItems(SETTING_CONFIGURATION_SUB_MENU_ITEMS)}
                permissionCheckMethod="any"
              >
                <>
                  {SETTING_CONFIGURATION_SUB_MENU_ITEMS.map((smi) => {
                    return <SecondaryMenuItemGroup header={t(smi.label)} key={smi.label} menuItems={smi.children ?? []} />;
                  })}
                </>
              </RequirePermissionsComponent>

              <RequirePermissionsComponent
                permissions={getAllPermissionsForSubMenuItems(USER_CONFIGURATION_SUB_MENU_ITEMS)}
                permissionCheckMethod="any"
              >
                <>
                  {USER_CONFIGURATION_SUB_MENU_ITEMS.map((smi) => {
                    return <SecondaryMenuItemGroup header={t(smi.label)} key={smi.label} menuItems={smi.children ?? []} />;
                  })}
                </>
              </RequirePermissionsComponent>

              <RequirePermissionsComponent
                permissions={getAllPermissionsForSubMenuItems(IMPORTER_CONFIGURATION_SUB_MENU_ITEMS)}
                permissionCheckMethod="any"
              >
                <>
                  {IMPORTER_CONFIGURATION_SUB_MENU_ITEMS.map((smi) => {
                    return <SecondaryMenuItemGroup header={t(smi.label)} key={smi.label} menuItems={smi.children ?? []} />;
                  })}
                </>
              </RequirePermissionsComponent>

              <RequirePermissionsComponent
                permissions={getAllPermissionsForSubMenuItems(APPLICATION_LOG_CONFIGURATION_SUB_MENU_ITEMS)}
                permissionCheckMethod="any"
              >
                <>
                  {APPLICATION_LOG_CONFIGURATION_SUB_MENU_ITEMS.map((smi) => {
                    return <SecondaryMenuItemGroup header={t(smi.label)} key={smi.label} menuItems={smi.children ?? []} />;
                  })}
                </>
              </RequirePermissionsComponent>
            </SecondaryMenuContent>
          </SecondaryMenu>
        </>
      }
    >
      <Outlet />
    </SecondaryMenuLayout>
  );
}
