import { useUser } from '@/modules/users/contexts/UserContext';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';

import React, { ReactElement } from 'react';
import { RouteObject, Navigate } from 'react-router-dom';

interface FirstAccessibleRouteProps {
  routes: RouteObject[];
  parentPath?: string;
}

const FirstAccessibleRoute: React.FC<FirstAccessibleRouteProps> = ({ routes, parentPath = '' }) => {
  const { hasPermission } = useUser();

  const getFirstAccessibleRoute = (
    routes: RouteObject[],
    hasPermission: (permission: Permission) => boolean,
    parentPath: string,
  ): string | null => {
    for (const route of routes) {
      const currentPath = parentPath + (route.path ? `/${route.path}` : '');
      if (route.element && !route.index && (route.element as ReactElement).props.requiredPermission) {
        const requiredPermission = (route.element as ReactElement).props.requiredPermission;
        if (hasPermission(requiredPermission)) {
          return currentPath || null;
        }
      }
      if (route.children) {
        const childPath = getFirstAccessibleRoute(route.children as RouteObject[], hasPermission, currentPath);
        if (childPath) {
          return childPath;
        }
      }
    }
    return null;
  };

  const firstRoute = getFirstAccessibleRoute(routes, hasPermission, parentPath);

  if (!firstRoute) {
    return <Navigate to="/" replace />; // Adjust the fallback route as needed
  }

  if (firstRoute.includes(':dashboardId')) {
    return <Navigate to="/app/dashboards/" />;
  }

  return <Navigate to={firstRoute} />;
};

export default FirstAccessibleRoute;
