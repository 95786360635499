import { FC, useEffect, useRef, useState } from 'react';
import mapboxgl, { LngLatLike } from 'mapbox-gl';
import { Feature, FeatureCollection } from 'geojson';
import { getBoundingBox } from '@/modules/map/lib/geo.utils';

interface LocationMapProps {
  locationMarker?: [number, number];
}

export const LocationMap: FC<LocationMapProps> = ({ locationMarker }) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [initialPaintCompleted, setInitialPaintCompleted] = useState(false);

  useEffect(() => {
    if (map.current) return; // Initialize map only once
    // mapboxgl.accessToken = 'YOUR_MAPBOX_ACCESS_TOKEN';

    map.current = new mapboxgl.Map({
      container: mapContainer.current!,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: !locationMarker ? ([4.5833, 52.0167] as LngLatLike) : undefined,

      bounds: locationMarker ? getBoundingBox([locationMarker]) : undefined,

      fitBoundsOptions: {
        padding: 50,
        maxZoom: 6,
      },
    });

    map.current.on('load', function () {
      setInitialPaintCompleted(true);
    });
  }, []);

  function drawMarker() {
    console.log({ currentMap: map.current, initialPaintCompleted });

    if (!map.current) return; // Wait for map to initialize
    if (!initialPaintCompleted) return;

    if (locationMarker && locationMarker.length > 0) {
      const marker = new mapboxgl.Marker({ scale: 0.75 }).setLngLat(locationMarker).addTo(map.current);

      zoomToPolygon();
    }
  }

  function createFeatureCollectionFromMarker(marker: number[]) {
    const markerFeature: Feature = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: marker,
      },
      properties: {},
      // properties: {
      //   id: marker.id,
      //   title: marker.title,
      //   assetCount: marker.count,
      // },
    };

    const featureCollection: FeatureCollection = {
      type: 'FeatureCollection',
      features: [markerFeature],
    };

    return featureCollection;
  }

  function zoomToPolygon() {
    if (!map.current) return;
    if (!locationMarker || locationMarker.length !== 2) {
      return;
    }
    // Geographic coordinates of the LineString
    const bounds = getBoundingBox([locationMarker]);
    if (!bounds) return;
    map.current.fitBounds(bounds, {
      zoom: 10,
    });
  }

  useEffect(() => {
    if (!map.current) return; // Wait for map to initialize

    if (!initialPaintCompleted) return;

    drawMarker();
  }, [locationMarker]);

  useEffect(() => {
    drawMarker();
  }, [initialPaintCompleted]);

  return <div ref={mapContainer} style={{ width: '100%', height: '350px' }} className="rounded-lg" />;
};
