import { useUser } from '@/modules/users/contexts/UserContext';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import React from 'react';

interface RequirePermissionsComponentProps {
  permissions: Permission[];
  children: JSX.Element;
  permissionCheckMethod?: 'all' | 'any';
}

const RequirePermissionsComponent: React.FC<RequirePermissionsComponentProps> = ({
  permissions,
  children,
  permissionCheckMethod = 'all',
}) => {
  const { hasAllPermissions, hasAnyPermission } = useUser();

  const hasSufficientPermissions = permissionCheckMethod === 'all' ? hasAllPermissions(permissions) : hasAnyPermission(permissions);

  if (!hasSufficientPermissions) {
    // Optionally, you can return null or a specific "No Access" component
    return null;
  }

  return children;
};

export default RequirePermissionsComponent;
