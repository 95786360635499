import { TFunction } from 'i18next';
import { IDashboardWidgetBaseConfiguration } from '../../../types/IDashboardWidgetBaseConfiguration';
import { GroupPerTimeType } from './group-per-time-type.enum';

export class BalanceOverTimeWidgetConfiguration implements IDashboardWidgetBaseConfiguration {
  title = 'dashboard.balanceOverTimeWidgetConfiguration.title';
  balanceOverTimeType = GroupPerTimeType.DAY;
  locationId = 0;
  assetTypeIds?: number[] = [];
  periodInDays = 30;

  constructor(t: TFunction) {
    this.title = t(this.title);
  }
}
