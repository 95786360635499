import { MenuItemGroup } from '@/modules/application';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';

export const TRACKER_CONFIGURATION_SUB_MENU_ITEMS: MenuItemGroup[] = [
  {
    label: 'Trackers',
    children: [
      {
        activeRouteIds: ['/app/configuration/trackers/*'],
        label: 'Trackers',
        url: '/app/configuration/trackers/overview',
        requiredPermission: Permission.TRACKERS_VIEW,
      },
    ],
  },
];
