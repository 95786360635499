import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { LabelCreateFormSchema, LabelFormModel, LabelFormSchema } from './LabelFormModel';
import { zodResolver } from '@hookform/resolvers/zod';
import LabelFormDisplay from './LabelFormDisplay';

interface LabelFormLogicProps {
  initialValues: LabelFormModel;
  onSubmit: (data: LabelFormModel) => Promise<boolean>;
  isCreate?: boolean;
  labelId?: number;
}

const LabelFormLogic: FunctionComponent<LabelFormLogicProps> = ({ initialValues, onSubmit, isCreate, labelId }) => {
  const form = useForm<LabelFormModel>({
    mode: 'onSubmit',
    defaultValues: initialValues,
    resolver: zodResolver(isCreate ? LabelCreateFormSchema : LabelFormSchema),
  });

  const handleSubmit = async (data: LabelFormModel) => {
    await onSubmit(data)
      .then(() => form.reset(data))
      .catch((err) => console.error(err));
  };

  return <LabelFormDisplay form={form} onSubmit={handleSubmit} isCreate={isCreate} labelId={labelId} />;
};

export default LabelFormLogic;
