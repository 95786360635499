import { TFunction } from 'i18next';
import { IDashboardWidgetBaseConfiguration } from '../../../types/IDashboardWidgetBaseConfiguration';

export enum TopAssetTypesMetric {
  assetCount = 'assetCount',
  assetTotalMonetaryValue = 'assetTotalMonetaryValue',
}

export class TopAssetTypesWidgetConfiguration implements IDashboardWidgetBaseConfiguration {
  title = 'dashboard.topAssetTypesWidgetConfiguration.title';
  metric = TopAssetTypesMetric.assetCount;

  constructor(t: TFunction) {
    this.title = t(this.title);
  }
}
