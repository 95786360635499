import React, { useContext } from 'react';
import { FilterBarContext, FilterFieldName } from '../FilterBarContext';
import LabelValuesSelect from '@/modules/labels/components/LabelValuesSelect';

interface LabelValuesFilterProps {
  filterFieldName: FilterFieldName;
}

const LabelValuesFilter: React.FC<LabelValuesFilterProps> = ({ filterFieldName }) => {
  const { setFilterValue, filterValues } = useContext(FilterBarContext);

  const selectedLabelValues = (filterValues[filterFieldName] as number[]) || [];

  const handleValueChange = (selectedIds: number[]) => {
    setFilterValue(filterFieldName, selectedIds);
  };

  return <LabelValuesSelect selectedValues={selectedLabelValues} onChange={handleValueChange} />;
};

export default LabelValuesFilter;
