import React, { ChangeEvent, useCallback, useRef } from 'react';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { CloudUpload as CloudUploadIcon, InsertDriveFileRounded } from '@mui/icons-material';
import ImportWizardNavigation from './ImportWizardNavigation';
import { useWizardContext } from './ImportWizardContext';
import { round } from 'lodash-es';
import { useTranslation } from '@/lib';

export const ImportWizardStepUpload: React.FC = () => {
  const { t } = useTranslation();
  const { currentStep, moveForward, moveBackward, uploadedFile, handleFileUpload, currentStepTitle } = useWizardContext();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        handleFileUpload(file);
      }
    },
    [handleFileUpload],
  );

  return (
    <div className="flex w-full flex-1 flex-col items-center justify-between ">
      <div></div>
      <div className="flex w-full flex-col items-center">
        <div className="text-lg font-semibold">{currentStepTitle}</div>
        <div>{t('importer.upload.files_you_can_imported')}</div>
        <input ref={fileInputRef} type="file" className="hidden" accept=".csv,.txt" onChange={handleFileChange} />
        {uploadedFile && (
          <Card variant="outlined" className="mt-6 px-2">
            <CardContent>
              <div className="mb-2 flex  items-center">
                <InsertDriveFileRounded className="w-6 pr-2" />
                {t('importer.upload.file_details')}
              </div>
              <div className="text-lg font-semibold ">{uploadedFile.name}</div>
              <Typography>
                {t('importer.upload.file_size')}: {round(uploadedFile.size / 1000 / 1000, 2)} MB
              </Typography>
            </CardContent>
          </Card>
        )}
        {!uploadedFile && (
          <Button
            variant="contained"
            color="primary"
            className="mt-6 focus:outline-none"
            startIcon={<CloudUploadIcon />}
            onClick={() => fileInputRef.current?.click()}
          >
            {t('importer.upload.select_csv_file')}
          </Button>
        )}
        {uploadedFile && (
          <Button
            variant="text"
            color="primary"
            className="mt-1 focus:outline-none"
            startIcon={<CloudUploadIcon />}
            onClick={() => fileInputRef.current?.click()}
          >
            {t('importer.upload.change_file')}
          </Button>
        )}
      </div>
      <div className="mt-12">
        <ImportWizardNavigation isNextDisabled={!uploadedFile} nextStep={moveForward} isPreviousDisabled={true} isPreviousHidden />
      </div>
    </div>
  );
};
