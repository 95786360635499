import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { FunctionComponent } from 'react';
import { TopAssetTypesMetric, TopAssetTypesWidgetConfiguration } from './TopAssetTypesWidgetConfiguration';
import { useDashboardContext } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { isNumber } from 'lodash-es';
import { useTranslation } from '@/lib';

interface TopAssetTypesWidgetConfigurationFormProps {
  configuration: TopAssetTypesWidgetConfiguration;
  id: string;
}

const TopAssetTypesWidgetConfigurationForm: FunctionComponent<TopAssetTypesWidgetConfigurationFormProps> = ({ configuration, id }) => {
  const { widgets, updateWidget, updateWidgetConfiguration } = useDashboardContext();
  const { t } = useTranslation();

  return (
    <>
      <TextField
        label="Name"
        value={configuration.title}
        onChange={(e) => {
          updateWidgetConfiguration<TopAssetTypesWidgetConfiguration>(id, {
            title: e.currentTarget.value,
          });
        }}
      />

      <FormControl>
        <InputLabel>Metric</InputLabel>
        <Select
          label={'Metric'}
          value={configuration.metric}
          onChange={(e) => {
            updateWidgetConfiguration<TopAssetTypesWidgetConfiguration>(id, {
              metric: e.target.value as TopAssetTypesMetric,
              title:
                (e.target.value as TopAssetTypesMetric) === TopAssetTypesMetric.assetCount
                  ? 'Top Asset Types by Asset Count'
                  : 'Top Asset Types by Monetary Value',
            });
          }}
        >
          {Object.values(TopAssetTypesMetric).map((metric, i) => {
            if (isNumber(metric)) {
              return;
            }
            return (
              <MenuItem key={i} value={metric}>
                {t(`dashboard.top_asset_types_metric.${metric}`)}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>The metric the top Asset Types will be determined by</FormHelperText>
      </FormControl>
    </>
  );
};

export default TopAssetTypesWidgetConfigurationForm;
