import { TFunction } from 'i18next';
import { IDashboardWidgetBaseConfiguration } from '../../../types/IDashboardWidgetBaseConfiguration';

export enum TopLocationsMetric {
  assetCount = 'assetCount',
  assetTotalMonetaryValue = 'assetTotalMonetaryValue',
}

export class TopLocationsWidgetConfiguration implements IDashboardWidgetBaseConfiguration {
  title = 'dashboard.topLocationsWidgetConfiguration.title';
  metric = TopLocationsMetric.assetCount;
  assetTypeId?: number;
  labelValueIDs?: number[] = [];

  constructor(t: TFunction) {
    this.title = t(this.title);
  }
}
