import { PoGrid } from '@/components/grid/PoGrid';
import { Button } from '@mui/material';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApiCall } from '@/hooks/useApiCall';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { labelService } from '../../api/label.service';
import { LabelModel } from '../../models/LabelModel';
import { GridOptions, ICellRendererParams, RowDoubleClickedEvent } from 'ag-grid-community';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { AddOutlined } from '@mui/icons-material';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import FilterBar from '@/components/filterbar/FilterBar';
import FilterBarSearchButton from '@/components/filterbar/FilterBarSearchButton';
import TextFilter from '@/components/filterbar/filters/TextFilter';
import { FilterValues } from '@/components/filterbar/FilterBarContext';
import { GridLabel } from '@/components/grid/GridLabel';
import RequirePermissionComponent from '@/components/permissions/RequirePermissionComponent';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useTranslation } from '@/lib';

export const LabelsPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data, isLoading, isError, setApiCallArg } = useApiCall<LabelModel[]>(() => labelService.getAll());

  const customGridOptions: GridOptions<LabelModel> = {
    onRowDoubleClicked(event: RowDoubleClickedEvent<LabelModel, unknown>) {
      navigate(`../detail/${event.data?.dto.id}`);
    },
  };

  const gridColumns: ColDefOrGroup<LabelModel>[] = [
    {
      field: 'dto.name',
      headerName: t('name'),
    },
    {
      field: 'assignmentCount',
      headerName: t('assignments'),
    },
    {
      field: 'valueNameString',
      headerName: t('values'),
      cellRenderer: (params: ICellRendererParams<LabelModel>) => {
        return (
          <div className="flex gap-x-1">
            {params.data?.valueNames.map((v) => <GridLabel key={v} value={v} color={params.data?.dto.color}></GridLabel>)}
          </div>
        );
      },
      flex: 1,
    },
  ];

  const handleSearch = (filterValues: FilterValues) => {
    setApiCallArg(() =>
      labelService.getAll({
        searchText: filterValues.searchText,
      }),
    );
  };

  return (
    <TabbedLayout
      topBar={
        <TabbedLayoutTopBar
          actions={
            <RequirePermissionComponent permission={Permission.LABELS_EDIT}>
              <Button startIcon={<AddOutlined></AddOutlined>} onClick={() => navigate('../create')}>
                {t('create_label')}
              </Button>
            </RequirePermissionComponent>
          }
        >
          <TabbedLayoutTopBarHeader icon={null}>{t('labels')}</TabbedLayoutTopBarHeader>
        </TabbedLayoutTopBar>
      }
    >
      <TabbedPageLayoutBody>
        {isError ? (
          <ErrorLoadingDataAlert />
        ) : (
          <div className="flex h-full flex-col">
            <div className="mb-2 flex items-center justify-between">
              <FilterBar onSearch={handleSearch}>
                <div className="w-44">
                  <TextFilter label={t('search')} />
                </div>

                <FilterBarSearchButton isLoading={isLoading} />
              </FilterBar>
            </div>
            <PoGrid isLoading={isLoading} colDefs={gridColumns} rowData={data} gridOptions={customGridOptions} disableResizeColumnsToFit />
          </div>
        )}
      </TabbedPageLayoutBody>
    </TabbedLayout>
  );
};
