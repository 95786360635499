import { Navigate, RouteObject } from 'react-router-dom';
import { ApplicationLogOverviewPage } from '../pages/ApplicationLogOverviewPage';
import RequirePermissionRoute from '@/components/permissions/RequirePermissionRoute';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';

export const APPLICATION_LOG_CONFIGURATION_ROUTES: RouteObject[] = [
  {
    path: 'application-log',
    children: [
      {
        index: true,
        element: (
          <RequirePermissionRoute requiredPermission={Permission.APPLICATION_LOG_VIEW}>
            <Navigate to="overview" replace />
          </RequirePermissionRoute>
        ),
      },
      {
        path: 'overview',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.APPLICATION_LOG_VIEW}>
            <ApplicationLogOverviewPage />
          </RequirePermissionRoute>
        ),
      },
    ],
  },
];
