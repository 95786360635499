import { PoGrid } from '@/components/grid/PoGrid';
import { FunctionComponent } from 'react';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { ValueGetterParams } from 'ag-grid-community';
import { TrackerTypeCellRenderer } from '@/components/grid/cells/TrackerTypeCellRenderer';
import { TrackerModel, TrackerModelFieldNames } from '@/modules/trackers/types/TrackerModel';
import { useTranslation } from '@/lib';

interface EventGroupTrackersGridProps {
  data?: TrackerModel[];
}

export const EventGroupTrackersGrid: FunctionComponent<EventGroupTrackersGridProps> = ({ data }) => {
  const { t } = useTranslation();
  if (!data) {
    return <div>{t('no_data')}</div>;
  }

  // const customGridOptions: GridOptions<AssetModel> = {
  //   onRowDoubleClicked(asset: RowDoubleClickedEvent<AssetModel, any>) {
  //     navigate(`/app/assets/${asset.data?.id}`);
  //   },
  // };

  const columns: ColDefOrGroup<TrackerModel>[] = [
    {
      headerName: t('identifier'),
      valueGetter: (params: ValueGetterParams<TrackerModel>) => {
        return params.data?.uniqueIdentifier;
      },
    },
    {
      headerName: t('type'),
      field: TrackerModelFieldNames.Type,
      cellRenderer: TrackerTypeCellRenderer,
    },
  ];

  return (
    <div className="flex h-full flex-1 flex-grow flex-col">
      <PoGrid isLoading={false} colDefs={columns} rowData={data} />
    </div>
  );
};
