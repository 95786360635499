import { useFormatting } from '@/hooks/useFormatting';
import { FunctionComponent } from 'react';

interface WidgetCountAndTitleProps {
  count: number;
  title: string;
}

export const WidgetCountAndTitle: FunctionComponent<WidgetCountAndTitleProps> = ({ count, title }) => {
  const { formatNumber } = useFormatting();

  return (
    <div className="flex h-full flex-col items-center justify-center ">
      <div className="text-4xl font-bold ">{formatNumber(count)}</div>
      <div className="text-center text-lg font-medium tracking-wide dark:text-gray-300">{title}</div>
    </div>
  );
};
