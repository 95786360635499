import LoadingBlock from '@/components/feedback/LoadingBlock';
import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent, useEffect } from 'react';
import { toast } from 'react-toastify';
import DeviceFormLogic from './DeviceFormLogic';
import { DeviceFormModel } from './DeviceFormModel';
import { useNavigate, useParams } from 'react-router-dom';
import { isNumber } from 'lodash-es';
import { DeviceModel } from '../../types/DeviceModel';
import { deviceService } from '../../api/device/device.service';
import { useUser } from '@/modules/users/contexts/UserContext';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useTranslation } from '@/lib';
import { DeviceType } from '../../api/device/device.contracts';

const DeviceFormApi: FunctionComponent = () => {
  const navigate = useNavigate();
  const hasDeviceEditPermission = useUser().hasPermission(Permission.DEVICES_EDIT);
  const { t } = useTranslation();
  const { deviceId } = useParams();
  const deviceIdNumber = deviceId ? Number.parseInt(deviceId) : 0;
  const isCreate = deviceId === 'create';

  const { data, isLoading, isError, setApiCallArg } = useApiCall<DeviceModel>(() => deviceService.getById(0), {
    manual: true,
  });

  useEffect(() => {
    if (deviceIdNumber) {
      setApiCallArg(() => deviceService.getById(deviceIdNumber));
    }
  }, [deviceIdNumber]);

  const handleSubmit = async (data: DeviceFormModel) => {
    if (!hasDeviceEditPermission) {
      toast.error(t('no_edit_device_permission'));
      return false;
    }

    // Process data
    if (isCreate) {
      const response = await deviceService.create({
        name: data.name,
        code: data.code,
        brand: data.brand,
        type: data.type,
        serialNumber: data.serialNumber,
        deviceType: data.deviceType,
        identifier: data.identifier,
        locationId: data.locationId,
      });

      if (!response.isSuccess) {
        toast(t('error_creating_device'), {
          type: 'error',
        });
      } else {
        toast.success(t('device_created'));
        navigate('/app/configuration/devices/all');
      }
    } else if (isNumber(deviceIdNumber)) {
      const response = await deviceService.update(deviceIdNumber, {
        name: data.name,
        code: data.code,
        brand: data.brand,
        type: data.type,
        serialNumber: data.serialNumber,
        deviceType: data.deviceType,
        identifier: data.identifier,
        locationId: data.locationId,
      });

      if (!response.isSuccess) {
        toast(t('error_updating_device'), {
          type: 'error',
        });
      } else {
        toast.success(t('device_updated'));
        navigate('/app/configuration/devices/all/');
      }
    }
    return true;
  };

  if (isCreate || !deviceId) {
    const newDevice: DeviceFormModel = {
      name: '',
      code: '',
      brand: '',
      type: '',
      serialNumber: '',
      deviceType: DeviceType.MOBILE_SCANNER,
      identifier: '',
      locationId: undefined,
    };
    return <DeviceFormLogic initialValues={newDevice} onSubmit={handleSubmit} isCreate />;
  } else {
    // return early if initial form data isn't loaded
    if (isLoading) return <LoadingBlock />;
    if (isError || !data) return <div>{t('no_data')}</div>;

    return (
      <DeviceFormLogic
        initialValues={{
          name: data.name,
          code: data.code,
          brand: data.brand,
          type: data.type,
          serialNumber: data.serialNumber,
          deviceType: data.deviceType,
          identifier: data.identifier,
          locationId: data.location?.id,
        }}
        onSubmit={handleSubmit}
        isCreate={isCreate}
      />
    );
  }
};

export default DeviceFormApi;
