export interface ApplicationLogDTO {
  id: number;
  occurredAt: Date;
  severity: string;
  category: string;
  message: string;
  details?: string;
  messageId?: string;
}

export enum ApplicationLogSeverity {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export enum ApplicationLogCategory {
  IMPORT = 'IMPORT',
  EVENT_PROCESSING = 'EVENT_PROCESSING',
  OTHER = 'OTHER',
}
