import { createContext, useState, useEffect, useRef, FC, PropsWithChildren } from 'react';

export interface TimerContextProps {
  loopCount: number;
}

export const TimerContext = createContext<TimerContextProps>({
  loopCount: 0,
});

interface TimerProviderProps extends PropsWithChildren {
  initialSeconds: number;
}

export const TimerProvider: FC<TimerProviderProps> = ({ children, initialSeconds }) => {
  const [loopCount, setLoopCount] = useState(0);
  const secondsRef = useRef(initialSeconds);

  useEffect(() => {
    const timer = setInterval(() => {
      if (secondsRef.current === 0) {
        setLoopCount((prevLoopCount) => prevLoopCount + 1);
        secondsRef.current = initialSeconds; // Reset the seconds for the next loop
      } else {
        secondsRef.current -= 1;
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [initialSeconds]);

  const value = { loopCount };

  return <TimerContext.Provider value={value}>{children}</TimerContext.Provider>;
};
