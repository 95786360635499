import { RouteObject } from 'react-router-dom';
import { RolesPage } from '../pages/RolesPage';
import RequirePermissionRoute from '@/components/permissions/RequirePermissionRoute';
import { Permission } from '../api/permissions.contracts';

export const ROLE_CONFIGURATION_ROUTES: RouteObject[] = [
  {
    path: 'roles',
    element: (
      <RequirePermissionRoute requiredPermission={Permission.USERS_ROLES_VIEW}>
        <RolesPage />
      </RequirePermissionRoute>
    ),
  },
];
