import { FunctionComponent, PropsWithChildren } from 'react';

interface TabbedPageLayoutProps extends PropsWithChildren {
  showFullscreen?: boolean;
}

export const TabbedPageLayout: FunctionComponent<TabbedPageLayoutProps> = ({ children, showFullscreen }) => {
  return (
    <div
      className={` flex h-full  flex-grow flex-col      
      bg-gray-50 dark:bg-gray-900
  

     ${showFullscreen ? 'fixed inset-0' : ''} `}
    >
      {children}
    </div>
  );
};
