// FilterBar.tsx
import React, { FunctionComponent, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { FilterBarContext, FilterValues } from './FilterBarContext';
import useFilterBar from './useFilterBar';
import { isEqual } from 'lodash-es';
import { Button, Popover } from '@mui/material';
import { useTranslation } from '@/lib';

interface FilterBarProps extends PropsWithChildren {
  onChange?: (filterValues: FilterValues) => void;
  onSearch?: (filterValues: FilterValues) => void;
  initialFilterValues?: FilterValues;
  showAsPopover?: boolean;
  buttonSize?: 'small' | 'medium' | 'large';
  setMinHeight?: boolean;
}

const FilterBar: FunctionComponent<FilterBarProps> = ({
  onChange,
  onSearch,
  children,
  initialFilterValues,
  showAsPopover,
  buttonSize = 'medium',
  setMinHeight = true,
}) => {
  const { filterValues, setFilterValue, triggerSearch } = useFilterBar({ onSearch, initialFilterValues });
  const previousFilterValuesRef = useRef(filterValues);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();

  // Emit the filter values on change
  useEffect(() => {
    if (onChange) {
      if (!isEqual(filterValues, previousFilterValuesRef.current)) {
        onChange(filterValues);

        previousFilterValuesRef.current = filterValues;
      }
    }
  }, [filterValues]);

  const handleFilterButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
    setIsPopoverOpen(!isPopoverOpen);
  };

  return (
    <FilterBarContext.Provider value={{ filterValues, setFilterValue, triggerSearch }}>
      {showAsPopover ? (
        <div className={`flex items-center gap-2 ${setMinHeight ? 'min-h-14' : ''}`}>
          <Button onClick={handleFilterButtonClick} variant="outlined" size={buttonSize}>
            {t('filter')}
          </Button>
          <Popover
            open={isPopoverOpen}
            onClose={() => setIsPopoverOpen(false)}
            anchorEl={anchorElement}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <div className="p-4">{children}</div>
          </Popover>
        </div>
      ) : (
        <div className="flex items-start gap-2 min-h-14">{children}</div>
      )}
    </FilterBarContext.Provider>
  );
};

export default FilterBar;
