import { useTranslation } from '@/lib';
import { Button } from '@mui/material';
import { FunctionComponent } from 'react';

interface ImportWizardNavigationProps {
  isNextDisabled: boolean;
  nextStep?: () => void;
  isPreviousDisabled: boolean;
  isPreviousHidden?: boolean;
  previousStep?: () => void;
}

const ImportWizardNavigation: FunctionComponent<ImportWizardNavigationProps> = ({
  isPreviousDisabled,
  isNextDisabled,
  nextStep,
  previousStep,
  isPreviousHidden,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full  max-w-screen-sm justify-center  ">
      <div className="flex w-full justify-center gap-x-4 py-3 ">
        {!isPreviousHidden && (
          <Button size="large" disabled={isPreviousDisabled} onClick={previousStep}>
            {t('importer.previous')}
          </Button>
        )}
        <Button size="large" disabled={isNextDisabled} variant="contained" color="primary" onClick={nextStep}>
          {t('importer.next')}
        </Button>
      </div>
    </div>
  );
};

export default ImportWizardNavigation;
