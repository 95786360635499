import { ReactNode, FunctionComponent, PropsWithChildren } from 'react';
import TabbedLayoutTopBarHeader from './TabbedLayoutTopBarHeader';
import NavigationTabs from './NavigationTabs';
import { TabMenuItem } from '../types/MenuItem';
import { Skeleton } from '@mui/material';
import { TabbedLayoutTopBarVerticalSeperator } from './TabbedLayoutTopBarVerticalSeperator';
import { useLayoutActions } from '@/providers/layout-actions-provider/LayoutActionsProvider';

interface TabbedLayoutTopBarProps extends PropsWithChildren {
  header?: string | ReactNode;
  headerIcon?: ReactNode;
  actions?: ReactNode;
  center?: ReactNode;
  tabRightActions?: ReactNode;
  tabItems?: TabMenuItem[];
}

export const TabbedLayoutTopBar: FunctionComponent<TabbedLayoutTopBarProps> = ({
  header,
  actions,
  center,
  headerIcon,
  tabItems = [],
  tabRightActions,
  children,
}) => {
  const { actions: contextActions } = useLayoutActions();

  return (
    <div className=" sticky top-0 z-10 flex min-h-[50px] w-full items-center justify-between border-b border-gray-200 bg-gray-50 bg-opacity-80  px-4 backdrop-blur-sm dark:border-gray-600 dark:bg-gray-900 dark:bg-opacity-80">
      <div className={` flex h-full items-center gap-x-1`}>
        {children || (
          <>
            <TabbedLayoutTopBarHeader icon={headerIcon}>{header}</TabbedLayoutTopBarHeader>
            <TabbedLayoutTopBarVerticalSeperator />
            {tabItems.length === 0 ? (
              <>
                <Skeleton variant="rounded" width={125} height={20} />
                <Skeleton variant="rounded" width={125} height={20} />
                <Skeleton variant="rounded" width={125} height={20} />
              </>
            ) : (
              <NavigationTabs tabItems={tabItems} />
            )}
            {tabRightActions && (
              <>
                <TabbedLayoutTopBarVerticalSeperator />

                <div>{tabRightActions}</div>
              </>
            )}
          </>
        )}
      </div>
      {center && <div>{center}</div>}
      <div className="flex gap-x-2">{contextActions ?? actions}</div>
    </div>
  );
};
