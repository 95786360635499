import { ButtonBase } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { MenuItem } from '../types/MenuItem';
import { useTranslation } from '@/lib';

interface SecondaryMenuItemProps {
  menuItem: MenuItem;
}

export const SecondaryMenuItem: FunctionComponent<SecondaryMenuItemProps> = ({ menuItem }) => {
  const [isRouteActive, setIsRouteActive] = useState(false);
  const currentLocation = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (currentLocation.pathname) {
      setIsRouteActive(
        menuItem.activeRouteIds.some((r) => {
          const regex = new RegExp(`^${r.replace('*', '.*')}$`);
          return regex.test(currentLocation.pathname);
        }),
      );
    }
  }, [currentLocation.pathname, menuItem.activeRouteIds]);

  return (
    <ButtonBase
      sx={{
        justifyContent: 'start',
      }}
      component={RouterLink}
      to={menuItem.url}
      className={`rounded-md px-2  py-1 font-medium hover:cursor-pointer
${
  isRouteActive
    ? 'bg-primary-100 text-primary-700 dark:bg-gray-900 dark:text-primary-500'
    : 'hover:bg-gray-200 hover:text-gray-800 dark:hover:bg-gray-700 dark:hover:bg-opacity-60 dark:hover:text-gray-100'
} `}
    >
      <span className="truncate">{t(menuItem.label)}</span>
    </ButtonBase>
  );
};
