import { ApplicationLogDTO, ApplicationLogSeverity, ApplicationLogCategory } from './application-log.contracts';
import { apiClient } from '@/lib/api/base-client';
import { ApiResponse } from '@/lib/api/api-response';

export class ApplicationLogSerivce {
  public basePath = 'application-log';

  async get({
    severity = undefined,
    category = undefined,
    messageId = undefined,
    limit = 1000,
  }: {
    severity?: ApplicationLogSeverity;
    category?: ApplicationLogCategory;
    messageId?: string;
    limit?: number;
  } = {}): Promise<ApiResponse<ApplicationLogDTO[]>> {
    try {
      const applicationResponse = await apiClient.get<ApplicationLogDTO[]>(this.basePath, { severity, category, limit, messageId });
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}
export const applicationLogService = new ApplicationLogSerivce();
