import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';

import { DashboardCreateDTO, DashboardReadDTO, DashboardUpdateDTO } from './dashboard.contracts';
import { DashboardModel } from '../../types/DashboardModel';

export class DashboardService {
  public basePath = 'dashboard';

  async create(dashboardToCreate: DashboardCreateDTO): Promise<ApiResponse<undefined>> {
    try {
      const applicationResponse = await apiClient.post<undefined>(this.basePath + '/', dashboardToCreate);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async update(dashboardId: number, dashboardToUpdate: DashboardUpdateDTO): Promise<ApiResponse<undefined>> {
    try {
      const applicationResponse = await apiClient.patch<undefined>(`${this.basePath}/${dashboardId}`, dashboardToUpdate);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getAll(): Promise<ApiResponse<DashboardModel[]>> {
    try {
      const applicationResponse = await apiClient.get<DashboardReadDTO[]>(this.basePath);
      return applicationResponse.processPayload((payload) => {
        return payload.map(DashboardModel.fromDTO);
      });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getById(id: number): Promise<ApiResponse<DashboardModel>> {
    try {
      const applicationResponse = await apiClient.get<DashboardReadDTO>(`${this.basePath}/${id}`);
      return applicationResponse.processPayload(DashboardModel.fromDTO);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getByQuery(query: string): Promise<ApiResponse<DashboardModel[]>> {
    try {
      const applicationResponse = await apiClient.get<DashboardReadDTO[]>(`${this.basePath}/search?query=${query}`);
      return applicationResponse.processPayload((p) => p.map(DashboardModel.fromDTO));
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async delete(id: number): Promise<ApiResponse<undefined>> {
    try {
      const applicationResponse = await apiClient.delete<undefined>(`${this.basePath}/${id}`);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const dashboardService = new DashboardService();
