import { createContext, FC, PropsWithChildren, useState } from 'react';

interface DashboardWidgetContextType {
  isConfiguring: boolean;
  setIsConfiguring: (isConfiguring: boolean) => void;
  widgetId?: string;
  isLoading?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
  isError?: boolean;
  setIsError?: (isError: boolean) => void;
}

export const DashboardWidgetContext = createContext<DashboardWidgetContextType>({
  isConfiguring: false,
  setIsConfiguring: () => null,
  widgetId: undefined,
  isLoading: false,
  setIsLoading: () => null,
  isError: false,
  setIsError: () => null,
});

interface WidgetProviderProps extends PropsWithChildren {
  widgetId: string;
  isLoading?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
  isError?: boolean;
  setIsError?: (isError: boolean) => void;
}

export const DashboardWidgetProvider: FC<WidgetProviderProps> = ({ children, widgetId, isLoading, setIsLoading, isError, setIsError }) => {
  const [isConfiguring, setIsConfiguring] = useState<boolean>(false);

  return (
    <DashboardWidgetContext.Provider value={{ isConfiguring, setIsConfiguring, widgetId, isLoading, setIsLoading, isError, setIsError }}>
      {children}
    </DashboardWidgetContext.Provider>
  );
};
