import { ApiResponse } from '@/lib/api/api-response';
import { useState, useEffect, useCallback, useRef } from 'react';

interface UseApiCallOptions {
  manual?: boolean;
}

export function useApiCall<T>(initialApiCallArg: () => Promise<ApiResponse<T>>, options: UseApiCallOptions = {}) {
  const { manual = false } = options;

  const apiCallArgRef = useRef<() => Promise<ApiResponse<T>>>(initialApiCallArg);
  const [data, setData] = useState<T>();
  const [response, setResponse] = useState<ApiResponse<T>>();

  const [isLoading, setIsLoading] = useState(!manual); // Set to false initially
  const [isError, setIsError] = useState(false);

  const fetchData = useCallback(async () => {
    setIsError(false);
    setIsLoading(true);

    try {
      const result = await apiCallArgRef.current();
      setResponse(result);
      setIsError(!result.isSuccess);
      setData(result.payload);
    } catch (error) {
      console.log({ error });
      setIsError(true);
    }

    setIsLoading(false);
  }, []);

  const setApiCallArg = useCallback(
    (newApiCallArg: () => Promise<ApiResponse<T>>) => {
      apiCallArgRef.current = newApiCallArg;
      fetchData();
    },
    [fetchData],
  );

  useEffect(() => {
    if (!manual) {
      fetchData();
    }
  }, [fetchData, manual]);

  return { data, isLoading, isError, fetchData, response, setApiCallArg };
}
