import { TFunction } from 'i18next';
import { IDashboardWidgetBaseConfiguration } from '../../../types/IDashboardWidgetBaseConfiguration';
import { GroupPerTimeType } from './group-per-time-type.enum';

export class EventsOverTimeWidgetConfiguration implements IDashboardWidgetBaseConfiguration {
  title = 'dashboard.eventsOverTimeWidgetConfiguration.title';
  eventsOverTimeType = GroupPerTimeType.DAY;

  constructor(t: TFunction) {
    this.title = t(this.title);
  }
}
