import { PoGrid } from '@/components/grid/PoGrid';
import { FunctionComponent, useState } from 'react';
import { useApiCall } from '@/hooks/useApiCall';
import { eventService } from '../api/events/event.service';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { GridOptions, ICellRendererParams, RowDoubleClickedEvent } from 'ag-grid-community';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { RelativeDateCellRenderer } from '@/components/grid/cells/RelativeDateCellRenderer';
import { TabbedPageLayout } from '@/modules/application/layouts/TabbedPageLayout';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { EventGroupListDto } from '../api/events/event.contracts';
import LinkCellRenderer, { LinkCellRendererParams } from '../../../components/grid/cells/LinkCellRenderer';
import EventGroupInfoDrawer from '../components/event-group/info-drawer/EventGroupInfoDrawer';
import { useTranslation } from '@/lib';

export const HandheldEventsPage: FunctionComponent = () => {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useApiCall<EventGroupListDto[]>(() => eventService.getEventGroups());

  const customGridOptions: GridOptions<EventGroupListDto> = {
    onRowDoubleClicked(event: RowDoubleClickedEvent<EventGroupListDto, unknown>) {
      if (event.data) {
        setSelectedEventGroupGuid(event.data.uuid);
        setIsDrawerOpen(true);
      }
    },
  };

  const columns: ColDefOrGroup<EventGroupListDto>[] = [
    {
      field: 'occurredAt',
      cellRenderer: RelativeDateCellRenderer,
      headerName: t('event.occurred_at'),
    },
    {
      field: 'location.name',
      headerName: t('event.location'),
      cellRenderer: LinkCellRenderer,
      cellRendererParams: (params: ICellRendererParams<EventGroupListDto>): LinkCellRendererParams => ({
        pathname: `/app/locations/${params.data?.location.id}`,
      }),
    },
    {
      field: 'device.name',
      headerName: t('event.device_name'),
    },
    {
      headerName: t('event.rfids'),
      field: 'totalEpcScans',
    },
    {
      headerName: t('event.barcodes'),
      field: 'totalBarcodeScans',
    },
    {
      headerName: t('event.mutations'),
      field: 'totalMutations',
    },
  ];

  // const handleSearch = (filterValues: FilterValues) => {
  //   console.log(JSON.stringify(filterValues));

  //   setApiCallArg(() =>
  //     eventService.get({
  //       locationId: filterValues.locationId,
  //       assetId: filterValues.assetId,
  //       dateFrom: filterValues.dateFrom,
  //       dateTo: filterValues.dateTo,
  //     }),
  //   );
  // };

  const [selectedEventGroupGuid, setSelectedEventGroupGuid] = useState<string | undefined>(undefined);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  return (
    <TabbedPageLayout>
      <TabbedPageLayoutBody>
        <div className="flex h-full flex-grow flex-col  ">
          {isError ? (
            <ErrorLoadingDataAlert />
          ) : (
            <>
              {/* <div className="mb-2">
                <FilterBar onSearch={handleSearch}>
                  <div className="w-72">
                    <LocationFilter label="Location" />
                  </div>
                  <div className="w-72">
                    <AssetFilter label="Asset" />
                  </div>
                  <div className="w-44">
                    <DateFilter label="Date From" filterField={FilterFieldName.dateFrom} />
                  </div>
                  <div className="w-44">
                    <DateFilter label="Date To" filterField={FilterFieldName.dateTo} />
                  </div>

                  <FilterBarSearchButton />
                </FilterBar>
              </div> */}
              <PoGrid isLoading={isLoading} colDefs={columns} rowData={data} gridOptions={customGridOptions} />
            </>
          )}
        </div>
        {selectedEventGroupGuid && (
          <EventGroupInfoDrawer eventGroupUid={selectedEventGroupGuid} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
        )}
      </TabbedPageLayoutBody>
    </TabbedPageLayout>
  );
};
