import { IDashboardWidget } from '../../../types/IDashboardWidget';
import { FunctionComponent, useEffect, useState } from 'react';
import { MapWidgetConfiguration } from './MapWidgetConfiguration';
import MapWidgetConfigurationForm from './MapWidgetConfigurationForm';
import ClusterMap, { MarkerProps } from '@/modules/map/components/ClusterMap';
import { DashboardWidgetProvider } from '../../dashboard-widget/DashboardWidgetContextProvider';
import DashboardWidgetConfigurationButton from '../../dashboard-widget/DashboardWidgetConfigurationButton';
import DashboardWidgetContent from '../../dashboard-widget/DashboardWidgetContent';
import DashboardWidgetConfigurationDrawer from '../../dashboard-widget/DasshboardWidgetConfigurationDrawer';
import { locationService } from '@/modules/locations/api/locations/location.service';
import { useTimerEndListener } from '@/providers/timer-provider/useTimerEndListener';
import DashboardWidgetTitle from '../../dashboard-widget/DashboardWidgetTitle';
import { AssetTypeBalanceAtLocationModel } from '@/modules/locations/types/AssetTypeBalanceAtLocationModel';

type MapWidgetProps = IDashboardWidget<MapWidgetConfiguration>;

const MapWidget: FunctionComponent<MapWidgetProps> = ({ configuration, id }) => {
  useTimerEndListener(getAssetTotals);
  const [isLoading, setIsLoading] = useState(true);
  const [locationMarkers, setLocationMarkers] = useState<MarkerProps[]>([]);
  const [locationAssetTotals, setLocationAssetTotals] = useState<AssetTypeBalanceAtLocationModel[]>([]);

  async function getAssetTotals() {
    const total = await locationService.getAssetTotals({
      includeEmptyLocations: configuration.includeEmptyLocations,
      includeInTransitAssets: configuration.includeInTransitAssets,
      assetTypeIds: configuration.assetTypeId ? [configuration.assetTypeId] : undefined,
    });

    if (total.isSuccess) {
      setLocationAssetTotals(total.payload);
      setIsLoading(false);
    } else {
      setLocationAssetTotals([]);
    }
  }
  useEffect(() => {
    getAssetTotals();
  }, [configuration.includeEmptyLocations, configuration.includeInTransitAssets, configuration.assetTypeId]);

  useEffect(() => {
    if (locationAssetTotals) {
      const markers = locationAssetTotals
        .filter((d) => d.hasValidCoordinates)
        .map<MarkerProps>((d) => {
          return {
            lat: d.coordinates ? d.coordinates[1] : 0,
            lng: d.coordinates ? d.coordinates[0] : 0,
            id: d.id,
            title: d.name,
            count: d.totalBalance,
            inTransitAssetId: d.inTransitAssetId,
          };
        });
      setLocationMarkers(markers);
    }
  }, [locationAssetTotals]);

  return (
    <DashboardWidgetProvider widgetId={id ?? 'unknown'} isLoading={isLoading} setIsLoading={setIsLoading}>
      <DashboardWidgetContent>
        <DashboardWidgetTitle>{configuration.title}</DashboardWidgetTitle>
        <DashboardWidgetConfigurationButton></DashboardWidgetConfigurationButton>

        <div className="w-full h-full rounded-b-lg overflow-hidden">
          <ClusterMap markers={locationMarkers} />
        </div>
      </DashboardWidgetContent>

      <DashboardWidgetConfigurationDrawer widgetName={configuration.title}>
        <MapWidgetConfigurationForm configuration={configuration} id={id} />
      </DashboardWidgetConfigurationDrawer>
    </DashboardWidgetProvider>
  );
};

export default MapWidget;
