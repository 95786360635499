import React, { useContext } from 'react';
import { FilterBarContext, FilterFieldName } from '../FilterBarContext';
import { AssetSelect } from '@/modules/assets/components/AssetSelect';
import { AssetModel } from '@/modules/assets/types/AssetModel';

interface AssetFilterProps {
  label: string;
}

const AssetFilter: React.FC<AssetFilterProps> = ({ label }) => {
  const { setFilterValue, filterValues } = useContext(FilterBarContext);

  function onAssetSelected(asset: AssetModel | null) {
    if (asset) {
      setFilterValue(FilterFieldName.assetId, asset.id);
    } else {
      setFilterValue(FilterFieldName.assetId, undefined);
    }
  }

  return (
    <AssetSelect
      label={label}
      selectedAssetId={filterValues.assetId ? Number.parseInt(filterValues.assetId.toString()) : undefined}
      onSelected={onAssetSelected}
    />
  );
};

export default AssetFilter;
