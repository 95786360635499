import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { LayoutActionsProvider } from '@/providers/layout-actions-provider/LayoutActionsProvider';
import { TabMenuItem } from '@/modules/application/types/MenuItem';
import { WidgetsRounded } from '@mui/icons-material';
import { Outlet } from 'react-router-dom';
import { useTranslation } from '@/lib';

export function AssetsLayout() {
  const { t } = useTranslation();

  const assetTabItems: TabMenuItem[] = [
    {
      activeRouteIds: ['/app/assets', '/app/assets/overview'],
      label: t('overview'),
      url: '/app/assets',
    },
  ];

  return (
    <LayoutActionsProvider>
      <TabbedLayout
        topBar={<TabbedLayoutTopBar header={t('assets')} headerIcon={<WidgetsRounded fontSize={'small'} />} tabItems={assetTabItems} />}
      >
        <Outlet />
      </TabbedLayout>
    </LayoutActionsProvider>
  );
}
