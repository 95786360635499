import { useTranslation } from '@/lib';
import { Alert } from '@mui/material';
import { FunctionComponent } from 'react';

interface ErrorLoadingDataAlertProps {}

const ErrorLoadingDataAlert: FunctionComponent<ErrorLoadingDataAlertProps> = () => {
  const { t } = useTranslation();
  return <Alert severity="error">{t('error_loading_data_verbose')}</Alert>;
};

export default ErrorLoadingDataAlert;
