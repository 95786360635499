import { z } from 'zod';

export const AssetFormSchema = z.object({
  code: z.string(),
  assetTypeId: z.number(),
  trackers: z.number().array(),
});

export const AssetCreateFormSchema = AssetFormSchema.extend({});

export type AssetFormModel = z.infer<typeof AssetFormSchema>;
