import { FunctionComponent, useMemo, useCallback, useEffect } from 'react';
import {
  GridApi,
  GridOptions,
  CellClickedEvent,
  SortChangedEvent,
  GridReadyEvent,
  ICellRendererParams,
  ColDef,
  ValueGetterParams,
} from 'ag-grid-community';

import { PoGrid, BASE_GRID_OPTIONS } from '@/components/grid/PoGrid';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { UserSettingKey } from '@/modules/users/api/user-settings/user-setting.contracts';
import { ColumnID } from '@/components/grid/column-ids';
import { RelativeDateCellRenderer } from '@/components/grid/cells/RelativeDateCellRenderer';
import LinkCellRenderer, { LinkCellRendererParams } from '@/components/grid/cells/LinkCellRenderer';
import { useTranslation } from '@/lib';
import { MovementModel } from '@/modules/movements/types/MovementModel';

interface AssetMovementsGridProps {
  data: MovementModel[] | undefined;
  isLoading: boolean;
  onSelectionChanged?: (selectedRows: MovementModel[]) => void;
  onRowDoubleClicked?: (movement: MovementModel) => void;
  onExportToExcelClicked?: (gridApi: GridApi<MovementModel> | undefined) => void;
  onSortChanged?: (event: SortChangedEvent<MovementModel>) => void;
  onGridReady?: (event: GridReadyEvent<MovementModel>) => void;
}

export const AssetMovementsGrid: FunctionComponent<AssetMovementsGridProps> = ({
  data,
  isLoading,
  onSelectionChanged,
  onRowDoubleClicked,
  onExportToExcelClicked,
  onSortChanged,
  onGridReady,
}) => {
  const { t } = useTranslation();

  // Initialize the grid column state hook
  const { setColumnStateGridApi, handleColumnStateChange, columnState, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.ASSET_MOVEMENT_TAB_GRID_COLUMN_STATE,
  );

  // Enable auto-saving of column state
  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  const columns: ColDef<MovementModel>[] = useMemo(() => {
    const baseColumns: ColDef<MovementModel>[] = [
      // {
      //   colId: ColumnID.SELECTION_CHECKBOX,
      //   checkboxSelection: true,
      //   headerCheckboxSelection: false,
      //   resizable: false,
      //   width: 40,
      //   minWidth: 40,
      //   maxWidth: 40,
      //   suppressColumnsToolPanel: true,
      //   suppressMenu: true,
      //   lockVisible: true,
      //   sortable: false,
      //   pinned: 'left',
      // },
      {
        colId: ColumnID.MOVEMENT_OCCURRED_AT,
        field: 'occurredAt',
        headerName: t('occurred_at'),
        cellRenderer: RelativeDateCellRenderer,
        valueFormatter: (params) => (params.value ? new Date(params.value).toISOString() : ''),
        sortable: true,
      },

      {
        colId: ColumnID.ASSET_CODE,
        field: 'asset.code',
        headerName: t('asset_code'),
        cellRenderer: LinkCellRenderer,
        cellRendererParams: (params: ICellRendererParams<MovementModel>): LinkCellRendererParams => ({
          pathname: `/app/assets/${params.data?.asset.id}`,
        }),
        sortable: true,
      },
      {
        colId: ColumnID.ASSET_TYPE_CODE,
        field: 'asset.assetType.code',
        headerName: t('asset_type_code'),
        sortable: true,
      },
      {
        colId: ColumnID.FROM_LOCATION_NAME,
        headerName: t('from_location'),
        valueGetter: (params) => params.data?.fromLocation?.name ?? '-',
        sortable: true,
      },
      {
        colId: ColumnID.TO_LOCATION_NAME,
        headerName: t('to_location'),
        valueGetter: (params) => params.data?.toLocation?.name ?? '-',
        sortable: true,
      },
      {
        colId: ColumnID.EVENT_TYPE,
        field: 'event.type',
        headerName: t('event_type'),
        sortable: true,
        valueGetter: (params: ValueGetterParams<MovementModel>) => {
          return t(`event.type.${params.data?.event.type}`);
        },
      },
      {
        colId: ColumnID.EVENT_SOURCE,
        field: 'event.source',
        headerName: t('event_source'),
        valueGetter: (params: ValueGetterParams<MovementModel>) => {
          return t(`event.event_source.${params.data?.event.source}`);
        },
        sortable: true,
      },
    ];

    // Apply saved column state to the column definitions
    if (data) {
      applyStateToDefinitions(baseColumns);
    }

    return baseColumns;
  }, [data, columnState, t]);

  const onCellClicked = useCallback((params: CellClickedEvent<MovementModel>) => {
    if (params.column.getColId() === ColumnID.SELECTION_CHECKBOX) {
      const node = params.node;
      node.setSelected(!node.isSelected());
    }
  }, []);

  const customGridOptions: GridOptions<MovementModel> = useMemo(
    (): GridOptions<MovementModel> => ({
      ...BASE_GRID_OPTIONS,
      onRowDoubleClicked(event) {
        if (onRowDoubleClicked) {
          onRowDoubleClicked(event.data!);
        }
      },
      onGridReady: (event) => {
        if (onGridReady) {
          onGridReady(event);
        }
        setColumnStateGridApi(event.api);
      },
      onSortChanged: (event) => {
        handleColumnStateChange(event);
        if (onSortChanged) {
          onSortChanged(event);
        }
      },
      getContextMenuItems: (params) => [
        'copy',
        'separator',
        {
          name: t('export_excel'),
          action: () => onExportToExcelClicked && onExportToExcelClicked(params.api),
        },
      ],
      getRowId: (params) => {
        return params.data.id.toLocaleString();
      },
      suppressRowClickSelection: true,

      onCellClicked,
      onSelectionChanged: (event) => {
        if (onSelectionChanged) {
          const selectedRows = event.api.getSelectedRows();
          onSelectionChanged(selectedRows);
        }
      },
      // Grid column state change handlers
      onColumnMoved: handleColumnStateChange,
      onColumnVisible: handleColumnStateChange,
      onColumnResized: handleColumnStateChange,
      suppressMultiSort: true,
    }),
    [
      t,
      onExportToExcelClicked,
      handleColumnStateChange,
      onSelectionChanged,
      onRowDoubleClicked,
      onGridReady,
      onSortChanged,
      setColumnStateGridApi,
    ],
  );

  return (
    <PoGrid
      isLoading={isLoading}
      colDefs={columns}
      gridOptions={customGridOptions}
      disableResizeColumnsToFit
      rowData={data}
      disableDefaultGridOptions
    />
  );
};
