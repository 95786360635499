import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@mui/material';
import { ChangeEvent, FunctionComponent, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useTranslation } from '@/lib';

interface StringInputModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (inputValue: string) => Promise<boolean>; // Asynchronously handle the input value
  title: string; // Title of the dialog
  description: string; // Description in the dialog
  inputLabel: string; // Label for the input field
  validationRule?: (inputValue: string) => boolean; // Optional validation rule for the input
  validationHelperText?: string; // Helper text to show when validation fails
}

const StringInputModal: FunctionComponent<StringInputModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  description,
  inputLabel,
  validationRule = () => true, // Default validation rule that always passes
  validationHelperText = '',
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [touched, setTouched] = useState(false);

  function onInputChange(e: ChangeEvent<HTMLInputElement>) {
    if (!touched) setTouched(true);
    setInputValue(e.target.value);
  }

  async function onConfirmClicked() {
    setIsLoading(true);
    try {
      const isSuccess = await onConfirm(inputValue);
      if (isSuccess) {
        onClose(); // Optionally close the dialog on success
      }
    } catch (error) {
      toast.error(t('error_occurred'));
    } finally {
      setIsLoading(false);
    }
  }

  const isValid = validationRule(inputValue);
  const showValidationError = !isValid && touched;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className="w-96">
        <DialogContentText>{description}</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label={inputLabel}
          type="text"
          fullWidth
          variant="standard"
          value={inputValue}
          onChange={onInputChange}
          helperText={showValidationError ? validationHelperText : ''}
          error={showValidationError}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {t('cancel')}
        </Button>
        <LoadingButton loading={isLoading} variant="contained" onClick={onConfirmClicked} disabled={!isValid || !touched}>
          {t('confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default StringInputModal;
