import { ImportType } from '../api/importer.contracts';
import { ImportDefinition } from './import-definition';
import { AssetImport } from './importers/asset-import';
import { LocationImport } from './importers/location-import';
import { ParentLocationImport } from './importers/parent-location-import';

export class ImportDefinitionFactory {
  public static create(type: ImportType): ImportDefinition {
    switch (type) {
      case ImportType.ASSET:
        return new AssetImport();
      case ImportType.LOCATION:
        return new LocationImport();
      case ImportType.PARENT_LOCATION:
        return new ParentLocationImport();
      default:
        throw new Error('Invalid import definition type');
    }
  }

  public static getAllImportDefinitions(): ImportDefinition[] {
    const definitions: ImportDefinition[] = [];
    for (const type in ImportType) {
      definitions.push(this.create(type as ImportType));
    }

    return definitions;
  }
}
