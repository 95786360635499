import { DTO } from '@/types/dto-wrapper';
import { BalancesAtLocationDTO, AssetTypeBalanceDTO } from '../api/balances/balance.contracts';

export class BalancesAtLocationModel extends DTO<BalancesAtLocationDTO> {
  public balances: BalanceAtLocationModel[];
  public children?: BalancesAtLocationModel[];

  constructor(data: BalancesAtLocationDTO) {
    super(data);
    this.balances = data.assetTypeBalances.map((balance) => new BalanceAtLocationModel(balance));

    this.children = data.children?.map((child) => new BalancesAtLocationModel(child));
  }

  get totalBalance(): number {
    return this.balances.reduce((acc, balance) => acc + balance.dto.balance, 0);
  }

  get totalIncoming(): number {
    return this.balances.reduce((acc, balance) => acc + balance.dto.incoming, 0);
  }

  get totalOutgoing(): number {
    return this.balances.reduce((acc, balance) => acc + balance.dto.outgoing, 0);
  }

  get totalNumberOfStaleAssets(): number {
    return this.balances.reduce((acc, balance) => acc + balance.dto.numberOfStaleAssets, 0);
  }

  get totalNumberOfStaleAssets60Days(): number {
    return this.balances.reduce((acc, balance) => acc + balance.dto.numberOfStaleAssets60Days, 0);
  }

  // Get for children of children as well
  get totalNumberOfChildren(): number {
    if (!this.children) return 0;
    return this.children.reduce((acc, child) => acc + 1 + child.totalNumberOfChildren, 0);
  }

  get isTotalBalanceDifferentFromIndividualBalances(): boolean {
    return this.dto.individualBalance !== undefined && this.totalBalance !== this.dto.individualBalance;
  }

  get isTotalIncomingDifferentFromIndividualIncoming(): boolean {
    return this.dto.individualIncoming !== undefined && this.totalIncoming !== this.dto.individualIncoming;
  }

  get isTotalOutgoingDifferentFromIndividualOutgoing(): boolean {
    return this.dto.individualOutgoing !== undefined && this.totalOutgoing !== this.dto.individualOutgoing;
  }

  get isTotalNumberOfStaleAssetsDifferentFromIndividualStaleAssets(): boolean {
    return this.dto.individualStaleAssets !== undefined && this.totalNumberOfStaleAssets !== this.dto.individualStaleAssets;
  }

  get isTotalNumberOfStaleAssets60DaysDifferentFromIndividualStaleAssets60Days(): boolean {
    return this.dto.individualStaleAssets60Days !== undefined && this.totalNumberOfStaleAssets60Days !== this.dto.individualStaleAssets60Days;
  }

  get returnRate(): number {
    return this.dto.returnRate;
  }

  static fromDTO(data: BalancesAtLocationDTO): BalancesAtLocationModel {
    return new BalancesAtLocationModel(data);
  }

  static fromDTOs(data: BalancesAtLocationDTO[]): BalancesAtLocationModel[] {
    return data.map((dto) => BalancesAtLocationModel.fromDTO(dto));
  }

  static distinctAssetTypes(models: BalancesAtLocationModel[]): BalanceAtLocationAssetTypeModel[] {
    const assetTypesMap = new Map<number, string>();

    models.forEach((model) => {
      model.balances.forEach((balance) => {
        if (!assetTypesMap.has(balance.dto.assetTypeId)) {
          assetTypesMap.set(balance.dto.assetTypeId, balance.dto.assetTypeName);
        }
      });
    });

    const distinctAssetTypes = Array.from(assetTypesMap.entries()).map(([assetTypeId, assetTypeName]) => {
      return new BalanceAtLocationAssetTypeModel(assetTypeId, assetTypeName);
    });

    return distinctAssetTypes;
  }
}

export class BalanceAtLocationModel extends DTO<AssetTypeBalanceDTO> {
  constructor(data: AssetTypeBalanceDTO) {
    super(data);
  }

  get isIndividualBalanceDifferentFromTotal(): boolean {
    if (this.dto.individualBalance === undefined) return false;

    return this.dto.individualBalance !== this.dto.balance;
  }
}

export class BalanceAtLocationAssetTypeModel {
  assetTypeId: number;
  assetTypeName: string;

  constructor(assetTypeId: number, assetTypeName: string) {
    this.assetTypeId = assetTypeId;
    this.assetTypeName = assetTypeName;
  }
}
