import { PlaceRounded } from '@mui/icons-material';
import { ImportType, ValidatedRow } from '../../api/importer.contracts';
import { ImportDefinition } from '../import-definition';
import { AxiosProgressEvent } from 'axios';
import { importerService } from '../../api/importer.service';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import i18n from '@/lib/i18n/i18nConfig';
import { ApiResponse } from '@/lib/api/api-response';
import { red } from '@mui/material/colors';
import { isEmpty, isString } from 'lodash-es';

export interface LocationImportSummary {
  locationsToCreate: number;
  locations: LocationImportRow[];
}

export interface LocationImportRow extends ValidatedRow {
  name: string;
  code: string;
}

export class LocationImport implements ImportDefinition<LocationImportSummary, LocationImportRow> {
  title = i18n.t('location_import');
  description = i18n.t('import_locations_into_the_system');
  icon = (<PlaceRounded sx={{ fontSize: 40 }} />);
  iconColor = red[500];
  type = ImportType.LOCATION;
  templateFileUrl = '/importers/locations/location_import_template.csv';

  async preview(data: { file: File; onUploadProgress: (progressEvent: AxiosProgressEvent) => void }) {
    console.log('LocationImport.preview', data);
    const response = await importerService.validateFile<LocationImportSummary, LocationImportRow>(
      this.type,
      data.file,
      data.onUploadProgress,
    );

    return response;
  }

  async import(payload: { summary: LocationImportSummary }): Promise<ApiResponse<boolean>> {
    console.log('LocationImport.import', payload);
    const response = await importerService.importLocations(payload.summary.locations);

    return response.processPayload((data) => response.isSuccess);
  }

  summaryColDefs: ColDefOrGroup<LocationImportSummary>[] = [
    {
      field: 'locationsToCreate',
      headerName: i18n.t('locations_to_create'),
    },
  ];
  summaryMaxHeightInPx = 200;
  summaryMaxWidthInPx = 400;

  errorRowColDefs: ColDefOrGroup<LocationImportRow>[] = [
    {
      headerName: i18n.t('location_code'),
      field: 'code',
    },
    {
      headerName: i18n.t('location_name'),
      field: 'name',
    },
    {
      field: 'isValid',
      headerName: i18n.t('valid'),
      cellDataType: 'text',
      valueFormatter: (params) => (params.value ? i18n.t('valid') : i18n.t('invalid')),
      cellClassRules: {
        'text-green-500': (params) => params.value,
        'text-red-500': (params) => !params.value,
      },
    },
    {
      headerName: i18n.t('error_reason'),
      field: 'errorReason',
      valueFormatter: (params) => {
        if (isEmpty(params.value) || !isString(params.value)) {
          return '';
        }

        return i18n.t(params.value.toLowerCase());
      },
    },
  ];
}
