import { Navigate, RouteObject } from 'react-router-dom';
import { ImporterOptionsPage } from '../pages/configuration/ImporterOptionsPage';
import { ImporterPage } from '../pages/configuration/Importer';
import RequirePermissionRoute from '@/components/permissions/RequirePermissionRoute';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';

export const IMPORTER_CONFIGURATION_ROUTES: RouteObject[] = [
  {
    path: 'importer',
    children: [
      {
        index: true,
        element: (
          <RequirePermissionRoute requiredPermission={Permission.FILE_IMPORTER_USE}>
            <Navigate to="options" replace />
          </RequirePermissionRoute>
        ),
      },
      {
        path: 'options',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.FILE_IMPORTER_USE}>
            <ImporterOptionsPage />
          </RequirePermissionRoute>
        ),
      },
      {
        path: ':importerType',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.FILE_IMPORTER_USE}>
            <ImporterPage />
          </RequirePermissionRoute>
        ),
      },
    ],
  },
];
