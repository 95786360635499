import { Navigate, RouteObject } from 'react-router-dom';
import { TrackersPage } from '../pages/configuration/TrackersPage';
import { TrackerDetailPage } from '../pages/configuration/TrackerDetailPage';
import RequirePermissionRoute from '@/components/permissions/RequirePermissionRoute';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';

export const TRACKER_CONFIGURATION_ROUTES: RouteObject[] = [
  {
    path: 'trackers',
    children: [
      {
        index: true,
        element: (
          <RequirePermissionRoute requiredPermission={Permission.TRACKERS_VIEW}>
            <Navigate to="overview" replace />
          </RequirePermissionRoute>
        ),
      },
      {
        path: 'overview',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.TRACKERS_VIEW}>
            <TrackersPage />
          </RequirePermissionRoute>
        ),
      },
      {
        path: 'create',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.TRACKERS_EDIT}>
            <TrackerDetailPage isCreate={true} />
          </RequirePermissionRoute>
        ),
      },
      {
        path: 'detail/:trackerId',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.TRACKERS_VIEW}>
            <TrackerDetailPage />
          </RequirePermissionRoute>
        ),
      },
    ],
  },
];
