import { FunctionComponent, ReactElement } from 'react';
import { MenuItem } from '../types/MenuItem';
import { SecondaryMenuItem } from './SecondaryMenuItem';

interface SecondaryMenuItemGroupProps {
  header: ReactElement | string;
  menuItems: MenuItem[];
  headerAction?: ReactElement;
}

export const SecondaryMenuItemGroup: FunctionComponent<SecondaryMenuItemGroupProps> = ({ menuItems, header, headerAction }) => {
  return (
    <div className="w-full ">
      <div className="mt-0.5 flex h-7 items-center justify-between pl-2">
        <div className=" text-xs font-semibold    text-slate-500 dark:text-slate-400">{header}</div>
        <div>{headerAction}</div>
      </div>
      <div className="mt-0 flex flex-col gap-y-2  text-sm  ">
        {menuItems.map((menuItem) => (
          <SecondaryMenuItem key={menuItem.url} menuItem={menuItem} />
        ))}
      </div>
    </div>
  );
};
