import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { UserCreateFormSchema, UserFormModel, UserFormSchema } from './UserFormModel';
import { zodResolver } from '@hookform/resolvers/zod';
import UserFormDisplay from './UserFormDisplay';

interface UserFormLogicProps {
  initialValues: UserFormModel;
  onSubmit: (data: UserFormModel) => Promise<boolean>;
  isCreate?: boolean;
  userId?: number;
}

const UserFormLogic: FunctionComponent<UserFormLogicProps> = ({ initialValues, onSubmit, isCreate, userId }) => {
  const form = useForm<UserFormModel>({
    mode: 'onSubmit',
    defaultValues: initialValues,
    resolver: zodResolver(isCreate ? UserCreateFormSchema : UserFormSchema),
  });

  const handleSubmit = async (data: UserFormModel) => {
    await onSubmit(data)
      .then(() => form.reset(data))
      .catch((err) => console.error(err));
  };

  return <UserFormDisplay form={form} onSubmit={handleSubmit} isCreate={isCreate} userId={userId} />;
};

export default UserFormLogic;
