import { ICellRendererParams } from 'ag-grid-community';
import { AssetTypeModel } from '../types/AssetTypeModel';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { useTranslation } from '@/lib';

export function generateAssetTypeColumns(): ColDefOrGroup<AssetTypeModel>[] {
  const { t } = useTranslation();

  return [
    {
      field: 'dto.imageThumbnailUrl',
      headerName: t('image'),
      cellRenderer: (params: ICellRendererParams<AssetTypeModel>) => {
        return params?.value ? (
          <div className="flex items-start justify-center h-full w-full max-w-full ">
            <img src={params.value} className="object-contain flex h-full mr-auto max-w-32" alt={params.data?.name} />
          </div>
        ) : (
          ''
        );
      },
    },
    {
      field: 'code',
      headerName: t('code'),
    },
    {
      field: 'name',
      headerName: t('name'),
    },
    {
      field: 'description',
      headerName: t('description'),
    },
    {
      field: 'modelYear',
      headerName: t('model_year'),
    },
    {
      field: 'count',
      headerName: t('number_of_assets'),
    },
  ];
}
