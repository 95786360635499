import AssetTypeSelect from '@/modules/asset-types/components/AssetTypeSelect';
import { LocationSelect } from '@/modules/locations/components/LocationSelect';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { FunctionComponent } from 'react';
import { Close } from '@mui/icons-material';

interface TransactionCreateModalProps {
  open: boolean;
  onClose: () => void;
  transactionId?: number;
  isCreate?: boolean;
}

const TransactionCreateModal: FunctionComponent<TransactionCreateModalProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'md'}>
      {/* header */}
      <div className="div flex items-center justify-between mr-4">
        <div className="flex flex-col">
          <DialogTitle>Create a new Transaction</DialogTitle>

          <div className="text-lg font-medium ">{/* {data.name} ({data.code}) */}</div>
        </div>
        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
          <Close />
        </IconButton>
      </div>
      {/* content */}
      <DialogContent>
        <div className="flex flex-col max-w-md gap-y-2">
          <DatePicker
            className="w-full"
            label={'Date'}
            format="DD-MM-YYYY"
            //   value={isNil(filterValues[filterField]) ? null : dayjs(filterValues[filterField])}
            //   onChange={handleChange}
          />
          <LocationSelect label="From Location" onSelected={(value) => console.log({ value })} />
          <LocationSelect label="To Location" onSelected={(value) => console.log({ value })} />
        </div>
        {/* <div className="grid grid-cols-2 items-center  max-w-md gap-y-2 mt-6">
            <div>Asset Type</div>
            <div className="justify-self-end">Quantity</div>
            <div>
              <AssetTypeSelect onChange={console.log} />
            </div>
            <div className="justify-self-end">123</div>
          </div> */}

        <div className="grid grid-cols-2 mt-4 items-center rounded-lg overflow-hidden  max-w-md bg-gray-50 border">
          <div className="bg-gray-200 px-2 py-2 text-xs font-bold uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
            Asset Type
          </div>
          <div className="bg-gray-200 px-2 py-2 text-right text-xs font-bold uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
            Count
          </div>
          <div className="px-2 py-1">
            <AssetTypeSelect onChange={console.log} />
          </div>
          <div className="justify-self-end px-2 font-bold text-lg tracking-wider">
            <TextField
              variant="outlined"
              value={123}
              InputProps={{
                inputProps: {
                  style: { textAlign: 'right' },
                },
              }}
            />
          </div>
          <div className="col-span-2 h-px bg-gray-300 dark:bg-gray-700"></div>
          <div className="px-2 py-1">
            <AssetTypeSelect onChange={console.log} />
          </div>
          <div className="justify-self-end px-2 font-bold text-lg tracking-wider">
            {' '}
            <TextField
              variant="outlined"
              value={123}
              InputProps={{
                inputProps: {
                  style: { textAlign: 'right' },
                },
              }}
            />
          </div>
          <div className="col-span-2 h-px bg-gray-300 dark:bg-gray-700"></div>
          <div className="px-2 py-1">
            <AssetTypeSelect onChange={console.log} />
          </div>
          <div className="justify-self-end px-2 font-bold text-lg tracking-wider">
            {' '}
            <TextField
              variant="outlined"
              value={123}
              InputProps={{
                inputProps: {
                  style: { textAlign: 'right' },
                },
              }}
            />
          </div>
          {/* <div className="col-span-2 h-px bg-gray-300 dark:bg-gray-700"></div> */}
          {/* {topAssetTypesByAssetCount.map((assetType) => (
                <Fragment key={assetType.id}>
                  <Link
                    component={RouterLink}
                    to={`/app/configuration/assets/types/${assetType.id}`}
                    underline="hover"
                    className="whitespace-nowrap px-2 py-2 font-medium text-gray-900 dark:text-white"
                  >
                    {assetType.name}
                  </Link>
                  <div className=" px-2 py-2 text-right">{formatNumber(assetType.count)}</div>
                  <div className="col-span-2 h-px bg-gray-300 dark:bg-gray-700"></div>
                </Fragment>
              ))} */}
        </div>

        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default TransactionCreateModal;
