import { SettingsRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FunctionComponent, useContext } from 'react';
import { DashboardWidgetContext } from './DashboardWidgetContextProvider';
import { useDashboardContext } from '../../contexts/DashboardContextProvider';

interface DashboardWidgetConfigurationButtonProps {}

const DashboardWidgetConfigurationButton: FunctionComponent<DashboardWidgetConfigurationButtonProps> = () => {
  const { isConfiguring, setIsConfiguring, widgetId } = useContext(DashboardWidgetContext);
  const { setEditingWidgetId, editingWidgetId, isEditingDashboard } = useDashboardContext();

  return (
    <div className={`absolute right-1 top-1 z-[1000] transition-opacity  ${isEditingDashboard ? 'opacity-100' : 'opacity-0'}`}>
      <IconButton
        size="small"
        color="primary"
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
          e.bubbles = false;

          // Don't allow edits when dashboard is not in edit mode
          if (!isEditingDashboard) {
            return;
          }
          // If clicked and it's the cogwheel of a different widget
          // Open the drawer for this widget
          if (editingWidgetId !== widgetId || !isConfiguring) {
            setIsConfiguring(true);
            setEditingWidgetId(widgetId ?? '');
          } else {
            setIsConfiguring(false);
            setEditingWidgetId('');
          }
        }}
      >
        <SettingsRounded></SettingsRounded>
      </IconButton>
    </div>
  );
};

export default DashboardWidgetConfigurationButton;
