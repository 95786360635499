import { PoGrid } from '@/components/grid/PoGrid';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { FunctionComponent } from 'react';
import { ApiKeyModel } from '../models/ApiKeyModel';
import { apiKeyService } from '../api/api-key.service';
import { useApiCall } from '@/hooks/useApiCall';
import { GridOptions, ICellRendererParams } from 'ag-grid-enterprise';
import { Button, IconButton } from '@mui/material';
import { useStringInputModal } from '@/components/modals/string-input-modal/useStringInputModal';
import { toast } from 'react-toastify';
import { RelativeDateCellRenderer } from '@/components/grid/cells/RelativeDateCellRenderer';
import { ContentCopyOutlined, Delete, EditOutlined } from '@mui/icons-material';
import useClipboard from '@/hooks/useClipboard';
import { isEmpty } from 'lodash-es';
import { useDeleteConfirmation } from '@/components/modals/delete-confirmation-modal/useDeleteConfirmationModal';
import RequirePermissionComponent from '@/components/permissions/RequirePermissionComponent';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useTranslation } from '@/lib';

interface ApiKeyManagerProps {
  isReadOnly?: boolean;
}

const ApiKeyManager: FunctionComponent<ApiKeyManagerProps> = ({ isReadOnly }) => {
  const { data, isLoading, isError, fetchData, setApiCallArg } = useApiCall<ApiKeyModel[]>(() => apiKeyService.getAll());
  const { t } = useTranslation();

  const { ModalComponent: deleteModal, handleOpenModal: openDeleteApiKeyConfirmation } = useDeleteConfirmation({
    questionTitle: t('setting.api.delete'),
    questionText: t('setting.api.delete_confirmation'),
    onDelete: async (id) => {
      if (!id) return;

      const response = await apiKeyService.delete(id);
      if (response.isSuccess) {
        fetchData();
        toast.success(t('setting.api.delete_success'));
        return true;
      } else {
        toast.error(t('error_occurred'));
        return false;
      }
    },
  });

  const { ModalComponent, handleOpenModal } = useStringInputModal({
    title: t('setting.api.add_title'),
    inputLabel: t('setting.api.add_name_label'),
    description: t('setting.api.add_description'),
    onConfirmed: async (name) => {
      const response = await apiKeyService.create(name);
      if (response.isSuccess) {
        fetchData();
        toast.success(t('setting.api.add_success'));
        return true;
      } else {
        toast.error(t('error_occurred'));
        return false;
      }
    },
    successMessage: t('setting.api.add_success'),
    validationRule: (inputValue) => inputValue.length > 0 && inputValue.length <= 25,
    validationHelperText: t('setting.api.add_name_validation'),
  });

  const columns: ColDefOrGroup<ApiKeyModel>[] = [
    {
      field: 'dto.name',
      headerName: t('setting.api.name'),
      editable: !isReadOnly,
      width: 125,
      cellRenderer: (params: ICellRendererParams<ApiKeyModel, string>) => {
        return (
          <div className="flex items-center justify-between">
            <span>{params.value}</span>
            {/* Edit icon */}
            {!isReadOnly && (
              <div className="flex items-center">
                <EditOutlined fontSize="inherit" />
              </div>
            )}
          </div>
        );
      },
      flex: 1,
    },
    {
      field: 'dto.createdAt',
      headerName: t('setting.api.created'),
      cellRenderer: RelativeDateCellRenderer,
      width: 175,
    },
    {
      field: 'dto.key',
      headerName: t('setting.api.key'),
      valueFormatter: (params) => {
        // Split string with '...', show start and end 5 characters
        return params.value.slice(0, 5) + '...' + params.value.slice(-5);
      },
      cellRenderer: (params: ICellRendererParams<ApiKeyModel, string>) => {
        const { copyToClipboard, isCopied } = useClipboard();

        return (
          <div className="flex">
            <span>{params.value?.slice(0, 5)}</span>
            <span>...</span>
            <span>{params.value?.slice(-5)}</span>
            {isCopied ? (
              <span className="text-green-700 dark:text-green-500">{t('copied')}</span>
            ) : (
              <IconButton size="small" onClick={() => copyToClipboard(params.value ?? '')}>
                <ContentCopyOutlined fontSize="inherit" />
              </IconButton>
            )}
          </div>
        );
      },
      width: 150,
    },
    {
      field: 'dto.isEnabled',
      headerName: t('setting.api.active'),
      editable: !isReadOnly,
      width: 90,
    },
    {
      field: 'dto.id',
      headerName: '',
      cellRenderer: (params: ICellRendererParams<ApiKeyModel, string>) => {
        return (
          <div className="flex items-center justify-start h-full">
            <IconButton
              disabled={isReadOnly}
              size="small"
              onClick={() => {
                if (!params.data) return;
                openDeleteApiKeyConfirmation(params.data.id);
              }}
            >
              <Delete fontSize="inherit" />
            </IconButton>
          </div>
        );
      },
      width: 50,
    },
  ];

  const customGridOptions: GridOptions<ApiKeyModel> = {
    defaultColDef: {
      suppressMenu: true,
      suppressMovable: true,
    },

    singleClickEdit: true,
    onRowDoubleClicked: (event) => {
      console.log(event);
    },
    onCellEditingStopped: async (event) => {
      if (event.data && (isEmpty(event.data.dto.name) || event.data.dto.name.length < 1 || event.data.dto.name.length > 25)) {
        toast.error(t('setting.api.name_validation'));

        // Revert the change
        event.data.dto.name = event.oldValue;

        // Refresh the cell to display the reverted value
        event.api.refreshCells({ rowNodes: [event.node], columns: [event.column] });

        return;
      }

      if (event.data && event.data.id && event.data.dto.name && event.data.dto.isEnabled !== undefined) {
        const response = await apiKeyService.update(event.data?.id, event.data?.dto.name, event.data?.dto.isEnabled);
        if (response.isSuccess) {
          toast.success(t('setting.api.update_success'));
        } else {
          toast.error(t('error_occurred'));
        }
      }
    },
  };

  const onAddApiKeyClicked = () => {
    handleOpenModal();
  };

  return (
    <div className="h-64">
      <div className="flex justify-between items-center mb-2">
        <RequirePermissionComponent permission={Permission.API_SETTINGS_EDIT}>
          <Button variant="text" color="primary" onClick={onAddApiKeyClicked}>
            {t('setting.api.add')}
          </Button>
        </RequirePermissionComponent>
      </div>
      {isError ? (
        <div>{t('error_loading_data')}</div>
      ) : (
        <PoGrid isLoading={isLoading} colDefs={columns} rowData={data} gridOptions={customGridOptions} />
      )}
      {ModalComponent}
      {deleteModal}
    </div>
  );
};

export default ApiKeyManager;
