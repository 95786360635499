import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTranslation } from '@/lib';
import TextLink from '@/components/TextLink';

interface LanguageToggleProps {
  language: string;
  onLanguageChange: (language: string) => void;
}

const LanguageToggle: React.FC<LanguageToggleProps> = ({ language, onLanguageChange }) => {
  const { t } = useTranslation();

  const handleLanguageChange = (event: React.MouseEvent<HTMLElement>, newLanguage: string | null) => {
    if (newLanguage) {
      onLanguageChange(newLanguage);
    }
  };

  return (
    <div className="flex w-full items-center justify-between pt-2">
      <TextLink className={'py-2'}>{t('language')}</TextLink>
      <ToggleButtonGroup size="small" value={language} exclusive onChange={handleLanguageChange} aria-label={t('language')}>
        <ToggleButton value="en" aria-label="English">
          <div className="text-xs">EN</div>
        </ToggleButton>
        <ToggleButton value="nl" aria-label="Nederlands">
          <div className="text-xs">NL</div>
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default LanguageToggle;
