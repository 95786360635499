import { FunctionComponent } from 'react';
import { PoGrid } from '@/components/grid/PoGrid';

import { useApiCall } from '@/hooks/useApiCall';
import { CellClassParams, ColDef, GridOptions, IGroupCellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { applicationLogService } from '../api/application-log.service';
import { ApplicationLogCategory, ApplicationLogDTO, ApplicationLogSeverity } from '../api/application-log.contracts';
import { FilterFieldName, FilterValues } from '@/components/filterbar/FilterBarContext';
import FilterBar from '@/components/filterbar/FilterBar';
import FilterBarSearchButton from '@/components/filterbar/FilterBarSearchButton';
import ListSelectFilter from '@/components/filterbar/filters/ListSelectFilter';
import { RelativeDateCellRenderer } from '@/components/grid/cells/RelativeDateCellRenderer';
import TextFilter from '@/components/filterbar/filters/TextFilter';
import { ITooltipCellRendererParams, TooltipCellRenderer } from '@/components/grid/cells/TooltipCellRenderer';
import { ApplicationLogDetailsCellRenderer } from '../components/ApplicationLogDetailsCellRenderer';
import { isEmpty } from 'lodash-es';
import { useTranslation } from '@/lib';

export const ApplicationLogOverviewPage: FunctionComponent = () => {
  const { data, isLoading, isError, setApiCallArg } = useApiCall<ApplicationLogDTO[]>(() => applicationLogService.get());
  const { t } = useTranslation();

  const APPLICATION_LOG_GRID_COLUMNS: ColDef<ApplicationLogDTO>[] = [
    {
      headerName: t('applicationLog.occurredAt'),
      field: 'occurredAt',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        innerRenderer: RelativeDateCellRenderer,
      } as IGroupCellRendererParams,
      width: 150,
    },
    {
      headerName: t('applicationLog.category.title'),
      field: 'category',
      width: 150,
      cellClass: 'font-semibold',
      cellClassRules: {
        'text-teal-700 dark:text-teal-500': (params: CellClassParams<ApplicationLogDTO, ApplicationLogCategory>) =>
          params.value === ApplicationLogCategory.IMPORT,
        'text-gray-700': (params: CellClassParams<ApplicationLogDTO, ApplicationLogCategory>) =>
          params.value === ApplicationLogCategory.OTHER,
        'text-purple-700 dark:text-purple-400': (params: CellClassParams<ApplicationLogDTO, ApplicationLogCategory>) =>
          params.value === ApplicationLogCategory.EVENT_PROCESSING,
      },
      valueFormatter: (params: ValueFormatterParams<ApplicationLogDTO, ApplicationLogCategory>) => {
        switch (params.value) {
          case ApplicationLogCategory.IMPORT:
            return t('applicationLog.category.import');
          case ApplicationLogCategory.OTHER:
            return t('applicationLog.category.other');
          case ApplicationLogCategory.EVENT_PROCESSING:
            return t('applicationLog.category.eventProcessing');
          default:
            return '';
        }
      },
    },
    {
      headerName: t('applicationLog.severity.title'),
      field: 'severity',
      width: 125,
      cellClass: 'font-semibold',
      cellClassRules: {
        'text-red-500': (params: CellClassParams<ApplicationLogDTO, ApplicationLogSeverity>) => params.value === ApplicationLogSeverity.ERROR,
        'text-yellow-800 dark:text-yellow-500': (params: CellClassParams<ApplicationLogDTO, ApplicationLogSeverity>) =>
          params.value === ApplicationLogSeverity.WARNING,
        'text-blue-500': (params: CellClassParams<ApplicationLogDTO, ApplicationLogSeverity>) => params.value === ApplicationLogSeverity.INFO,
      },
      valueFormatter: (params: ValueFormatterParams<ApplicationLogDTO, ApplicationLogSeverity>) => {
        switch (params.value) {
          case ApplicationLogSeverity.ERROR:
            return t('applicationLog.severity.error');
          case ApplicationLogSeverity.WARNING:
            return t('applicationLog.severity.warning');
          case ApplicationLogSeverity.INFO:
            return t('applicationLog.severity.info');
          default:
            return '';
        }
      },
    },
    {
      headerName: t('applicationLog.messageId'),
      field: 'messageId',
      width: 150,
      cellRenderer: TooltipCellRenderer,
      cellRendererParams: (params: ValueFormatterParams<ApplicationLogDTO, string>): ITooltipCellRendererParams => ({
        tooltip: params.value ?? undefined,
      }),
    },
    {
      headerName: t('applicationLog.message'),
      field: 'message',
      flex: 1,
    },
  ];

  const handleSearch = (filterValues: FilterValues) => {
    setApiCallArg(() =>
      applicationLogService.get({
        messageId: filterValues.messageId,
        severity: filterValues.logSeverity,
        category: filterValues.logCategory,
      }),
    );
  };

  const customGridOptions: GridOptions<ApplicationLogDTO> = {
    getRowId: (data) => data.data.id.toString(),
    masterDetail: true,
    detailRowAutoHeight: true,
    detailCellRenderer: ApplicationLogDetailsCellRenderer,
    isRowMaster: (logItem) => {
      if (isEmpty(logItem.details)) {
        return false;
      } else {
        return true;
      }
    },
  };

  return (
    <TabbedLayout
      topBar={
        <TabbedLayoutTopBar>
          <TabbedLayoutTopBarHeader icon={null}>{t('applicationLog.title')}</TabbedLayoutTopBarHeader>
        </TabbedLayoutTopBar>
      }
    >
      <TabbedPageLayoutBody>
        <div className="flex h-full flex-grow flex-col  ">
          {isError ? (
            <ErrorLoadingDataAlert />
          ) : (
            <>
              <div className="mb-2 flex items-center justify-between">
                <FilterBar onSearch={handleSearch}>
                  <div className="w-72">
                    <TextFilter label={t('applicationLog.messageId')} filterFieldName={FilterFieldName.messageId} />
                  </div>
                  <div className="w-72">
                    <ListSelectFilter
                      label={t('applicationLog.severity.title')}
                      filterFieldName={FilterFieldName.logSeverity}
                      options={[ApplicationLogSeverity.ERROR, ApplicationLogSeverity.WARNING, ApplicationLogSeverity.INFO, null]}
                      renderOption={(item) => {
                        switch (item) {
                          case ApplicationLogSeverity.ERROR:
                            return t('applicationLog.severity.error');
                          case ApplicationLogSeverity.WARNING:
                            return t('applicationLog.severity.warning');
                          case ApplicationLogSeverity.INFO:
                            return t('applicationLog.severity.info');
                          default:
                            return <div className="italic">{t('applicationLog.severity.all')}</div>;
                        }
                      }}
                      valueExtractor={(item) => item}
                    />
                  </div>
                  <div className="w-72">
                    <ListSelectFilter
                      label={t('applicationLog.category.title')}
                      filterFieldName={FilterFieldName.logCategory}
                      options={[ApplicationLogCategory.IMPORT, ApplicationLogCategory.EVENT_PROCESSING, ApplicationLogCategory.OTHER, null]}
                      renderOption={(item) => {
                        switch (item) {
                          case ApplicationLogCategory.IMPORT:
                            return t('applicationLog.category.import');
                          case ApplicationLogCategory.EVENT_PROCESSING:
                            return t('applicationLog.category.eventProcessing');
                          case ApplicationLogCategory.OTHER:
                            return t('applicationLog.category.other');
                          default:
                            return <div className="italic">{t('applicationLog.category.all')}</div>;
                        }
                      }}
                      valueExtractor={(item) => item}
                    />
                  </div>

                  <FilterBarSearchButton isLoading={isLoading} />
                </FilterBar>
              </div>
              <PoGrid
                isLoading={isLoading}
                colDefs={APPLICATION_LOG_GRID_COLUMNS}
                rowData={data}
                disableResizeColumnsToFit
                gridOptions={customGridOptions}
              />
            </>
          )}
        </div>
      </TabbedPageLayoutBody>
    </TabbedLayout>
  );
};
