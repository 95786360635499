import { FunctionComponent, ReactElement } from 'react';
import { useTimelineItemContext } from './TimelineItemContext';

interface TimelineItemCenterPathAndIconProps {
  icon: ReactElement;
  onClick?: () => void;
}

const TimelineItemCenterPathAndIcon: FunctionComponent<TimelineItemCenterPathAndIconProps> = ({ icon, onClick }) => {
  const { isFirst, isLast } = useTimelineItemContext();
  return (
    <div className="flex flex-none flex-col items-center  ">
      {!isFirst && <div className="h-4 w-1 rounded-ee-3xl rounded-es-3xl bg-gray-500"></div>}
      <div
        onKeyDown={onClick}
        tabIndex={0}
        role="button"
        aria-roledescription="button"
        className={`flex w-12 flex-none  items-center justify-center ${onClick ? 'group cursor-pointer' : ''}`}
        onClick={onClick}
      >
        <div className="m-1 flex h-7 w-7 items-center justify-center rounded-full bg-blue-600 text-white group-hover:bg-primary-500">
          {icon}
        </div>
      </div>
      {!isLast && <div className="min-h-[2px] w-1 grow rounded-se-3xl rounded-ss-3xl bg-gray-500"></div>}
    </div>
  );
};

export default TimelineItemCenterPathAndIcon;
