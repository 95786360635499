import { ActionBar, ColorModeContext, PageLayout, PageLayoutBody } from '@/modules/application';
import { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import Dashboard from '../components/dashboard/Dashboard';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import { DashboardContext, useDashboard } from '../contexts/DashboardContextProvider';
import { Button, ButtonGroup, IconButton, MenuItem, Menu } from '@mui/material';
import DashboardWidgetCatalog from '../components/widget-catalog/DashboardWidgetCatalog';
import { generateWidgetCatalog } from '../components/widgets/widget-catalog';
import { TimerProvider } from '@/providers/timer-provider/TimerProvider';
import { ArrowDropDown, Close, Fullscreen, LeaderboardRounded } from '@mui/icons-material';
import { SettingsContext } from '@/modules/settings/contexts/settings.context';
import { SettingKey } from '@/modules/settings/api/settings/setting.contracts';
import { useFullscreenToggle } from '@/hooks/useFullscreenToggle';
import { useApiCall } from '@/hooks/useApiCall';
import { TabMenuItem } from '@/modules/application/types/MenuItem';
import { dashboardService } from '../api/dashboard/dashboard.service';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import CreateDashboardDialog from '../components/dashboard/CreateDashboardDialog';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { toast } from 'react-toastify';
import RenameDashboardDialog from '../components/dashboard/RenameDasbhoardDialog';
import { useDeleteConfirmation } from '@/components/modals/delete-confirmation-modal/useDeleteConfirmationModal';
import { useTranslation } from '@/lib';
import RequirePermissionComponent from '@/components/permissions/RequirePermissionComponent';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';

export const DashboardPage: FunctionComponent = () => {
  const { dashboardId } = useParams();
  const { t } = useTranslation();
  const [isDashboardFullscreen, toggleIsDashboardFullscreen] = useFullscreenToggle();

  const {
    setPlaceholderSize,
    widgetPlaceholderHeight,
    widgetPlaceholderWidth,
    editingWidgetId,
    setEditingWidgetId,
    isEditingDashboard,
    setIsEditingDashboard,
    widgets,
    setWidgets,
    dashboard,
    updateWidget,
    isLoadingDashboard,
    removeWidget,
    updateWidgetConfiguration,
  } = useDashboard(dashboardId);

  const { getSettingValueByKey } = useContext(SettingsContext);
  const isWhiteLabled = getSettingValueByKey(SettingKey.WHITE_LABEL_ENABLED);
  const logoUrl = getSettingValueByKey(SettingKey.WHITE_LABEL_LOGO_URL);
  const logoDarkModeUrl = getSettingValueByKey(SettingKey.WHITE_LABEL_LOGO_DARK_MODE_URL);
  const { isDarkMode } = useContext(ColorModeContext);
  const logoForCurrentModeUrl = isDarkMode ? logoDarkModeUrl : logoUrl;

  // Dashboard tabs
  const [dashboardMenuItems, setDashboardMenuItems] = useState<TabMenuItem[]>([]);
  const { data, isLoading, fetchData } = useApiCall(() => dashboardService.getAll());
  const navigate = useNavigate();

  const [isCreateDashboardDialogOpen, setIsCreateDashboardDialogOpen] = useState(false);

  function onAddDashboardClicked() {
    setIsCreateDashboardDialogOpen(true);
  }

  function onDashboardCreated() {
    setIsCreateDashboardDialogOpen(false);
    fetchData();
  }

  useEffect(() => {
    if (!data) return;

    const menuItems = data.map<TabMenuItem>((d) => {
      return {
        activeRouteIds: [`/app/dashboards/${d.id}`],
        label: d.name,
        url: `/app/dashboards/${d.id}`,
        entityId: d.id,
      };
    });

    setDashboardMenuItems(menuItems);
    if (menuItems.length > 0) {
      // If route contains does not contain a dashboardId, default to top menu item
      if (!dashboardId) {
        navigate(menuItems[0].url, { replace: true });
      }
    }
  }, [data]);

  const { ModalComponent, handleOpenModal } = useDeleteConfirmation({
    questionText: t('dashboard.deleteConfirmation.questionText'), // Translated
    questionTitle: t('dashboard.deleteConfirmation.questionTitle'), // Translated
    onDelete: deleteDashboard,
  });

  function onDeleteDashboardClicked() {
    handleOpenModal();
  }

  async function deleteDashboard() {
    if (!dashboardId) return;

    const result = await dashboardService.delete(+dashboardId);
    if (result.isSuccess) {
      toast.success('Dashboard deleted');
      navigate('/dashboards', { replace: true });
    }
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function onRenameDashboardClicked() {
    setIsRenameDashboardDialogOpen(true);
  }

  const [isRenameDashboardDialogOpen, setIsRenameDashboardDialogOpen] = useState(false);

  function onDashboardRenamed() {
    setIsRenameDashboardDialogOpen(false);
    fetchData();
  }

  const widgetCatalog = useMemo(() => generateWidgetCatalog(t), [t]);

  return (
    <DashboardContext.Provider
      value={{
        setPlaceholderSize,
        widgetPlaceholderHeight,
        widgetPlaceholderWidth,
        editingWidgetId,
        setEditingWidgetId,
        isEditingDashboard,
        setIsEditingDashboard,
        widgets,
        setWidgets,
        dashboard,
        updateWidget,
        isLoadingDashboard,
        removeWidget,
        updateWidgetConfiguration,
      }}
    >
      <TimerProvider initialSeconds={60}>
        <TabbedLayout
          topBar={
            <TabbedLayoutTopBar
              header={t('dashboard.header')}
              headerIcon={<LeaderboardRounded fontSize={'small'} />}
              tabItems={dashboardMenuItems}
              tabRightActions={
                <RequirePermissionComponent permission={Permission.DASHBOARDS_EDIT}>
                  <div className="mt-0.5">
                    <Button variant="text" color="secondary" onClick={onAddDashboardClicked} className="px-2">
                      {t('dashboard.addTab')}
                    </Button>
                  </div>
                </RequirePermissionComponent>
              }
              actions={
                <>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      toggleIsDashboardFullscreen();
                    }}
                    startIcon={<Fullscreen />}
                  >
                    {t('dashboard.actions.showFullscreen')}
                  </Button>
                  <RequirePermissionComponent permission={Permission.DASHBOARDS_EDIT}>
                    <>
                      <ButtonGroup>
                        <Button
                          onClick={() => {
                            setIsEditingDashboard(!isEditingDashboard);
                          }}
                        >
                          {t('dashboard.actions.editWidgets')}
                        </Button>

                        <Button onClick={handleClick}>
                          <ArrowDropDown />
                        </Button>
                      </ButtonGroup>
                      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                        <MenuItem
                          onClick={() => {
                            onRenameDashboardClicked();
                            handleClose();
                          }}
                        >
                          {t('dashboard.actions.renameDashboard')}
                        </MenuItem>
                        <MenuItem
                          disabled={!data || data.length <= 1}
                          onClick={() => {
                            onDeleteDashboardClicked();
                            handleClose();
                          }}
                        >
                          {t('dashboard.actions.deleteDashboard')}
                        </MenuItem>
                      </Menu>
                    </>
                  </RequirePermissionComponent>

                  <DashboardWidgetCatalog isOpen={isEditingDashboard} widgets={widgetCatalog}></DashboardWidgetCatalog>
                </>
              }
            />
          }
        >
          <PageLayout showFullscreen={isDashboardFullscreen}>
            {isDashboardFullscreen ? (
              <div className="flex w-full items-center justify-between px-4">
                <div>
                  {dashboard ? (
                    <div className="text-2xl font-semibold">{dashboard.name}</div>
                  ) : (
                    <div className="text-2xl font-semibold">Dashboard</div>
                  )}
                </div>
                <div className="mt-2 flex flex-col items-center">
                  {isWhiteLabled && logoForCurrentModeUrl ? (
                    <>
                      <img
                        style={{
                          fill: 'currentColor',
                        }}
                        alt="logo"
                        src={logoForCurrentModeUrl.toString()}
                      />
                      <div className="text-gray mt-2 font-medium tracking-wide">Powered by Posito.io</div>
                    </>
                  ) : (
                    <div className="text-gray mt-2 font-medium tracking-wide">Powered by Posito.io</div>
                  )}
                </div>
                <div>
                  <IconButton
                    onClick={() => {
                      toggleIsDashboardFullscreen();
                    }}
                  >
                    <Close />
                  </IconButton>
                </div>
              </div>
            ) : (
              ''
            )}

            <PageLayoutBody>
              {isLoadingDashboard || isLoading ? (
                <LoadingBlock />
              ) : !dashboard ? (
                <div className="mt-4">
                  <ErrorLoadingDataAlert />
                </div>
              ) : (
                <>
                  <div
                    className={` ${
                      isEditingDashboard ? 'right-96 p-2' : 'right-0'
                    } absolute bottom-0 left-0 top-0 h-full overflow-auto  scrollbar scrollbar-track-gray-200 scrollbar-thumb-gray-300 dark:scrollbar-track-gray-700 dark:scrollbar-thumb-gray-950`}
                  >
                    <div className="pb-4 ">{widgets && <Dashboard widgets={widgets}></Dashboard>}</div>
                  </div>
                  <div className={'w-96'}></div>
                  {isEditingDashboard && (
                    <ActionBar location="top">
                      <Button onClick={() => setIsEditingDashboard(false)}>{t('dashboard.actions.saveDashboard')}</Button>
                    </ActionBar>
                  )}
                </>
              )}
            </PageLayoutBody>
          </PageLayout>
          {dashboard !== undefined && (
            <RenameDashboardDialog
              isOpen={isRenameDashboardDialogOpen}
              onClose={() => setIsRenameDashboardDialogOpen(false)}
              onDashboardRenamed={onDashboardRenamed}
              dashboard={dashboard}
            />
          )}
          <CreateDashboardDialog
            isOpen={isCreateDashboardDialogOpen}
            onClose={() => setIsCreateDashboardDialogOpen(false)}
            onDashboardCreated={onDashboardCreated}
          />
          {ModalComponent}
        </TabbedLayout>
      </TimerProvider>
    </DashboardContext.Provider>
  );
};
