import { FunctionComponent, ReactNode } from 'react';

export type DashboardWidgetCatalogItemImageColorType = 'green' | 'red' | 'blue' | 'yellow';
export interface DashboardWidgetCatalogItemImageProps {
  icon: ReactNode;
  color: DashboardWidgetCatalogItemImageColorType;
}

const DashboardWidgetCatalogItemImage: FunctionComponent<DashboardWidgetCatalogItemImageProps> = ({ icon, color }) => {
  let colorClasses = '';

  switch (color) {
    case 'green':
      colorClasses = 'bg-green-500 text-green-700';
      break;
    case 'red':
      colorClasses = 'bg-red-500 text-red-700';
      break;
    case 'blue':
      colorClasses = 'bg-indigo-500 text-indigo-700';
      break;
    case 'yellow':
      colorClasses = 'bg-yellow-500 text-yellow-700';
      break;
    default:
      break;
  }
  return <div className={`flex h-full w-full items-center justify-center rounded-lg  bg-opacity-20 text-3xl ${colorClasses}`}>{icon}</div>;
};

export default DashboardWidgetCatalogItemImage;
