import { RouteObject } from 'react-router-dom';
import { UserDetailPage, UsersPage } from '@/modules/users';
import { ROLE_CONFIGURATION_ROUTES } from '../submodules/roles/routes/role-configuration-routes';
import RequirePermissionRoute from '@/components/permissions/RequirePermissionRoute';
import { Permission } from '../submodules/roles/api/permissions.contracts';

export const USER_CONFIGURATION_ROUTES: RouteObject[] = [
  {
    path: 'users',
    element: (
      <RequirePermissionRoute requiredPermission={Permission.USER_VIEW}>
        <UsersPage />
      </RequirePermissionRoute>
    ),
  },
  {
    path: 'users/create',
    element: (
      <RequirePermissionRoute requiredPermission={Permission.USER_EDIT}>
        <UserDetailPage isCreate={true} />
      </RequirePermissionRoute>
    ),
  },
  {
    path: 'users/:userId',
    element: (
      <RequirePermissionRoute requiredPermission={Permission.USER_VIEW}>
        <UserDetailPage />
      </RequirePermissionRoute>
    ),
  },

  ...ROLE_CONFIGURATION_ROUTES,
];
