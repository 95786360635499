import { MenuItemGroup } from '@/modules/application';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';

export const IMPORTER_CONFIGURATION_SUB_MENU_ITEMS: MenuItemGroup[] = [
  {
    label: 'import',
    children: [
      {
        activeRouteIds: ['/app/configuration/importer/*'],
        label: 'file_importer',
        url: '/app/configuration/importer/options',
        requiredPermission: Permission.FILE_IMPORTER_USE,
      },
    ],
  },
];
