import { FunctionComponent, ReactElement, cloneElement } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { SxProps, Theme } from '@mui/system';

type EmptyStateProps = {
  icon?: ReactElement;
  className?: string;
  title: string;
  description: string | ReactElement;
  buttonText?: string;
  onButtonClick?: () => void;
  sx?: SxProps<Theme>; // Allows for custom styling
};

export const EmptyState: FunctionComponent<EmptyStateProps> = ({ icon, title, description, buttonText, onButtonClick, className }) => {
  return (
    <div className={`flex flex-col items-center p-2 ${className}`}>
      {icon && (
        <Box mb={2} color="text.secondary">
          {cloneElement(icon, { style: { fontSize: 60 } })}
        </Box>
      )}
      <Typography variant="h5" component="h2" gutterBottom>
        {title}
      </Typography>
      <Typography color="text.secondary" paragraph>
        {description}
      </Typography>
      {buttonText && onButtonClick && (
        <Button variant="contained" color="primary" onClick={onButtonClick}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};
