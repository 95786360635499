import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { PagePaginationResultDto, PageSortOrder } from '@/lib/api/pagination.page.dto';
import PagedResultDataText from '@/components/filterbar/PagedResultDataText';
import PaginationControls from '@/components/grid/PaginationControls';
import useQueryParamsFilters from '@/hooks/useQueryParamFilters';
import { CacheKey } from '@/providers/cache-provider/cache-key.enum';
import { FilterValues } from '@/components/filterbar/FilterBarContext';
import { GridReadyEvent, SortChangedEvent } from 'ag-grid-community';
import { ColumnID } from '@/components/grid/column-ids'; // Ensure this includes the necessary column IDs
import { useTranslation } from '@/lib';
import { MovementSortOption, MovementType } from '@/modules/movements/api/movements.contracts';
import { MovementModel } from '@/modules/movements/types/MovementModel';
import { movementService } from '@/modules/movements/api/movements.service';
import { AssetMovementsGrid } from './AssetMovementsGrid';

const MOVEMENT_FETCH_LIMIT = 1000;

export const AssetMovementsTab: FunctionComponent = () => {
  const { assetId } = useParams<{ assetId: string }>();
  const { t } = useTranslation();

  if (!assetId) {
    return null; // Or render an appropriate message
  }

  const assetIdNumber = Number.parseInt(assetId);

  const [searchParams] = useSearchParams();
  const { setFiltersToUrl, filters } = useQueryParamsFilters(
    {
      pageNumber: searchParams.get('pageNumber') ? Number.parseInt(searchParams.get('pageNumber')!) : 1,
      sortOption: MovementSortOption.OCCURRED_AT,
      sortDirection: PageSortOrder.DESC,
    },
    CacheKey.ASSET_MOVEMENT_FILTERS,
  );

  const {
    data: movementData,
    isLoading: movementIsLoading,
    isError: movementIsError,
    fetchData: fetchMovements,
    setApiCallArg,
  } = useApiCall<PagePaginationResultDto<MovementModel>>(() =>
    movementService.get(
      {
        assetId: assetIdNumber,
        type: MovementType.INCOMING,
      },
      {
        page: filters.current.pageNumber,
        limit: MOVEMENT_FETCH_LIMIT,
        order: filters.current.sortDirection,
        sort: filters.current.sortOption,
      },
    ),
  );

  const handleSearch = (filterValues: FilterValues, page?: number) => {
    setFiltersToUrl({
      ...filterValues,
      pageNumber: page ?? filters.current.pageNumber,
    });

    setApiCallArg(() =>
      movementService.get(
        {
          assetId: assetIdNumber,
          type: MovementType.INCOMING,
        },
        {
          page: filterValues.pageNumber,
          limit: MOVEMENT_FETCH_LIMIT,
          order: filterValues.sortDirection,
          sort: filterValues.sortOption,
        },
      ),
    );
  };

  const onPageChanged = (event: React.ChangeEvent<unknown>, page: number) => {
    handleSearch(
      {
        ...filters.current,
        pageNumber: page,
      },
      page,
    );
  };

  const onSortChanged = (event: SortChangedEvent<MovementModel>) => {
    if (event.columns && event.columns.length > 0 && event.source === 'uiColumnSorted') {
      const changedColumn = event.columns.find((col) => col.getSort());
      if (!changedColumn) {
        handleSearch(
          {
            ...filters.current,
            sortOption: undefined,
            sortDirection: undefined,
          },
          1,
        );
        return;
      }

      const changedColumnId = changedColumn.getColId();

      let sortOption: MovementSortOption | undefined;
      switch (changedColumnId) {
        case ColumnID.MOVEMENT_OCCURRED_AT:
          sortOption = MovementSortOption.OCCURRED_AT;
          break;
        case ColumnID.MOVEMENT_TYPE:
          sortOption = MovementSortOption.EVENT_TYPE;
          break;
        case ColumnID.ASSET_CODE:
          sortOption = MovementSortOption.ASSET_CODE;
          break;
        // Add other cases as necessary
        default:
          break;
      }

      let sortOrder: PageSortOrder | undefined;
      switch (changedColumn.getSort()) {
        case 'asc':
          sortOrder = PageSortOrder.ASC;
          break;
        case 'desc':
          sortOrder = PageSortOrder.DESC;
          break;
        default:
          sortOption = undefined;
          break;
      }

      handleSearch(
        {
          ...filters.current,
          sortOption,
          sortDirection: sortOrder,
        },
        1,
      );
    }
  };

  const onGridReady = (event: GridReadyEvent<MovementModel>) => {
    // Apply initial sort state
    if (filters.current.sortOption && filters.current.sortDirection) {
      let columnId: ColumnID | undefined;

      switch (filters.current.sortOption) {
        case MovementSortOption.OCCURRED_AT:
          columnId = ColumnID.MOVEMENT_OCCURRED_AT;
          break;
        case MovementSortOption.EVENT_TYPE:
          columnId = ColumnID.MOVEMENT_TYPE;
          break;
        case MovementSortOption.ASSET_CODE:
          columnId = ColumnID.ASSET_CODE;
          break;
        // Add other cases as necessary
        default:
          break;
      }

      if (columnId) {
        event.api.applyColumnState({
          state: [
            {
              colId: columnId,
              sort: filters.current.sortDirection === PageSortOrder.ASC ? 'asc' : 'desc',
            },
          ],
          defaultState: { sort: null },
        });
      }
    }
  };

  return (
    <div className="flex h-full flex-1 flex-grow max-w-[1920px] ">
      <div className="flex flex-col h-full w-full">
        <AssetMovementsGrid
          data={movementData?.data}
          isLoading={movementIsLoading}
          onSortChanged={onSortChanged}
          onGridReady={onGridReady}

          // Add any other necessary props
        />

        <div className="flex items-center gap-x-4">
          <PaginationControls
            isLoading={movementIsLoading}
            totalPageCount={movementData?.totalPages ?? 1}
            currentPage={filters.current.pageNumber ?? 1}
            totalElements={movementData?.totalElements ?? 0}
            onChange={onPageChanged}
          />
          <PagedResultDataText data={movementData} name={t('movement', { count: movementData?.totalElements ?? 0 })} />
        </div>
      </div>
    </div>
  );
};
