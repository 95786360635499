import { PoGrid } from '@/components/grid/PoGrid';
import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApiCall } from '@/hooks/useApiCall';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { CellClassFunc, GridOptions, RowDoubleClickedEvent, ValueGetterParams } from 'ag-grid-community';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { FilterValues } from '@/components/filterbar/FilterBarContext';
import { TabbedPageLayout } from '@/modules/application/layouts/TabbedPageLayout';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import dayjs from 'dayjs';
import { useExportToExcel } from '@/hooks/useExportToExcel';
import { useFormatting } from '@/hooks/useFormatting';
import { TransactionModel } from '../types/TransactionModel';
import { transactionService } from '../api/transactions/transaction.service';
import { RelativeDateCellRenderer } from '@/components/grid/cells/RelativeDateCellRenderer';
import { TransactionType } from '../api/transactions/transaction.contracts';
import TransactionCreateModal from '../components/TransactionCreateModal';

const EVENT_FETCH_LIMIT = 1000;

export const TransactionsPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { formatNumber } = useFormatting();
  const [selectedEventId, setSelectedEventId] = useState<number | undefined>(undefined);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const { exportToExcel } = useExportToExcel();
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState<boolean>(false);

  const onTransactionModalClose = () => {
    setIsTransactionModalOpen(false);
  };

  const { data, isLoading, isError, fetchData, setApiCallArg } = useApiCall<TransactionModel[]>(() =>
    transactionService.get({
      dateFrom: dayjs().subtract(1, 'month').startOf('day').utc(true),
      limit: EVENT_FETCH_LIMIT,
    }),
  );

  const customGridOptions: GridOptions<TransactionModel> = {
    onRowDoubleClicked(event: RowDoubleClickedEvent<TransactionModel, unknown>) {
      setSelectedEventId(event.data?.dto.id);
      setIsDrawerOpen(true);
    },
    rowClass: 'group',
    getRowClass: (params) => {
      if (params.rowIndex % 2 === 0) {
        return 'bg-gray-50 dark:bg-gray-850';
      }
      return '  dark:bg-gray-900';
    },
    getRowId: (params) => params.data.dto.id.toString(),
  };

  const transactionMetaDataCellClass = 'bg-gray-100  dark:bg-gray-800 group-hover:bg-gray-200 dark:group-hover:bg-gray-700';
  const transactionMetaDataCellClassFunc: CellClassFunc<TransactionModel, unknown> = (params) => {
    if (params.rowIndex % 2 === 0) {
      return 'bg-gray-100 dark:bg-gray-800 group-hover:bg-gray-200 dark:group-hover:bg-gray-700';
    } else {
      return 'bg-gray-50 dark:bg-gray-850 group-hover:bg-gray-200 dark:group-hover:bg-gray-700';
    }
  };
  const transactionMutationCellClassFunc: CellClassFunc<TransactionModel, unknown> = (params) => {
    if (params.rowIndex % 2 === 0) {
      return 'ag-right-aligned-cell group-hover:bg-gray-100 dark:bg-gray-900  dark:group-hover:bg-gray-800';
    } else {
      return 'ag-right-aligned-cell group-hover:bg-gray-100 dark:bg-gray-950 dark:group-hover:bg-gray-800';
    }
  };

  const columns: ColDefOrGroup<TransactionModel>[] = [
    {
      field: 'dto.date',
      cellRenderer: RelativeDateCellRenderer,
      headerName: 'Date',
      width: 150,

      cellClass: transactionMetaDataCellClassFunc,
    },
    {
      field: 'dto.number',
      headerName: 'Number',
      width: 150,
      headerClass: '',
      cellClass: transactionMetaDataCellClassFunc,
    },
    {
      field: 'dto.type',
      headerName: 'Type',
      width: 150,
      cellClass: transactionMetaDataCellClassFunc,
      cellClassRules: {
        'text-green-600  ': (params) => {
          return params.data?.dto.type === TransactionType.TakeIn;
        },
        'text-blue-800 dark:text-blue-700  ': (params) => {
          return params.data?.dto.type === TransactionType.GiveOut;
        },
      },
      valueFormatter: (params) => {
        switch (params.data?.dto.type) {
          case TransactionType.Transfer:
            return 'Transfer';
          case TransactionType.TakeIn:
            return 'Take In';
          case TransactionType.GiveOut:
            return 'Give Out';
          default:
            return '';
        }
      },
    },
    {
      field: 'dto.fromLocation.name',
      headerName: 'From Location',
      width: 200,
      cellClass: transactionMetaDataCellClassFunc,
    },
    {
      field: 'dto.toLocation.name',
      headerName: 'To Location',
      width: 200,
      cellClass: transactionMetaDataCellClassFunc,
    },
  ];

  if (data !== undefined && data.length > 0) {
    const firstTransaction = data[0];
    if (firstTransaction.dto.mutations !== undefined) {
      const mutationColumns: ColDefOrGroup<TransactionModel>[] = firstTransaction.dto.mutations.map((mutation, index, array) => ({
        valueGetter: (params: ValueGetterParams<TransactionModel>) => {
          const mutationOfAssetType = params.data?.dto.mutations?.find(
            (assetTypeMutation) => mutation.assetType.id === assetTypeMutation.assetType.id,
          );
          return mutationOfAssetType?.quantity;
        },
        headerName: mutation.assetType.code,
        width: 200,
        headerClass: 'ag-right-aligned-header',
        cellClass: transactionMutationCellClassFunc, // 'ag-right-aligned-cell group-hover:bg-gray-100 bg-gray-900 dark:group-hover:bg-gray-800',
        // flex: index === array.length - 1 ? 1 : undefined,
      }));
      columns.push(...mutationColumns);
    }
  }

  const handleSearch = (filterValues: FilterValues) => {
    console.log(JSON.stringify(filterValues));
    if (!validateFilterValues(filterValues)) {
      return;
    }

    setApiCallArg(() =>
      transactionService.get({
        locationId: filterValues.locationId,
        assetId: filterValues.assetId,
        dateFrom: filterValues.dateFrom?.startOf('day')?.utc(true), // .utc(true) takes the date as is without converting from local time first
        dateTo: filterValues.dateTo?.startOf('day')?.utc(true),
        limit: EVENT_FETCH_LIMIT,
      }),
    );
  };

  function onFilterChange(filterValues: FilterValues) {
    console.log(JSON.stringify(filterValues));

    // validateFilterValues(filterValues);
  }

  function validateFilterValues(filterValues: FilterValues): boolean {
    // // If the daterange is larger than 1 month, show a warning
    // if (filterValues.dateFrom && filterValues.dateTo) {
    //   // Date from cannot be after date to
    //   if (filterValues.dateFrom.isAfter(filterValues.dateTo)) {
    //     toast.warning('Date from cannot be after date to');
    //     return false;
    //   } else if (filterValues.dateTo.isBefore(filterValues.dateFrom)) {
    //     toast.warning('Date to cannot be before date from');
    //     return false;
    //   }

    //   const diffDays = filterValues.dateTo.diff(filterValues.dateFrom, 'days');
    //   if (diffDays > 31) {
    //     toast.warning('The date range is larger than 1 month. Please use a smaller range.');
    //     return false;
    //   }
    // } else if (filterValues.dateFrom) {
    //   const diffDays = dayjs().diff(filterValues.dateFrom, 'days');
    //   console.log({ diffDays, dateFrom: filterValues.dateFrom });

    //   if (diffDays > 31) {
    //     toast.warning('The date range is larger than 1 month. Please use a smaller range.');
    //     return false;
    //   }
    // } else if (filterValues.dateTo) {
    //   const diffDays = dayjs().diff(filterValues.dateTo, 'days');
    //   if (diffDays > 31) {
    //     toast.warning('The date range is larger than 1 month. Please use a smaller range.');
    //     return false;
    //   }
    // }
    return true;
  }

  return (
    <TabbedPageLayout>
      <TabbedPageLayoutBody>
        <div className="flex h-full flex-grow flex-col  ">
          {isError ? (
            <ErrorLoadingDataAlert />
          ) : (
            <>
              {/* <div className="mb-2 flex items-center justify-between">
                <FilterBar
                  onSearch={handleSearch}
                  onChange={onFilterChange}
                  initialFilterValues={{
                    dateFrom: dayjs().subtract(1, 'month'),
                  }}
                >
                  <div className="w-72">
                    <LocationFilter label="Location" />
                  </div>
                  <div className="w-72">
                    <AssetFilter label="Asset" />
                  </div>
                  <div className="w-44">
                    <DateFilter label="Date From" filterField={FilterFieldName.dateFrom} />
                  </div>
                  <div className="w-44">
                    <DateFilter label="Date To" filterField={FilterFieldName.dateTo} />
                  </div>

                  <FilterBarSearchButton isLoading={isLoading} />
                  {data !== undefined && !isLoading && (
                    <div className="h-full  self-end pb-1  pl-3">
                      {formatNumber(data?.length)}
                      {data?.length === EVENT_FETCH_LIMIT && '+'} {data?.length === 1 ? 'Event' : 'Events'} Found{' '}
                      {data.length >= EVENT_FETCH_LIMIT && `(Showing first ${formatNumber(data?.length)})`}
                    </div>
                  )}
                </FilterBar> 
                <div>
                  <Button disabled={isLoading || data === undefined} size="medium" variant="outlined">
                    Export to Excel
                  </Button>
                </div>
              </div>*/}
              <PoGrid isLoading={isLoading} colDefs={columns} rowData={data} gridOptions={customGridOptions} disableResizeColumnsToFit />
            </>
          )}
        </div>
        {/* {selectedEventId !== undefined && (
          <EventInfoDrawer eventId={selectedEventId} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
        )} */}
        <TransactionCreateModal open={isTransactionModalOpen} onClose={onTransactionModalClose} />
      </TabbedPageLayoutBody>
    </TabbedPageLayout>
  );
};
