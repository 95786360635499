export enum ImportError {
  NON_UNIQUE_ASSET_CODE = 'NON_UNIQUE_ASSET_CODE',
  NON_UNIQUE_TRACKER_BARCODE = 'NON_UNIQUE_TRACKER_BARCODE',
  NON_UNIQUE_TRACKER_EPC = 'NON_UNIQUE_TRACKER_EPC',
  NON_EXISTENT_ASSET_TYPE_CODE = 'NON_EXISTENT_ASSET_TYPE_CODE',
  BARCODE_AND_EPC_CODE_NOT_DEFINED = 'BARCODE_AND_EPC_CODE_NOT_DEFINED',
}

export enum ImportType {
  ASSET = 'ASSET',
  LOCATION = 'LOCATION',
  PARENT_LOCATION = 'PARENT_LOCATION',
}

export interface ImportPreviewResponse<TSummary, TValidatedRow extends ValidatedRow> {
  summary?: TSummary;
  errorRows: TValidatedRow[];
}

export interface ValidatedRow {
  isValid: boolean;
  errorReason?: ImportError;
}
