import { ButtonBase } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';

interface MainMenuButtonProps {
  isActive: boolean;
  label: string;
  icon: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const MainMenuButton: FunctionComponent<MainMenuButtonProps> = ({ onClick, icon, label, isActive }) => {
  return (
    <ButtonBase
      onClick={onClick}
      component="div"
      className={`flex h-9 justify-start   items-center  pl-3 pr-1  rounded-lg 
          dark:focus:text-primary-500  focus:text-primary-700 ${
            isActive
              ? 'bg-primary-100  text-primary-700 dark:bg-gray-850 dark:text-primary-500 '
              : 'text-gray-700 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700'
          }`}
    >
      <div className="flex items-center text-base ">{icon}</div>

      <span className="ml-2.5 text-sm font-semibold truncate">{label}</span>
    </ButtonBase>
  );
};
