import { IDashboardWidget } from '../../../types/IDashboardWidget';
import { FunctionComponent, useEffect, useState } from 'react';
import { EventsOverTimeByTypeWidgetConfiguration } from './EventsOverTimeByTypeWidgetConfiguration';

import EventsOverTimeByTypeWidgetConfigurationForm from './EventsOverTimeByTypeWidgetConfigurationForm';
import DashboardWidgetConfigurationButton from '../../dashboard-widget/DashboardWidgetConfigurationButton';
import DashboardWidgetContent from '../../dashboard-widget/DashboardWidgetContent';
import { DashboardWidgetProvider } from '../../dashboard-widget/DashboardWidgetContextProvider';
import DashboardWidgetConfigurationDrawer from '../../dashboard-widget/DasshboardWidgetConfigurationDrawer';
import { useTimerEndListener } from '@/providers/timer-provider/useTimerEndListener';
import { eventService } from '@/modules/events/api/events/event.service';
import { ReactECharts } from '@/components/chart/ReactEcharts';
import { EChartsOption } from 'echarts';
import DashboardWidgetTitle from '../../dashboard-widget/DashboardWidgetTitle';
import { EventCountOverTimeByTypeDTO } from '@/modules/events/api/events/event.contracts';
import dayjs from 'dayjs';
import { upperFirst } from 'lodash-es';

type EventsOverTimeByTypeWidgetProps = IDashboardWidget<EventsOverTimeByTypeWidgetConfiguration>;

const EventsOverTimeByTypeWidget: FunctionComponent<EventsOverTimeByTypeWidgetProps> = ({ configuration, id, layout }) => {
  const [eventsOverTime, setEventsOverTime] = useState<EventCountOverTimeByTypeDTO[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  // Listens to the timer context
  useTimerEndListener(handleTimerEnd);

  async function fetchEventsOverTime() {
    const response = await eventService.getCountsByType();
    if (response.isSuccess) {
      setEventsOverTime(response.payload);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchEventsOverTime();
  }, []);

  function handleTimerEnd() {
    fetchEventsOverTime();
  }

  // Get highest event count
  let highestEventCount = 0;
  eventsOverTime.forEach((event) => {
    event.counts.forEach((count) => {
      if (count.count > highestEventCount) {
        highestEventCount = count.count;
      }
    });
  });

  // Calculate left margin based on highest event count
  let leftMargin = 30;
  if (highestEventCount > 100) {
    leftMargin = leftMargin + 8;
  }
  if (highestEventCount > 1000) {
    leftMargin = leftMargin + 8;
  }
  if (highestEventCount > 10000) {
    leftMargin = leftMargin + 8;
  }

  const option: EChartsOption = {
    grid: {
      left: leftMargin,
      top: 10,
      right: 10, // Adjust the right padding for the legend
      bottom: 50,
    },
    xAxis: {
      type: 'category',
      data: eventsOverTime.map((event) => {
        return dayjs(event.date).format('D MMM');
      }),
      axisTick: {
        alignWithLabel: true,
      },
    },
    yAxis: {
      type: 'value',
      minInterval: 1,
    },
    series: eventsOverTime[0]?.counts.map((count) => ({
      name: upperFirst(count.type.toString()),
      type: 'bar',
      stack: 'total',
      data: eventsOverTime.map((event) => event.counts.find((c) => c.type === count.type)?.count || 0),
    })),
    tooltip: {
      trigger: 'axis',
      renderMode: 'html',
      appendTo: 'body',
      extraCssText: 'z-index: 9999999;', // This is needed to make sure the tooltip is on top of the drawer
      axisPointer: {
        type: 'shadow',
      },
    },
    backgroundColor: '#f01',
    legend: {
      orient: 'horizontal',
      bottom: 5,
      padding: [0, 0, 0, 0],
    },
  };

  return (
    <DashboardWidgetProvider widgetId={id ?? 'unknown'} isLoading={isLoading} setIsLoading={setIsLoading}>
      <DashboardWidgetContent>
        <DashboardWidgetConfigurationButton></DashboardWidgetConfigurationButton>
        <DashboardWidgetTitle>{configuration.title}</DashboardWidgetTitle>
        <div className="h-full w-full flex-1">
          <ReactECharts option={option} />
        </div>
      </DashboardWidgetContent>

      <DashboardWidgetConfigurationDrawer widgetName={configuration.title}>
        <EventsOverTimeByTypeWidgetConfigurationForm configuration={configuration} id={id} />
      </DashboardWidgetConfigurationDrawer>
    </DashboardWidgetProvider>
  );
};

export default EventsOverTimeByTypeWidget;
