import { useRef, useEffect, useCallback } from 'react';
import { throttle } from 'lodash-es';

function useResizeObserver(throttleMs: number, action: () => void) {
  // Ref for the element to observe
  const ref = useRef<HTMLDivElement>(null);

  // Throttle the provided action
  const throttledAction = useCallback(
    throttle(action, throttleMs, {
      leading: true,
      trailing: true,
    }),
    [action, throttleMs],
  );

  useEffect(() => {
    const observeTarget = ref.current;

    if (!observeTarget) return;

    // Create a new ResizeObserver that calls the throttled action
    const observer = new ResizeObserver(throttledAction);

    // Start observing the target element
    observer.observe(observeTarget);

    // Cleanup observer on component unmount or ref changes
    return () => {
      observer.disconnect();
    };
  }, [throttledAction]);

  return ref;
}

export default useResizeObserver;
