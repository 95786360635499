import { DTO } from '@/types/dto-wrapper';
import { LocationAssetDTO } from '../api/locations/location.contracts';

export class LocationAssetModel extends DTO<LocationAssetDTO> {
  constructor(data: LocationAssetDTO) {
    super(data);
  }

  static fromDTO(dto: LocationAssetDTO): LocationAssetModel {
    return new LocationAssetModel(dto);
  }
}
