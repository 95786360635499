import { useTranslation } from '@/lib';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';

export const useDate = () => {
  const { language } = useTranslation();
  // Get duration between two dates
  // In days, hours, minutes, seconds
  // Only shows the smallest units that are not 0
  function formatDuration(dateStart: Date, dateEnd: Date) {
    const duration = dayjs.duration(dayjs(dateEnd).diff(dayjs(dateStart)));

    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    let result = '';
    if (days) {
      result += `${days}d `;
    }
    if (hours) {
      result += `${hours}h `;
    }
    if (minutes) {
      result += `${minutes}m `;
    }
    if (seconds) {
      result += `${seconds}s`;
    }

    // If duration is 0, return 0s
    if (!result) {
      result = '0s';
    }

    return result;
  }

  function formatPeriodInDays(dateFrom: Date, dateTo: Date = new Date()) {
    if (!dateFrom) {
      return '';
    }

    return dayjs(dateTo).diff(dayjs(dateFrom), 'days');
  }

  function formatRelativeDate(date?: Date) {
    if (!date) {
      return '';
    }

    return dayjs(date).locale(language).fromNow();
  }
  function formatDateTimestamp(date?: Date) {
    if (!date) {
      return '';
    }

    return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
  }

  function formatDateTimestampShort(date?: Date) {
    if (!date) {
      return '';
    }

    return dayjs(date).format('DD MMM - HH:mm');
  }

  function formatDateStamp(date?: Date) {
    if (!date) {
      return '';
    }

    return dayjs(date).format('YYYY-MM-DD');
  }

  return {
    formatPeriodInDaysFromNow: formatPeriodInDays,
    formatRelativeDate,
    formatDateTimestamp,
    formatDateTimestampShort,
    formatDateStamp,
    formatDuration,
  };
};
