import React, { useEffect, useMemo, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { LabelModel } from '@/modules/labels/models/LabelModel';
import { labelService } from '../api/label.service';

interface LabelSelectProps {
  value: number | undefined;
  onChange: (event: SelectChangeEvent<number>) => void;
  disabled?: boolean;
  excludedLabelIds?: number[];
}

const LabelSelect: React.FC<LabelSelectProps> = ({ value, onChange, disabled, excludedLabelIds }) => {
  const [labels, setLabels] = useState<LabelModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const excludedLabelIdsMemoed = useMemo(() => excludedLabelIds?.toString(), [excludedLabelIds]);

  const fetchLabels = async () => {
    setIsLoading(true);
    const response = await labelService.getAll();
    if (response.isSuccess && response.payload) {
      if (excludedLabelIds) {
        setLabels(response.payload.filter((t) => !excludedLabelIds.includes(t.dto.id)));
      } else {
        setLabels(response.payload);
      }
    } else {
      console.error('Failed to fetch labels:', response.message);
      setLabels([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchLabels();
  }, []);

  useEffect(() => {
    console.log('loading exludedLocationIds', { excludedLabelIds });

    fetchLabels();
  }, [excludedLabelIdsMemoed]);

  return (
    <FormControl fullWidth disabled={isLoading || disabled}>
      <InputLabel>Label</InputLabel>
      <Select
        value={value && labels.length > 0 ? value : ''}
        onChange={onChange}
        label="Label"
        sx={{
          ':disabled': {
            color: 'text.primary',
          },
        }}
      >
        {labels.map((label) => (
          <MenuItem key={label.dto.id} value={label.dto.id}>
            {label.dto.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LabelSelect;
