import { DTO } from '@/types/dto-wrapper';
import { DeviceReadDTO, DeviceType, DeviceLocationDTO } from '../api/device/device.contracts';

class DeviceLocationModel extends DTO<DeviceLocationDTO> {
  constructor(dto: DeviceLocationDTO) {
    super(dto);
  }

  get id(): number {
    return this.dto.id;
  }

  get name(): string {
    return this.dto.name;
  }

  get code(): string {
    return this.dto.code;
  }

  toDTO(): DeviceLocationDTO {
    return {
      id: this.id,
      name: this.name,
      code: this.code,
    };
  }
}

export class DeviceModel extends DTO<DeviceReadDTO> {
  public location?: DeviceLocationModel;

  constructor(dto: DeviceReadDTO) {
    super(dto);
    this.location = dto.location ? new DeviceLocationModel(dto.location) : undefined;
  }

  get id(): number {
    return this.dto.id;
  }

  get code(): string {
    return this.dto.code;
  }

  get name(): string {
    return this.dto.name;
  }

  get brand(): string {
    return this.dto.brand;
  }

  get type(): string {
    return this.dto.type;
  }

  get serialNumber(): string {
    return this.dto.serialNumber;
  }

  get deviceType(): DeviceType {
    return this.dto.deviceType;
  }

  get identifier(): string | undefined {
    return this.dto.identifier;
  }

  get locationId(): number | undefined {
    return this.dto.locationId;
  }

  get lastEventReceivedAt(): Date | undefined {
    return this.dto.lastEventReceivedAt ? new Date(this.dto.lastEventReceivedAt) : undefined;
  }

  static fromDTO(dto: DeviceReadDTO): DeviceModel {
    return new DeviceModel(dto);
  }

  toDTO(): DeviceReadDTO {
    return {
      id: this.id,
      code: this.code,
      name: this.name,
      brand: this.brand,
      type: this.type,
      serialNumber: this.serialNumber,
      deviceType: this.deviceType,
      identifier: this.identifier,
      locationId: this.locationId,
      location: this.location ? this.location.toDTO() : undefined,
      lastEventReceivedAt: this.lastEventReceivedAt ? this.lastEventReceivedAt : undefined,
    };
  }
}
