import { FunctionComponent } from 'react';
import { useWizardContext } from './ImportWizardContext';
import { Alert, CircularProgress } from '@mui/material';
import { PoGrid } from '@/components/grid/PoGrid';
import { useTranslation } from '@/lib';
import ImportWizardNavigation from './ImportWizardNavigation';

export const ImportWizardStepPreview: FunctionComponent = () => {
  const { isUploadingFile, uploadProgress, previewResponse, importDefinition, moveForward, moveBackward, currentStepTitle } =
    useWizardContext();
  const { t } = useTranslation();

  return (
    <div className="flex h-full w-full flex-grow flex-col items-center px-20">
      <div className="flex w-full flex-1 flex-grow flex-col items-center justify-center gap-y-2">
        {isUploadingFile ? (
          <div className="flex h-full flex-1 flex-grow flex-col items-center justify-center">
            <div className="text-lg font-semibold">{currentStepTitle}</div>
            <div className="mt-6 flex w-full flex-col items-center">
              <div className="relative flex">
                <CircularProgress variant="determinate" size={125} value={uploadProgress} thickness={1.5} />
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="text-2xl">{`${Math.round(uploadProgress)}%`}</div>
                </div>
              </div>
              <div className="mt-4 text-lg font-semibold">{t('uploading_file')}</div>
            </div>
          </div>
        ) : previewResponse?.isSuccess ? (
          previewResponse.payload.summary ? (
            <div
              className="mt-4 flex h-full w-full flex-1 flex-grow flex-col justify-center"
              style={{
                maxWidth: importDefinition.summaryMaxWidthInPx ? `${importDefinition.summaryMaxWidthInPx}px` : '100%',
                maxHeight: importDefinition.summaryMaxHeightInPx ? `${importDefinition.summaryMaxHeightInPx}px` : '100%',
              }}
            >
              <div className="mb-2 w-full text-center text-lg font-semibold">{currentStepTitle}</div>
              <PoGrid rowData={[previewResponse.payload.summary]} colDefs={importDefinition.summaryColDefs}></PoGrid>
            </div>
          ) : (
            <div className="flex h-full w-full flex-1 flex-grow flex-col">
              <div className="my-4 flex flex-col items-center">
                <Alert severity="error">
                  <div className="">
                    {t('importer.preview.file_contains_errors', {
                      count: previewResponse.payload.errorRows.length,
                    })}
                  </div>
                </Alert>
              </div>

              <PoGrid rowData={previewResponse.payload.errorRows} colDefs={importDefinition.errorRowColDefs}></PoGrid>
            </div>
          )
        ) : (
          <div className="mt-8 flex w-full flex-col items-center">
            <div className="text-lg font-semibold">{t('importer.preview.file_upload_failed')}</div>
            <div className="mt-4 text-lg font-semibold">{previewResponse?.message}</div>
          </div>
        )}
      </div>
      <ImportWizardNavigation
        isNextDisabled={!previewResponse?.payload.summary}
        nextStep={moveForward}
        previousStep={moveBackward}
        isPreviousDisabled={false}
      />
    </div>
  );
};
