import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';
import { UserModel } from '../../types/UserModel';
import { UserCreateDTO, UserDTO, UserUpdateDTO } from './users.contracts';

export class UserService {
  public basePath = 'users';

  async getUsers(): Promise<ApiResponse<UserModel[]>> {
    try {
      const response = await apiClient.get<UserDTO[]>(this.basePath + '/');
      return response.processPayload((p) => p.map(UserModel.fromDto));
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getMe(firebaseId: string): Promise<ApiResponse<UserModel>> {
    try {
      const response = await apiClient.get<UserDTO>(`${this.basePath}/me?firebaseId=${firebaseId}`);
      return response.processPayload(UserModel.fromDto);
    } catch (error) {
      console.log(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getUserById(userId: number): Promise<ApiResponse<UserModel>> {
    try {
      const response = await apiClient.get<UserDTO>(`${this.basePath}/${userId}`);
      return response.processPayload(UserModel.fromDto);
    } catch (error) {
      console.log(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }
  async createUser(newUser: UserCreateDTO): Promise<ApiResponse<undefined>> {
    try {
      const response = await apiClient.post<undefined>(this.basePath + '/', newUser);
      return response;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async updateUser(userId: number, userToUpdate: UserUpdateDTO): Promise<ApiResponse<undefined>> {
    try {
      const response = await apiClient.put<undefined>(`${this.basePath}/${userId}`, userToUpdate);
      return response;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async deleteUser(userId: number): Promise<ApiResponse<undefined>> {
    try {
      const response = await apiClient.delete<undefined>(`${this.basePath}/${userId}`);
      return response;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const userService = new UserService();
