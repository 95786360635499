import { DARK_THEME } from '@/config/theme/dark-theme';
import { BASE_THEME } from '@/config/theme/light-theme';
import { FunctionComponent, PropsWithChildren, useContext } from 'react';
import { ColorModeContext } from '../context/ColorModeContext';
import { ThemeProvider } from '@mui/material';

interface ActionBarProps extends PropsWithChildren {
  location?: 'top' | 'bottom';
  visible?: boolean;
}

export const ActionBar: FunctionComponent<ActionBarProps> = ({ children, location = 'bottom', visible = true }) => {
  const colorMode = useContext(ColorModeContext);
  const theme = colorMode.isDarkMode ? BASE_THEME : DARK_THEME;

  let containerClasses = '';
  if (location === 'bottom') {
    if (visible) {
      containerClasses = '-translate-y-3 bottom-0';
    } else {
      containerClasses = 'translate-y-full bottom-0';
    }
  } else {
    if (visible) {
      containerClasses = 'top-0';
    } else {
      containerClasses = 'translate-y-full';
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div
        className={`${
          colorMode.isDarkMode ? 'text-slate-800' : 'text-white'
        } fixed  left-0  right-0  z-10 mx-auto flex min-w-min max-w-4xl justify-center gap-x-2 pointer-events-none 
        ${containerClasses} transition-transform duration-300 ease-in-out
        `}
      >
        <div
          className={`flex items-center gap-x-4 min-w-80 justify-center  bg-slate-700 px-4  py-2 dark:bg-slate-300 pointer-events-auto  ${
            location === 'bottom' ? 'rounded-xl' : 'rounded-b-xl'
          } `}
        >
          {visible && children}
        </div>
      </div>
    </ThemeProvider>
  );
};
