import { useTranslation } from '@/lib';
import { EventModel, GpsAccuracyLevel } from '@/modules/events/types/EventModel';
import { SignalCellular1Bar, SignalCellular3Bar, SignalCellular4Bar } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { TFunction } from 'i18next';
import { FunctionComponent } from 'react';

export function formatGpsAccuracy(gpsAccuracy: GpsAccuracyLevel, t: TFunction): string {
  switch (gpsAccuracy) {
    case GpsAccuracyLevel.HIGH:
      return t('high');
    case GpsAccuracyLevel.MEDIUM:
      return t('medium');
    case GpsAccuracyLevel.LOW:
      return t('low');
    case GpsAccuracyLevel.UNKNOWN:
    default:
      return t('unknown');
  }
}

export const GpsAccuracyLevelCellRenderer: FunctionComponent<ICellRendererParams<EventModel>> = (props: ICellRendererParams<EventModel>) => {
  const { t } = useTranslation();
  const gpsSourceLabel = formatGpsAccuracy(props.value as GpsAccuracyLevel, t);
  const tooltipTitle = (props.value as GpsAccuracyLevel) === GpsAccuracyLevel.UNKNOWN ? '' : `${props.data?.gpsAccuracy} meters`;

  return (
    <Tooltip title={tooltipTitle} placement="left">
      <div className="flex h-full  ">
        <div className="flex items-center pr-2 text-base">
          {props.value === GpsAccuracyLevel.HIGH && <SignalCellular4Bar fontSize="inherit" color="success" />}
          {props.value === GpsAccuracyLevel.MEDIUM && <SignalCellular3Bar fontSize="inherit" color="warning" />}
          {props.value === GpsAccuracyLevel.LOW && <SignalCellular1Bar fontSize="inherit" color="error" />}
        </div>
        <div>{gpsSourceLabel}</div>
      </div>
    </Tooltip>
  );
};
