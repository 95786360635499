import React, { useContext } from 'react';
import { FilterBarContext, FilterFieldName } from '../FilterBarContext';
import { LocationSelect } from '@/modules/locations/components/LocationSelect';
import { LocationModel } from '@/modules/locations/types/LocationModel';

interface LocationFilterProps {
  label: string;
}

const LocationFilter: React.FC<LocationFilterProps> = ({ label }) => {
  const { setFilterValue, filterValues } = useContext(FilterBarContext);

  function onLocationSelected(location: LocationModel | null) {
    if (location) {
      setFilterValue(FilterFieldName.locationId, location.dto.id);
    } else {
      setFilterValue(FilterFieldName.locationId, undefined);
    }
  }

  return (
    <LocationSelect
      label={label}
      selectedLocationId={filterValues.locationId ? Number.parseInt(filterValues.locationId.toString()) : undefined}
      onSelected={onLocationSelected}
    />
  );
};

export default LocationFilter;
