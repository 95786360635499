import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';
import { BalancesAtLocationDTO, BalanceLocationDTO, CurrentBalanceLocationDTO } from './balance.contracts';
import { BalancesAtLocationModel } from '../../models/BalanceAtLocationModel';

export class BalanceService {
  public basePath = 'balance';

  async getLocationBalanceOverTime({
    locationId,
    periodInDays,
    includeHierarchical,
  }: {
    locationId: number;
    periodInDays?: number;
    includeHierarchical?: boolean;
  }): Promise<ApiResponse<BalanceLocationDTO[]>> {
    try {
      const applicationResponse = await apiClient.get<BalanceLocationDTO[]>(`${this.basePath}/location/${locationId}`, {
        periodInDays: periodInDays,
        includeHierarchical: includeHierarchical,
      });
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getCurrentBalanceForLocation({ locationId }: { locationId: number }): Promise<ApiResponse<CurrentBalanceLocationDTO[]>> {
    try {
      const applicationResponse = await apiClient.get<CurrentBalanceLocationDTO[]>(`${this.basePath}/location/${locationId}/current`);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getBalancesForLocations({
    locationId,
    labelValueIds,
    query,
  }: { locationId?: number; labelValueIds?: number[]; query?: string } = {}): Promise<ApiResponse<BalancesAtLocationModel[]>> {
    try {
      const applicationResponse = await apiClient.get<BalancesAtLocationDTO[]>(`${this.basePath}`, { locationId, labelValueIds, query });
      return applicationResponse.processPayload(BalancesAtLocationModel.fromDTOs);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getBalancesForLocationsHierarchical({
    locationId,
    labelValueIds,
    query,
  }: { locationId?: number; labelValueIds?: number[]; query?: string } = {}): Promise<ApiResponse<BalancesAtLocationModel[]>> {
    try {
      const applicationResponse = await apiClient.get<BalancesAtLocationDTO[]>(`${this.basePath}/hierarchical`, {
        locationId,
        labelValueIds,
        query,
      });
      return applicationResponse.processPayload(BalancesAtLocationModel.fromDTOs);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const balanceService = new BalanceService();
