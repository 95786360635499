import { Outlet } from 'react-router-dom';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { TabMenuItem } from '@/modules/application/types/MenuItem';
import { useTranslation } from '@/lib';

export default function BalanceLayout() {
  const { t } = useTranslation();
  const balanceTabItems: TabMenuItem[] = [
    {
      activeRouteIds: ['/app/balances/overview'],
      label: t('overview'),
      url: '/app/balances/overview',
    },
  ];

  return (
    <TabbedLayout
      topBar={<TabbedLayoutTopBar header={t('balances')} headerIcon={<ShowChartIcon fontSize={'small'} />} tabItems={balanceTabItems} />}
    >
      <Outlet />
    </TabbedLayout>
  );
}
