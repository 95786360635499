import { IDashboardWidgetCatalogItem } from '../../../types/IDashboardWidgetCatalogItem';
import { EventsOverTimeByTypeWidgetConfiguration } from './EventsOverTimeByTypeWidgetConfiguration';
import { DashboardWidgetType } from '@/modules/dashboards/types/DashboardWidgetType';
import { v4 } from 'uuid';
import { BarChartRounded } from '@mui/icons-material';
import { TFunction } from 'i18next';
import { DashboardWidgetCatalogItemImageColorType } from '../../widget-catalog/DashboardWidgetCatalogItemImage';
import { Layout } from 'react-grid-layout';

export class EventsOverTimeByTypeWidgetCatalogItem implements IDashboardWidgetCatalogItem<EventsOverTimeByTypeWidgetConfiguration> {
  title: string;
  description: string;
  icon: JSX.Element;
  iconColor: DashboardWidgetCatalogItemImageColorType;
  id: string;
  type: DashboardWidgetType;
  configuration: EventsOverTimeByTypeWidgetConfiguration;
  layout: Layout;

  constructor(t: TFunction) {
    this.title = t('dashboard.eventsOverTimeByTypeWidget.title'); // 'Events Over Time by Type'
    this.description = t('dashboard.eventsOverTimeByTypeWidget.description'); // 'Shows the total amount of Events of the last 7 days, grouped by Type.'
    this.icon = <BarChartRounded />;
    this.iconColor = 'green';
    this.id = v4();
    this.type = DashboardWidgetType.EventsOverTimeByTypeWidget;
    this.configuration = new EventsOverTimeByTypeWidgetConfiguration(t);
    this.layout = {
      h: 2,
      w: 3,
      i: v4(),
      x: 0,
      y: 0,
      maxH: 2,
      maxW: 4,
      minH: 2,
      minW: 2,
    };
  }
}
