import { useCallback } from 'react';
import * as XLSX from 'xlsx';

export const useExportToExcel = () => {
  const exportToExcel = useCallback((data: Record<string, unknown>[], fileName = 'data.xlsx') => {
    // Create a new workbook
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    // Convert the data to a worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Write the workbook to a binary format (XLSX)
    const wbout: ArrayBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a blob from the binary format
    const blob: Blob = new Blob([wbout], { type: 'application/octet-stream' });

    // Create a download link and click it to trigger the download
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  return { exportToExcel };
};
