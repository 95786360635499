import LoadingBlock from '@/components/feedback/LoadingBlock';
import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import TrackerModalFormLogic from './TrackerModalFormLogic';
import { TrackerModalFormModel } from './TrackerModalFormModel';
import { useNavigate } from 'react-router-dom';
import { isNumber } from 'lodash-es';
import { TrackerModel } from '../../types/TrackerModel';
import { trackerService } from '../../api/trackers/tracker.service';
import { TrackerReadDTO, TrackerType } from '../../api/trackers/tracker.contracts';
import { ApiResponseCode } from '@/lib/api/api-response-code';

interface TrackerModalFormApiProps {
  isCreate?: boolean;
  trackerId?: number;
  isOpen: boolean;
  onClose: () => void;
  onTrackerCreated: (newTracker: TrackerReadDTO) => void;
}

const TrackerModalFormApi: FunctionComponent<TrackerModalFormApiProps> = ({ isCreate, trackerId, isOpen, onClose, onTrackerCreated }) => {
  const navigate = useNavigate();

  const handleSubmit = async (data: TrackerModalFormModel) => {
    console.log({ SUBMITTING: isCreate });

    // Process data
    if (isCreate) {
      const response = await trackerService.create({
        epc: data.epc,
        type: data.type,
        barcode: data.barcode,
        deviceId: data.deviceId,
      });

      if (!response.isSuccess) {
        if (response.responseCode === ApiResponseCode.DUPLICATE_TRACKER_IDENTIFIER) {
          if (data.type === TrackerType.Barcode) {
            toast('The Barcode is already in use, and must be unique. Please provide a different one.');
          }
          if (data.type === TrackerType.RFID) {
            toast('The Epc is already in use, and must be unique. Please provide a different one.');
          }
          if (data.type === TrackerType.UnaTag || data.type === TrackerType.BLE) {
            toast('The Device ID is already in use, and must be unique. Please provide a different one.');
          }
        } else {
          toast('Error Creating Tracker', {
            type: 'error',
          });
        }
      } else {
        toast.success('Tracker created');

        onTrackerCreated(response.payload);
        onClose();
        // navigate('/app/configuration/trackers/overview');
      }
    } else if (isNumber(trackerId)) {
      const response = await trackerService.update(trackerId, {
        epc: data.epc,
        type: data.type,
        barcode: data.barcode,
        deviceId: data.deviceId,
      });

      if (!response.isSuccess) {
        if (response.responseCode === ApiResponseCode.DUPLICATE_TRACKER_IDENTIFIER) {
          if (data.type === TrackerType.Barcode) {
            toast('The Barcode is already in use, and must be unique. Please provide a different one.');
          }
          if (data.type === TrackerType.RFID) {
            toast('The Epc is already in use, and must be unique. Please provide a different one.');
          }
          if (data.type === TrackerType.UnaTag || data.type === TrackerType.BLE) {
            toast('The Device ID is already in use, and must be unique. Please provide a different one.');
          }
        } else {
          toast('Error Updating Tracker', {
            type: 'error',
          });
        }
      } else {
        toast.success('Tracker updated');
        navigate('/app/configuration/trackers/overview/');
      }
    }
    return true;
  };

  if (isCreate || !trackerId) {
    const newTracker: TrackerModalFormModel = {
      type: TrackerType.RFID,
      epc: '',
      barcode: '',
      deviceId: '',
    };
    return <TrackerModalFormLogic onClose={onClose} isOpen={isOpen} initialValues={newTracker} onSubmit={handleSubmit} isCreate />;
  } else {
    const { data, isLoading, isError, fetchData } = useApiCall<TrackerModel>(() => trackerService.getById(trackerId));

    // return early if initial form data isn't loaded
    if (isLoading) return <LoadingBlock />;
    if (isError || !data) return <div>No Data Error</div>;

    return (
      <TrackerModalFormLogic
        onClose={onClose}
        isOpen={isOpen}
        initialValues={data}
        onSubmit={handleSubmit}
        isCreate={isCreate}
        trackerId={trackerId}
      />
    );
  }
};

export default TrackerModalFormApi;
