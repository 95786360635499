export enum TransactionSourceType {
  Event = 1,
  User = 2,
  System = 3,
}

export enum TransactionType {
  Unknown = 1,
  Transfer = 2,
  TakeIn = 3,
  GiveOut = 4,
}

export interface TransactionReadDTO {
  id: number;

  // Base transaction fields
  source: TransactionSourceType;
  number?: string;
  date: Date;
  fromLocation: TransactionLocation;
  toLocation: TransactionLocation;
  type: TransactionType;

  eventId?: number;

  internalNotes?: string;

  // Mutations
  mutations: TransactionMutation[];

  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface TransactionLocation {
  id: number;
  name: string;
  code: string;
}

export interface TransactionMutation {
  id: number;

  assetType: {
    id: number;
    code: string;
  };

  asset?: {
    id: number;
    code: string;
  };

  quantity: number;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface TransactionCreateDTO {
  source: TransactionSourceType;
  date: Date;
  fromLocationId: number;
  toLocationId: number;
  type: TransactionType;
  eventId?: number;
  internalNotes?: string;
  mutations: TransactionMutationCreateDTO[];
}

export interface TransactionMutationCreateDTO {
  assetTypeId: number;
  assetId?: number;
  quantity: number;
}
