import { DTO } from '@/types/dto-wrapper';
import { ReadLabelDTO, ReadLabelValueDTO, LabelAssignmentDTO } from '../api/label.contracts';

export class LabelModel extends DTO<ReadLabelDTO> {
  public values: LabelValueModel[];
  public assignments: LabelAssignmentModel[];

  constructor(dto: ReadLabelDTO) {
    super(dto);

    this.values = LabelValueModel.fromDTOs(dto.values);
    this.assignments = LabelAssignmentModel.fromDTOs(dto.assignments);
  }

  get assignmentCount(): number {
    return this.assignments.length;
  }

  get valueCount(): number {
    return this.values.length;
  }

  get valueNames(): string[] {
    return this.values.map((value) => value.dto.value);
  }

  get valueNameString(): string {
    return this.valueNames.join(', ');
  }

  static fromDTO(dto: ReadLabelDTO): LabelModel {
    return new LabelModel(dto);
  }

  static fromDTOs(dtos: ReadLabelDTO[]): LabelModel[] {
    return dtos.map((dto) => LabelModel.fromDTO(dto));
  }
}

export class LabelValueModel extends DTO<ReadLabelValueDTO> {
  static fromDTO(dto: ReadLabelValueDTO): LabelValueModel {
    return new LabelValueModel(dto);
  }

  static fromDTOs(dtos: ReadLabelValueDTO[]): LabelValueModel[] {
    return dtos.map((dto) => LabelValueModel.fromDTO(dto));
  }
}

export class LabelAssignmentModel extends DTO<LabelAssignmentDTO> {
  static fromDTO(dto: LabelAssignmentDTO): LabelAssignmentModel {
    return new LabelAssignmentModel(dto);
  }

  static fromDTOs(dtos: LabelAssignmentDTO[]): LabelAssignmentModel[] {
    return dtos.map((dto) => LabelAssignmentModel.fromDTO(dto));
  }
}
