import { DTO } from '@/types/dto-wrapper';
import { ReadAPIKeyDTO } from '../api/api-key.contracts';

export class ApiKeyModel extends DTO<ReadAPIKeyDTO> {
  constructor(dto: ReadAPIKeyDTO) {
    super(dto);
  }

  get id(): number {
    return this.dto.id;
  }

  get key(): string {
    return this.dto.key;
  }

  get createdAt(): Date {
    return this.dto.createdAt;
  }

  get updatedAt(): Date {
    return this.dto.updatedAt;
  }

  get name(): string {
    return this.dto.name;
  }

  get isEnabled(): boolean | undefined {
    return this.dto.isEnabled;
  }

  public static fromDTO(dto: ReadAPIKeyDTO): ApiKeyModel {
    return new ApiKeyModel(dto);
  }
}
