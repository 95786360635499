import { FunctionComponent } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useTranslation } from '@/lib';

interface DeleteConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirmDelete: () => void;
  questionText: string;
  questionTitle: string;
}

export const DeleteConfirmationModal: FunctionComponent<DeleteConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirmDelete,
  questionText,
  questionTitle,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{questionTitle}</DialogTitle>
      <DialogContent className="w-96">
        <Typography>{questionText}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="text">
          {t('cancel')}
        </Button>
        <Button onClick={onConfirmDelete} color="error" variant="contained">
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
