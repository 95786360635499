import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
import { ImportWizardStepUpload } from './ImportWizardStepUpload';
import { ImportWizardStepPreview } from './ImportWizardStepPreview';
import { ImportWizardStepImport } from './ImportWizardStepImport';
import useImportWizard, { ImportWizardStep } from './useImportWizard';
import { ImportWizardProvider } from './ImportWizardContext';
import { ImportDefinition } from '../definitions/import-definition';

interface WizardProps {
  importDefinition: ImportDefinition;
}

const Wizard: React.FC<WizardProps> = ({ importDefinition }) => {
  const wizard = useImportWizard(importDefinition);

  const getStepContent = (step: ImportWizardStep) => {
    switch (step) {
      case ImportWizardStep.Upload:
        return <ImportWizardStepUpload />;
      case ImportWizardStep.Preview:
        return <ImportWizardStepPreview />;
      case ImportWizardStep.Import:
        return <ImportWizardStepImport />;
      default:
        return 'Unknown step';
    }
  };

  return (
    <ImportWizardProvider wizard={wizard}>
      <div className=" flex flex-1 h-full flex-grow flex-col gap-y-6 rounded-lg bg-gray-100 py-12 dark:bg-gray-850">
        <div className="flex w-full  justify-center">
          <div className="w-full max-w-screen-md">
            <Stepper activeStep={wizard.currentStep}>
              {wizard.steps.map((label, index) => (
                <Step key={index}>
                  <StepLabel>{wizard.titleForStep(label)}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>

        <div className="flex h-full w-full flex-1 flex-grow flex-col items-center justify-center   ">
          {getStepContent(wizard.currentStep)}
        </div>
      </div>
    </ImportWizardProvider>
  );
};

export default Wizard;
