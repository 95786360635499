import React, { useContext } from 'react';
import { FilterBarContext, FilterFieldName } from '../FilterBarContext';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash-es';

interface DateFilterProps {
  label: string;
  filterField: FilterFieldName;
  processValue?: (date: Dayjs) => Dayjs;
}

const DateFilter: React.FC<DateFilterProps> = ({ label, filterField, processValue }) => {
  const { setFilterValue, filterValues } = useContext(FilterBarContext);

  const handleChange = (value: Dayjs | null) => {
    if (value && processValue) {
      value = processValue(value);
    }
    setFilterValue(filterField, value);
  };

  return (
    <DatePicker
      className="w-full"
      label={label}
      format="DD-MM-YYYY"
      value={isNil(filterValues[filterField]) ? null : dayjs(filterValues[filterField] as string)}
      onChange={handleChange}
    />
  );
};

export default DateFilter;
