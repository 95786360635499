import { ErrorRounded } from '@mui/icons-material';
import { FunctionComponent } from 'react';

interface ErrorBlockProps {}

const ErrorBlock: FunctionComponent<ErrorBlockProps> = () => {
  return (
    <div className="flex items-center h-full w-full justify-center flex-col">
      <ErrorRounded />
      <div className="text-center mt-2 text-xs">Something went wrong</div>
    </div>
  );
};

export default ErrorBlock;
