import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent, useState } from 'react';
import { locationService } from '../../api/locations/location.service';
import { AssetTypeTotalModel } from '@/modules/asset-types';
import { useParams } from 'react-router-dom';
import { LocationBalanceGrid } from './LocationBalanceGrid';
import LocationHierarchyToggleButton from '../LocationHierarchyToggleButton';
import { DisplayMode } from '@/types/display-mode.enum';
import { useTranslation } from '@/lib';

export const LocationBalanceTab: FunctionComponent = () => {
  const { locationId } = useParams();
  if (!locationId) {
    return;
  }

  const { t } = useTranslation();

  const locationIdNumber = Number.parseInt(locationId);
  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.INDIVIDUAL);
  const { data, isLoading, isError } = useApiCall<AssetTypeTotalModel[]>(() => locationService.getBalance({ locationId: locationIdNumber }));

  return (
    <div className="flex flex-col h-full flex-1 flex-grow gap-x-4  max-w-[1920px] ">
      <div className="flex items-center  justify-between w-full">
        <div className="text-md  font-bold">{t('balance_per_asset_type')}</div>
        <div className="text-xs flex items-center scale-75 origin-right">
          <LocationHierarchyToggleButton displayMode={displayMode} onChange={setDisplayMode} reverseOrder />
        </div>
      </div>

      <LocationBalanceGrid
        data={data}
        isError={isError}
        isLoading={isLoading}
        balanceDisplayMode={displayMode}
        locationId={locationIdNumber}
      ></LocationBalanceGrid>
    </div>
  );
};
