import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { IDashboardWidget } from '../types/IDashboardWidget';
import { IDashboardWidgetBaseConfiguration } from '../types/IDashboardWidgetBaseConfiguration';
import { assign, merge } from 'lodash-es';
import { DashboardModel } from '../types/DashboardModel';
import { dashboardService } from '../api/dashboard/dashboard.service';

interface DashboardContextType {
  widgetPlaceholderHeight: number;
  widgetPlaceholderWidth: number;
  setPlaceholderSize: (width: number, height: number) => void;
  editingWidgetId?: string;
  setEditingWidgetId: (id: string) => void;
  isEditingDashboard: boolean;
  setIsEditingDashboard: (isEditing: boolean) => void;
  isLoadingDashboard: boolean;
  // setIsLoadingDashboard: (isLoading: boolean) => void;
  widgets: IDashboardWidget<IDashboardWidgetBaseConfiguration>[];
  setWidgets: Dispatch<SetStateAction<IDashboardWidget<IDashboardWidgetBaseConfiguration>[]>>;
  updateWidget: <T extends IDashboardWidgetBaseConfiguration>(widgetToUpdateId: string, widgetToUpdate: Partial<IDashboardWidget<T>>) => void;
  updateWidgetConfiguration: <T extends IDashboardWidgetBaseConfiguration>(
    widgetToUpdateId: string,
    widgetContigurationUpdate: Partial<T>,
  ) => void;
  dashboard?: DashboardModel;
  removeWidget: (widgetIdToRemove: string) => void;
}
export const DashboardContext = createContext<DashboardContextType | null>(null);

export function useDashboard(dashboardId?: string | number) {
  const [widgetPlaceholderHeight, setWidgetPlaceholderHeight] = useState(1);
  const [widgetPlaceholderWidth, setWidgetPlaceholderWidth] = useState(1);
  const [editingWidgetId, setEditingWidgetId] = useState<string>('');
  const [isEditingDashboard, setIsEditingDashboard] = useState(false);
  const [widgets, setWidgets] = useState<IDashboardWidget<IDashboardWidgetBaseConfiguration>[]>([]);
  const [dashboard, setDashboard] = useState<DashboardModel>();
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(true);

  function setPlaceholderSize(width: number, height: number) {
    setWidgetPlaceholderHeight(height);
    setWidgetPlaceholderWidth(width);
  }

  function updateWidget<T extends IDashboardWidgetBaseConfiguration>(widgetToUpdateId: string, widgetToUpdate: Partial<IDashboardWidget<T>>) {
    if (!dashboard) return;
    // Create a new array where the object with the matching id is replaced
    const newWidgets = dashboard.widgets.map((widget) => {
      if (widget.id === widgetToUpdateId) {
        return merge(widget, widgetToUpdate); // Replace the widget
      }
      return widget; // Leave the widget as-is
    });

    // Update the state with the new array
    setWidgets(newWidgets);
    dashboard.widgets = newWidgets;

    // Autosave the changes
    saveDashboard();
  }

  function updateWidgetConfiguration<T extends IDashboardWidgetBaseConfiguration>(widgetToUpdateId: string, configurationUpdate: Partial<T>) {
    if (!dashboard) return;
    // Create a new array where the object with the matching id is replaced
    const newWidgets = dashboard.widgets.map((widget) => {
      if (widget.id === widgetToUpdateId) {
        widget.configuration = assign(widget.configuration, configurationUpdate); // Replace the widget
        console.log({ widgetToUpdateId, configurationUpdate, upedConfig: widget.configuration });
        return widget;
      }
      return widget; // Leave the widget as-is
    });

    // Update the state with the new array
    setWidgets(newWidgets);
    dashboard.widgets = newWidgets;

    // Autosave the changes
    saveDashboard();
  }

  function removeWidget(widgetIdToRemove: string) {
    if (!dashboard) return;

    // Remove widget from dashboard widgets
    const newWidgetCollection = dashboard?.widgets.filter((w) => w.id !== widgetIdToRemove);

    // Update the state with the new array
    setWidgets(newWidgetCollection);
    dashboard.widgets = newWidgetCollection;

    // Autosave the changes
    saveDashboard();
  }

  useEffect(() => {
    const fetchDashboard = async () => {
      setIsLoadingDashboard(true);
      const dashboardResponse = await dashboardService.getById(Number.parseInt(dashboardId ? dashboardId.toString() : '0'));
      if (dashboardResponse.isSuccess) {
        setDashboard(dashboardResponse.payload);
        setWidgets(dashboardResponse.payload.widgets);
      }
      setIsLoadingDashboard(false);
    };
    if (dashboardId) {
      fetchDashboard();
    }
  }, [dashboardId]);

  function saveDashboard() {
    if (dashboard) {
      dashboardService.update(dashboard.id, {
        ...dashboard?.toDTO(),
        widgets,
      });
    }
  }

  useEffect(() => {
    if (!dashboard) return;

    dashboard.widgets = widgets;
    saveDashboard();
  }, [widgets]);

  useEffect(() => {
    if (!isEditingDashboard) {
      setEditingWidgetId('');
    }
  }, [isEditingDashboard]);

  console.log({ updateWidget, updateWidgetConfiguration });

  return {
    widgetPlaceholderHeight,
    widgetPlaceholderWidth,
    setPlaceholderSize,
    editingWidgetId,
    setEditingWidgetId,
    isEditingDashboard,
    setIsEditingDashboard,
    widgets,
    setWidgets,
    updateWidget,
    dashboard,
    setDashboard,
    isLoadingDashboard,
    removeWidget,
    updateWidgetConfiguration,
  };
}

export const useDashboardContext = () => {
  const dashboardContext = useContext(DashboardContext);

  if (!dashboardContext) {
    throw new Error('useDashboard has to be used within <DashboardContext.Provider>');
  }

  return dashboardContext;
};
