import { MenuItemGroup } from '@/modules/application';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';

export const LABEL_CONFIGURATION_SUB_MENU_ITEMS: MenuItemGroup[] = [
  {
    label: 'Labels',
    children: [
      {
        activeRouteIds: ['/app/configuration/labels/*'],
        label: 'Labels',
        url: '/app/configuration/labels/overview',
        requiredPermission: Permission.LABELS_VIEW,
      },
    ],
  },
];
