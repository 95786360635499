import { Box, Drawer, IconButton, Portal, Tab } from '@mui/material';
import { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import { useApiCall } from '@/hooks/useApiCall';
import { eventService } from '../../../api/events/event.service';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { EventGroupAssetsGrid } from './EventGroupAssetsGrid';
import { EventGroupTrackersGrid } from './EventGroupTrackersGrid';
import { TrackerModel } from '@/modules/trackers/types/TrackerModel';
import { useDate } from '@/hooks/useDate';
import { AssetTypeTotalModel, AssetTypeTotalTable } from '@/modules/asset-types';
import { useTranslation } from '@/lib';

interface EventGroupInfoDrawerProps {
  eventGroupUid: string;
  isOpen: boolean;
  onClose: () => void;
}

const EventGroupInfoDrawer: FunctionComponent<EventGroupInfoDrawerProps> = ({ eventGroupUid, isOpen, onClose }) => {
  const { data, isLoading, setApiCallArg } = useApiCall(() => eventService.getEventGroupByUuid(eventGroupUid));
  const { formatDateStamp } = useDate();
  const { t } = useTranslation();

  useEffect(() => {
    setApiCallArg(() => eventService.getEventGroupByUuid(eventGroupUid));
  }, [eventGroupUid]);

  const [currentSelectedTabIndex, setCurrentSelectedTabIndex] = useState('1');

  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    setCurrentSelectedTabIndex(newValue);
  };

  const assetTypeTotals: AssetTypeTotalModel[] = [];

  data?.assets.forEach((asset) => {
    const assetTypeTotal = assetTypeTotals.find((at) => at.id === asset.assetType.id);
    if (assetTypeTotal) {
        assetTypeTotal.hierarchicalBalance++;
    } else {
      assetTypeTotals.push(
        new AssetTypeTotalModel({ id: asset.assetType.id, name: asset.assetType.name, code: asset.assetType.code, hierarchicalBalance: 1, individualBalance: 1 }),
      );
    }
  });

  return (
    <Portal>
      <Drawer anchor={'right'} open={isOpen} hideBackdrop variant="persistent">
        <div className="flex h-full  w-[512px] flex-col  justify-between  px-6 py-4">
          <div className="flex h-full  flex-col ">
            {!data || isLoading ? (
              <LoadingBlock />
            ) : (
              <>
                {/* header */}
                <div className="div flex items-center justify-between">
                  <div className="flex flex-col">
                    <div className="text-xs text-gray-500 dark:text-gray-400 ">Event</div>
                    <div className="text-lg font-medium ">
                      {formatDateStamp(data.occurredAt)} {t('at')} {data.location.name} ({data.location.code})
                    </div>
                  </div>
                  <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                    <Close />
                  </IconButton>
                </div>
                {/* content */}
                <div className="flex  h-full flex-col ">
                  <div className="mt-4 flex flex-col">
                    <div className="mb-1 font-medium">{t('totals')}</div>
                    {assetTypeTotals.length > 0 && <AssetTypeTotalTable assetTypeTotals={assetTypeTotals}></AssetTypeTotalTable>}
                  </div>
                  <div className="my-2 flex h-full flex-grow flex-col ">
                    <TabContext value={currentSelectedTabIndex}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                          <Tab label={`${t('event.assets')} (${data.assets.length})`} value="1" />
                          <Tab label={`${t('event.trackers')} (${data.trackers.length})`} value="2" />
                        </TabList>
                      </Box>
                      <TabPanel className=" p-0 pt-4" value="1" style={{ flexGrow: 1 }}>
                        <EventGroupAssetsGrid data={data.assets} />
                      </TabPanel>
                      <TabPanel className=" p-0 pt-4" value="2" style={{ flexGrow: 1 }}>
                        <EventGroupTrackersGrid
                          data={data.trackers.map(
                            (t) =>
                              new TrackerModel({
                                id: t.id,
                                type: t.type,
                                epc: t.epc,
                                barcode: t.barcode,
                                deviceId: t.deviceId,
                                asset: undefined,
                                createdAt: new Date(),
                                updatedAt: new Date(),
                                deletedAt: new Date(),
                              }),
                          )}
                        />
                      </TabPanel>
                    </TabContext>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="w-full"></div>
        </div>
      </Drawer>
    </Portal>
  );
};

export default EventGroupInfoDrawer;
