import { AssetSortOption } from '@/modules/assets/api/assets/asset.contracts';
import { BalanceSortOption } from '@/modules/balances/api/balances/balance.contracts';
import { EventSortOption } from '@/modules/events/api/events/event.contracts';
import { LocationSortOption } from '@/modules/locations/api/locations/location.contracts';
import { LocationAssetSortOption } from '@/modules/locations/api/locations/location.contracts';

export interface PagePaginationResultDto<T> {
  data: T[];
  page: number;
  totalElements: number;
  totalPages: number;
  hasNextPage: boolean;
}

export enum PageSortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface PagePaginationQueryDtoParams {
  page?: number;
  limit?: number;
  sort?: PageSortOption;
  order?: PageSortOrder;
}

export type PageSortOption = AssetSortOption | LocationAssetSortOption | EventSortOption | LocationSortOption | BalanceSortOption | string;

export class PagePaginationQueryDto {
  page: number = 1;
  limit: number = 1000;
  sort?: string;
  order?: PageSortOrder;

  constructor(params: PagePaginationQueryDtoParams) {
    if (params.page) {
      this.page = params.page;
    }

    if (params.limit && params.limit <= 1000) {
      this.limit = params.limit;
    }

    this.sort = params.sort;

    const order = params.order?.toUpperCase();
    if (order === PageSortOrder.ASC || order === PageSortOrder.DESC) {
      this.order = order;
    }
  }
}

// Add typeguard to check if the object is a PagePaginationResultDto
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isPagePaginationResultDto<T>(object: any): object is PagePaginationResultDto<T> {
  return 'data' in object && 'page' in object && 'totalElements' in object && 'totalPages' in object && 'hasNextPage' in object;
}

export type ExtractPageResultType<T> = T extends PagePaginationResultDto<infer U> ? U : never;
