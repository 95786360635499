import { ActionBar } from '@/modules/application';
import { Card, CardContent, Typography, TextField, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { FunctionComponent } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { DeviceFormModel } from './DeviceFormModel';
import { LoadingButton } from '@mui/lab';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useUser } from '@/modules/users/contexts/UserContext';
import { useTranslation } from '@/lib';
import { DeviceType } from '../../api/device/device.contracts';
import { LocationSelect } from '@/modules/locations/components/LocationSelect';

interface DeviceFormDisplayProps {
  form: UseFormReturn<DeviceFormModel>;
  onSubmit: (data: DeviceFormModel) => void;
  isCreate?: boolean;
}

const DeviceFormDisplay: FunctionComponent<DeviceFormDisplayProps> = ({ form, onSubmit, isCreate = false }) => {
  const { formState, register, handleSubmit, getValues, control, watch } = form;
  const { errors, isSubmitting } = formState;
  const hasDeviceEditPermission = useUser().hasPermission(Permission.DEVICES_EDIT);
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-4 lg:grid-cols-2 2xl:grid-cols-3 ">
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" className="pb-2" color="text.secondary">
              {t('details')}
            </Typography>
            <div className="flex w-full flex-col gap-y-2">
              <Controller
                name="deviceType"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormControl>
                    <InputLabel id="demo-simple-select-helper-label">{t('type')}</InputLabel>
                    <Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper" label={t('type')} {...field}>
                      <MenuItem value={DeviceType.MOBILE_SCANNER}>{t('mobile_scanner')}</MenuItem>
                      <MenuItem value={DeviceType.BLE_GATEWAY}>{t('ble_gateway')}</MenuItem>
                    </Select>
                    <FormHelperText>{errors.type?.message} </FormHelperText>
                  </FormControl>
                )}
              />

              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField error={errors.name !== undefined} helperText={errors.name?.message} label={t('name')} {...field} />
                )}
              />

              {watch('deviceType') === DeviceType.BLE_GATEWAY && (
                <>
                  <Controller
                    name="identifier"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        error={errors.identifier !== undefined}
                        helperText={errors.identifier?.message}
                        label={t('identifier')}
                        {...field}
                      />
                    )}
                  />

                  <Controller
                    name="locationId"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                      <LocationSelect
                        helperText={errors.locationId?.message}
                        label={t('location')}
                        selectedLocationId={value !== null ? value : undefined}
                        onSelected={(selectedLocation) => {
                          if (selectedLocation) {
                            onChange(selectedLocation.dto.id);
                          } else {
                            onChange(null);
                          }
                        }}
                      />
                    )}
                  />
                </>
              )}

              {watch('deviceType') === DeviceType.MOBILE_SCANNER && (
                <Controller
                  name="code"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      error={errors.code !== undefined}
                      helperText={errors.code?.message ?? t('code_can_be_used_in_posito_scan_app')}
                      label={t('code')}
                      {...field}
                    />
                  )}
                />
              )}

              <Controller
                name="brand"
                control={control}
                render={({ field }) => (
                  <TextField error={errors.brand !== undefined} helperText={errors.brand?.message} label={t('brand')} {...field} />
                )}
              />

              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <TextField error={errors.type !== undefined} helperText={errors.type?.message} label={t('description')} {...field} />
                )}
              />

              <Controller
                name="serialNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    error={errors.serialNumber !== undefined}
                    helperText={errors.serialNumber?.message}
                    label={t('serial_number')}
                    {...field}
                  />
                )}
              />
            </div>
          </CardContent>
        </Card>
      </div>

      <ActionBar visible={hasDeviceEditPermission}>
        <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
          {t('save')}
        </LoadingButton>
      </ActionBar>
    </form>
  );
};

export default DeviceFormDisplay;
