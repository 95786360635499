import { FunctionComponent, useContext } from 'react';
import { FilterBarContext, FilterFieldName } from '../FilterBarContext';
import AssetTypeSelect from '@/modules/asset-types/components/AssetTypeSelect';
import { AssetTypeModel } from '@/modules/asset-types/types/AssetTypeModel';

interface AssetTypeFilterProps {
  label: string;
}

const AssetTypeFilter: FunctionComponent<AssetTypeFilterProps> = ({ label }) => {
  const { setFilterValue, filterValues } = useContext(FilterBarContext);

  function onAssetTypeSelected(assetType?: AssetTypeModel) {
    if (assetType) {
      setFilterValue(FilterFieldName.assetTypeId, assetType.id);
    } else {
      setFilterValue(FilterFieldName.assetTypeId, undefined);
    }
  }

  return (
    <AssetTypeSelect
      selectedAssetTypeId={filterValues.assetTypeId ? Number.parseInt(filterValues.assetTypeId.toString()) : undefined}
      onChange={onAssetTypeSelected}
    />
  );
};

export default AssetTypeFilter;
