import { FunctionComponent, PropsWithChildren, ReactNode } from 'react';

interface TabbedLayoutTopBarHeaderProps extends PropsWithChildren {
  icon?: ReactNode;
}

const TabbedLayoutTopBarHeader: FunctionComponent<TabbedLayoutTopBarHeaderProps> = ({ children, icon }) => {
  return (
    <div className=" flex items-center  font-bold">
      {icon ? <span className="mr-2 flex">{icon}</span> : null}
      {children}
    </div>
  );
};

export default TabbedLayoutTopBarHeader;
