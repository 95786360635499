import { TrackerTypeChip } from '@/modules/trackers/components/TrackerTypeChip';
import { ICellRendererParams } from 'ag-grid-community';
import { FunctionComponent } from 'react';

export const TrackerTypeCellRenderer: FunctionComponent<ICellRendererParams<unknown>> = (props: ICellRendererParams<unknown>) => {
  return (
    <div className="flex h-full items-center">
      <TrackerTypeChip trackerType={props.value} />
    </div>
  );
};
