import { FunctionComponent, useContext } from 'react';
import { FilterBarContext, FilterFieldName } from '../FilterBarContext';
import { AssetTypeModel } from '@/modules/asset-types/types/AssetTypeModel';
import AssetTypesSelect from '@/modules/asset-types/components/AssetTypesSelect';

const AssetTypesFilter: FunctionComponent = () => {
  const { setFilterValue, filterValues } = useContext(FilterBarContext);

  function onAssetTypesSelected(assetTypes?: AssetTypeModel[] | undefined) {
    if (assetTypes && assetTypes.length > 0) {
      setFilterValue(
        FilterFieldName.assetTypeIds,
        assetTypes.map((at) => at.id),
      );
    } else {
      setFilterValue(FilterFieldName.assetTypeIds, undefined);
    }
  }

  return (
    <AssetTypesSelect selectedAssetTypeIds={filterValues.assetTypeIds ? filterValues.assetTypeIds : []} onChange={onAssetTypesSelected} />
  );
};

export default AssetTypesFilter;
