import { FunctionComponent } from 'react';
import { TrackerType } from '../api/trackers/tracker.contracts';
import { trackerTypeToString } from '../lib/tracker.utils';
import { useTranslation } from '@/lib';

interface TrackerTypeLabelProps {
  trackerType: TrackerType;
}

export const TrackerTypeLabel: FunctionComponent<TrackerTypeLabelProps> = ({ trackerType }) => {
  const { t } = useTranslation();

  interface TrackerTypeStyling {
    textClasses: string;
  }

  const trackerTypeStylingMap: Record<TrackerType, TrackerTypeStyling> = {
    [TrackerType.Barcode]: {
      textClasses: 'text-green-950 dark:text-green-400',
    },
    [TrackerType.RFID]: {
      textClasses: ' text-blue-950 dark:text-blue-400',
    },
    [TrackerType.UnaTag]: {
      textClasses: ' text-yellow-950 dark:text-yellow-400',
    },
    [TrackerType.BLE]: {
      textClasses: ' text-blue-950 dark:text-blue-400',
    },
  };

  const { textClasses } = trackerTypeStylingMap[trackerType];

  return <div className={`${textClasses}`}>{trackerTypeToString(trackerType, t)}</div>;
};
