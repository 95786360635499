import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { LayoutActionsProvider } from '@/providers/layout-actions-provider/LayoutActionsProvider';
import { useState, useEffect, FunctionComponent } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { assetService } from '../api/assets/asset.service';
import { AssetModel } from '../types/AssetModel';
import { BackButton } from '@/components/BackButton';
import NavigationTabs from '@/modules/application/components/NavigationTabs';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { TabbedLayoutTopBarVerticalSeperator } from '@/modules/application/components/TabbedLayoutTopBarVerticalSeperator';
import { Skeleton } from '@mui/material';
import { TabbedPageLayout } from '@/modules/application/layouts/TabbedPageLayout';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { TabMenuItem } from '@/modules/application/types/MenuItem';
import { useTranslation } from '@/lib';

export const AssetDetailsLayout: FunctionComponent = () => {
  const { t } = useTranslation();
  const { assetId } = useParams();
  const assetIdNumber = assetId ? Number.parseInt(assetId) : 0;
  const isCreate = assetId === 'create';

  const [asset, setAsset] = useState<AssetModel | undefined>(undefined);

  useEffect(() => {
    if (assetId) {
      assetService.getById(Number.parseInt(assetId)).then((asset) => {
        if (asset.isSuccess) {
          setAsset(asset.payload);
        }
      });
    }
  }, [assetId]);

  const tabItems: TabMenuItem[] = [];

  if (isCreate) {
    tabItems.push({
      activeRouteIds: ['/app/assets/create'],
      label: t('details'),
      url: '/app/assets/create',
    });
  } else {
    tabItems.push({
      activeRouteIds: [`/app/assets/${assetIdNumber}/details`],
      label: t('details'),
      url: `/app/assets/${assetIdNumber}/details`,
    });
    tabItems.push({
      activeRouteIds: [`/app/assets/${assetIdNumber}/movements`],
      label: 'Movements',
      url: `/app/assets/${assetIdNumber}/movements`,
    });
    tabItems.push({
      activeRouteIds: [`/app/assets/${assetIdNumber}/events`],
      label: t('events'),
      url: `/app/assets/${assetIdNumber}/events`,
    });
    tabItems.push({
      activeRouteIds: [`/app/assets/${assetIdNumber}/history`],
      label: t('history'),
      url: `/app/assets/${assetIdNumber}/history`,
    });
  }

  return (
    <LayoutActionsProvider>
      <TabbedLayout
        topBar={
          <TabbedLayoutTopBar>
            <BackButton pathname="/app/assets/overview" />
            <TabbedLayoutTopBarHeader icon={null}>
              {isCreate ? t('new_asset') : asset?.code ? `${t('asset')} - ${asset.code}` : <Skeleton width={200} />}
            </TabbedLayoutTopBarHeader>

            <TabbedLayoutTopBarVerticalSeperator />
            <NavigationTabs tabItems={tabItems} />
          </TabbedLayoutTopBar>
        }
      >
        <TabbedPageLayout>
          <TabbedPageLayoutBody>
            <div className="flex h-full flex-grow flex-col ">
              <Outlet />
            </div>
          </TabbedPageLayoutBody>
        </TabbedPageLayout>
      </TabbedLayout>
    </LayoutActionsProvider>
  );
};
