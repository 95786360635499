import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';
import { AssetTypeModel } from '../../types/AssetTypeModel';

import {
  AssetByAssetCountDTO,
  AssetByAssetMonetaryValueDTO,
  AssetMetricType,
  AssetTypeCreateDTO,
  AssetTypeReadDTO,
  AssetTypeUpdateDTO,
  ReadAssetTypeBalanceDTO,
} from './asset-type.contracts';

export class AssetTypeService {
  public basePath = 'asset-type';

  async create(assetTypeToCreate: AssetTypeCreateDTO): Promise<ApiResponse<undefined>> {
    try {
      const applicationResponse = await apiClient.post<undefined>(this.basePath + '/', assetTypeToCreate);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async update(assetTypeId: number, assetToUpdate: AssetTypeUpdateDTO): Promise<ApiResponse<undefined>> {
    try {
      const applicationResponse = await apiClient.patch<undefined>(`${this.basePath}/${assetTypeId}`, assetToUpdate);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async uploadImage(assetTypeId: number, image: File | null) {
    const formData = new FormData();
    if (image) {
      formData.append('image', image);
    }
    try {
      const applicationResponse = await apiClient.uploadFiles<undefined>(
        `${this.basePath}/${assetTypeId}/image`,
        formData,

        (progressEvent) => {
          return;
        },
      );
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  removeImage(assetTypeId: number) {
    try {
      const applicationResponse = apiClient.delete<undefined>(`${this.basePath}/${assetTypeId}/image`);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getAll(): Promise<ApiResponse<AssetTypeModel[]>> {
    try {
      const applicationResponse = await apiClient.get<AssetTypeReadDTO[]>(this.basePath);
      return applicationResponse.processPayload((payload) => {
        return payload.map(AssetTypeModel.fromDTO);
      });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getById(id: number): Promise<ApiResponse<AssetTypeModel>> {
    try {
      const applicationResponse = await apiClient.get<AssetTypeReadDTO>(`${this.basePath}/${id}`);
      return applicationResponse.processPayload(AssetTypeModel.fromDTO);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getByCode(code: string): Promise<ApiResponse<AssetTypeModel | undefined>> {
    try {
      const applicationResponse = await apiClient.get<AssetTypeReadDTO>(`${this.basePath}/by-code`, { code });
      return applicationResponse.processPayload(AssetTypeModel.fromDTO);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getTopAssetTypesByAssetCount(): Promise<ApiResponse<AssetByAssetCountDTO[]>> {
    try {
      const applicationResponse = await apiClient.get<AssetByAssetCountDTO[]>(`${this.basePath}/top?metric=${AssetMetricType.COUNT}`, {});
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getTopAssetTypesByAssetMonetaryValue(): Promise<ApiResponse<AssetByAssetMonetaryValueDTO[]>> {
    try {
      const applicationResponse = await apiClient.get<AssetByAssetMonetaryValueDTO[]>(
        `${this.basePath}/top?metric=${AssetMetricType.MONETARY_VALUE}`,
        {},
      );
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getBalancePerLocation(args: { assetTypeId?: number }): Promise<ApiResponse<ReadAssetTypeBalanceDTO[]>> {
    try {
      const applicationResponse = await apiClient.get<ReadAssetTypeBalanceDTO[]>(`${this.basePath}/${args.assetTypeId}/balances`, {});
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async delete(assetTypeId: number): Promise<ApiResponse<undefined>> {
    try {
      const applicationResponse = await apiClient.delete<undefined>(`${this.basePath}/${assetTypeId}`);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async unarchive(id: number): Promise<ApiResponse<void>> {
    try {
      const applicationResponse = await apiClient.put<void>(`${this.basePath}/archived/${id}`, {});
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const assetTypeService = new AssetTypeService();
