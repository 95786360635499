import { DTO } from '@/types/dto-wrapper';
import { AssetTypeBalanceAtLocationDetailDto, AssetTypeBalanceAtLocationDto } from '../api/locations/location.contracts';

export class AssetTypeBalanceAtLocationDetailModel extends DTO<AssetTypeBalanceAtLocationDetailDto> {
  constructor(dto: AssetTypeBalanceAtLocationDetailDto) {
    super(dto);
  }

  static fromDTO(dto: AssetTypeBalanceAtLocationDetailDto): AssetTypeBalanceAtLocationDetailModel {
    return new AssetTypeBalanceAtLocationDetailModel(dto);
  }

  static fromDTOs(dtos: AssetTypeBalanceAtLocationDetailDto[]): AssetTypeBalanceAtLocationDetailModel[] {
    return dtos.map((dto) => AssetTypeBalanceAtLocationDetailModel.fromDTO(dto));
  }
}

export class AssetTypeBalanceAtLocationModel extends DTO<AssetTypeBalanceAtLocationDto> {
  public balances: AssetTypeBalanceAtLocationDetailModel[];

  constructor(dto: AssetTypeBalanceAtLocationDto) {
    super(dto);
    this.balances = AssetTypeBalanceAtLocationDetailModel.fromDTOs(dto.balances);
  }

  get totalBalance(): number {
    return this.dto.totalBalance;
  }

  get id(): number | undefined {
    return this.dto.id;
  }

  get name(): string | undefined {
    return this.dto.name;
  }

  get coordinates(): number[] | undefined {
    return this.dto.coordinates;
  }

  get hasValidCoordinates(): boolean {
    return this.coordinates !== undefined && this.coordinates.length === 2;
  }

  get inTransitAssetId(): number | undefined {
    return this.dto.inTransitAssetId;
  }

  static fromDTO(dto: AssetTypeBalanceAtLocationDto): AssetTypeBalanceAtLocationModel {
    return new AssetTypeBalanceAtLocationModel(dto);
  }

  static fromDTOs(dtos: AssetTypeBalanceAtLocationDto[]): AssetTypeBalanceAtLocationModel[] {
    return dtos.map((dto) => AssetTypeBalanceAtLocationModel.fromDTO(dto));
  }
}
