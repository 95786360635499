export enum ApiResponseCode {
  SUCCESS = 'SUCCESS',
  // base-error.ts enum 'ErrorStatusCode' in API below:
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  NOT_FOUND = 'NOT_FOUND',
  MISMATCHED_TENANT_ID = 'MISMATCHED_TENANT_ID',
  EMAIL_IN_USE = 'EMAIL_IN_USE',
  INVALID = 'INVALID',
  LOCATION_HAS_ASSETS = 'LOCATION_HAS_ASSETS',
  LOCATION_IS_SYSTEM = 'LOCATION_IS_SYSTEM',
  LOCATION_HAS_CHILDREN = 'LOCATION_HAS_CHILDREN',
  DUPLICATE_ENTITY_CODE = 'DUPLICATE_ENTITY_CODE',
  DUPLICATE_ENTITY_NAME = 'DUPLICATE_ENTITY_NAME',
  DUPLICATE_TRACKER_IDENTIFIER = 'DUPLICATE_TRACKER_IDENTIFIER',
  INVALID_PARENT_LOCATION = 'INVALID_PARENT_LOCATION',
  UNLINKED_ASSET = 'UNLINKED_ASSET',
  DELETED_TRACKER = 'DELETED_TRACKER',
  NO_ASSET_ON_TRACKER = 'NO_ASSET_ON_TRACKER',
  ASSET_TYPE_HAS_ASSETS = 'ASSET_TYPE_HAS_ASSETS',
  NON_EXISTENT_LOCATION_ID = 'NON_EXISTENT_LOCATION_ID',
  UNKNOWN_TRACKER_TYPE = 'UNKNOWN_TRACKER_TYPE',
}
