import { Alert, Button, Drawer, IconButton, Portal, SelectChangeEvent, Skeleton } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { Close, Delete, IosShare, LabelOutlined } from '@mui/icons-material';
import { LabelAssignmentEntityType, RemoveAssignmentsDTO, SetAssignmentsDTO } from '../api/label.contracts';
import LabelSelect from './LabelSelect';
import { v4 } from 'uuid';
import LabelValueSelect from './LabelValueSelect';
import { labelService } from '../api/label.service';
import { useApiCall } from '@/hooks/useApiCall';
import { isNil } from 'lodash-es';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { EmptyState } from '@/components/feedback/EmptyState';
import TextLink from '@/components/TextLink';
import { useTranslation } from '@/lib';

interface LabelAssignment {
  labelId: number;
  labelValueId: number;
  entityType: LabelAssignmentEntityType;
  entityId: number;
}

interface ExistingAggregatedAssignmentUpdate {
  originalLabelId: number;
  originalLabelValueId: number;
  newLabelId: number;
  newLabelValueId: number;
  isRemoved: boolean;
  uuid: string;
}

interface AggregatedValueWithUpdate {
  originalValue: number[];
  update?: ExistingAggregatedAssignmentUpdate;
}

interface ManageLabelAssignmentsDrawerProps {
  entityType: LabelAssignmentEntityType;
  labelAssignments: LabelAssignment[];
  isOpen: boolean;
  onClose: () => void;
  entityIds: number[];
}

const ManageLabelAssignmentsDrawer: FunctionComponent<ManageLabelAssignmentsDrawerProps> = ({
  entityType,
  labelAssignments,
  isOpen,
  onClose,
  entityIds,
}) => {
  const [newAssignments, setNewAssignments] = useState<{ labelId?: number; labelValueId?: number; uiId: string }[]>([]);
  const [existingAggregatedAssignmentUpdates, setExistingAggregatedAssignmentUpdates] = useState<ExistingAggregatedAssignmentUpdate[]>([]);
  const { t } = useTranslation();

  const { data: allLabels, isLoading: isLoadingAllLabels, isError } = useApiCall(() => labelService.getAll());

  const [aggregatedAssignments, setAggregatedAssignments] = useState<Map<number, AggregatedValueWithUpdate>>(
    new Map<number, AggregatedValueWithUpdate>(),
  );

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const newAggregatedAssignments = labelAssignments.reduce((acc, assignment) => {
      const labelId = assignment.labelId;
      const labelValueId = assignment.labelValueId;

      if (acc.has(labelId)) {
        const labelValue = acc.get(labelId)!;
        if (!labelValue.originalValue.includes(labelValueId)) {
          labelValue.originalValue.push(labelValueId);
        }
      } else {
        acc.set(labelId, { originalValue: [labelValueId] });
      }

      return acc;
    }, new Map<number, AggregatedValueWithUpdate>());

    // apply updates
    // Check if there is an update for this label
    for (const update of existingAggregatedAssignmentUpdates) {
      if (update) {
        // Set the update for the label
        if (newAggregatedAssignments.has(update.originalLabelId)) {
          const labelValue = newAggregatedAssignments.get(update.originalLabelId)!;
          labelValue.update = update;
          newAggregatedAssignments.set(update.originalLabelId, labelValue);
        } else {
          // Create a new entry for the label
          newAggregatedAssignments.set(update.originalLabelId, { originalValue: [], update: update });
        }
      }
    }

    setAggregatedAssignments(newAggregatedAssignments);
  }, [labelAssignments, existingAggregatedAssignmentUpdates]);

  function onAddLabelClicked() {
    setNewAssignments((prev) => [...prev, { labelValueId: undefined, labelId: undefined, uiId: v4() }]);
  }

  function onNewLabelAssignmentChanged(event: SelectChangeEvent<number>, uiId: string) {
    const labelId = event.target.value as number;
    setNewAssignments((prev) =>
      prev.map((assignment) => {
        if (assignment.uiId === uiId) {
          return { ...assignment, labelId, labelValueId: undefined };
        }
        return assignment;
      }),
    );
  }

  function onNewLabelAssignmentValueChanged(event: SelectChangeEvent<number>, uiId: string) {
    const labelValueId = event.target.value as number;
    setNewAssignments((prev) =>
      prev.map((assignment) => {
        if (assignment.uiId === uiId) {
          return { ...assignment, labelValueId };
        }
        return assignment;
      }),
    );
  }

  function onAggregatedAssignmentLabelChanged(
    event: SelectChangeEvent<number>,
    oldLabelId: number,
    valueWithUpdate: AggregatedValueWithUpdate,
  ) {
    const newLabelId = event.target.value as number;

    // If the label is changed back to the original value, remove the update
    if (valueWithUpdate.update && newLabelId === oldLabelId) {
      setExistingAggregatedAssignmentUpdates((prev) => prev.filter((update) => update.uuid !== valueWithUpdate.update?.uuid));
      return;
    }

    // check if the labelId is already in the updates
    if (valueWithUpdate.update) {
      valueWithUpdate.update.newLabelId = newLabelId;
      // update the existing update
      setExistingAggregatedAssignmentUpdates((prev) =>
        prev.map((update) => (update.uuid === valueWithUpdate.update?.uuid ? valueWithUpdate.update : update)),
      );
    } else {
      const newUpdate: ExistingAggregatedAssignmentUpdate = {
        originalLabelId: oldLabelId,
        originalLabelValueId: 0,
        newLabelId: newLabelId,
        newLabelValueId: 0,
        isRemoved: false,
        uuid: v4(),
      };
      setExistingAggregatedAssignmentUpdates((prev) => [...prev, newUpdate]);
    }
  }

  function onAggregatedAssignmentLabelValueChanged(
    event: SelectChangeEvent<number>,
    labelId: number,
    valueWithUpdate: AggregatedValueWithUpdate,
  ) {
    const newLabelValueId = event.target.value as number;

    if (valueWithUpdate.update) {
      valueWithUpdate.update.newLabelValueId = newLabelValueId;
      // update the existing update
      setExistingAggregatedAssignmentUpdates((prev) =>
        prev.map((update) => (update.uuid === valueWithUpdate.update?.uuid ? valueWithUpdate.update : update)),
      );
    } else {
      const newUpdate: ExistingAggregatedAssignmentUpdate = {
        originalLabelId: labelId,
        originalLabelValueId: 0,
        newLabelId: labelId,
        newLabelValueId: newLabelValueId,
        isRemoved: false,
        uuid: v4(),
      };
      setExistingAggregatedAssignmentUpdates((prev) => [...prev, newUpdate]);
    }
  }

  async function onSaveClicked() {
    try {
      setIsSaving(true);
      // Save new assignments
      const newAssignmentsToSave = newAssignments.map((assignment) => {
        const newAssignmentToSave: SetAssignmentsDTO = {
          labelValueId: assignment.labelValueId!,
          entityType: entityType,
          entityIds: entityIds,
        };
        return newAssignmentToSave;
      });

      const removedAssignments = existingAggregatedAssignmentUpdates
        .filter((update) => update.isRemoved || update.originalLabelId !== update.newLabelId)
        .map((update) => {
          const removedAssignment: RemoveAssignmentsDTO = {
            labelId: update.originalLabelId,
            entityType: entityType,
            entityIds: entityIds,
          };
          return removedAssignment;
        });

      const newAssignmentsFromUpdates = existingAggregatedAssignmentUpdates
        .filter(
          (update) =>
            !update.isRemoved && (update.originalLabelId !== update.newLabelId || update.originalLabelValueId !== update.newLabelValueId),
        )
        .map((update) => {
          const newAssignmentToSave: SetAssignmentsDTO = {
            labelValueId: update.newLabelValueId,
            entityType: entityType,
            entityIds: entityIds,
          };
          return newAssignmentToSave;
        });

      await labelService.updateAssignments({
        setAssignments: newAssignmentsToSave.concat(newAssignmentsFromUpdates),
        removeAssignments: removedAssignments,
      });
      onClose();
      toast.success('Label assignments saved');
    } catch (error) {
      console.error('Failed to save label assignments:', error);
      toast.error('Failed to save label assignments');
    } finally {
      setIsSaving(false);
    }
  }

  function onRemoveNewLabelAssignmentClicked(uiId: string) {
    setNewAssignments((prev) => prev.filter((assignment) => assignment.uiId !== uiId));
  }

  function onRemoveExistingLabelAssignment(labelId: number) {
    // check if the labelId is already in the updates
    const existingUpdate = existingAggregatedAssignmentUpdates.find((update) => update.originalLabelId === labelId);
    if (existingUpdate) {
      existingUpdate.isRemoved = true;
      // force update
      setExistingAggregatedAssignmentUpdates((prev) => [...prev]);

      return;
    } else {
      setExistingAggregatedAssignmentUpdates((prev) => [
        ...prev,
        {
          originalLabelId: labelId,
          originalLabelValueId: 0,
          newLabelId: labelId,
          newLabelValueId: 0,
          isRemoved: true,
          uuid: v4(),
        },
      ]);
    }
  }

  useEffect(() => {
    if (isOpen) {
      setNewAssignments([]);
      setExistingAggregatedAssignmentUpdates([]);
    }
  }, [isOpen]);

  const areAllNewAssignmentsValid = newAssignments.every((assignment) => assignment.labelId && assignment.labelValueId);
  const hasChanges = newAssignments.length > 0 || existingAggregatedAssignmentUpdates.length > 0;
  const canSubmit = hasChanges && areAllNewAssignmentsValid;

  const labelIdsInAssignments = [
    ...newAssignments.map((assignment) => assignment.labelId),
    ...Array.from(aggregatedAssignments.entries())
      .filter((val) => !val[1].update?.isRemoved)
      .map((notRemovedAssignment) => (notRemovedAssignment[1].update ? notRemovedAssignment[1].update.newLabelId : notRemovedAssignment[0])),
  ].filter((val): val is number => !isNil(val));

  // Determine if "Add Label" button should be visible
  const showAddLabelButton = () => {
    if (isLoadingAllLabels || isError || !allLabels) {
      // If we are loading labels, have encountered an error, or labels are not yet fetched, do not show the button.
      return false;
    }

    if (aggregatedAssignments.size === 0 && newAssignments.length === 0) {
      return false;
    }

    const totalLabelIdsAvailable = allLabels.length;
    let assignedLabelIds = 0;

    // Consider new assignments
    assignedLabelIds += newAssignments.length;

    // Consider existing assignments
    assignedLabelIds += Array.from(aggregatedAssignments.entries()).filter(
      (val) => !existingAggregatedAssignmentUpdates.some((update) => update.originalLabelId === val[0] && update.isRemoved),
    ).length;

    // The button is visible if there are labels that have not yet been assigned
    return assignedLabelIds < totalLabelIdsAvailable;
  };

  const systemContainsLabels = !isLoadingAllLabels && !isError && allLabels && allLabels.length > 0;

  return (
    <Portal>
      <Drawer anchor={'right'} open={isOpen} onClose={onClose} variant="temporary">
        <div className="flex  w-[768px] flex-col justify-between px-6 py-4">
          <div className="flex flex-col">
            {/* Header */}
            <div className="flex items-center justify-between">
              <div className="text-lg font-medium">{t('manage_label_assignments')}</div>
              <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                <Close />
              </IconButton>
            </div>
            {/* Content */}
            <div className="flex flex-col overflow-auto">
              {!systemContainsLabels ? (
                <EmptyState
                  className="my-12"
                  description={
                    <div className=" text-center flex-wrap">
                      {t('label_create_description')} <br />
                      {t('labels_description')} <br />
                      <div className="mt-4">
                        <TextLink
                          className="font-semibold text-primary-500"
                          fullWidth={false}
                          to={{ pathname: '/app/configuration/labels/create' }}
                          rightIcon={<IosShare fontSize="inherit" />}
                        >
                          {t('create_a_label')}
                        </TextLink>{' '}
                        {t('assign_label_to_location')}
                      </div>
                    </div>
                  }
                  title={t('manage_label_assignments_empty_state_title')}
                  buttonText={t('create_label')}
                  icon={<LabelOutlined />}
                />
              ) : (
                <>
                  <p className="text-sm pr-2 flex flex-wrap">
                    {t('label_description_example') + ' '}(e.g.,
                    <span className="font-semibold">
                      type<span className="px-px">:</span>customer
                    </span>
                    <span className="px-0.5">{t('or')}</span>
                    <span>
                      <span className="font-semibold">
                        country
                        <span className="px-px">:</span>nl
                      </span>
                      ).
                    </span>
                  </p>
                  {aggregatedAssignments.size === 0 && newAssignments.length === 0 ? (
                    <div className="flex flex-col items-center justify-center py-8">
                      <LabelOutlined className="w-12 h-12 text-gray-500 dark:text-gray-400" color="inherit" />
                      <div className="text-sm mt-2 text-gray-500 dark:text-gray-400">{t('no_labels_assigned')}</div>
                      <Button className="mt-6" variant="outlined" color="primary" onClick={onAddLabelClicked}>
                        {t('add_label')}
                      </Button>
                    </div>
                  ) : (
                    Array.from(aggregatedAssignments.entries())
                      .filter((val) => !val[1].update?.isRemoved)
                      .map(([labelId, labelValueIdsWithUpdate]) => (
                        <div key={labelId} className="flex items-start py-1 gap-x-4 group">
                          <div className="w-1/2">
                            <LabelSelect
                              value={labelValueIdsWithUpdate.update ? labelValueIdsWithUpdate.update.newLabelId : labelId}
                              onChange={(event) => onAggregatedAssignmentLabelChanged(event, labelId, labelValueIdsWithUpdate)}
                              excludedLabelIds={labelIdsInAssignments.filter(
                                (val) => val !== (labelValueIdsWithUpdate.update ? labelValueIdsWithUpdate.update.newLabelId : labelId),
                              )}
                            />
                          </div>
                          <div className="w-1/2">
                            {/* {JSON.stringify({ labelValueIdsWithUpdate })} */}
                            {(labelValueIdsWithUpdate.update || labelId) && (
                              <LabelValueSelect
                                labelId={labelValueIdsWithUpdate.update ? labelValueIdsWithUpdate.update.newLabelId : labelId}
                                value={
                                  labelValueIdsWithUpdate.update
                                    ? labelValueIdsWithUpdate.update.newLabelValueId
                                    : labelValueIdsWithUpdate.originalValue.length > 0 &&
                                        entityIds.length ===
                                          labelAssignments.filter(
                                            (la) =>
                                              la.labelId === labelId &&
                                              // check if all labelAssignments have the same labelValueId
                                              la.labelValueId === labelValueIdsWithUpdate.originalValue[0],
                                          ).length
                                      ? labelValueIdsWithUpdate.originalValue[0]
                                      : ''
                                }
                                hasMixedValues={
                                  !(
                                    labelValueIdsWithUpdate.originalValue.length > 0 &&
                                    entityIds.length ===
                                      labelAssignments.filter(
                                        (la) =>
                                          la.labelId === labelId &&
                                          // check if all labelAssignments have the same labelValueId
                                          la.labelValueId === labelValueIdsWithUpdate.originalValue[0],
                                      ).length
                                  ) && !labelValueIdsWithUpdate.update
                                }
                                onChange={(event) => onAggregatedAssignmentLabelValueChanged(event, labelId, labelValueIdsWithUpdate)}
                              />
                            )}
                          </div>
                          <div className="pt-2.5 w-10">
                            <IconButton
                              className="group-hover:flex hidden"
                              color="secondary"
                              onClick={() => onRemoveExistingLabelAssignment(labelId)}
                            >
                              <Delete />
                            </IconButton>
                          </div>
                        </div>
                      ))
                  )}

                  {newAssignments.map((assignment, i) => (
                    <div key={assignment.uiId}>
                      <div className="flex items-start  pt-1 gap-x-4 group">
                        <div className="w-1/2">
                          <LabelSelect
                            value={assignment.labelId}
                            onChange={(event) => onNewLabelAssignmentChanged(event, assignment.uiId)}
                            excludedLabelIds={labelIdsInAssignments.filter((val) => val !== assignment.labelId)}
                          />
                          {!assignment.labelId && <div className="text-xs text-red-500 pl-2">{t('select_label')}</div>}
                        </div>
                        <div className="w-1/2">
                          {assignment.labelId && (
                            <LabelValueSelect
                              labelId={assignment.labelId}
                              value={assignment.labelValueId}
                              onChange={(event) => onNewLabelAssignmentValueChanged(event, assignment.uiId)}
                            />
                          )}
                          {assignment.labelId && !assignment.labelValueId && (
                            <div className="text-xs text-red-500 pl-2">{t('select_label_value')}</div>
                          )}
                        </div>
                        <div className="pt-2.5 w-10">
                          <IconButton
                            className="group-hover:flex hidden"
                            color="secondary"
                            onClick={() => onRemoveNewLabelAssignmentClicked(assignment.uiId)}
                          >
                            <Delete />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className="flex flex-col mt-4 items-start w-full">
                    {isLoadingAllLabels && (
                      <div className="flex items-center justify-center">
                        <Skeleton variant="rounded" width={425} height={20} />
                      </div>
                    )}
                    {isError && <Alert severity="error">{t('label_fetch_error')}</Alert>}
                    <div>
                      {showAddLabelButton() && (
                        <Button variant="outlined" color="primary" onClick={onAddLabelClicked}>
                          {t('add_label')}
                        </Button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {systemContainsLabels && (
            <div className="w-full mt-12 flex gap-x-4">
              <LoadingButton variant="contained" color="primary" size="medium" onClick={onSaveClicked} disabled={!canSubmit}>
                {t('save')}
              </LoadingButton>
              {hasChanges ? (
                <Button variant="text" color="secondary" onClick={onClose} size="medium">
                  {t('discard_changes')}
                </Button>
              ) : (
                <Button variant="text" color="secondary" onClick={onClose} size="medium" disabled={isSaving}>
                  {t('cancel')}
                </Button>
              )}
            </div>
          )}
        </div>
      </Drawer>
    </Portal>
  );
};

export default ManageLabelAssignmentsDrawer;
