import { Navigate, RouteObject } from 'react-router-dom';
import EventsLayout from '../layouts/EventsLayout';
import { EventsPage } from '../pages/EventsPage';
import { EventDetailPage } from '../pages/EventDetailPage';
import { HandheldEventsPage } from '../pages/HandheldEventsPage';

export const EVENT_ROUTES: RouteObject[] = [
  {
    path: 'events',
    element: <EventsLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="overview" replace />,
      },
      {
        path: 'overview',
        element: <EventsPage />,
      },
      {
        path: 'handheld',
        element: <HandheldEventsPage />,
      },
      {
        path: 'create',
        element: <EventDetailPage isCreate={true} />,
      },
      {
        path: ':eventId',
        element: <EventDetailPage />,
      },
    ],
  },
];
