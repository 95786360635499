import { Box, Drawer, IconButton, Portal, Tab } from '@mui/material';
import { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import { useApiCall } from '@/hooks/useApiCall';
import { locationService } from '../../api/locations/location.service';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { LocationEventsTab } from './location-events-tab/LocationEventsTab';
import { LocationAssetsTab } from './location-assets-tab/LocationAssetsTab';
import { DisplayMode } from '@/types/display-mode.enum';
import { useTranslation } from '@/lib';
import { LocationBalanceTab } from './location-balance-tab/LocationBalanceTab';

interface LocationInfoDrawerProps {
  locationId: number;
  isOpen: boolean;
  onClose: () => void;
}

const LocationInfoDrawer: FunctionComponent<LocationInfoDrawerProps> = ({ locationId, isOpen, onClose }) => {
  const { t } = useTranslation();
  const { data, fetchData, isLoading, setApiCallArg } = useApiCall(() => locationService.getById(locationId));
  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.INDIVIDUAL);
  const {
    data: assetTotalData,
    fetchData: fetchAssetTotalData,
    isLoading: isAssetTotalDataLoading,
    setApiCallArg: setAssetTotalsApiCallArg,
  } = useApiCall(() => locationService.getBalance({ locationId }));

  useEffect(() => {
    setApiCallArg(() => locationService.getById(locationId));
    setAssetTotalsApiCallArg(() => locationService.getBalance({ locationId }));
  }, [locationId]);

  const [value, setValue] = useState('1');

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Portal>
      <Drawer anchor={'right'} open={isOpen} hideBackdrop variant="persistent">
        <div className="flex h-full  w-[512px] flex-col  justify-between  px-6 py-4">
          <div className="flex h-full  flex-col ">
            {!data || isLoading ? (
              <LoadingBlock />
            ) : (
              <>
                {/* header */}
                <div className="div flex items-center justify-between">
                  <div className="flex flex-col">
                    <div className="text-xs text-gray-500 dark:text-gray-400 ">{t('location')}</div>
                    <div className="text-lg font-medium ">
                      {data.dto.name} ({data.dto.code})
                    </div>
                  </div>
                  <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                    <Close />
                  </IconButton>
                </div>
                {/* content */}
                <div className="flex  h-full flex-col ">
                  {/* <AssetsPerTypeBarChart data={assetPerTypeChartData} /> */}
                  {/* 
                  <div className="mb-2 mt-4 flex flex-col">
                    <div className="flex items-center justify-between mb-1">
                      <div className=" font-medium">{t('assets_on_location')}</div>
                      <div className="text-xs flex items-center scale-75 origin-right">
                        <LocationHierarchyToggleButton displayMode={displayMode} onChange={setDisplayMode} reverseOrder />
                      </div>
                    </div>
                    {assetTotalData !== undefined && (
                      <AssetTypeTotalTable displayMode={displayMode} assetTypeTotals={assetTotalData}></AssetTypeTotalTable>
                    )}
                  </div> */}
                  <div className="mb-2  flex h-full flex-grow flex-col ">
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                          <Tab label={t('balances')} value="1" />
                          <Tab label={t('assets')} value="2" />
                          <Tab label={t('recent_events')} value="3" />
                        </TabList>
                      </Box>
                      <TabPanel className="p-0" value="1" style={{ flexGrow: 1 }}>
                        {locationId && <LocationBalanceTab locationId={locationId} />}
                      </TabPanel>
                      <TabPanel className="p-0" value="2" style={{ flexGrow: 1 }}>
                        {locationId && <LocationAssetsTab locationId={locationId} />}
                      </TabPanel>
                      <TabPanel className="p-0" value="3" style={{ flexGrow: 1 }}>
                        {locationId && <LocationEventsTab locationId={locationId} />}
                      </TabPanel>
                    </TabContext>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="w-full"></div>
        </div>
      </Drawer>
    </Portal>
  );
};

export default LocationInfoDrawer;
