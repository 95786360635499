import { Outlet } from 'react-router-dom';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import { RssFeedRounded } from '@mui/icons-material';
import { useTranslation } from '@/lib';

export default function EventsLayout() {
  const { t } = useTranslation();
  const eventTabItems = [
    {
      activeRouteIds: ['/app/events/overview'],
      label: t('event.all'),
      url: '/app/events/overview',
    },
    {
      activeRouteIds: ['/app/events/handheld*'],
      label: t('event.handheld'),
      url: '/app/events/handheld',
    },
  ];

  return (
    <TabbedLayout
      topBar={
        <TabbedLayoutTopBar
          header={t('event.title')}
          headerIcon={<RssFeedRounded fontSize={'small'} />}
          // actions={
          //   <Button variant="contained" onClick={() => navigate({ pathname: '../create' })}>
          //     Create
          //   </Button>
          // }
          tabItems={eventTabItems}
        />
      }
    >
      <Outlet />
    </TabbedLayout>
  );
}
