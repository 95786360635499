import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { ImportDefinitionFactory } from '../../definitions/import-factory';
import ImportWizard from '../../components/ImportWizard';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { TabbedPageLayout } from '@/modules/application/layouts/TabbedPageLayout';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { useTranslation } from '@/lib';

export const ImporterPage: FunctionComponent = () => {
  const allImporters = ImportDefinitionFactory.getAllImportDefinitions();
  const params = useParams();
  const activeImporter = allImporters.find((i) => i.type === params.importerType);
  const { t } = useTranslation();

  return (
    <TabbedLayout
      topBar={
        <TabbedLayoutTopBar>
          <TabbedLayoutTopBarHeader icon={null}>
            {t('import')} {activeImporter?.title}
          </TabbedLayoutTopBarHeader>
        </TabbedLayoutTopBar>
      }
    >
      <TabbedPageLayout>
        <TabbedPageLayoutBody>{activeImporter && <ImportWizard importDefinition={activeImporter} />}</TabbedPageLayoutBody>
      </TabbedPageLayout>
    </TabbedLayout>
  );
};
