import LoadingBlock from '@/components/feedback/LoadingBlock';
import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import TrackerFormLogic from './TrackerFormLogic';
import { TrackerFormModel } from './TrackerFormModel';
import { useNavigate } from 'react-router-dom';
import { isNumber } from 'lodash-es';
import { TrackerModel } from '../../types/TrackerModel';
import { trackerService } from '../../api/trackers/tracker.service';
import { TrackerType } from '../../api/trackers/tracker.contracts';
import { ApiResponseCode } from '@/lib/api/api-response-code';
import { useUser } from '@/modules/users/contexts/UserContext';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useTranslation } from '@/lib';

interface TrackerFormApiProps {
  isCreate?: boolean;
  trackerId?: number;
}

const TrackerFormApi: FunctionComponent<TrackerFormApiProps> = ({ isCreate, trackerId }) => {
  const navigate = useNavigate();
  const hasTrackerEditPermission = useUser().hasPermission(Permission.TRACKERS_EDIT);
  const { t } = useTranslation();

  const handleSubmit = async (data: TrackerFormModel) => {
    if (!hasTrackerEditPermission) {
      toast.error(t('error_no_permission_to_edit_trackers'));
      return false;
    }

    // Process data
    if (isCreate) {
      const response = await trackerService.create({
        epc: data.epc,
        type: data.type,
        barcode: data.barcode,
        deviceId: data.deviceId,
      });

      if (!response.isSuccess) {
        if (response.responseCode === ApiResponseCode.DUPLICATE_TRACKER_IDENTIFIER) {
          if (data.type === TrackerType.Barcode) {
            toast(t('tracker_in_use_barcode'));
          }
          if (data.type === TrackerType.RFID) {
            toast(t('tracker_in_use_epc'));
          }
          if (data.type === TrackerType.UnaTag || data.type === TrackerType.BLE) {
            toast(t('tracker_in_use_device_id'));
          }
        } else if (response.responseCode === ApiResponseCode.DUPLICATE_ENTITY_CODE) {
          toast(t('error_duplicate_code'));
        } else {
          toast(t('error_creating_tracker'), {
            type: 'error',
          });
        }
      } else {
        toast.success(t('tracker_created'));
        navigate('/app/configuration/trackers/overview');
      }
    } else if (isNumber(trackerId)) {
      const response = await trackerService.update(trackerId, {
        epc: data.epc,
        type: data.type,
        barcode: data.barcode,
        deviceId: data.deviceId,
      });

      if (!response.isSuccess) {
        if (response.responseCode === ApiResponseCode.DUPLICATE_TRACKER_IDENTIFIER) {
          if (data.type === TrackerType.Barcode) {
            toast(t('tracker_in_use_barcode'));
          }
          if (data.type === TrackerType.RFID) {
            toast(t('tracker_in_use_epc'));
          }
          if (data.type === TrackerType.UnaTag || data.type === TrackerType.BLE) {
            toast(t('tracker_in_use_device_id'));
          }
        } else if (response.responseCode === ApiResponseCode.DUPLICATE_ENTITY_CODE) {
          toast(t('error_duplicate_code'));
        } else {
          toast(t('error_updating_tracker'), {
            type: 'error',
          });
        }
      } else {
        toast.success(t('tracker_updated'));
        navigate('/app/configuration/trackers/overview/');
      }
    }
    return true;
  };

  if (isCreate || !trackerId) {
    const newTracker: TrackerFormModel = {
      type: TrackerType.RFID,
      epc: '',
      barcode: '',
      deviceId: '',
    };
    return <TrackerFormLogic initialValues={newTracker} onSubmit={handleSubmit} isCreate trackerId={trackerId} />;
  } else {
    const { data, isLoading, isError } = useApiCall<TrackerModel>(() => trackerService.getById(trackerId));

    // return early if initial form data isn't loaded
    if (isLoading) return <LoadingBlock />;
    if (isError || !data) return <div>No Data Error</div>;

    return <TrackerFormLogic initialValues={data} onSubmit={handleSubmit} isCreate={isCreate} trackerId={trackerId} />;
  }
};

export default TrackerFormApi;
