import LoadingBlock from '@/components/feedback/LoadingBlock';
import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent, useEffect } from 'react';
import { toast } from 'react-toastify';
import AssetTypeFormLogic from './AssetTypeFormLogic';
import { AssetTypeFormModel } from './AssetTypeFormModel';
import { useNavigate } from 'react-router-dom';
import { isNumber } from 'lodash-es';
import { assetTypeService } from '../../api/asset-types/asset-type.service';
import { AssetTypeModel } from '../../types/AssetTypeModel';
import { ApiResponseCode } from '@/lib/api/api-response-code';
import { useUser } from '@/modules/users/contexts/UserContext';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useTranslation } from '@/lib';

interface AssetTypeFormApiProps {
  isCreate?: boolean;
  assetTypeId?: number;
}

const AssetTypeFormApi: FunctionComponent<AssetTypeFormApiProps> = ({ isCreate, assetTypeId }) => {
  const navigate = useNavigate();
  const hasAssetTypeEditPermission = useUser().hasPermission(Permission.ASSET_TYPES_EDIT);
  const { t } = useTranslation();
  const { data, isLoading, isError, setApiCallArg } = useApiCall<AssetTypeModel>(() => assetTypeService.getById(0), {
    manual: true,
  });

  useEffect(() => {
    if (!isCreate && assetTypeId) {
      setApiCallArg(() => assetTypeService.getById(assetTypeId));
    }
  }, [assetTypeId]);

  const handleSubmit = async (data: AssetTypeFormModel) => {
    if (!hasAssetTypeEditPermission) {
      toast.error(t('no_permission_edit_asset_types'));
      return false;
    }

    // Process data
    if (isCreate) {
      const response = await assetTypeService.create({
        name: data.name,
        code: data.code,
        description: data.description,
        widthInMm: data.widthInMm,
        heightInMm: data.heightInMm,
        depthInMm: data.depthInMm,
        volumeInCubicMm: data.volumeInCubicMm,
        modelYear: data.modelYear,
        monetaryValue: data.monetaryValue,
      });

      if (!response.isSuccess) {
        if (response.responseCode === ApiResponseCode.DUPLICATE_ENTITY_CODE) {
          toast(t('error_duplicate_code'));
        } else {
          toast(t('error_creating_asset_type'), {
            type: 'error',
          });
        }
      } else {
        toast.success(t('asset_type_created'));
        navigate('/app/configuration/assets/types/');
      }
    } else if (isNumber(assetTypeId)) {
      const response = await assetTypeService.update(assetTypeId, {
        name: data.name,
        code: data.code,
        description: data.description,
        widthInMm: data.widthInMm,
        heightInMm: data.heightInMm,
        depthInMm: data.depthInMm,
        volumeInCubicMm: data.volumeInCubicMm,
        modelYear: data.modelYear,
        monetaryValue: data.monetaryValue,
      });

      if (!response.isSuccess) {
        if (response.responseCode === ApiResponseCode.DUPLICATE_ENTITY_CODE) {
          toast(t('error_duplicate_code'));
        } else {
          toast(t('error_updating_asset_type'), {
            type: 'error',
          });
        }
      } else {
        // Upload image if it exists
        if (data.image) {
          const imageResponse = await assetTypeService.uploadImage(assetTypeId, data.image);
          if (!imageResponse.isSuccess) {
            toast(t('error_uploading_image'), {
              type: 'error',
            });
          }
          // Remove image if it exists and is explicitly set to null
        } else if (data.image === null) {
          const imageResponse = await assetTypeService.removeImage(assetTypeId);
          if (!imageResponse.isSuccess) {
            toast(t('error_removing_image'), {
              type: 'error',
            });
          }
        }

        toast.success(t('asset_type_updated'));
        navigate('/app/configuration/assets/types/');
      }
    }
    return true;
  };

  if (isCreate || !assetTypeId) {
    const newAssetType: AssetTypeFormModel = {
      name: '',
      code: '',
      description: '',
    };
    return <AssetTypeFormLogic initialValues={newAssetType} onSubmit={handleSubmit} isCreate />;
  } else {
    // return early if initial form data isn't loaded
    if (isLoading) return <LoadingBlock />;
    if (isError || !data) return <div>No Data Error</div>;

    return <AssetTypeFormLogic initialValues={data.dto} onSubmit={handleSubmit} isCreate={isCreate} />;
  }
};

export default AssetTypeFormApi;
