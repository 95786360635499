import Slider from '@mui/material/Slider';

interface RangeInKmSliderProps {
  value: number;
  onChange: (value: number) => void;
}

const marks = [
  {
    value: 100,
    label: '100m',
  },

  {
    value: 1000,
    label: '1km',
  },
  {
    value: 5000,
    label: '5km',
  },
];

function valuetext(value: number) {
  return `${value} km`;
}

export function RangeInKmSlider({ value, onChange }: RangeInKmSliderProps) {
  const handleChange = (event: Event, newValue: number | number[]) => {
    onChange(newValue as number);
  };

  return (
    <Slider
      aria-label="Custom marks"
      getAriaValueText={valuetext}
      step={10}
      min={0}
      max={5000}
      valueLabelDisplay="auto"
      marks={marks}
      value={value}
      onChange={handleChange}
    />
  );
}
