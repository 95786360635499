export interface BaseDTO<T> {
  dto: T;
}

export abstract class DTO<T> implements BaseDTO<T> {
  dto: T;

  constructor(data: T) {
    this.dto = data;
  }
}
