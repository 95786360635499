import { TFunction } from 'i18next';
import { IDashboardWidgetBaseConfiguration } from '../../../types/IDashboardWidgetBaseConfiguration';

export class MapWidgetConfiguration implements IDashboardWidgetBaseConfiguration {
  title = 'dashboard.mapWidgetConfiguration.title'; //'Map Widget';
  includeEmptyLocations = false;
  includeInTransitAssets = false;
  assetTypeId?: number;

  constructor(t: TFunction) {
    this.title = t(this.title);
  }
}
