import { router } from '@/router/router';
import { AxiosStatic } from 'axios';

export const registerErrorInterceptor = (axios: AxiosStatic) => {
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && error.response.status === 401) {
        router.navigate('/login?logOut=true');
      }
      return Promise.reject(error);
    },
  );
};
