import { PoGrid } from '@/components/grid/PoGrid';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridOptions, ICellRendererParams, RowDoubleClickedEvent, ValueGetterParams } from 'ag-grid-community';
import LinkCellRenderer, { LinkCellRendererParams } from '@/components/grid/cells/LinkCellRenderer';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { EventModel, EventModelFieldNames } from '@/modules/events/types/EventModel';
import { RelativeDateCellRenderer } from '@/components/grid/cells/RelativeDateCellRenderer';
import { useTranslation } from '@/lib';
import { ColumnID } from '@/components/grid/column-ids';
import { ARCHIVED_LOCATION_NAME } from '@/modules/locations/api/locations/location.contracts';

interface AssetEventsGridProps {
  data?: EventModel[];
  isError: boolean;
}

export const AssetEventsGrid: FunctionComponent<AssetEventsGridProps> = ({ data, isError }) => {
  const { t } = useTranslation();
  if (!data) {
    return <div>{t('no_data')}</div>;
  }
  const navigate = useNavigate();

  const customGridOptions: GridOptions<EventModel> = {
    onRowDoubleClicked(event: RowDoubleClickedEvent<EventModel, unknown>) {
      navigate(`/app/events/${event.data?.id}`);
    },
  };

  const columns: ColDefOrGroup<EventModel>[] = [
    {
      colId: ColumnID.ASSET_CODE,
      headerName: t('event.asset_code'),
      field: EventModelFieldNames.AssetCode,
    },
    {
      colId: ColumnID.OCCURRED_AT,
      field: EventModelFieldNames.OccurredAt,
      headerName: t('event.occurred_at'),
      cellRenderer: RelativeDateCellRenderer,
    },
    {
      colId: ColumnID.LOCATION_NAME,
      field: EventModelFieldNames.LocationName,
      headerName: t('event.location'),
      cellRenderer: LinkCellRenderer,
      cellRendererParams: (params: ICellRendererParams<EventModel>): LinkCellRendererParams => ({
        pathname: params.data?.location.name === ARCHIVED_LOCATION_NAME ? undefined : `/app/locations/${params.data?.location.id}`,
      }),
    },
    {
      colId: ColumnID.EVENT_TYPE,
      headerName: t('event.type.title'),
      valueGetter: (params: ValueGetterParams<EventModel>) => {
        return t(`event.type.${params.data?.type}`);
      },
    },
    {
      colId: ColumnID.EVENT_SOURCE,
      headerName: 'Source',
      valueGetter: (params: ValueGetterParams<EventModel>) => {
        return t(`event.event_source.${params.data?.eventSource}`);
      },
    },
  ];

  return (
    <div className="flex h-full flex-1 flex-grow flex-col">
      {isError ? <ErrorLoadingDataAlert /> : <PoGrid isLoading={false} colDefs={columns} rowData={data} gridOptions={customGridOptions} />}
    </div>
  );
};
