import { TextField } from '@mui/material';
import { FunctionComponent } from 'react';
import { EventsOverTimeByTypeWidgetConfiguration } from './EventsOverTimeByTypeWidgetConfiguration';
import { useDashboardContext } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { useTranslation } from '@/lib';

interface EventsOverTimeByTypeWidgetConfigurationFormProps {
  configuration: EventsOverTimeByTypeWidgetConfiguration;
  id: string;
}

const EventsOverTimeByTypeWidgetConfigurationForm: FunctionComponent<EventsOverTimeByTypeWidgetConfigurationFormProps> = ({
  configuration,
  id,
}) => {
  const { widgets, updateWidget } = useDashboardContext();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-y-3">
      <TextField
        label="Name"
        value={configuration.title}
        onChange={(e) => {
          updateWidget<EventsOverTimeByTypeWidgetConfiguration>(id, {
            configuration: {
              title: e.currentTarget.value,
            },
          });
        }}
      />
    </div>
  );
};

export default EventsOverTimeByTypeWidgetConfigurationForm;
