import LoadingBlock from '@/components/feedback/LoadingBlock';
import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent, useEffect } from 'react';
import { toast } from 'react-toastify';
import AssetFormLogic from './AssetFormLogic';
import { AssetFormModel } from './AssetFormModel';
import { useNavigate, useParams } from 'react-router-dom';
import { isNumber } from 'lodash-es';
import { AssetModel } from '../../types/AssetModel';
import { assetService } from '../../api/assets/asset.service';
import { ApiResponseCode } from '@/lib/api/api-response-code';
import { useUser } from '@/modules/users/contexts/UserContext';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useTranslation } from '@/lib'; // Import your custom useTranslation hook

const AssetFormApi: FunctionComponent = () => {
  const { assetId } = useParams();
  const assetIdNumber = assetId ? Number.parseInt(assetId) : 0;
  const isCreate = assetId === 'create';

  const navigate = useNavigate();
  const hasAssetsEditPermission = useUser().hasPermission(Permission.ASSETS_EDIT);
  const { t } = useTranslation(); // Initialize the translation function
  const { data, isLoading, isError, setApiCallArg } = useApiCall<AssetModel>(() => assetService.getById(0), {
    manual: true,
  });

  useEffect(() => {
    if (assetIdNumber) {
      setApiCallArg(() => assetService.getById(assetIdNumber));
    }
  }, [assetIdNumber]);

  const handleSubmit = async (data: AssetFormModel) => {
    if (!hasAssetsEditPermission) {
      toast.error(t('no_permission_edit_assets'));
      return false;
    }

    // Process data
    if (isCreate) {
      const response = await assetService.create({
        code: data.code,
        assetTypeId: data.assetTypeId,
      });

      if (!response.isSuccess) {
        if (response.responseCode === ApiResponseCode.DUPLICATE_ENTITY_CODE) {
          toast(t('duplicate_code'));
        } else {
          toast(t('error_creating_asset'), {
            type: 'error',
          });
        }
      } else {
        const trackerUpdateResponse = await assetService.updateTrackers(response.payload.id, data.trackers ?? []);
        if (trackerUpdateResponse.isSuccess) {
          toast.success(t('asset_created'));
        } else {
          toast.warning(t('linking_trackers_warning'), { autoClose: 10000 });
        }
        navigate('/app/assets/overview');
      }
    } else if (isNumber(assetIdNumber)) {
      const response = await assetService.update(assetIdNumber, {
        code: data.code,
        assetTypeId: data.assetTypeId,
      });

      const trackerUpdateResponse = await assetService.updateTrackers(assetIdNumber, data.trackers ?? []);

      if (!response.isSuccess || !trackerUpdateResponse.isSuccess) {
        if (response.responseCode === ApiResponseCode.DUPLICATE_ENTITY_CODE) {
          toast(t('duplicate_code'));
        } else {
          toast(t('error_updating_asset'), {
            type: 'error',
          });
        }
      } else {
        toast.success(t('asset_updated'));
        navigate('/app/assets/overview/');
      }
    }
    return true;
  };

  if (isCreate || !assetId) {
    const newAsset: AssetFormModel = {
      code: '',
      assetTypeId: 0,
      trackers: [],
    };
    return <AssetFormLogic initialValues={newAsset} onSubmit={handleSubmit} isCreate assetId={assetIdNumber} />;
  } else {
    // return early if initial form data isn't loaded
    if (isLoading) return <LoadingBlock />;
    if (isError || !data) return <div>{t('no_data')}</div>;

    const existingAsset: AssetFormModel = {
      code: data.code,
      assetTypeId: data.assetTypeId,
      trackers: data.trackers.map((t) => t.id),
    };

    return <AssetFormLogic initialValues={existingAsset} onSubmit={handleSubmit} isCreate={isCreate} assetId={assetIdNumber} />;
  }
};

export default AssetFormApi;
