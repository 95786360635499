import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { Place } from '@mui/icons-material';
import { FunctionComponent, useState } from 'react';
import { RangeInKmSlider } from './RangeInKmSlider';
import { useTranslation } from '@/lib';
import { GeocodeResultDTO } from '@/modules/map/api/map.contracts';

export interface GeocodedAddressSelectDialogProps {
  open: boolean;
  options: GeocodeResultDTO[];
  onClose: (selectedFeature?: GeocodeResultDTO, radiusInMeters?: number) => void;
}

export const GeocodedAddressSelectDialog: FunctionComponent<GeocodedAddressSelectDialogProps> = ({ onClose, open, options }) => {
  const [geofenceRadiusInMeters, setGeofenceRadiusInMeters] = useState(500);
  const { t } = useTranslation();

  const handleListItemClick = (feature: GeocodeResultDTO) => {
    onClose(feature, geofenceRadiusInMeters);
  };

  return (
    <Dialog onClose={() => onClose()} open={open}>
      <DialogTitle>{t('geofence_select_title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('geofence_select_description')}</DialogContentText>
        <div className="my-4 px-6">
          <Typography>
            {t('geofence_radius')}: <span className="font-bold">{geofenceRadiusInMeters} m</span>
          </Typography>
          <RangeInKmSlider onChange={(range) => setGeofenceRadiusInMeters(range)} value={geofenceRadiusInMeters} />
        </div>
        <List className="w-full">
          {options.map((feature) => (
            <ListItem disableGutters key={feature.placeId}>
              <ListItemButton onClick={() => handleListItemClick(feature)} key={feature.placeId}>
                <ListItemAvatar>
                  <Avatar className="bg-primary-200 bg-opacity-10 text-primary-500">
                    <Place />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={feature.formattedAddress} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={() => onClose()} size="large">
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
