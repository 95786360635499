import { FunctionComponent } from 'react';
import { SettingEditor } from '../components/SettingEditor';
import { SettingsGroup } from '../components/SettingsGroup';
import CopyableTextbox from '@/components/CopyableTextBox';
import { useAuthUserContext } from '@/modules/auth';
import { Environment } from '@/config/Environment';
import useString from '@/hooks/useString';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { ApiKeyDTO } from '../api/settings/setting.contracts';
import { settingService } from '../api/settings/setting.service';
import { useApiCall } from '@/hooks/useApiCall';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import SettingsGroupList from '../components/SettingsGroupList';
import { useTranslation } from '@/lib';

interface ConnectorSettingsPageProps {}

export const ConnectorSettingsPage: FunctionComponent<ConnectorSettingsPageProps> = () => {
  const { tenantId } = useAuthUserContext();
  const { stringToHex } = useString();
  const hexEncodedTenantId = stringToHex(tenantId || '');
  const { data, isLoading, isError } = useApiCall<ApiKeyDTO>(() => settingService.getApiKey());
  const { t } = useTranslation();

  const unaConnectUnaTagUrl = `https://webhooks.posito.io/${hexEncodedTenantId}/${Environment.POSITO_ENVIRONMENT}/unatag?apiKey=${data?.positoApiKey}`;
  const nedapConnectionUrl = `https://webhooks.posito.io/${hexEncodedTenantId}/${Environment.POSITO_ENVIRONMENT}/nedap?apiKey=${data?.positoApiKey}`;
  const epcConnectionUrl = `https://webhooks.posito.io/${hexEncodedTenantId}/${Environment.POSITO_ENVIRONMENT}/epc?apiKey=${data?.positoApiKey}`;

  return (
    <TabbedLayout
      topBar={
        <TabbedLayoutTopBar>
          <TabbedLayoutTopBarHeader icon={null}>{t('setting.connector.title')}</TabbedLayoutTopBarHeader>
        </TabbedLayoutTopBar>
      }
    >
      <TabbedPageLayoutBody>
        <SettingsGroupList>
          {isLoading ? (
            <LoadingBlock></LoadingBlock>
          ) : isError ? (
            <ErrorLoadingDataAlert />
          ) : (
            <>
              <SettingsGroup title={t('setting.connector.unaconnect_title')} subtitle={t('setting.connector.unaconnect_description')}>
                <SettingEditor
                  title={t('setting.connector.unaconnect_url_title')}
                  description={t('setting.connector.unaconnect_url_description')}
                >
                  <CopyableTextbox text={unaConnectUnaTagUrl} />
                </SettingEditor>
              </SettingsGroup>
              <SettingsGroup title={t('setting.connector.nedap_title')} subtitle={t('setting.connector.nedap_description')}>
                <SettingEditor title={t('setting.connector.nedap_url_title')} description={t('setting.connector.nedap_url_description')}>
                  <CopyableTextbox text={nedapConnectionUrl} />
                </SettingEditor>
              </SettingsGroup>
              <SettingsGroup title={t('setting.connector.epc_title')} subtitle={t('setting.connector.epc_description')}>
                <SettingEditor title={t('setting.connector.epc_url_title')} description={t('setting.connector.epc_url_description')}>
                  <CopyableTextbox text={epcConnectionUrl} />
                </SettingEditor>
              </SettingsGroup>
            </>
          )}
        </SettingsGroupList>
      </TabbedPageLayoutBody>
    </TabbedLayout>
  );
};
