import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { LayoutActionsProvider } from '@/providers/layout-actions-provider/LayoutActionsProvider';
import { useState, useEffect, FunctionComponent } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { DeviceModel } from '../types/DeviceModel';
import { BackButton } from '@/components/BackButton';
import NavigationTabs from '@/modules/application/components/NavigationTabs';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { TabbedLayoutTopBarVerticalSeperator } from '@/modules/application/components/TabbedLayoutTopBarVerticalSeperator';
import { Skeleton } from '@mui/material';
import { TabbedPageLayout } from '@/modules/application/layouts/TabbedPageLayout';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { TabMenuItem } from '@/modules/application/types/MenuItem';
import { deviceService } from '../api/device/device.service';
import { useTranslation } from '@/lib';

export const DeviceDetailsLayout: FunctionComponent = () => {
  const { deviceId } = useParams();
  const deviceIdNumber = deviceId ? Number.parseInt(deviceId) : 0;
  const isCreate = deviceId === 'create';
  const { t } = useTranslation();

  const [device, setDevice] = useState<DeviceModel | undefined>(undefined);

  useEffect(() => {
    if (deviceId) {
      deviceService.getById(Number.parseInt(deviceId)).then((device) => {
        if (device.isSuccess) {
          setDevice(device.payload);
        }
      });
    }
  }, []);

  const tabItems: TabMenuItem[] = [];

  if (isCreate) {
    tabItems.push({
      activeRouteIds: ['/app/configuration/devices/create'],
      label: t('details'),
      url: '/app/configuration/devices/create',
    });
  } else {
    tabItems.push({
      activeRouteIds: [`/app/configuration/devices/${deviceIdNumber}/details`],
      label: t('details'),
      url: `/app/configuration/devices/${deviceIdNumber}/details`,
    });
  }

  return (
    <LayoutActionsProvider>
      <TabbedLayout
        topBar={
          <TabbedLayoutTopBar>
            <BackButton pathname="/app/configuration/devices/all" />
            <TabbedLayoutTopBarHeader icon={null}>
              {isCreate ? t('new_device') : device?.dto.name ? t('device') + ` - ${device.dto.name}` : <Skeleton width={200} />}
            </TabbedLayoutTopBarHeader>

            <TabbedLayoutTopBarVerticalSeperator />
            <NavigationTabs tabItems={tabItems} />
          </TabbedLayoutTopBar>
        }
      >
        <TabbedPageLayout>
          <TabbedPageLayoutBody>
            <div className="flex h-full flex-grow flex-col ">
              <Outlet />
            </div>
          </TabbedPageLayoutBody>
        </TabbedPageLayout>
      </TabbedLayout>
    </LayoutActionsProvider>
  );
};
