import { TFunction } from 'i18next';
import { IDashboardWidgetBaseConfiguration } from '../../../types/IDashboardWidgetBaseConfiguration';

export class CountWidgetConfiguration implements IDashboardWidgetBaseConfiguration {
  title = 'dashboard.countWidgetConfiguration.title';
  locationId?: number = 1;
  labelValueIds?: number[] = [];
  assetTypeIds?: number[] = [];

  constructor(t: TFunction) {
    this.title = t(this.title);
  }
}
