import React, { FunctionComponent, KeyboardEvent, useContext } from 'react';
import { TextField } from '@mui/material';
import { FilterBarContext, FilterFieldName } from '../FilterBarContext';
import { Key } from '@/types/key.enum';

interface TextFilterProps {
  label: string;
  /** The name of the filter field. Defaults to FilterFieldName `searchText` */
  filterFieldName?: FilterFieldName;
}

const TextFilter: FunctionComponent<TextFilterProps> = ({ label, filterFieldName = FilterFieldName.searchText }) => {
  const { setFilterValue, filterValues, triggerSearch } = useContext(FilterBarContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(filterFieldName, e.target.value);
  };

  function onKeyDown(e: KeyboardEvent) {
    if (e.key === Key.Enter) {
      triggerSearch();
    }
  }

  return (
    <TextField
      autoComplete=""
      variant="outlined"
      type="search"
      label={label}
      value={filterValues[filterFieldName] || ''}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      fullWidth
    />
  );
};

export default TextFilter;
