import { Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { FunctionComponent } from 'react';

export interface ITooltipCellRendererParams {
  tooltip?: string;
  showDashedUnderline?: boolean;
}

interface ITooltipCellInternalRendererParams extends ITooltipCellRendererParams, ICellRendererParams {}

export const TooltipCellRenderer: FunctionComponent<ITooltipCellInternalRendererParams> = (props) => {
  const { value, valueFormatted, tooltip, showDashedUnderline } = props;

  return (
    <Tooltip title={tooltip}>
      <span className={showDashedUnderline ? 'underline underline-offset-4 decoration-dotted decoration-slate-400' : ''}>
        {valueFormatted ?? value}
      </span>
    </Tooltip>
  );
};
