import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { BASE_GRID_OPTIONS, PoGrid } from '@/components/grid/PoGrid';
import {
  ColumnState,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  MenuItemDef,
  SortChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { BalancesAtLocationModel, BalanceAtLocationAssetTypeModel } from '../models/BalanceAtLocationModel';
import { useExportToExcel } from '@/hooks/useExportToExcel';
import dayjs from 'dayjs';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { UserSettingKey } from '@/modules/users/api/user-settings/user-setting.contracts';
import { ColumnID } from '@/components/grid/column-ids';
import LinkCallbackCellRenderer, { ILinkCallbackCellRendererParams } from '@/components/grid/cells/LinkCallbackCellRenderer';
import LocationBalanceDrawer from './LocationBalanceDrawer';
import { DisplayMode } from '@/types/display-mode.enum';
import { useTranslation } from '@/lib';
import { PageSortOption, PageSortOrder } from '@/lib/api/pagination.page.dto';
import { BalanceSortOption } from '../api/balances/balance.contracts';
interface LocationsBalanceGridProps {
  data?: BalancesAtLocationModel[];
  isError: boolean;
  noBorder?: boolean;
  isLoading?: boolean;
  initialSortOption?: PageSortOption;
  initialSortOrder?: PageSortOrder;
  onSortChanged: (sortOrder: SortChangedEvent<BalancesAtLocationModel>) => void;
}

export const LocationsBalanceGrid: FunctionComponent<LocationsBalanceGridProps> = ({
  data,
  isError,
  noBorder,
  isLoading,
  initialSortOption,
  initialSortOrder,
  onSortChanged,
}) => {
  const {
    setColumnStateGridApi,
    handleColumnStateChange,
    columnState,
    applyStateToDefinitions,
    setIsAutoSaveEnabled,
    setDefaultColumnState,
  } = useGridColumnState(UserSettingKey.BALANCE_OVERVIEW_COLUMN_STATE);
  const { t } = useTranslation();

  const [isLocationInfoDrawerOpen, setIsLocationInfoDrawerOpen] = useState(false);
  const [locationDrawerLocationId, setLocationDrawerLocationId] = useState<number>();
  const [gridApi, setGridApi] = useState<GridApi<BalancesAtLocationModel>>();

  const { exportToExcel } = useExportToExcel();
  const columns: ColDefOrGroup<BalancesAtLocationModel>[] = useMemo(() => {
    const baseColumns: ColDefOrGroup<BalancesAtLocationModel>[] = [
      {
        colId: ColumnID.LOCATION_NAME,
        field: 'dto.locationName',
        headerName: t('location'),
        cellRenderer: LinkCallbackCellRenderer,
        cellRendererParams: (
          params: ICellRendererParams<BalancesAtLocationModel>,
        ): ILinkCallbackCellRendererParams<BalancesAtLocationModel> => ({
          buttonText: params.data?.dto.locationName || '',
          callback: (rowData) => {
            setLocationDrawerLocationId(rowData.dto.locationId);
            setIsLocationInfoDrawerOpen(true);
          },
          // pathname: params.data?.dto.locationId ? `/app/locations/${params.data?.dto.locationId}` : undefined,
        }),
        pinned: 'left',
        headerClass: 'font-bold',
        lockVisible: true,
        lockPinned: true,
        lockPosition: true,
      },
      {
        colId: ColumnID.TOTAL_BALANCE,
        field: 'totalBalance',
        headerName: t('total_balance'),
        headerClass: 'font-bold ag-right-aligned-header',
        type: 'numericColumn',
        lockVisible: true,
      },
      {
        colId: ColumnID.TOTAL_INCOMING,
        field: 'totalIncoming',
        headerName: `${t('incoming')} (30d)`,
        headerClass: 'font-bold ag-right-aligned-header',
        // If it's 0, show a dash instead
        valueGetter: (params: ValueGetterParams<BalancesAtLocationModel>) => params.data?.totalIncoming || '-',
        // Fix sorting so it's not sorting as string and taking '-' as the lowest value
        comparator: (a: number | string, b: number | string) => {
          if (a === '-' && b === '-') return 0;
          if (a === '-') return -1;
          if (b === '-') return 1;
          return (a as number) - (b as number);
        },
        type: 'numericColumn',
        lockVisible: false,
      },
      {
        colId: ColumnID.TOTAL_OUTGOING,
        field: 'totalOutgoing',
        headerName: `${t('outgoing')} (30d)`,
        headerClass: 'font-bold ag-right-aligned-header',
        // If it's 0, show a dash instead
        valueGetter: (params: ValueGetterParams<BalancesAtLocationModel>) => params.data?.totalOutgoing || '-',
        // Fix sorting so it's not sorting as string and taking '-' as lowest value
        comparator: (a: number | string, b: number | string) => {
          if (a === '-' && b === '-') return 0;
          if (a === '-') return -1;
          if (b === '-') return 1;
          return (a as number) - (b as number);
        },
        type: 'numericColumn',
        lockVisible: false,
      },
      // Calculate the percentage of returned items
      {
        colId: ColumnID.RETURN_RATE,
        field: 'returnRate',
        headerName: `${t('return_rate')} (30d)`,
        headerTooltip: t('return_rate_tooltip'),
        valueFormatter: (params: ValueFormatterParams<BalancesAtLocationModel>) => {
          if (params.value === 0 && !params.data?.totalIncoming) return '-';
          return `${params.value.toFixed(0)}%`;
        },

        headerClass: 'font-bold ag-right-aligned-header',
        type: 'numericColumn',
        lockVisible: false,
      },
      {
        colId: ColumnID.STAYTIME,
        field: 'totalNumberOfStaleAssets',
        headerName: `30d+ ${t('staytime')}`,
        headerClass: 'font-bold ag-right-aligned-header',
        type: 'numericColumn',
        lockVisible: false,
      },
      {
        colId: ColumnID.STAYTIME_60_DAYS,
        field: 'totalNumberOfStaleAssets60Days',
        headerName: `60d+ ${t('staytime')}`,
        headerClass: 'font-bold ag-right-aligned-header',
        type: 'numericColumn',
        lockVisible: false,
      },
    ];

    if (!data) {
      return baseColumns;
    }

    const distinctAssetTypes: BalanceAtLocationAssetTypeModel[] = BalancesAtLocationModel.distinctAssetTypes(data);

    const assetTypeColumns = distinctAssetTypes.map<ColDefOrGroup<BalancesAtLocationModel>>((assetType, index) => ({
      colId: `ASSET_TYPE_BALANCE_ID_${assetType.assetTypeId}`,
      headerName: assetType.assetTypeName,
      valueGetter: (params: ValueGetterParams<BalancesAtLocationModel>) => {
        if (!params.data?.balances) return 0;
        return params.data.balances.find((balance) => balance.dto.assetTypeId === assetType.assetTypeId)?.dto.balance || 0;
      },
      flex: index === distinctAssetTypes.length - 1 ? 1 : undefined,
      minWidth: index === distinctAssetTypes.length - 1 ? 100 : undefined,
      type: 'numericColumn',
      lockVisible: false,
    }));

    setDefaultColumnState(generateLocationBalanceTableColumnState(distinctAssetTypes));
    const columnsWithAssetTypes = [...baseColumns, ...assetTypeColumns];

    applyStateToDefinitions(columnsWithAssetTypes);

    return columnsWithAssetTypes;
  }, [data, columnState]);

  // Handle sorting changes
  const handleSortChange = (event: SortChangedEvent<BalancesAtLocationModel>) => {
    handleColumnStateChange(event); // Handle the state change for columns

    // Call the onSortChanged callback to update the external state
    onSortChanged(event);

    // Apply the sort state to the grid
    const column = event.columns && event.columns[0];
    if (column) {
      gridApi?.applyColumnState({
        state: [
          {
            colId: column.getColId(),
            sort: column.getSort(),
          },
        ],
        defaultState: { sort: null },
      });
    }
  };

  const customGridOptions: GridOptions<BalancesAtLocationModel> = {
    ...BASE_GRID_OPTIONS,
    alwaysShowHorizontalScroll: true,
    alwaysShowVerticalScroll: true,
    suppressDragLeaveHidesColumns: true,
    processUnpinnedColumns: (columns) => {
      // simply don't change the order of the columns
      return [];
    },
    getContextMenuItems: (params) => {
      const result: (string | MenuItemDef)[] = [
        'copy',
        'separator',
        {
          name: t('export_excel'),
          action: () => {
            console.log('Export to Excel clicked');

            onExportToExcelClicked(params.api);
          },
        },
      ];
      return result;
    },
    onGridReady: onGridReady,
    onFirstDataRendered: onFirstDataRendered,

    // Grid column state changes
    onColumnMoved: handleColumnStateChange,
    onColumnVisible: handleColumnStateChange,
    onColumnResized: handleColumnStateChange,
    onColumnRowGroupChanged: handleColumnStateChange,
    onSortChanged: handleSortChange,
    onColumnPinned: handleColumnStateChange,
  };

  function onExportToExcelClicked(gridApi: GridApi<BalancesAtLocationModel> | undefined) {
    if (gridApi) {
      const data: BalancesAtLocationModel[] = [];
      gridApi.forEachNode((node) => {
        if (node.data) {
          data.push(node.data);
        }
      });
      if (data.length > 0) {
        exportToExcel(
          data.map((balance) => ({
            locationName: balance.dto.locationName,
            locationCode: balance.dto.locationCode,
            parentLocationCodeLevel1: balance.dto.parentLocationCode1,
            parentLocationCodeLevel2: balance.dto.parentLocationCode2,
            totalBalance: balance.totalBalance,
            totalIncoming: balance.totalIncoming,
            totalOutgoing: balance.totalOutgoing,
            returnRate: balance.returnRate,
            totalNumberOfStaleAssets: balance.totalNumberOfStaleAssets,
            totalNumberOfStaleAssets60Days: balance.totalNumberOfStaleAssets60Days,
            ...balance.balances.reduce((acc: Record<string, unknown>, balance) => {
              acc[`${balance.dto.assetTypeName}`] = balance.dto.balance;
              return acc;
            }, {}),
          })),
          `balances_${dayjs().format('YYYY_MM_DD_HHmmss')}.xlsx`,
        );
      }
    }
  }

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  function onGridReady(event: GridReadyEvent) {
    setGridApi(event.api);
    setColumnStateGridApi(event.api);
  }

  function onFirstDataRendered(event: FirstDataRenderedEvent) {
    // Apply initial sorting if provided
    if (initialSortOption && initialSortOrder) {
      let colId: ColumnID | string | undefined;

      switch (initialSortOption) {
        case BalanceSortOption.LOCATION_NAME:
          colId = ColumnID.LOCATION_NAME;
          break;
        case BalanceSortOption.TOTAL_BALANCE:
          colId = ColumnID.TOTAL_BALANCE;
          break;
        case BalanceSortOption.INCOMING:
          colId = ColumnID.TOTAL_INCOMING;
          break;
        case BalanceSortOption.OUTGOING:
          colId = ColumnID.TOTAL_OUTGOING;
          break;
        case BalanceSortOption.RETURN_RATE:
          colId = ColumnID.RETURN_RATE;
          break;
        case BalanceSortOption.STAYTIME_30:
          colId = ColumnID.STAYTIME;
          break;
        case BalanceSortOption.STAYTIME_60:
          colId = ColumnID.STAYTIME_60_DAYS;
          break;
        default:
          colId = initialSortOption; // Should only be used for the dynamic column ids
          break;
      }

      if (colId) {
        event.api.applyColumnState({
          state: [
            {
              colId,
              sort: initialSortOrder === PageSortOrder.ASC ? 'asc' : 'desc',
            },
          ],
          defaultState: { sort: null },
        });
      }
    }
  }

  return (
    <div className="flex h-full flex-1 flex-grow flex-col">
      {isError ? (
        <ErrorLoadingDataAlert />
      ) : (
        <PoGrid
          noBorder={noBorder}
          isLoading={isLoading}
          colDefs={columns}
          rowData={data}
          gridOptions={customGridOptions}
          disableResizeColumnsToFit
          disableDefaultGridOptions
        />
      )}
      {locationDrawerLocationId !== undefined && (
        <LocationBalanceDrawer
          displayMode={DisplayMode.INDIVIDUAL}
          locationId={locationDrawerLocationId}
          isOpen={isLocationInfoDrawerOpen}
          onClose={() => setIsLocationInfoDrawerOpen(false)}
        />
      )}
    </div>
  );
};

function generateLocationBalanceTableColumnState(distinctAssetTypes: BalanceAtLocationAssetTypeModel[]): ColumnState[] {
  const assetTypeColumns = distinctAssetTypes.map<ColumnState>((assetType) => ({
    colId: `ASSET_TYPE_BALANCE_ID_${assetType.assetTypeId}`,
    hide: false,
  }));

  return [
    { colId: ColumnID.LOCATION_NAME, hide: false, pinned: true },
    { colId: ColumnID.TOTAL_BALANCE, hide: false },
    { colId: ColumnID.TOTAL_INCOMING, hide: false },
    { colId: ColumnID.TOTAL_OUTGOING, hide: false },
    { colId: ColumnID.RETURN_RATE, hide: false },
    { colId: ColumnID.STAYTIME, hide: false },
    { colId: ColumnID.STAYTIME_60_DAYS, hide: false },
    ...assetTypeColumns,
  ];
}
