import { RouteObject } from 'react-router-dom';
import DashboardsLayout from '../layouts/DashboardsLayout';
import { DashboardPage } from '../pages/DashboardPage';
import RequirePermissionRoute from '@/components/permissions/RequirePermissionRoute';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';

export const DASHBOARD_ROUTES: RouteObject[] = [
  {
    path: 'dashboards',
    element: <DashboardsLayout />,
    children: [
      {
        path: ':dashboardId?',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.DASHBOARDS_VIEW}>
            <DashboardPage />
          </RequirePermissionRoute>
        ),
      },
    ],
  },
];
