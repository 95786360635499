import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import BarcodeScanner from './BarcodeScanner';
import { Close } from '@mui/icons-material';
import { useTranslation } from '@/lib';

interface BarcodeScanModalProps {
  open: boolean;
  onCancel: () => void;
  onScan: (barcode: string) => void;
}

const BarcodeScanModal: React.FC<BarcodeScanModalProps> = ({ open, onScan, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Dialog fullScreen open={open} onClose={onCancel}>
      <div className="flex items-center justify-between">
        <DialogTitle>{t('scan_a_barcode')}</DialogTitle>
        <IconButton className="mr-6" edge="start" color="inherit" onClick={onCancel} aria-label="close">
          <Close />
        </IconButton>
      </div>
      <DialogContent>
        <div className="flex justify-center mb-6">{t('position_the_barcode_in_the_center_of_the_screen')}</div>

        <BarcodeScanner
          onBarcodeScanned={(barcode) => {
            onScan(barcode);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button size="large" variant="outlined" onClick={onCancel}>
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BarcodeScanModal;
