import { TrackerType } from '@/modules/trackers';

export interface ReadAssetDtoLocation {
  name: string;
  code: string;
  id: number;
}

export interface ReadAssetTrackerDTO {
  id: number;
  type: TrackerType;
  epc?: string;
  barcode?: string;
  deviceId?: string;
}

export interface AssetFilterOptionsDTO {
  locationId?: number;
  searchText?: string;
  assetTypeId?: number;
  stayTimeInDays?: number;
}

export interface AssetReadDTO {
  id: number;
  code: string;
  assetTypeId: number;
  assetType: {
    name: string;
    code: string;
  };
  trackers: ReadAssetTrackerDTO[];
  location?: ReadAssetDtoLocation;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  lastEventDate?: Date;
  firstEventDate?: Date;
  locationEnteredDate?: Date;
}
export interface AssetCreateDTO {
  code: string;
  assetTypeId: number;
}
export interface AssetUpdateDTO {
  code: string;
  assetTypeId: number;
}

export interface AssetTrackerUpdateDTO {
  trackerIds: number[];
}

export interface EventMapBreadcrumbDTO {
  eventId: number;
  gpsLat: number;
  gpsLong: number;
}

export interface MoveAssetsDTO {
  assetIds: number[];
  locationId: number;
}

export interface AssetBulkUpdateDTO {
  assetIds: number[];
  assetTypeId: number;
}

export enum AssetSortOption {
  ASSET_CODE = 'asset-code',
  ASSET_TYPE_NAME = 'asset-type-name',
  LOCATION_NAME = 'location-name',
  LAST_EVENT_DATE = 'last-event-date',
  FIRST_EVENT_DATE = 'first-event-date',
  LOCATION_ENTER_DATE = 'location-enter-date',
}
