import { DTO } from '@/types/dto-wrapper';
import { LocationReadDTO } from '../api/locations/location.contracts';

export class LocationModel extends DTO<LocationReadDTO> {
  parentLocationId?: number;
  totalChildrenCount?: number;
  children?: LocationModel[];

  constructor(data: LocationReadDTO) {
    super(data);

    if (data.parentLocationIdLevel2) {
      this.parentLocationId = data.parentLocationIdLevel2;
    } else if (data.parentLocationIdLevel1) {
      this.parentLocationId = data.parentLocationIdLevel1;
    } else {
      this.parentLocationId = undefined;
    }

    this.dto.country = data.country ?? undefined;
    this.dto.city = data.city ?? undefined;
    this.dto.postalCode = data.postalCode ?? undefined;
    this.dto.streetName = data.streetName ?? undefined;
    this.dto.streetNumber = data.streetNumber ?? undefined;

    // Recursively count children
    this.children = this.dto.children ? this.dto.children.map(LocationModel.fromDTO) : [];
    this.totalChildrenCount = LocationModel.countAllChildren(this);
  }

  static countAllChildren(location: LocationModel): number {
    // Base case: If there are no children, return 0
    if (!location.children || location.children.length === 0) {
      return 0;
    }

    // Count the children of this location
    let totalCount = location.children.length;

    // Recursively count the children of each child location
    for (const child of location.children) {
      totalCount += LocationModel.countAllChildren(child);
    }

    return totalCount;
  }

  static fromDTO(dto: LocationReadDTO): LocationModel {
    return new LocationModel(dto);
  }
}
