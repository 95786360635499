import { createContext, useContext, useState, ReactNode, useCallback, FunctionComponent } from 'react';

interface CacheContextType {
  setItem: (key: string, value: unknown) => void;
  getItem: <T>(key: string) => T | undefined;
  clearItem: (key: string) => void;
  hasItem: (key: string) => boolean;
}

export const CacheContext = createContext<CacheContextType | null>(null);

export const CacheProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const [cache, setCache] = useState<Map<string, unknown>>(new Map());

  const setItem = useCallback((key: string, value: unknown) => {
    setCache((prevCache) => new Map(prevCache).set(key, value));
  }, []);

  const getItem = useCallback(
    <T,>(key: string): T | undefined => {
      return cache.get(key) as T | undefined;
    },
    [cache],
  );

  const clearItem = useCallback((key: string) => {
    setCache((prevCache) => {
      const newCache = new Map(prevCache);
      newCache.delete(key);
      return newCache;
    });
  }, []);

  const hasItem = useCallback(
    (key: string): boolean => {
      return cache.has(key);
    },
    [cache],
  );

  return <CacheContext.Provider value={{ setItem, getItem, clearItem, hasItem }}>{children}</CacheContext.Provider>;
};

export const useCache = (): CacheContextType => {
  const context = useContext(CacheContext);
  if (!context) {
    throw new Error('useCache must be used within a CacheProvider');
  }
  return context;
};

export const useCacheItem = <T,>(
  key: string,
): { item: T | undefined; setItemHandler: (value: T) => void; clearItemHandler: () => void; hasItemHandler: boolean } => {
  const { getItem, setItem, clearItem, hasItem } = useCache();

  const item = getItem<T>(key);
  const setItemHandler = (value: T) => setItem(key, value);
  const clearItemHandler = () => clearItem(key);
  const hasItemHandler = hasItem(key);

  return { item, setItemHandler, clearItemHandler, hasItemHandler };
};
