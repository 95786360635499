import { camelCase, startCase } from 'lodash-es';
import { DTO } from '@/types/dto-wrapper';
import { RoleDTO } from '../api/roles.contracts';
import { Permission } from '../api/permissions.contracts';

export class RoleModel extends DTO<RoleDTO> {
  constructor(data: RoleDTO) {
    super(data);
  }

  get nameInTitleCase() {
    return startCase(camelCase(this.dto.name));
  }

  get description() {
    return this.dto.description;
  }

  hasPermission(permission: Permission) {
    return this.dto.permissions.some((p) => p.name === permission);
  }

  hasAllPermissions(permissions: Permission[]): boolean | undefined {
    return permissions.every((permission) => this.hasPermission(permission));
  }
}
