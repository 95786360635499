import { FunctionComponent } from 'react';
import { FilterFieldName } from '../FilterBarContext';
import { useTranslation } from '@/lib';
import ListSelectFilter from './ListSelectFilter';
import { LinkedTrackerSearchParam } from '@/modules/trackers/api/trackers/tracker.contracts';

const LinkedTrackerFilter: FunctionComponent = () => {
  const filterFieldName = FilterFieldName.linkedTracker;
  const { t } = useTranslation();

  return (
    <ListSelectFilter
      label={t('state')}
      filterFieldName={filterFieldName}
      options={[LinkedTrackerSearchParam.LINKED, LinkedTrackerSearchParam.UNLINKED, null]}
      renderOption={(item) => {
        switch (item) {
          case LinkedTrackerSearchParam.LINKED:
            return t('linked_trackers');
          case LinkedTrackerSearchParam.UNLINKED:
            return t('unlinked_trackers');
          default:
            return <div className="italic">{t('all')}</div>;
        }
      }}
      valueExtractor={(item) => item}
    />
  );
};

export default LinkedTrackerFilter;
