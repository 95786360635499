import { IDashboardWidget } from '../../../types/IDashboardWidget';
import { FunctionComponent, useEffect, useState } from 'react';
import { SystemTotalWidgetConfiguration } from './SystemTotalWidgetConfiguration';

import SystemTotalWidgetConfigurationForm from './SystemTotalWidgetConfigurationForm';
import DashboardWidgetConfigurationButton from '../../dashboard-widget/DashboardWidgetConfigurationButton';
import DashboardWidgetContent from '../../dashboard-widget/DashboardWidgetContent';
import { DashboardWidgetProvider } from '../../dashboard-widget/DashboardWidgetContextProvider';
import DashboardWidgetConfigurationDrawer from '../../dashboard-widget/DasshboardWidgetConfigurationDrawer';
import { locationService } from '@/modules/locations/api/locations/location.service';
import { useTimerEndListener } from '@/providers/timer-provider/useTimerEndListener';
import { WidgetCountAndTitle } from '../base/WidgetCountAndTitle';
import { SystemTotalType } from './system-total-type.enum';
import { assetService } from '@/modules/assets/api/assets/asset.service';
import { eventService } from '@/modules/events/api/events/event.service';

type SystemTotalWidgetProps = IDashboardWidget<SystemTotalWidgetConfiguration>;

const SystemTotalWidget: FunctionComponent<SystemTotalWidgetProps> = ({ configuration, id, layout }) => {
  const [systemTotal, setSystemTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  // Listens to the timer context
  useTimerEndListener(handleTimerEnd);

  async function fetchSystemTotal() {
    switch (configuration.systemTotalType) {
      case SystemTotalType.Assets: {
        const response = await assetService.getCount();
        if (response.isSuccess) {
          setSystemTotal(response.payload);
          setIsLoading(false);
        }
        break;
      }
      case SystemTotalType.Locations: {
        const response = await locationService.getCount();
        if (response.isSuccess) {
          setSystemTotal(response.payload);
          setIsLoading(false);
        }
        break;
      }
      case SystemTotalType.Events: {
        const response = await eventService.getCount();
        if (response.isSuccess) {
          setSystemTotal(response.payload);
          setIsLoading(false);
        }
        break;
      }
      default:
        break;
    }
  }
  useEffect(() => {
    fetchSystemTotal();
  }, [configuration.systemTotalType]);

  function handleTimerEnd() {
    fetchSystemTotal();
  }

  return (
    <DashboardWidgetProvider widgetId={id ?? 'unknown'} isLoading={isLoading} setIsLoading={setIsLoading}>
      <DashboardWidgetContent>
        <DashboardWidgetConfigurationButton></DashboardWidgetConfigurationButton>

        <WidgetCountAndTitle count={systemTotal} title={configuration.title} />
      </DashboardWidgetContent>

      <DashboardWidgetConfigurationDrawer widgetName={configuration.title}>
        <SystemTotalWidgetConfigurationForm configuration={configuration} id={id} />
      </DashboardWidgetConfigurationDrawer>
    </DashboardWidgetProvider>
  );
};

export default SystemTotalWidget;
