import { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import UserFormApi from '../components/forms/user/UserFormApi';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { BackButton } from '@/components/BackButton';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { TabbedLayoutTopBarVerticalSeperator } from '@/modules/application/components/TabbedLayoutTopBarVerticalSeperator';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { UserModel } from '@/modules/users/types/UserModel';
import { Tab, Skeleton } from '@mui/material';
import { userService } from '../api/users/users.service';
import { useTranslation } from '@/lib';

interface UserDetailPageProps {
  isCreate?: boolean;
}

export const UserDetailPage: FunctionComponent<UserDetailPageProps> = ({ isCreate }) => {
  const { userId } = useParams();
  const { t } = useTranslation();

  const [value, setValue] = useState('1');
  const [user, setUser] = useState<UserModel | undefined>(undefined);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (userId) {
      userService.getUserById(Number.parseInt(userId)).then((user) => {
        if (user.isSuccess) {
          setUser(user.payload);
        }
      });
    }
  }, [userId]);

  return (
    <TabContext value={value}>
      <TabbedLayout
        topBar={
          <TabbedLayoutTopBar>
            <BackButton pathname={'/app/configuration/users'} />

            <TabbedLayoutTopBarHeader icon={null}>
              {isCreate ? t('new_user') : user?.name ? t('user_name', { name: user.name }) : <Skeleton width={200} />}
            </TabbedLayoutTopBarHeader>
            <TabbedLayoutTopBarVerticalSeperator />
            <TabList onChange={handleChange} aria-label={t('user_tabs')}>
              <Tab label={t('details')} value="1" />
            </TabList>
          </TabbedLayoutTopBar>
        }
      >
        {/* <PageLayoutTopBar header={isCreate ? 'New Location' : `Location`} navigateBackPathname={'../'} /> */}
        <TabbedPageLayoutBody>
          <div className="flex h-full flex-grow flex-col ">
            <TabPanel className="p-0" value="1" style={{ flexGrow: 1, height: '100%' }}>
              <UserFormApi isCreate={isCreate} userId={userId ? Number.parseInt(userId) : undefined} />
            </TabPanel>
          </div>
        </TabbedPageLayoutBody>
      </TabbedLayout>
    </TabContext>
  );
};
