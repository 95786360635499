import { z } from 'zod';

export const DeviceFormSchema = z.object({
  name: z.string().min(1),
  code: z.string(),
  brand: z.string(),
  type: z.string(),
  serialNumber: z.string(),
  deviceType: z.number(),
  identifier: z.string().optional(),
  locationId: z.number().optional(),
});

export const DeviceCreateFormSchema = DeviceFormSchema.extend({});

export type DeviceFormModel = z.infer<typeof DeviceFormSchema>;
