import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { FunctionComponent } from 'react';
import { TopLocationsMetric, TopLocationsWidgetConfiguration } from './TopLocationsWidgetConfiguration';
import { useDashboardContext } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { isNumber } from 'lodash-es';
import AssetTypeSelect from '@/modules/asset-types/components/AssetTypeSelect';
import { useTranslation } from '@/lib';
import LabelValuesSelect from '@/modules/labels/components/LabelValuesSelect';

interface TopLocationsWidgetConfigurationFormProps {
  configuration: TopLocationsWidgetConfiguration;
  id: string;
}

const TopLocationsWidgetConfigurationForm: FunctionComponent<TopLocationsWidgetConfigurationFormProps> = ({ configuration, id }) => {
  const { widgets, updateWidget, updateWidgetConfiguration } = useDashboardContext();
  const { t } = useTranslation();

  return (
    <>
      <TextField
        label="Name"
        value={configuration.title}
        onChange={(e) => {
          updateWidgetConfiguration<TopLocationsWidgetConfiguration>(id, {
            title: e.currentTarget.value,
          });
        }}
      />

      <FormControl>
        <InputLabel>Metric</InputLabel>
        <Select
          label={'Metric'}
          value={configuration.metric}
          onChange={(e) => {
            updateWidgetConfiguration<TopLocationsWidgetConfiguration>(id, {
              metric: e.target.value as TopLocationsMetric,
              title:
                (e.target.value as TopLocationsMetric) === TopLocationsMetric.assetCount
                  ? 'Top Locations by Asset Count'
                  : 'Top Locations by Monetary Value',
            });
          }}
        >
          {Object.values(TopLocationsMetric).map((metric, i) => {
            if (isNumber(metric)) {
              return;
            }
            return (
              <MenuItem key={i} value={metric}>
                {t(`dashboard.top_locations_metric.${metric}`)}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>The metric the top Locations will be determined by</FormHelperText>
      </FormControl>

      <AssetTypeSelect
        selectedAssetTypeId={configuration.assetTypeId}
        onChange={(assetType) => {
          updateWidgetConfiguration<TopLocationsWidgetConfiguration>(id, {
            assetTypeId: assetType?.id,
          });
        }}
      />

      <LabelValuesSelect
        selectedValues={configuration.labelValueIDs ?? []}
        onChange={(values) => {
          updateWidgetConfiguration<TopLocationsWidgetConfiguration>(id, {
            labelValueIDs: values,
          });
        }}
      />
    </>
  );
};

export default TopLocationsWidgetConfigurationForm;
