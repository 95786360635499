import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { UploadOutlined } from '@mui/icons-material';
import { useTranslation } from '@/lib';

interface ImageUploadProps {
  initialImageUrl?: string; // URL for the initial image to display
  onImageUpload: (file: File) => void; // Callback to handle the image once it's selected
  onRemoveImage: () => void; // Callback for removing the image externally
}

const ImageUpload: FunctionComponent<ImageUploadProps> = ({ initialImageUrl, onImageUpload, onRemoveImage }) => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    // Set the image preview URL if an initial image URL is provided
    if (initialImageUrl) {
      setImagePreview(initialImageUrl);
    }
  }, [initialImageUrl]);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      // Use FileReader to preview the image
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);

      // Call the external handler with the selected file
      onImageUpload(file);
    }
  };

  const handleRemoveImageClick = () => {
    setImagePreview(null); // Clear the preview
    onRemoveImage(); // Call the external remove handler
  };

  return (
    <>
      {imagePreview ? (
        <div className="flex gap-x-4 gap-y-2">
          <div className="flex border border-dashed border-gray-400 dark:border-gray-600 bg-gray-200 dark:bg-gray-800 rounded-lg p-2">
            <img src={imagePreview} alt="Uploaded" className="w-40 h-40 rounded-lg object-contain" />
          </div>
          <div className="flex flex-col gap-4 pt-2">
            <Button variant="contained" component="label" color="primary" sx={{ margin: 1 }}>
              {t('image_upload_new')}
              <input type="file" hidden accept="image/*" onChange={handleImageChange} />
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleRemoveImageClick}>
             {t('image_remove')}
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex">
          <div className="w-48 h-32 border-dashed border border-gray-400 flex items-center rounded-xl justify-center">
            <Button variant="text" component="label" color="primary" startIcon={<UploadOutlined />}>
              {t('image_upload')}
              <input type="file" hidden accept="image/*" onChange={handleImageChange} />
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageUpload;
