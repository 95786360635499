import { z } from 'zod';

// Define a schema for the image
const ImageFileSchema = z
  .instanceof(File)
  .optional()
  .nullable()
  .refine((file) => !file || file.size < 4096 * 1024, {
    message: 'File size must be under 4MB',
  })
  .refine((file) => !file || file.type.startsWith('image/'), {
    message: 'Only image files are allowed',
  });

export const AssetTypeFormSchema = z.object({
  name: z.string().min(3),
  code: z.string().min(1).max(20),
  description: z.string().optional(),
  widthInMm: z.coerce.number().optional(),
  heightInMm: z.coerce.number().optional(),
  depthInMm: z.coerce.number().optional(),
  volumeInCubicMm: z.coerce.number().optional(),
  modelYear: z.coerce.number().optional(),
  monetaryValue: z.coerce.number().optional(),
  image: ImageFileSchema,
  imageUrl: z.string().optional().nullable(),
  imageThumbnailUrl: z.string().optional().nullable(),
});

export const AssetTypeCreateFormSchema = AssetTypeFormSchema.extend({});

export type AssetTypeFormModel = z.infer<typeof AssetTypeFormSchema>;
