import { IDashboardWidget } from '../../../types/IDashboardWidget';
import { FunctionComponent, useEffect, useState } from 'react';
import { CountWidgetConfiguration } from './CountWidgetConfiguration';

import CountWidgetConfigurationForm from './CountWidgetConfigurationForm';
import DashboardWidgetConfigurationButton from '../../dashboard-widget/DashboardWidgetConfigurationButton';
import DashboardWidgetContent from '../../dashboard-widget/DashboardWidgetContent';
import { DashboardWidgetProvider } from '../../dashboard-widget/DashboardWidgetContextProvider';
import DashboardWidgetConfigurationDrawer from '../../dashboard-widget/DasshboardWidgetConfigurationDrawer';
import { locationService } from '@/modules/locations/api/locations/location.service';
import { useTimerEndListener } from '@/providers/timer-provider/useTimerEndListener';
import TextLink from '@/components/TextLink';
import { useLocationDrawerContext } from '@/modules/locations/contexts/LocationDrawerContext';
import { useFormatting } from '@/hooks/useFormatting';

type CountWidgetProps = IDashboardWidget<CountWidgetConfiguration>;

const CountWidget: FunctionComponent<CountWidgetProps> = ({ configuration, id, layout }) => {
  const { formatNumber } = useFormatting();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [assetTotalCount, setAssetTotalCount] = useState(0);

  // Location Info Drawer
  const { setLocationId, openDrawer } = useLocationDrawerContext();

  // Listens to the timer context
  useTimerEndListener(handleTimerEnd);

  async function fetchAssetTotalCount() {
    const total = await locationService.getAssetTotalAtLocation({
      locationId: configuration.locationId,
      labelValueIds: configuration.labelValueIds,
      assetTypeIds: configuration.assetTypeIds,
    });
    if (total.isSuccess) {
      setAssetTotalCount(total.payload.totalAssets);
      setIsError(false);
    } else {
      setIsError(true);
    }
    setIsLoading(false);
  }
  useEffect(() => {
    fetchAssetTotalCount();
  }, [configuration.locationId, configuration.labelValueIds, configuration.assetTypeIds]);

  function handleTimerEnd() {
    // Handle timer end in ChildComponent1
    fetchAssetTotalCount();
  }

  function onTotalCountClicked() {
    if (configuration.locationId) {
      setLocationId(configuration.locationId);
      openDrawer();
    }
  }

  return (
    <DashboardWidgetProvider
      widgetId={id ?? 'unknown'}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      isError={isError}
      setIsError={setIsError}
    >
      <DashboardWidgetContent>
        <DashboardWidgetConfigurationButton></DashboardWidgetConfigurationButton>

        <TextLink
          onClick={onTotalCountClicked}
          className="flex h-full flex-col items-center justify-center"
          disabled={configuration.locationId === undefined}
        >
          <div className="text-4xl font-bold ">{formatNumber(assetTotalCount)}</div>
          <div className="text-center text-lg font-medium tracking-wide dark:text-gray-300">{configuration.title}</div>
        </TextLink>
      </DashboardWidgetContent>

      <DashboardWidgetConfigurationDrawer widgetName={configuration.title}>
        <CountWidgetConfigurationForm configuration={configuration} id={id} />
      </DashboardWidgetConfigurationDrawer>
    </DashboardWidgetProvider>
  );
};

export default CountWidget;
