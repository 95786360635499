import { AxiosStatic } from 'axios';
import { getAuth, getIdToken, onAuthStateChanged } from 'firebase/auth';
import { SKIP_AUTH_HEADER } from './base-client';

export const registerFirebaseInterceptor = (axios: AxiosStatic) => {
  axios.interceptors.request.use(async (request) => {
    // Check if the request should skip auth
    if (request.headers && request.headers[SKIP_AUTH_HEADER]) {
      // Remove the custom header after checking to avoid sending it to the server
      delete request.headers[SKIP_AUTH_HEADER];
      return request;
    }

    const token = await getUserToken();

    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
  });
};

const getUserToken = async () => {
  return new Promise((resolve, reject) => {
    const unsub = onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        const token = await getIdToken(user);
        resolve(token);
      } else {
        console.log('User not logged in');
        resolve(null);
      }
      unsub();
    });
  });
};
