import { Navigate, RouteObject } from 'react-router-dom';
import { AssetsLayout } from '../layouts/AssetsLayout';
import { AssetsPage } from '../pages/AssetsPage';
import { AssetDetailsLayout } from '../layouts/AssetDetailsLayout';
import AssetFormApi from '../components/asset-form/AssetFormApi';
import { AssetEventsTab } from '../components/asset-event-tab/AssetEventsTab';
import { AssetHistoryTab } from '../components/asset-history-tab/AssetHistoryTab';
import { AssetMovementsTab } from '../components/asset-movement-tab/AssetMovementsTab';

export const ASSET_ROUTES: RouteObject[] = [
  {
    path: 'assets',
    element: <AssetsLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="overview" replace />,
      },
      {
        path: 'overview',
        element: <AssetsPage />,
      },
    ],
  },
  {
    path: 'assets/:assetId',
    element: <AssetDetailsLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="details" replace />,
      },
      {
        path: 'movements',
        element: <AssetMovementsTab />,
      },
      {
        path: 'details',
        element: <AssetFormApi />,
      },
      {
        path: 'events',
        element: <AssetEventsTab />,
      },
      {
        path: 'history',
        element: <AssetHistoryTab />,
      },
    ],
  },
];
