import { ActionBar } from '@/modules/application';
import { Typography, TextField, IconButton } from '@mui/material';
import { FunctionComponent } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { LabelFormModel } from './LabelFormModel';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ActionBarDeleteButton } from '@/modules/application/components/ActionBarDeleteButton';
import { labelService } from '../../api/label.service';
import { Delete } from '@mui/icons-material';
import LabelColorSelect from '../LabelColorSelect';
import { useUser } from '@/modules/users/contexts/UserContext';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import RequirePermissionComponent from '@/components/permissions/RequirePermissionComponent';
import { useTranslation } from '@/lib';

interface LabelFormDisplayProps {
  form: UseFormReturn<LabelFormModel>;
  onSubmit: (data: LabelFormModel) => void;
  isCreate?: boolean;
  labelId?: number;
}

const LabelFormDisplay: FunctionComponent<LabelFormDisplayProps> = ({ form, onSubmit, isCreate = false, labelId }) => {
  const { formState, handleSubmit, control, watch } = form;
  const { errors, isSubmitting } = formState;
  const hasEditLabelPermission = useUser().hasPermission(Permission.LABELS_EDIT);
  const { t } = useTranslation();

  const navigate = useNavigate();

  async function deleteLabel() {
    console.log({ labelId });

    if (labelId) {
      const response = await labelService.delete(labelId);
      if (!response.isSuccess) {
        toast.error(t('label_error_deleting'));
      } else {
        toast.success(t('label_deleted'));
        navigate('/app/configuration/labels/overview');
      }
    }
  }

  const areValuesUnique = watch('values') ? new Set(watch('values').map((v) => v.value)).size === watch('values').length : true;
  const hasEmptyValue = watch('values') ? watch('values').some((v) => v.value === '') : false;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* {JSON.stringify(watch('values'))} */}
      {/* {JSON.stringify(hasEmptyValue)} */}
      {/*    // {getValues('values') && getValues('values').length} */}
      <div className="grid gap-4 max-w-sm ">
        <div className="flex w-full flex-col gap-y-2 pr-10">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField error={errors.name !== undefined} helperText={errors.name?.message} label={t('label_name')} {...field} />
            )}
          />
        </div>

        <div className="flex w-full flex-col gap-y-2">
          {/* {JSON.stringify(value)} */}
          <Controller
            name="color"
            control={control}
            render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
              <>
                {/* {JSON.stringify(value)} */}
                <LabelColorSelect selectedColor={value} onChange={onChange} />
              </>
            )}
          />
        </div>

        <div className="flex-col flex">
          <Typography variant="h5" className="" color="text.secondary">
            {t('values')}
          </Typography>
          <div className="flex w-full flex-col gap-y-2">
            <Controller
              name="values"
              control={control}
              render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                <>
                  {/* {JSON.stringify(value)} */}
                  <div className="w-full overflow-hidden flex flex-col">
                    <div className="mt-2 mb-4">
                      <Typography variant="caption">{t('label_value_caption')}</Typography>
                    </div>
                    <div className="flex flex-col gap-y-2">
                      {value?.map((val, index) => (
                        <div className="flex items-center group " key={index}>
                          <TextField
                            error={errors.values !== undefined}
                            helperText={errors.values?.message}
                            label={`${t('value')} ${index + 1}`}
                            value={val.value}
                            fullWidth
                            onChange={(e) => {
                              const newValue = value ? [...value] : [];
                              newValue[index] = { id: val.id, value: e.target.value };
                              onChange(newValue);
                            }}
                          />
                          <div className="mt-1 ml-1 w-10">
                            <IconButton
                              className="group-hover:flex hidden"
                              color="secondary"
                              onClick={() => {
                                const newValue = value ? [...value] : [];
                                newValue.splice(index, 1);
                                onChange(newValue);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* show alert if labels are not unique */}
                    <div className="mt-2">
                      {!areValuesUnique && (
                        <Typography variant="caption" color="error">
                          {t('label_error_values_unique')}
                        </Typography>
                      )}
                      {hasEmptyValue && (
                        <Typography variant="caption" color="error">
                          {t('label_error_values_empty')}
                        </Typography>
                      )}
                    </div>
                  </div>

                  <div className="mt-2 flex flex-col items-start">
                    <RequirePermissionComponent permission={Permission.LABELS_EDIT}>
                      <LoadingButton
                        variant="outlined"
                        onClick={() => {
                          onChange([...(value ?? []), { value: '' }]);
                        }}
                      >
                        {t('add_value')}
                      </LoadingButton>
                    </RequirePermissionComponent>
                  </div>
                </>
              )}
            />
          </div>
        </div>
        {/* </CardContent>
        </Card> */}
      </div>
      <ActionBar visible={hasEditLabelPermission}>
        <LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={!areValuesUnique || hasEmptyValue}>
          {t('save')}
        </LoadingButton>
        <ActionBarDeleteButton
          deleteConfirmationQuestion={t('label_delete_confirmation')}
          onDeleteConfirmed={deleteLabel}
          isVisible={!isCreate}
          isLoading={isSubmitting}
        />
      </ActionBar>
    </form>
  );
};

export default LabelFormDisplay;
