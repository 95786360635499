import RequirePermissionRoute from '@/components/permissions/RequirePermissionRoute';
import { AssetTypeDetailPage, AssetTypesPage } from '@/modules/asset-types';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { Navigate, RouteObject } from 'react-router-dom';

export const ASSET_CONFIGURATION_ROUTES: RouteObject[] = [
  {
    path: 'assets',
    children: [
      {
        index: true,
        element: (
          <RequirePermissionRoute requiredPermission={Permission.ASSET_TYPES_VIEW}>
            <Navigate to="types" replace />
          </RequirePermissionRoute>
        ),
      },
      {
        path: 'types',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.ASSET_TYPES_VIEW}>
            <AssetTypesPage />
          </RequirePermissionRoute>
        ),
      },
      {
        path: 'types/create',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.ASSET_TYPES_EDIT}>
            <AssetTypeDetailPage isCreate={true} />
          </RequirePermissionRoute>
        ),
      },
      {
        path: 'types/:assetTypeId',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.ASSET_TYPES_VIEW}>
            <AssetTypeDetailPage />
          </RequirePermissionRoute>
        ),
      },
    ],
  },
];
