import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import RGL, { Layout, WidthProvider, ItemCallback } from 'react-grid-layout';
import { useDashboardContext } from '../../contexts/DashboardContextProvider';
import { IDashboardWidget } from '../../types/IDashboardWidget';
import { IDashboardWidgetBaseConfiguration } from '../../types/IDashboardWidgetBaseConfiguration';
import { DashboardWidget } from '../dashboard-widget/DashboardWidget';
import { v4 } from 'uuid';
import { WIDGET_WIDTH_IN_PX, WIDGET_HEIGHT_IN_PX, DASHBOARD_COLUMN_COUNT, DASHBOARD_ROW_COUNT } from '../../configuration/configuration';
import { DashboardWidgetType } from '../../types/DashboardWidgetType';
import { WidgetCatalogFactory } from '../widgets/widget-catalog';
import { useTranslation } from '@/lib';

const ReactGridLayout = WidthProvider(RGL);

interface DashboardProps extends RGL.ReactGridLayoutProps, RGL.WidthProviderProps {
  widgets: IDashboardWidget<IDashboardWidgetBaseConfiguration>[];
}

const Dashboard: FunctionComponent<DashboardProps> = (props) => {
  const { t } = useTranslation();
  const { widgets, setWidgets, widgetPlaceholderHeight, widgetPlaceholderWidth, isEditingDashboard } = useDashboardContext();
  const widgetElements = useMemo(() => {
    return widgets.map((x) => {
      return <DashboardWidget key={x.layout.i} widget={x} data-grid={x.layout} />;
    });
  }, [widgets]);

  const [dragClass, setDragClass] = useState('');
  const onLayoutChange = useCallback<ItemCallback>(
    (_, oldItem, newItem) => {
      const newWidgetArr = [...widgets];
      newWidgetArr.forEach((x) => {
        if (x.layout.i === oldItem.i) {
          x.layout = newItem;
        }
      });
      setWidgets(newWidgetArr);
    },
    [widgets],
  );

  const onDrop = useCallback(
    (_: Layout[], item: Layout, e: DragEvent) => {
      const raw = e.dataTransfer?.getData('droppableWidget');

      if (!raw) {
        return;
      }
      console.log({ raw });

      const droppableWidget = WidgetCatalogFactory.createWidgetFromType(raw as DashboardWidgetType, t);

      const newWidgetArr = [...widgets];

      droppableWidget.layout.x = item.x;
      droppableWidget.layout.y = item.y;
      droppableWidget.layout.isDraggable = undefined;
      droppableWidget.id = v4();
      droppableWidget.layout.i = Math.random().toString(36).slice(2);
      newWidgetArr.push(droppableWidget);

      setWidgets(newWidgetArr);
    },
    [widgets],
  );

  // Memoize styles
  const gridStyle = useMemo(
    () => ({
      minWidth: DASHBOARD_COLUMN_COUNT * WIDGET_WIDTH_IN_PX,
      minHeight: DASHBOARD_ROW_COUNT * WIDGET_HEIGHT_IN_PX,
      width: DASHBOARD_COLUMN_COUNT * WIDGET_WIDTH_IN_PX,
      height: DASHBOARD_ROW_COUNT * WIDGET_HEIGHT_IN_PX,
    }),
    [],
  );

  return (
    <div
      className={`${
        isEditingDashboard
          ? 'rounded-lg border-2 border-dashed border-gray-300 bg-gray-200 transition-all dark:border-gray-700  dark:bg-gray-800'
          : ''
      }`}
      style={gridStyle}
    >
      <ReactGridLayout
        {...props}
        className={`dashboard-grid  ${dragClass} `}
        cols={DASHBOARD_COLUMN_COUNT}
        rowHeight={WIDGET_HEIGHT_IN_PX}
        maxRows={DASHBOARD_ROW_COUNT}
        compactType={null}
        width={DASHBOARD_COLUMN_COUNT * WIDGET_WIDTH_IN_PX}
        useCSSTransforms={true}
        style={gridStyle}
        measureBeforeMount={false}
        preventCollision={true}
        onDrop={onDrop}
        // Override draggable handle because toggling draggable on either the grid or the grid items, does not work
        draggableHandle=".draggable"
        onDrag={(layout: Layout[], oldItem: Layout, newItem: Layout, placeholder: Layout, event: MouseEvent, element: HTMLElement) => {
          if (event.movementX > 0 && dragClass !== 'dragging-right') {
            setDragClass('dragging-right ');
          } else if (event.movementX < 0 && dragClass !== 'dragging-left') {
            setDragClass('dragging-left');
          }
        }}
        onDropDragOver={(e) => {
          return { h: widgetPlaceholderHeight, w: widgetPlaceholderWidth };
        }}
        onDragStop={onLayoutChange}
        onResizeStop={onLayoutChange}
        isDroppable
      >
        {widgetElements}
      </ReactGridLayout>
    </div>
  );
};

export default Dashboard;
