import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { TabMenuItem } from '@/modules/application/types/MenuItem';
import { Outlet } from 'react-router-dom';
import { Devices, Smartphone, Bluetooth } from '@mui/icons-material';
import { useTranslation } from '@/lib';
import { LayoutActionsProvider } from '@/providers/layout-actions-provider/LayoutActionsProvider';

export function DevicesLayout() {
  const { t } = useTranslation();

  const deviceTabItems: TabMenuItem[] = [
    {
      label: t('all'),
      url: '/app/configuration/devices/all',
      icon: <Devices />,
      activeRouteIds: ['/app/configuration/devices', '/app/configuration/devices/all'],
    },
    {
      label: t('mobile_scanners'),
      url: '/app/configuration/devices/mobile-scanners',
      icon: <Smartphone />,
      activeRouteIds: ['/app/configuration/devices/mobile-scanners'],
    },
    {
      label: t('ble_gateways'),
      url: '/app/configuration/devices/ble-gateways',
      icon: <Bluetooth />,
      activeRouteIds: ['/app/configuration/devices/ble-gateways'],
    },
  ];

  return (
    <LayoutActionsProvider>
      <TabbedLayout
        topBar={<TabbedLayoutTopBar header={t('devices')} tabItems={deviceTabItems} headerIcon={<Devices fontSize="inherit" />} />}
      >
        <Outlet />
      </TabbedLayout>
    </LayoutActionsProvider>
  );
}
