import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';
import { GeocodeRequestDTO, GeocodeResultDTO } from './map.contracts';

export class MapService {
  public static basePath = 'map';

  async forwardGeocode(geocodeRequest: GeocodeRequestDTO): Promise<ApiResponse<GeocodeResultDTO[]>> {
    try {
      const response = await apiClient.post<GeocodeResultDTO[]>(`${MapService.basePath}/forward-geocode`, geocodeRequest);
      return response;
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const mapService = new MapService();
