import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@mui/material';
import { ChangeEvent, FunctionComponent, useState } from 'react';
import { dashboardService } from '../../api/dashboard/dashboard.service';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useTranslation } from '@/lib';

interface CreateDashboardDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onDashboardCreated: () => void;
}

const CreateDashboardDialog: FunctionComponent<CreateDashboardDialogProps> = ({ isOpen, onClose, onDashboardCreated }) => {
  const [dashboardName, setDashboardName] = useState('');
  const [isCreatingDashboard, setIsCreatingDashboard] = useState(false);
  const { t } = useTranslation(); // Use useTranslation hook

  function onDashboardNameChange(e: ChangeEvent<HTMLInputElement>) {
    setDashboardName(e.target.value);
  }

  async function onCreateDashboardClicked() {
    setIsCreatingDashboard(true);
    const newDashboard = await dashboardService.create({
      name: dashboardName,
      widgets: [],
    });
    if (newDashboard.isSuccess) {
      toast.success(t('dashboard.create.success')); // Use translation for success message

      onDashboardCreated();
    }
    setIsCreatingDashboard(false);
  }
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t('dashboard.create.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('dashboard.create.description')}</DialogContentText>
        <TextField
          className="mt-4"
          autoFocus
          margin="dense"
          id="name"
          label={t('dashboard.create.nameLabel')}
          type="text"
          fullWidth
          variant="standard"
          helperText={t('dashboard.create.helperText')}
          onChange={onDashboardNameChange}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {t('dashboard.create.cancel')}
        </Button>
        <LoadingButton
          loading={isCreatingDashboard}
          variant="contained"
          onClick={onCreateDashboardClicked}
          disabled={dashboardName.length < 3}
        >
          {t('dashboard.create.createButton')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDashboardDialog;
