import { Navigate, RouteObject } from 'react-router-dom';
import { LabelsPage } from '../pages/configuration/LabelsPage';
import { LabelDetailPage } from '../pages/configuration/LabelDetailPage';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import RequirePermissionRoute from '@/components/permissions/RequirePermissionRoute';

export const LABEL_CONFIGURATION_ROUTES: RouteObject[] = [
  {
    path: 'labels',
    children: [
      {
        index: true,
        element: (
          <RequirePermissionRoute requiredPermission={Permission.LABELS_VIEW}>
            <Navigate to="overview" replace />
          </RequirePermissionRoute>
        ),
      },
      {
        path: 'overview',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.LABELS_VIEW}>
            <LabelsPage />
          </RequirePermissionRoute>
        ),
      },
      {
        path: 'create',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.LABELS_EDIT}>
            <LabelDetailPage isCreate={true} />
          </RequirePermissionRoute>
        ),
      },
      {
        path: 'detail/:labelId',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.LABELS_VIEW}>
            <LabelDetailPage />
          </RequirePermissionRoute>
        ),
      },
    ],
  },
];
