import { FunctionComponent, MouseEvent, PropsWithChildren, ReactElement } from 'react';
import { To, Link } from 'react-router-dom';

interface TextLinkProps extends PropsWithChildren {
  to?: To;
  onClick?: () => void;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  fullWidth?: boolean;
  className?: string;
  target?: string;
  disabled?: boolean;
}

const TextLink: FunctionComponent<TextLinkProps> = ({ children, to, onClick, leftIcon, rightIcon, fullWidth = true, className, target, disabled }) => {
  function handleClick(e: MouseEvent) {
    if (disabled) {
      e.preventDefault();
      return;
    }

    if (onClick) {
      e.preventDefault();
      onClick();
    }
  }

  return (
    <Link
      to={to ?? '/'}
      className={`${fullWidth ? 'flex w-full' : 'inline-flex'} items-center text-inherit no-underline ${disabled ? 'cursor-default' : 'hover:text-primary-500'} ${className} `}
      onClick={handleClick}
      target={target}
    > 
      {leftIcon && <div className="flex pr-1">{leftIcon}</div>}
      {children}
      {rightIcon && <div className="flex pl-1">{rightIcon}</div>}
    </Link>
  );
};

export default TextLink;
