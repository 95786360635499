import { FunctionComponent } from 'react';
import MobilePage from '../components/MobilePage';
import MobilePageContent from '../components/MobilePageContent';
import MobilePageHeader from '../components/MobilePageHeader';
import { useApiCall } from '@/hooks/useApiCall';
import { eventService } from '@/modules/events/api/events/event.service';
import { EventModel } from '@/modules/events/types/EventModel';
import dayjs from 'dayjs';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import ErrorBlock from '@/components/feedback/ErrorBlock';
import MobileEventCard from '../components/MobileEventCard';
import { PagePaginationResultDto, PageSortOrder } from '@/lib/api/pagination.page.dto';
import { EventSortOption } from '@/modules/events/api/events/event.contracts';
import { useTranslation } from '@/lib';

const EVENT_FETCH_LIMIT = 50;

interface MobileEventsPageProps {}

const MobileEventsPage: FunctionComponent<MobileEventsPageProps> = () => {
  const { t } = useTranslation();
  const {
    data: events,
    isLoading,
    isError,
  } = useApiCall<PagePaginationResultDto<EventModel>>(() =>
    eventService.get(
      { dateFrom: dayjs().subtract(1, 'month').startOf('day').utc(true).toISOString() },
      {
        page: 1,
        limit: EVENT_FETCH_LIMIT,
        order: PageSortOrder.DESC,
        sort: EventSortOption.OCCURRED_AT,
      },
    ),
  );

  return (
    <MobilePage>
      <MobilePageHeader title={t('events')} />

      <MobilePageContent>
        <div className="flex flex-col flex-1 gap-y-4">
          <div className="text-sm mt-2">{t('showing_most_recent_events', { count: EVENT_FETCH_LIMIT })}</div>
          {isLoading && <LoadingBlock />}
          {isError && <ErrorBlock />}
          {!isLoading && !isError && events?.data?.map((event) => <MobileEventCard event={event} key={event.id} />)}
        </div>
      </MobilePageContent>
    </MobilePage>
  );
};

export default MobileEventsPage;
