import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { FunctionComponent } from 'react';
import { EventsOverTimeWidgetConfiguration } from './EventsOverTimeWidgetConfiguration';
import { useDashboardContext } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { isNumber, lowerCase } from 'lodash-es';
import { GroupPerTimeType } from './group-per-time-type.enum';
import { useTranslation } from '@/lib';

interface EventsOverTimeWidgetConfigurationFormProps {
  configuration: EventsOverTimeWidgetConfiguration;
  id: string;
}

const EventsOverTimeWidgetConfigurationForm: FunctionComponent<EventsOverTimeWidgetConfigurationFormProps> = ({ configuration, id }) => {
  const { widgets, updateWidget } = useDashboardContext();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-y-3">
      <TextField
        label="Name"
        value={configuration.title}
        onChange={(e) => {
          updateWidget<EventsOverTimeWidgetConfiguration>(id, {
            configuration: {
              title: e.currentTarget.value,
              eventsOverTimeType: configuration.eventsOverTimeType,
            },
          });
        }}
      />

      <FormControl>
        <InputLabel id="demo-simple-select-helper-label">Group by</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label={'Group by'}
          defaultValue={configuration.eventsOverTimeType}
          onChange={(e) => {
            updateWidget<EventsOverTimeWidgetConfiguration>(id, {
              configuration: {
                title: 'Events per ' + lowerCase(e.target.value as GroupPerTimeType),
                eventsOverTimeType: e.target.value as GroupPerTimeType,
              },
            });
          }}
        >
          {Object.values(GroupPerTimeType).map((totalType, i) => {
            if (isNumber(totalType)) {
              return;
            }
            return (
              <MenuItem key={totalType} value={totalType}>
                {t(`dashboard.group_per_time_type.${totalType}`)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default EventsOverTimeWidgetConfigurationForm;
