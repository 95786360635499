import { FunctionComponent, PropsWithChildren } from 'react';

interface SecondaryMenuProps extends PropsWithChildren {}

export const SecondaryMenu: FunctionComponent<SecondaryMenuProps> = ({ children }: SecondaryMenuProps) => {
  return (
    <>
      <div className="flex w-52 shrink-0 flex-col  border-r border-gray-200 bg-gray-100   dark:border-gray-600 dark:bg-gray-850">
        {children}
      </div>
    </>
  );
};
