import { Navigate, RouteObject } from 'react-router-dom';
import { AllDevicesPage } from '../pages/configuration/AllDevicesPage';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import RequirePermissionRoute from '@/components/permissions/RequirePermissionRoute';

import { DevicesLayout } from '../layouts/DevicesLayout';
import DeviceFormApi from '../components/device-form/DeviceFormApi';
import { DeviceDetailsLayout } from '../layouts/DeviceDetailsLayout';
import { MobileScannerDevicesPage } from '../pages/configuration/MobileScannerDevicesPage';
import { BLEGatewayDevicesPage } from '../pages/configuration/BLEGatewayDevicesPage';

export const DEVICE_CONFIGURATION_ROUTES: RouteObject[] = [
  {
    path: 'devices',
    element: <DevicesLayout />,
    children: [
      {
        index: true,
        element: (
          <RequirePermissionRoute requiredPermission={Permission.DEVICES_VIEW}>
            <Navigate to="all" replace />
          </RequirePermissionRoute>
        ),
      },
      {
        path: 'all',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.DEVICES_VIEW}>
            <AllDevicesPage />
          </RequirePermissionRoute>
        ),
      },
      {
        path: 'mobile-scanners',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.DEVICES_VIEW}>
            <MobileScannerDevicesPage />
          </RequirePermissionRoute>
        ),
      },
      {
        path: 'ble-gateways',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.DEVICES_VIEW}>
            <BLEGatewayDevicesPage />
          </RequirePermissionRoute>
        ),
      },
    ],
  },
  {
    path: 'devices/:deviceId',
    element: <DeviceDetailsLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="details" replace />,
      },
      {
        path: 'details',
        element: <DeviceFormApi />,
      },
    ],
  },
];
