import { IDashboardWidget } from '../../../types/IDashboardWidget';
import { FunctionComponent, useEffect, useState } from 'react';
import { EventsOverTimeWidgetConfiguration } from './EventsOverTimeWidgetConfiguration';

import EventsOverTimeWidgetConfigurationForm from './EventsOverTimeWidgetConfigurationForm';
import DashboardWidgetConfigurationButton from '../../dashboard-widget/DashboardWidgetConfigurationButton';
import DashboardWidgetContent from '../../dashboard-widget/DashboardWidgetContent';
import { DashboardWidgetProvider } from '../../dashboard-widget/DashboardWidgetContextProvider';
import DashboardWidgetConfigurationDrawer from '../../dashboard-widget/DasshboardWidgetConfigurationDrawer';
import { useTimerEndListener } from '@/providers/timer-provider/useTimerEndListener';
import { eventService } from '@/modules/events/api/events/event.service';
import { ReactECharts } from '@/components/chart/ReactEcharts';
import { EChartsOption } from 'echarts';
import DashboardWidgetTitle from '../../dashboard-widget/DashboardWidgetTitle';
import { EventCountOverTimeDTO } from '@/modules/events/api/events/event.contracts';
import { GroupPerTimeType } from './group-per-time-type.enum';
import dayjs from 'dayjs';

type EventsOverTimeWidgetProps = IDashboardWidget<EventsOverTimeWidgetConfiguration>;

const EventsOverTimeWidget: FunctionComponent<EventsOverTimeWidgetProps> = ({ configuration, id, layout }) => {
  const [eventsOverTime, setEventsOverTime] = useState<EventCountOverTimeDTO[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  // Listens to the timer context
  useTimerEndListener(handleTimerEnd);

  async function fetchEventsOverTime() {
    const response = await eventService.getEventCountGroupedByTime(configuration.eventsOverTimeType);
    if (response.isSuccess) {
      setEventsOverTime(response.payload);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchEventsOverTime();
  }, [configuration.eventsOverTimeType]);

  function handleTimerEnd() {
    fetchEventsOverTime();
  }

  // Get highest event count
  let highestEventCount = 0;
  eventsOverTime.forEach((event) => {
    if (event.count > highestEventCount) {
      highestEventCount = event.count;
    }
  });

  // Calculate left margin based on highest event count
  let leftMargin = 30;
  if (highestEventCount > 100) {
    leftMargin = leftMargin + 8;
  }
  if (highestEventCount > 1000) {
    leftMargin = leftMargin + 8;
  }
  if (highestEventCount > 10000) {
    leftMargin = leftMargin + 8;
  }

  const option: EChartsOption = {
    grid: {
      left: leftMargin,
      top: 10,
      right: 10,
      bottom: 30,
    },
    legend: {
      orient: 'horizontal',
      bottom: 5,
      padding: [0, 0, 0, 0],
    },
    xAxis: {
      type: 'category',
      data: eventsOverTime.map((event) => {
        // Based on configuration.eventsOverTimeType, we need to format the event.date (a date string)
        // Use dayjs to format for example the hour, the day of the month or the week number
        switch (configuration.eventsOverTimeType) {
          case GroupPerTimeType.HOUR:
            return dayjs(event.date).format('HH:mm');
          case GroupPerTimeType.DAY:
            return dayjs(event.date).format('D MMM');
          case GroupPerTimeType.WEEK:
            return 'Wk ' + dayjs(event.date).week();

          default:
            return '';
        }
      }),
      axisTick: {
        alignWithLabel: true,
      },
    },
    yAxis: {
      type: 'value',
      minInterval: 1,
    },
    series: [
      {
        data: eventsOverTime.map((event) => event.count),
        type: 'bar',
      },
    ],
    tooltip: {
      trigger: 'axis',
      renderMode: 'html',
      appendTo: 'body',
      extraCssText: 'z-index: 9999999;', // This is needed to make sure the tooltip is on top of the drawer
      axisPointer: {
        type: 'shadow',
      },
    },
    backgroundColor: '#f01',
  };

  return (
    <DashboardWidgetProvider widgetId={id ?? 'unknown'} isLoading={isLoading} setIsLoading={setIsLoading}>
      <DashboardWidgetContent>
        <DashboardWidgetConfigurationButton></DashboardWidgetConfigurationButton>
        <DashboardWidgetTitle>{configuration.title}</DashboardWidgetTitle>
        <div className="h-full w-full flex-1">
          <ReactECharts option={option} />
        </div>
      </DashboardWidgetContent>

      <DashboardWidgetConfigurationDrawer widgetName={configuration.title}>
        <EventsOverTimeWidgetConfigurationForm configuration={configuration} id={id} />
      </DashboardWidgetConfigurationDrawer>
    </DashboardWidgetProvider>
  );
};

export default EventsOverTimeWidget;
