import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ImportDefinitionFactory } from '../../definitions/import-factory';
import { ImportType } from '../../api/importer.contracts';
import { useTranslation } from '@/lib';
import { ImportOption } from '../../components/ImportOption';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { TabbedPageLayout } from '@/modules/application/layouts/TabbedPageLayout';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';

export const ImporterOptionsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const allImporters = ImportDefinitionFactory.getAllImportDefinitions();

  function onImportClick(importerType: ImportType) {
    navigate(`/app/configuration/importer/${importerType}`);
  }

  return (
    <TabbedLayout
      topBar={
        <TabbedLayoutTopBar>
          <TabbedLayoutTopBarHeader icon={null}>{t('importer.upload_a_file')}</TabbedLayoutTopBarHeader>
        </TabbedLayoutTopBar>
      }
    >
      <TabbedPageLayout>
        <TabbedPageLayoutBody>
          {/* A List of import options */}
          <div className="flex flex-wrap gap-4">
            {allImporters.map((importer) => {
              return (
                <ImportOption
                  key={importer.type}
                  importerType={importer.type}
                  iconColor={importer.iconColor}
                  icon={importer.icon}
                  title={importer.title}
                  description={importer.description}
                  templateFileUrl={importer.templateFileUrl}
                  onImportClick={onImportClick}
                />
              );
            })}
          </div>
        </TabbedPageLayoutBody>
      </TabbedPageLayout>
    </TabbedLayout>
  );
};
