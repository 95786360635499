import { useDate } from '@/hooks/useDate';
import { useTranslation } from '@/lib';
import { Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { FunctionComponent } from 'react';

export const PeriodInDaysCellRenderer: FunctionComponent<ICellRendererParams<unknown>> = (props: ICellRendererParams<unknown>) => {
  const { formatPeriodInDaysFromNow, formatDateTimestamp } = useDate();
  const { t } = useTranslation();

  return (
    <Tooltip title={t('location_entered_at', { date: formatDateTimestamp(props.value) })} placement="left">
      <div>{props.value && t('number_of_days', { day: formatPeriodInDaysFromNow(props.value) })}</div>
    </Tooltip>
  );
};
