import { FC, PropsWithChildren, createContext, useContext } from 'react';
import { ImportWizardStep } from './useImportWizard';
import { ImportDefinition } from '../definitions/import-definition';
import { ImportPreviewResponse } from '../api/importer.contracts';
import { ApiResponse } from '@/lib/api/api-response';

export interface WizardContextProps {
  steps: ImportWizardStep[];
  currentStep: ImportWizardStep;
  moveForward: () => void;
  moveBackward: () => void;
  uploadedFile: File | null;
  handleFileUpload: (file: File) => void;
  previewResponse: ApiResponse<ImportPreviewResponse<any, any>> | null;
  isUploadingFile: boolean;
  titleForStep: (step: ImportWizardStep) => string;
  currentStepTitle: string;
  importDefinition: ImportDefinition;
  uploadFileForPreview: () => void;
  uploadProgress: number;
  isImporting: boolean;
  importResponse: ApiResponse<boolean> | null;
  startImport: () => void;
}

const ImportWizardContext = createContext<WizardContextProps | undefined>(undefined);

interface ImportWizardProviderProps extends PropsWithChildren {
  wizard: WizardContextProps;
}

export const ImportWizardProvider: FC<ImportWizardProviderProps> = ({ wizard, children }) => {
  return <ImportWizardContext.Provider value={wizard}>{children}</ImportWizardContext.Provider>;
};

export const useWizardContext = (): WizardContextProps => {
  const context = useContext(ImportWizardContext);
  if (!context) {
    throw new Error('useWizardContext must be used within an ImportWizardProvider');
  }
  return context;
};
