import { Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { FunctionComponent } from 'react';
import { LinkCellRendererParams } from './LinkCellRenderer';

export interface ILinkCallbackCellRendererParams<T> {
  buttonText: string;
  callback: (rowData: T) => void;
  tooltip?: string;
  rightSlotRenderer?: FunctionComponent<LinkCellRendererParams> | null;
}

export interface ILinkCallbackCellRendererProps extends ICellRendererParams, ILinkCallbackCellRendererParams<unknown> {}

const LinkCallbackCellRenderer: FunctionComponent<ILinkCallbackCellRendererProps> = (props) => {
  const handleClick = () => {
    if (props.data) {
      props.callback(props.data);
    }
  };

  return (
    <Tooltip title={props.tooltip}>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleClick();
          }
        }}
        onClick={handleClick}
        className="truncate cursor-pointer flex w-full items-center  group  hover:text-primary-500"
      >
        <div className="flex w-full">{props.buttonText}</div>
        {props.rightSlotRenderer && <props.rightSlotRenderer {...props} />}
      </div>
    </Tooltip>
  );
};

export default LinkCallbackCellRenderer;
