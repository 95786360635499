import { FunctionComponent, PropsWithChildren } from 'react';
import { TimelineItemProvider, useTimelineItem } from './TimelineItemContext';

interface TimelineItemProps extends PropsWithChildren {
  isFirst?: boolean;
  isLast?: boolean;
}

export const TimelineItem: FunctionComponent<TimelineItemProps> = ({ children, isFirst, isLast }) => {
  const { isFirst: isFirstItem, isLast: isLastItem } = useTimelineItem({
    isFirst: isFirst,
    isLast: isLast,
  });
  return (
    <TimelineItemProvider isFirst={isFirstItem} isLast={isLastItem}>
      <div className="flex  ">{children}</div>
    </TimelineItemProvider>
  );
};
