import { useDate } from '@/hooks/useDate';
import { Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { FunctionComponent } from 'react';

export const RelativeDateCellRenderer: FunctionComponent<ICellRendererParams<unknown>> = (props: ICellRendererParams<unknown>) => {
  const { formatRelativeDate, formatDateTimestamp } = useDate();

  return (
    <Tooltip title={formatDateTimestamp(props.value)} placement="left">
      <div>{formatRelativeDate(props.value)}</div>
    </Tooltip>
  );
};
