// MoveAssetsToAnotherTypeDialog.tsx

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useTranslation } from '@/lib';

import AssetTypeSelect from './AssetTypeSelect'; // Adjust the import path as necessary
import { AssetTypeModel } from '../types/AssetTypeModel';
import { assetService } from '@/modules/assets/api/assets/asset.service';

interface MoveAssetsToAnotherTypeDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onAssetsMoved: () => void;
  assetTypeToDeleteID: number;
  assetTypeToDeleteName: string;
}

const MoveAssetsToAnotherTypeDialog: FunctionComponent<MoveAssetsToAnotherTypeDialogProps> = ({
  isOpen,
  onClose,
  onAssetsMoved,
  assetTypeToDeleteID,
  assetTypeToDeleteName,
}) => {
  const [selectedAssetType, setSelectedAssetType] = useState<AssetTypeModel | undefined>(undefined);
  const [isMovingAssets, setIsMovingAssets] = useState(false);
  const { t } = useTranslation();

  async function onMoveAssetsClicked() {
    if (!selectedAssetType) return;

    setIsMovingAssets(true);

    // Get all current assets of the asset type to delete
    const allAssetsResponse = await assetService.getAll({ assetTypeId: assetTypeToDeleteID });
    if (!allAssetsResponse.isSuccess) {
      toast.error(t('failed_to_move_assets_try_again'));
      setIsMovingAssets(false);
      return;
    }

    // Make a list of asset IDs to move
    const assetIds = allAssetsResponse.payload.map((asset) => asset.id);

    // Move assets to the new asset type
    const response = await assetService.bulkUpdate({ assetTypeId: selectedAssetType.id, assetIds });

    if (!response.isSuccess) {
      toast.error(t('failed_to_move_assets_try_again'));
      setIsMovingAssets(false);
      return;
    }

    // Now that the assets have been moved, close the dialog
    onAssetsMoved();
    onClose();

    setIsMovingAssets(false);
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t('move_assets_to_another_type')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('asset_type_cannot_be_deleted', { assetTypeName: assetTypeToDeleteName })}</DialogContentText>
        <div className="mt-4">
          <AssetTypeSelect selectedAssetTypeId={selectedAssetType?.id} onChange={setSelectedAssetType} label={t('select_new_asset_type')} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {t('cancel')}
        </Button>
        <LoadingButton loading={isMovingAssets} variant="contained" onClick={onMoveAssetsClicked} disabled={!selectedAssetType}>
          {t('move_assets')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default MoveAssetsToAnotherTypeDialog;
