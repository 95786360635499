export enum DeviceType {
  MOBILE_SCANNER = 0,
  BLE_GATEWAY = 1,
}

export interface DeviceLocationDTO {
  id: number;
  name: string;
  code: string;
}

export interface DeviceReadDTO {
  id: number;
  code: string;
  name: string;
  brand: string;
  type: string;
  serialNumber: string;
  lastEventReceivedAt?: Date;
  deviceType: DeviceType;
  identifier?: string;
  locationId?: number;
  location?: DeviceLocationDTO;
}
export interface DeviceUpdateDTO {
  name: string;
  code: string;
  brand: string;
  type: string;
  serialNumber: string;
  deviceType: DeviceType;
  identifier?: string;
  locationId?: number;
}
export interface DeviceCreateDTO {
  name: string;
  code: string;
  brand: string;
  type: string;
  serialNumber: string;
  deviceType: DeviceType;
  identifier?: string;
  locationId?: number;
}
