import { useState } from 'react';
import StringInputModal from './StringInputModal';

export const useStringInputModal = ({
  title,
  description,
  inputLabel,
  onConfirmed,
  validationRule,
  validationHelperText,
}: {
  title: string;
  description: string;
  inputLabel: string;
  successMessage: string;
  onConfirmed: (inputValue: string) => Promise<boolean>;
  validationRule?: (inputValue: string) => boolean;
  validationHelperText?: string;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmation = async (inputValue: string) => {
    const isSuccess = await onConfirmed(inputValue);
    if (isSuccess) {
      handleCloseModal(); // Close the modal on successful input handling
    }

    return isSuccess;
  };

  const ModalComponent = (
    <StringInputModal
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      onConfirm={handleConfirmation}
      title={title}
      description={description}
      inputLabel={inputLabel}
      validationRule={validationRule}
      validationHelperText={validationHelperText}
    />
  );

  return { ModalComponent, handleOpenModal };
};
