import { PoGrid } from '@/components/grid/PoGrid';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { AssetModel } from '@/modules/assets/types/AssetModel';
import { GridOptions, RowDoubleClickedEvent, ValueGetterParams } from 'ag-grid-community';
import { useTranslation } from '@/lib';

interface LocationAssetsGridProps {
  data?: AssetModel[];
  isError: boolean;
}

export const LocationAssetsGrid: FunctionComponent<LocationAssetsGridProps> = ({ data, isError }) => {
  const { t } = useTranslation();
  if (!data) {
    return <div>{t('no_data')}</div>;
  }
  const navigate = useNavigate();

  const customGridOptions: GridOptions<AssetModel> = {
    onRowDoubleClicked(asset: RowDoubleClickedEvent<AssetModel, unknown>) {
      navigate(`/app/assets/${asset.data?.id}`);
    },
  };

  const columns: ColDefOrGroup<AssetModel>[] = [
    {
      colId: 'type',
      headerName: t('type'),
      valueGetter: (params: ValueGetterParams<AssetModel>) => {
        return params.data?.assetType.name;
      },
    },
    {
      colId: 'code',
      field: 'code',
      headerName: t('code'),
    },
  ];

  return (
    <div className="flex h-full flex-1 flex-grow flex-col">
      {isError ? <ErrorLoadingDataAlert /> : <PoGrid isLoading={false} colDefs={columns} rowData={data} gridOptions={customGridOptions} />}
    </div>
  );
};
