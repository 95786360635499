import { AxiosProgressEvent } from 'axios';
import { ImportType, ImportPreviewResponse, ValidatedRow } from './importer.contracts';
import { apiClient } from '@/lib/api/base-client';
import { ApiResponse } from '@/lib/api/api-response';
import { AssetTrackerDto } from '../definitions/importers/asset-import';
import { LocationImportRow } from '../definitions/importers/location-import';
import { ParentLocationDto } from '../definitions/importers/parent-location-import';

export class ImporterSerivce {
  public basePath = 'importer';

  public async validateFile<TSummary, TValidatedRows extends ValidatedRow>(
    type: ImportType,
    fileToValide: File,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
  ): Promise<ApiResponse<ImportPreviewResponse<TSummary, TValidatedRows>>> {
    const formData = new FormData();
    formData.append('file', fileToValide);

    try {
      const response = await apiClient.uploadFiles<ImportPreviewResponse<TSummary, TValidatedRows>>(
        `${this.basePath}?type=${type}`,
        formData,
        onUploadProgress,
      );

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async importAssets(dtos: AssetTrackerDto[]) {
    try {
      const applicationResponse = await apiClient.post<void>('asset/bulk-import', dtos);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async importLocations(dtos: LocationImportRow[]) {
    try {
      const applicationResponse = await apiClient.post<void>('location/bulk-import', dtos);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async importLocationsParentLocations(dtos: ParentLocationDto[]) {
    try {
      const applicationResponse = await apiClient.post<void>('location/bulk-import/level-2-parents', dtos);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const importerService = new ImporterSerivce();
