import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent } from 'react';
import { AssetTypeBalanceGrid } from './AssetTypeBalanceGrid';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import { assetTypeService } from '../../api/asset-types/asset-type.service';
import { ReadAssetTypeBalanceDTO } from '../../api/asset-types/asset-type.contracts';

interface AssetTypeBalanceTabProps {
  assetTypeId: number;
}

export const AssetTypeBalanceTab: FunctionComponent<AssetTypeBalanceTabProps> = ({ assetTypeId }) => {
  const { data, isLoading, isError, fetchData } = useApiCall<ReadAssetTypeBalanceDTO[]>(() =>
    assetTypeService.getBalancePerLocation({ assetTypeId }),
  );
  return (
    <div className="flex h-full flex-1 flex-grow flex-col ">
      {isLoading ? <LoadingBlock /> : <AssetTypeBalanceGrid data={data} isError={isError}></AssetTypeBalanceGrid>}
    </div>
  );
};
