import { BASE_GRID_OPTIONS, PoGrid } from '@/components/grid/PoGrid';
import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridOptions, ICellRendererParams, RowDoubleClickedEvent } from 'ag-grid-community';
import LinkCellRenderer, { LinkCellRendererParams } from '@/components/grid/cells/LinkCellRenderer';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { AssetTypeTotalModel } from '@/modules/asset-types';
import { DisplayMode } from '@/types/display-mode.enum';
import { useTranslation } from '@/lib';
import { AssetsAtLocationModal } from '@/modules/assets/components/assets-at-location-modal/AssetsAtLocationModal';
import LinkCallbackCellRenderer, { ILinkCallbackCellRendererParams } from '@/components/grid/cells/LinkCallbackCellRenderer';

interface LocationBalanceGridProps {
  data?: AssetTypeTotalModel[];
  isError: boolean;
  noBorder?: boolean;
  isLoading?: boolean;
  balanceDisplayMode?: DisplayMode;
  locationId: number;
}

export const LocationBalanceGrid: FunctionComponent<LocationBalanceGridProps> = ({
  data,
  isError,
  noBorder,
  isLoading,
  balanceDisplayMode,
  locationId,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isAssetModalOpen, setIsAssetModalOpen] = useState(false);
  const [selectedAssetTypeId, setSelectedAssetTypeId] = useState<number | undefined>(undefined);

  const customGridOptions: GridOptions<AssetTypeTotalModel> = {
    ...BASE_GRID_OPTIONS,
    onRowDoubleClicked(event: RowDoubleClickedEvent<AssetTypeTotalModel, unknown>) {
      navigate(`/app/configuration/assets/types/${event.data?.id}`);
    },
  };

  const columns: ColDefOrGroup<AssetTypeTotalModel>[] = [
    {
      field: 'name',
      headerName: t('asset_type'),
      cellRenderer: LinkCellRenderer,
      cellRendererParams: (params: ICellRendererParams<AssetTypeTotalModel>): LinkCellRendererParams => ({
        pathname: params.data?.id ? `/app/configuration/assets/types/${params.data?.id}` : undefined,
      }),
    },
  ];

  if (balanceDisplayMode === DisplayMode.HIERARCHICAL) {
    columns.push({
      field: 'hierarchicalBalance',
      headerName: t('balance'),
      flex: 1,
      cellRenderer: LinkCallbackCellRenderer,
      cellRendererParams: (params: ICellRendererParams<AssetTypeTotalModel>): ILinkCallbackCellRendererParams<AssetTypeTotalModel> => ({
        callback: (data) => {
          setSelectedAssetTypeId(data?.id);
          setIsAssetModalOpen(true);
        },
        buttonText: params.data?.hierarchicalBalance?.toString() ?? '',
      }),
    });
  } else {
    columns.push({
      field: 'individualBalance',
      headerName: t('balance'),
      flex: 1,
      cellRenderer: LinkCallbackCellRenderer,
      cellRendererParams: (params: ICellRendererParams<AssetTypeTotalModel>): ILinkCallbackCellRendererParams<AssetTypeTotalModel> => ({
        callback: (data) => {
          setSelectedAssetTypeId(data?.id);
          setIsAssetModalOpen(true);
        },
        buttonText: params.data?.individualBalance?.toString() ?? '',
      }),
    });
  }

  const onAssetModalClosed = () => {
    setIsAssetModalOpen(false);
  };

  return (
    <div className="flex h-full flex-1 flex-grow flex-col">
      {isError ? (
        <ErrorLoadingDataAlert />
      ) : (
        <PoGrid
          noBorder={noBorder}
          isLoading={isLoading}
          colDefs={columns}
          rowData={data}
          gridOptions={customGridOptions}
          disableDefaultGridOptions
        />
      )}
      <AssetsAtLocationModal
        isOpen={isAssetModalOpen}
        locationId={locationId}
        onClose={onAssetModalClosed}
        assetTypeId={selectedAssetTypeId}
        key={1}
        balanceDisplayMode={balanceDisplayMode}
      />
    </div>
  );
};
