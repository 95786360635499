import { Navigate, RouteObject } from 'react-router-dom';
import { LocationsLayout } from '../layouts/LocationsLayout';
import { LocationsPage } from '../pages/LocationsPage';
import { LocationDetailsLayout } from '../layouts/LocationDetailsLayout';
import LocationFormApi from '../components/location-form/LocationFormApi';
import { LocationEventsTab } from '../components/location-event-tab/LocationEventsTab';
import { LocationBalanceTab } from '../components/location-balance-tab/LocationBalanceTab';
import { LocationAssetsTab } from '../components/location-assets-tab/LocationAssetsTab';
import { LocationMovementsTab } from '../components/location-movements-tab/LocationMovementsTab';

export const LOCATION_ROUTES: RouteObject[] = [
  {
    path: 'locations',
    element: <LocationsLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="overview" replace />,
      },
      {
        path: 'overview',
        element: <LocationsPage />,
      },
    ],
  },
  {
    path: 'locations/:locationId',
    element: <LocationDetailsLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="details" replace />,
      },
      {
        path: 'movements',
        element: <LocationMovementsTab />,
      },
      {
        path: 'details',
        element: <LocationFormApi />,
      },
      {
        path: 'events',
        element: <LocationEventsTab />,
      },
      {
        path: 'balance',
        element: <LocationBalanceTab />,
      },
      {
        path: 'assets',
        element: <LocationAssetsTab />,
      },
    ],
  },
];
