import { Navigate, RouteObject } from 'react-router-dom';

import TransactionsLayout from '../layouts/TransactionsLayout';
import { TransactionsPage } from '../pages/TransactionsPage';

export const TRANSACTION_ROUTES: RouteObject[] = [
  {
    path: 'transactions',
    element: <TransactionsLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="overview" replace />,
      },
      {
        path: 'overview',
        index: true,
        element: <TransactionsPage />,
      },
      // {
      //   path: 'handheld',
      //   element: <HandheldEventsPage />,
      // },
      // {
      //   path: 'create',
      //   element: <EventDetailPage isCreate={true} />,
      // },
      // {
      //   path: ':eventId',
      //   element: <EventDetailPage />,
      // },
    ],
  },
];
