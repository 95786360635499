import { ActionBar } from '@/modules/application';
import { Card, CardContent, Typography, TextField, MenuItem, Select, FormControl, FormHelperText, InputLabel, Button } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { AssetFormModel } from './AssetFormModel';
import { LoadingButton } from '@mui/lab';
import { assetTypeService } from '../../../asset-types/api/asset-types/asset-type.service';
import { AssetTypeModel } from '../../../asset-types/types/AssetTypeModel';
import { TrackerSelect } from '@/modules/trackers';
import { AddOutlined } from '@mui/icons-material';
import TrackerModalFormApi from '@/modules/trackers/components/tracker-modal-form/TrackerModalFormApi';
import { TrackerList } from '@/modules/trackers/components/TrackerList';
import { assetService } from '../../api/assets/asset.service';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ActionBarDeleteButton } from '@/modules/application/components/ActionBarDeleteButton';
import AssetLocationMap from '../AssetLocationMap';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import RequirePermissionComponent from '@/components/permissions/RequirePermissionComponent';
import { useUser } from '@/modules/users/contexts/UserContext';
import { useTranslation } from '@/lib';

interface AssetFormDisplayProps {
  form: UseFormReturn<AssetFormModel>;
  onSubmit: (data: AssetFormModel) => void;
  isCreate?: boolean;
  assetId?: number;
}

const AssetFormDisplay: FunctionComponent<AssetFormDisplayProps> = ({ form, onSubmit, isCreate = false, assetId }) => {
  const { formState, register, handleSubmit, getValues, control, watch, setValue } = form;
  const { errors, isSubmitting } = formState;

  const { t } = useTranslation();

  const [assetTypes, setAssetTypes] = useState<AssetTypeModel[]>([]);
  const hasAssetsEditPermission = useUser().hasPermission(Permission.ASSETS_EDIT);

  const navigate = useNavigate();

  async function getAllAssetTypes() {
    const assetTypes = await assetTypeService.getAll();
    if (assetTypes.isSuccess) {
      setAssetTypes(assetTypes.payload);
    }
  }

  const [isCreateNewTrackerModalOpen, setIsCreateNewTrackerModalOpen] = useState(false);
  function onCreateNewTrackerClicked() {
    setIsCreateNewTrackerModalOpen(true);
  }

  useEffect(() => {
    getAllAssetTypes();
  }, []);

  async function deleteAsset() {
    console.log({ assetId });

    if (assetId) {
      const response = await assetService.delete(assetId);
      if (!response.isSuccess) {
        toast.error(t('error_deleting_asset'));
      } else {
        toast.success(t('asset_deleted'));
        navigate('/app/assets/overview');
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid max-w-screen-xl grid-cols-1 gap-4 xl:grid-cols-6">
        <Card variant="outlined" className="xl:col-span-2">
          <CardContent>
            <Typography variant="h6" className="pb-2" color="text.secondary">
              {t('details')}
            </Typography>
            <div className="flex w-full flex-col gap-y-2">
              <Controller
                name="code"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField error={errors.code !== undefined} helperText={errors.code?.message} label={t('code')} {...field} />
                )}
              />
              <Controller
                name="assetTypeId"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormControl>
                    <InputLabel id="asset-type-label">{t('asset_type')}</InputLabel>
                    <Select labelId="asset-type-label" id="asset-type-select" label={t('asset_type')} {...field}>
                      {assetTypes.map((assetType) => {
                        return (
                          <MenuItem key={assetType.id} value={assetType.id}>
                            {assetType.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>{errors.assetTypeId?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </div>
          </CardContent>
        </Card>
        <Card variant="outlined" className="h-full xl:col-span-4">
          <CardContent>
            <Typography variant="h6" className="pb-2" color="text.secondary">
              {t('trackers')}
            </Typography>
            <div className="flex w-full flex-col gap-y-2">
              <Controller
                name="trackers"
                control={control}
                render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                  <>
                    <div className="w-full overflow-hidden">
                      <TrackerList onChange={onChange} value={value ?? []} assetId={assetId} canUnlink={hasAssetsEditPermission} />
                    </div>

                    <RequirePermissionComponent permission={Permission.ASSETS_EDIT}>
                      <div className="mt-4 flex flex-col">
                        <div className="mb-2">
                          <span className="font-bold">{t('link_a_tracker')}</span>
                        </div>
                        <div className="w-full">
                          <TrackerSelect
                            clearSelectionOnSelect
                            exludedTrackerIds={value}
                            onSelected={(selectedTracker) => {
                              if (selectedTracker) {
                                onChange([...value, selectedTracker.id]);
                              }
                            }}
                          />
                        </div>
                        <div className="mt-2">
                          <span className="mr-3">{t('or')}</span>

                          <Button variant="contained" startIcon={<AddOutlined />} onClick={onCreateNewTrackerClicked}>
                            {t('create_new_tracker')}
                          </Button>

                          <TrackerModalFormApi
                            isCreate
                            onTrackerCreated={(newTracker) => {
                              console.log({ newTracker });
                              const newValue = value ? [...value, newTracker.id] : [newTracker.id];
                              onChange(newValue);
                            }}
                            isOpen={isCreateNewTrackerModalOpen}
                            onClose={() => {
                              setIsCreateNewTrackerModalOpen(false);
                            }}
                          />
                        </div>
                      </div>
                    </RequirePermissionComponent>
                  </>
                )}
              />
            </div>
          </CardContent>
        </Card>

        {!isCreate && assetId !== undefined && assetId > 0 && (
          <Card variant="outlined" className="h-full xl:col-span-4">
            <CardContent>
              <Typography variant="h6" className="pb-2" color="text.secondary">
                {t('current_location')}
              </Typography>
              <AssetLocationMap assetId={assetId} />
            </CardContent>
          </Card>
        )}
      </div>
      <ActionBar visible={hasAssetsEditPermission}>
        <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
          {t('save')}
        </LoadingButton>
        <ActionBarDeleteButton
          deleteConfirmationQuestion={t('delete_confirmation_question')}
          onDeleteConfirmed={deleteAsset}
          isVisible={!isCreate}
          isLoading={isSubmitting}
        />
      </ActionBar>
    </form>
  );
};

export default AssetFormDisplay;
