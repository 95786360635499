import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';
import { SettingModel } from '../../types/SettingModel';
import { SettingUpdateDTO, SettingReadDTO, SettingKey, SettingValue, ApiKeyDTO } from './setting.contracts';

export class SettingService {
  public basePath = 'setting';

  async getAll(): Promise<ApiResponse<SettingModel[]>> {
    try {
      const applicationResponse = await apiClient.get<SettingReadDTO[]>(this.basePath);
      return applicationResponse.processPayload((payload) => {
        return payload.map(SettingModel.fromDTO);
      });
    } catch (error) {
      console.error(error);

      return ApiResponse.UnknownErrorResponse();
    }
  }
  async getApiKey(): Promise<ApiResponse<ApiKeyDTO>> {
    try {
      const applicationResponse = await apiClient.get<ApiKeyDTO>(`${this.basePath}/api-key`);
      return applicationResponse;
    } catch (error) {
      console.error(error);

      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getByKey(key: SettingKey): Promise<ApiResponse<SettingModel>> {
    try {
      const applicationResponse = await apiClient.get<SettingReadDTO>(`${this.basePath}/${key}`);
      return applicationResponse.processPayload(SettingModel.fromDTO);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async updateValue(key: SettingKey, value: SettingValue): Promise<ApiResponse<SettingModel>> {
    try {
      const updatedSetting: Partial<SettingUpdateDTO> = { value: value };
      const applicationResponse = await apiClient.patch<SettingReadDTO>(`${this.basePath}/${key}`, updatedSetting);
      return applicationResponse.processPayload(SettingModel.fromDTO);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const settingService = new SettingService();
