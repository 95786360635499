import { createTheme, Theme, ThemeOptions } from '@mui/material';
import { slate, orange } from 'tailwindcss/colors';

const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    background: {
      default: slate[900], //"#232333",
      paper: '#162032', // Custom slate 850
    },
    primary: {
      main: orange[500], //"#ea580c",
    },
    secondary: {
      main: slate[400],
    },
  },

  components: {
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingRight: 12,
          paddingLeft: 12,
          paddingTop: 8,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
        disableElevation: true,
        variant: 'contained',
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: 'small',
        disableElevation: true,
        variant: 'contained',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFab: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: 'dense',
        shrink: true,
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
        variant: 'outlined',
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
        notched: true,
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        size: 'small',
      },
    },
  },

  shape: {
    borderRadius: 6,
  },

  typography: {
    fontFamily: 'Open Sans Variable, sans-serif',
    button: {
      textTransform: 'none',
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '0.9375rem',
      fontWeight: 600,
    },
  },
  spacing: 1,
};

export const DARK_THEME: Theme = createTheme(darkThemeOptions);
