import { MenuItemGroup } from '@/modules/application';
import { Permission } from '../submodules/roles/api/permissions.contracts';

export const USER_CONFIGURATION_SUB_MENU_ITEMS: MenuItemGroup[] = [
  {
    label: 'users',
    children: [
      {
        activeRouteIds: ['/app/configuration/users*'],
        label: 'overview',
        url: '/app/configuration/users',
        requiredPermission: Permission.USER_VIEW,
      },
      {
        activeRouteIds: ['/app/configuration/roles*'],
        label: 'roles',
        url: '/app/configuration/roles',
        requiredPermission: Permission.USERS_ROLES_VIEW,
      },
    ],
  },
];
