import { useUser } from '@/modules/users/contexts/UserContext';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import React from 'react';

interface RequirePermissionComponentProps {
  permission: Permission;
  children: JSX.Element;
}

const RequirePermissionComponent: React.FC<RequirePermissionComponentProps> = ({ permission, children }) => {
  const hasPermission = useUser().hasPermission(permission);

  if (!hasPermission) {
    // Optionally, you can return null or a specific "No Access" component
    return null;
  }

  return children;
};

export default RequirePermissionComponent;
