import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';
import { CreateAPIKeyDTO, ReadAPIKeyDTO } from './api-key.contracts';
import { ApiKeyModel } from '../models/ApiKeyModel';

export class ApiKeyService {
  public basePath = 'api-key';

  async getAll(): Promise<ApiResponse<ApiKeyModel[]>> {
    try {
      const applicationResponse = await apiClient.get<ReadAPIKeyDTO[]>(this.basePath);
      return applicationResponse.processPayload((payload) => {
        return payload.map(ApiKeyModel.fromDTO);
      });
    } catch (error) {
      console.error(error);

      return ApiResponse.UnknownErrorResponse();
    }
  }

  async create(name: string): Promise<ApiResponse<ApiKeyModel>> {
    try {
      const payload: CreateAPIKeyDTO = { name, isEnabled: true };
      const applicationResponse = await apiClient.post<ReadAPIKeyDTO>(this.basePath, payload);
      return applicationResponse.processPayload(ApiKeyModel.fromDTO);
    } catch (error) {
      console.error(error);

      return ApiResponse.UnknownErrorResponse();
    }
  }

  async update(id: number, name: string, isEnabled: boolean): Promise<ApiResponse<ApiKeyModel>> {
    try {
      const payload: CreateAPIKeyDTO = { name, isEnabled };
      const applicationResponse = await apiClient.put<ReadAPIKeyDTO>(`${this.basePath}/${id}`, payload);
      return applicationResponse.processPayload(ApiKeyModel.fromDTO);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async delete(id: number): Promise<ApiResponse<void>> {
    try {
      const applicationResponse = await apiClient.delete<void>(`${this.basePath}/${id}`);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const apiKeyService = new ApiKeyService();
