import { createContext, useContext, ReactNode, useState, FunctionComponent, PropsWithChildren } from 'react';

// Define the context type
type LayoutActionsContextType = {
  actions: ReactNode;
  setActions: (actions: ReactNode) => void;
  setTopBar: (topBar: ReactNode) => void;
  topBar: ReactNode;
};

// Create the context
const LayoutActionsContext = createContext<LayoutActionsContextType>({
  actions: null,
  setActions: () => {}, // default empty function
  setTopBar: () => {},
  topBar: null,
});

// Export the provider
export const LayoutActionsProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [actions, setActions] = useState<ReactNode>(null);
  const [topBar, setTopBar] = useState<ReactNode>(null);

  return <LayoutActionsContext.Provider value={{ actions, setActions, setTopBar, topBar }}>{children}</LayoutActionsContext.Provider>;
};

// Export the hook to use the context
export const useLayoutActions = () => useContext(LayoutActionsContext);
