import { useCallback } from 'react';

export function useFormatting() {
  // Format numbers with thousands separators and max 2 decimal places
  const formatNumber = useCallback((num: number | string | undefined): string => {
    if (typeof num === 'string') num = parseFloat(num);
    if (typeof num !== 'number') return '';

    const formatter = new Intl.NumberFormat('nl-NL', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    return formatter.format(num);
  }, []);

  // Format percentages with max 2 decimal places
  const formatPercentage = useCallback((num: number | undefined): string => {
    if (typeof num !== 'number') return '';

    const formatter = new Intl.NumberFormat('nl-NL', {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    return formatter.format(num);
  }, []);

  /**
   * Format display size with max 4 characters.
   * @param num - The number to format.
   * @returns The formatted display size string.
   */
  const formatNumberToDisplaySize = useCallback((num: number | undefined): string => {
    if (typeof num !== 'number') return '';

    if (num >= 1000000) {
      const formattedNum = (num / 1000000).toFixed(1);
      return formattedNum + 'm+';
    } else if (num >= 10000) {
      const formattedNum = (num / 1000).toFixed(0);
      return formattedNum + 'k';
    } else if (num >= 1000) {
      const formattedNum = (num / 1000).toFixed(num >= 10000 ? 0 : 1);
      return formattedNum + 'k';
    } else {
      return num.toString();
    }
  }, []);

  return { formatNumber, formatPercentage, formatNumberToDisplaySize };
}
