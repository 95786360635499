import { TFunction } from 'i18next';
import { IDashboardWidgetBaseConfiguration } from '../../../types/IDashboardWidgetBaseConfiguration';
import { SystemTotalType } from './system-total-type.enum';

export class SystemTotalWidgetConfiguration implements IDashboardWidgetBaseConfiguration {
  title = 'dashboard.systemTotalWidgetConfiguration.title';
  systemTotalType = SystemTotalType.Assets;

  constructor(t: TFunction) {
    this.title = t(this.title);
  }
}
