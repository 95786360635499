import { Drawer, IconButton, Portal } from '@mui/material';
import { FunctionComponent, PropsWithChildren, useContext } from 'react';
import { DashboardWidgetContext } from './DashboardWidgetContextProvider';
import { useDashboardContext } from '../../contexts/DashboardContextProvider';
import { Close } from '@mui/icons-material';
import { DashboardWidgetConfigurationDrawerDeleteButton } from './DashboardWidgetConfigurationDrawerDeleteButton';
import { useTranslation } from '@/lib';

interface DashboardWidgetConfigurationDrawerProps extends PropsWithChildren {
  widgetName: string;
}

const DashboardWidgetConfigurationDrawer: FunctionComponent<DashboardWidgetConfigurationDrawerProps> = ({ children, widgetName }) => {
  const { isConfiguring, setIsConfiguring, widgetId } = useContext(DashboardWidgetContext);
  const { editingWidgetId, setEditingWidgetId } = useDashboardContext();
  const { t } = useTranslation();

  const isConfiguringThisWidget = isConfiguring && widgetId === editingWidgetId;

  return (
    <Portal>
      <Drawer anchor={'right'} open={isConfiguringThisWidget} hideBackdrop variant="persistent">
        <div className="flex h-full w-96 flex-col  justify-between px-6 py-4 ">
          <div className="flex flex-col">
            <div className="div flex items-center justify-between">
              <div className="text-lg font-medium ">
                {t('dashboard.widget.editWidget', { widgetName })} {/* Use dynamic translation with variable */}
              </div>
              <IconButton edge="start" color="inherit" onClick={() => setEditingWidgetId('')} aria-label="close">
                <Close />
              </IconButton>
            </div>
            <div className="mb-6 mt-2">{t('dashboard.widget.editConfiguration')}</div>
            <div className="flex flex-col gap-y-2">{isConfiguringThisWidget && children}</div>
          </div>
          <div className="w-full">
            <DashboardWidgetConfigurationDrawerDeleteButton />
          </div>
        </div>
      </Drawer>
    </Portal>
  );
};

export default DashboardWidgetConfigurationDrawer;
