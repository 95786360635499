import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FunctionComponent } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'; // Placeholder for 'Create New' Icon
import LinkIcon from '@mui/icons-material/Link';
import { useTranslation } from '@/lib';

interface CreateNewOrUseExistingToggleButtonProps {
  useExisting: boolean;
  onChange: (useExisting: boolean) => void;
}

const CreateNewOrUseExistingToggleButton: FunctionComponent<CreateNewOrUseExistingToggleButtonProps> = ({ useExisting, onChange }) => {
  const { t } = useTranslation();

  function onButtonGroupValueChanged(event: React.MouseEvent<HTMLElement, MouseEvent>, value: boolean) {
    if (value !== null) {
      onChange(value);
    }
  }

  return (
    <ToggleButtonGroup
      color="primary"
      value={useExisting}
      exclusive
      onChange={onButtonGroupValueChanged}
      aria-label={t('create_new_or_use_existing')}
    >
      <ToggleButton value={false} aria-label={t('create_new')}>
        <AddCircleOutlineIcon />
        <span className="px-2">{t('create_new')}</span>
      </ToggleButton>
      <ToggleButton value={true} aria-label={t('use_existing')}>
        <LinkIcon />
        <span className="px-2">{t('use_existing')}</span>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default CreateNewOrUseExistingToggleButton;
