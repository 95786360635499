import { RouteObject } from 'react-router-dom';
import { ConfigurationLayout } from '../layouts/ConfigurationLayout';
import { USER_CONFIGURATION_ROUTES } from '@/modules/users';
import { ASSET_CONFIGURATION_ROUTES } from '@/modules/assets';
import { TRACKER_CONFIGURATION_ROUTES } from '@/modules/trackers';
import { DEVICE_CONFIGURATION_ROUTES } from '@/modules/devices';
import { SETTING_CONFIGURATION_ROUTES } from '@/modules/settings';
import { IMPORTER_CONFIGURATION_ROUTES } from '@/modules/importer';
import { APPLICATION_LOG_CONFIGURATION_ROUTES } from '@/modules/application-log/routes/application-log-configuration-routes';
import { LABEL_CONFIGURATION_ROUTES } from '@/modules/labels/routes/label-configuration-routes';
import FirstAccessibleRoute from '@/components/permissions/FirstAccessibleRoute';

export const CONFIGURATION_ROUTES: RouteObject[] = [
  {
    path: 'configuration',
    element: <ConfigurationLayout />,
    children: [
      {
        index: true,
        element: (
          <FirstAccessibleRoute
            parentPath="/app/configuration"
            routes={[
              ...USER_CONFIGURATION_ROUTES,
              ...ASSET_CONFIGURATION_ROUTES,
              ...TRACKER_CONFIGURATION_ROUTES,
              ...DEVICE_CONFIGURATION_ROUTES,
              ...SETTING_CONFIGURATION_ROUTES,
              ...IMPORTER_CONFIGURATION_ROUTES,
              ...APPLICATION_LOG_CONFIGURATION_ROUTES,
              ...LABEL_CONFIGURATION_ROUTES,
            ]}
          />
        ),
      },
      ...USER_CONFIGURATION_ROUTES,
      ...ASSET_CONFIGURATION_ROUTES,
      ...TRACKER_CONFIGURATION_ROUTES,
      ...DEVICE_CONFIGURATION_ROUTES,
      ...SETTING_CONFIGURATION_ROUTES,
      ...IMPORTER_CONFIGURATION_ROUTES,
      ...APPLICATION_LOG_CONFIGURATION_ROUTES,
      ...LABEL_CONFIGURATION_ROUTES,
    ],
  },
];
