import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';
import { CreateLabelDTO, ReadLabelDTO, UpdateAssignmentsDTO, UpdateLabelDTO } from './label.contracts';
import { LabelModel } from '../models/LabelModel';

export class LabelService {
  public static basePath = 'label';

  async create(labelToCreate: CreateLabelDTO): Promise<ApiResponse<LabelModel>> {
    try {
      const applicationResponse = await apiClient.post<LabelModel>(LabelService.basePath + '/', labelToCreate);
      return applicationResponse;
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async update(labelId: number, labelToUpdate: UpdateLabelDTO): Promise<ApiResponse<undefined>> {
    try {
      const applicationResponse = await apiClient.patch<undefined>(`${LabelService.basePath}/${labelId}`, labelToUpdate);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getAll(query: { searchText?: string } = {}): Promise<ApiResponse<LabelModel[]>> {
    try {
      const applicationResponse = await apiClient.get<ReadLabelDTO[]>(LabelService.basePath, query);
      return applicationResponse.processPayload((payload) => {
        return payload.map((dto) => LabelModel.fromDTO(dto));
      });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getById(id: number): Promise<ApiResponse<LabelModel>> {
    try {
      const applicationResponse = await apiClient.get<ReadLabelDTO>(`${LabelService.basePath}/${id}`);
      return applicationResponse.processPayload((payload) => LabelModel.fromDTO(payload));
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async delete(id: number): Promise<ApiResponse<void>> {
    try {
      const applicationResponse = await apiClient.delete<void>(`${LabelService.basePath}/${id}`);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async updateAssignments(updates: UpdateAssignmentsDTO): Promise<ApiResponse<void>> {
    try {
      const applicationResponse = await apiClient.post<void>(`${LabelService.basePath}/assignments`, updates);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const labelService = new LabelService();
