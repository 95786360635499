import { Card } from '@mui/material';
import { forwardRef, PropsWithChildren } from 'react';
import { useDashboardContext } from '../../contexts/DashboardContextProvider';
import { IDashboardWidget } from '../../types/IDashboardWidget';
import { IDashboardWidgetBaseConfiguration } from '../../types/IDashboardWidgetBaseConfiguration';
import { DashboardWidgetType } from '../../types/DashboardWidgetType';
import MapWidget from '../widgets/map-widget/MapWidget';
import CountWidget from '../widgets/count-widget/CountWidget';
import { MapWidgetConfiguration } from '../widgets/map-widget/MapWidgetConfiguration';
import { CountWidgetConfiguration } from '../widgets/count-widget/CountWidgetConfiguration';
import SystemTotalWidget from '../widgets/system-total-widget/SystemTotalWidget';
import { SystemTotalWidgetConfiguration } from '../widgets/system-total-widget/SystemTotalWidgetConfiguration';
import { TopLocationsWidgetConfiguration } from '../widgets/top-locations-widget/TopLocationsWidgetConfiguration';
import TopLocationsWidget from '../widgets/top-locations-widget/TopLocationsWidget';
import { TopAssetTypesWidgetConfiguration } from '../widgets/top-assets-widget/TopAssetTypesWidgetConfiguration';
import TopAssetTypesWidget from '../widgets/top-assets-widget/TopAssetTypesWidget';
import EventsOverTimeWidget from '../widgets/events-over-time-widget/EventsOverTimeWidget';
import { EventsOverTimeWidgetConfiguration } from '../widgets/events-over-time-widget/EventsOverTimeWidgetConfiguration';
import BalanceOverTimeWidget from '../widgets/balance-over-time-widget/BalanceOverTimeWidget';
import { BalanceOverTimeWidgetConfiguration } from '../widgets/balance-over-time-widget/BalanceOverTimeWidgetConfiguration';
import EventsOverTimeByTypeWidget from '../widgets/events-over-time-by-type-widget /EventsOverTimeByTypeWidget';
import { EventsOverTimeByTypeWidgetConfiguration } from '../widgets/events-over-time-by-type-widget /EventsOverTimeByTypeWidgetConfiguration';

interface DashboardWidgetProps extends PropsWithChildren {
  widget: IDashboardWidget<IDashboardWidgetBaseConfiguration>;
}

export const DashboardWidget = forwardRef<HTMLDivElement, DashboardWidgetProps>(function DashboardWidget(props, ref) {
  const { widget } = props;
  let widgetComponent;

  switch (widget.type) {
    case DashboardWidgetType.MapWidget:
      widgetComponent = <MapWidget {...(widget as IDashboardWidget<MapWidgetConfiguration>)} />;
      break;
    case DashboardWidgetType.CountWidget:
      widgetComponent = <CountWidget {...(widget as IDashboardWidget<CountWidgetConfiguration>)} />;
      break;
    case DashboardWidgetType.SystemTotalWidget:
      widgetComponent = <SystemTotalWidget {...(widget as IDashboardWidget<SystemTotalWidgetConfiguration>)} />;
      break;
    case DashboardWidgetType.TopLocationsWidget:
      widgetComponent = <TopLocationsWidget {...(widget as IDashboardWidget<TopLocationsWidgetConfiguration>)} />;
      break;
    case DashboardWidgetType.TopAssetTypesWidget:
      widgetComponent = <TopAssetTypesWidget {...(widget as IDashboardWidget<TopAssetTypesWidgetConfiguration>)} />;
      break;
    case DashboardWidgetType.EventsOverTimeWidget:
      widgetComponent = <EventsOverTimeWidget {...(widget as IDashboardWidget<EventsOverTimeWidgetConfiguration>)} />;
      break;
    case DashboardWidgetType.BalanceOverTimeWidget:
      widgetComponent = <BalanceOverTimeWidget {...(widget as IDashboardWidget<BalanceOverTimeWidgetConfiguration>)} />;
      break;
    case DashboardWidgetType.EventsOverTimeByTypeWidget:
      widgetComponent = <EventsOverTimeByTypeWidget {...(widget as IDashboardWidget<EventsOverTimeByTypeWidgetConfiguration>)} />;
      break;
    default:
      widgetComponent = <div>Unknown widget type</div>;
      break;
  }

  const { isEditingDashboard, editingWidgetId } = useDashboardContext();
  return (
    <div ref={ref} {...props}>
      <Card
        variant="outlined"
        className={`dashboard-widget-tile h-full w-full overflow-visible rounded-lg bg-gray-100 dark:bg-gray-850  ${
          isEditingDashboard && editingWidgetId !== widget.id ? 'draggable' : 'disabled'
        }`}
      >
        {widgetComponent}

        {/* Only add resizable component when editing dashboard, as dynamically toggling the isResizable prop is not working */}
        {isEditingDashboard && (
          <div className="z-40 w-full h-full absolute inset-0 cursor-move bg-gray-200 opacity-40 rounded-lg">{props.children}</div>
        )}
      </Card>
    </div>
  );
});
