import { MenuItemGroup } from '@/modules/application';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';

export const ASSET_CONFIGURATION_SUB_MENU_ITEMS: MenuItemGroup[] = [
  {
    label: 'assets',
    children: [
      {
        activeRouteIds: ['/app/configuration/assets/types*'],
        label: 'asset_types',
        url: '/app/configuration/assets/types',
        requiredPermission: Permission.ASSET_TYPES_VIEW,
      },
    ],
  },
];
