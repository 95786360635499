import { Navigate, RouteObject } from 'react-router-dom';
import BalanceLayout from '../layouts/BalanceLayout';
import BalanceOverviewPage from '../pages/BalanceOverviewPage';

export const BALANCE_ROUTES: RouteObject[] = [
  {
    path: 'balances',
    element: <BalanceLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="overview" replace />,
      },
      {
        path: 'overview',
        index: true,
        element: <BalanceOverviewPage />,
      },
    ],
  },
];
