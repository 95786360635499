import React, { createContext, useState, ReactNode } from 'react';
import { SettingModel } from '../types/SettingModel';
import { settingService } from '../api/settings/setting.service';
import { SettingKey, SettingValue } from '../api/settings/setting.contracts';
import { toast } from 'react-toastify';
import { ApiResponse } from '@/lib/api/api-response';
import { useTranslation } from '@/lib';

interface SettingsContextProps {
  settings: SettingModel[];
  setSettings: React.Dispatch<React.SetStateAction<SettingModel[]>>;
  loadSettings: () => Promise<SettingModel[]>;
  getSettingByKey: (key: SettingKey) => SettingModel | undefined;
  getSettingValueByKey: (key: SettingKey) => SettingValue | undefined;
  updateSettingValueByKey: ({
    key,
    value,
    showToast,
    toastTitle,
  }: {
    key: SettingKey;
    value: SettingValue;
    showToast?: boolean;
    toastTitle?: string;
  }) => Promise<ApiResponse<SettingModel>>;
}

export const SettingsContext = createContext<SettingsContextProps>({
  settings: [],
  setSettings: () => null,
  loadSettings: () => new Promise(() => null),
  getSettingByKey: (key: SettingKey) => undefined,
  getSettingValueByKey: (key: SettingKey) => undefined,
  updateSettingValueByKey: ({ key, value, showToast }: { key: SettingKey; value: SettingValue; showToast?: boolean }) =>
    new Promise(() => null),
});

interface SettingsProviderProps {
  initialSettings: SettingModel[];
  children: ReactNode;
}

export const SettingsProvider: React.FC<SettingsProviderProps> = ({ children, initialSettings }) => {
  const { t } = useTranslation();
  const [settings, setSettings] = useState<SettingModel[]>(initialSettings);

  async function loadSettings() {
    const settings = await settingService.getAll();
    if (settings.isSuccess) {
      setSettings(settings.payload);
      return settings.payload;
    }
    return [];
  }

  function getSettingByKey(key: SettingKey) {
    return settings.find((setting) => setting.key === key);
  }

  function getSettingValueByKey(key: SettingKey) {
    return settings.find((setting) => setting.key === key)?.value;
  }

  async function updateSettingValueByKey({
    key,
    value,
    showToast = true,
    toastTitle = t('updated_setting'),
  }: {
    key: SettingKey;
    value: SettingValue;
    showToast?: boolean;
    toastTitle?: string;
  }): Promise<ApiResponse<SettingModel>> {
    const response = await settingService.updateValue(key, value);
    if (response.isSuccess) {
      if (showToast) {
        toast.success(toastTitle, { position: 'top-right' });
      }

      const updatedSettings = settings.map((setting) => {
        if (setting.key === key) {
          setting.value = value;
          return setting;
        }
        return setting;
      });
      setSettings(updatedSettings);
    } else {
      if (showToast) {
        toast.error(t('error_updating_setting'), { position: 'top-right' });
      }
    }
    return response;
  }

  return (
    <SettingsContext.Provider
      value={{
        settings,
        setSettings,
        loadSettings,
        getSettingByKey,
        getSettingValueByKey,
        updateSettingValueByKey,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
