import { useState, useEffect } from 'react';

export enum TailwindBreakpoint {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = '2xl',
}

export function useScreenSize() {
  const [breakpoint, setBreakpoint] = useState<TailwindBreakpoint>(getCurrentBreakpoint());

  function getCurrentBreakpoint(): TailwindBreakpoint {
    const width = window.innerWidth;

    if (width < 640) return TailwindBreakpoint.XS;
    else if (width >= 640 && width < 768) return TailwindBreakpoint.SM;
    else if (width >= 768 && width < 1024) return TailwindBreakpoint.MD;
    else if (width >= 1024 && width < 1280) return TailwindBreakpoint.LG;
    else if (width >= 1280 && width < 1536) return TailwindBreakpoint.XL;
    else if (width >= 1536) return TailwindBreakpoint.XXL;

    return TailwindBreakpoint.XS;
  }

  useEffect(() => {
    function updateSize() {
      setBreakpoint(getCurrentBreakpoint());
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const isBelowSM = breakpoint === TailwindBreakpoint.XS;
  const isBelowMD = isBelowSM || breakpoint === TailwindBreakpoint.SM;
  const isBelowLG = isBelowMD || breakpoint === TailwindBreakpoint.MD;
  const isBelowXL = isBelowLG || breakpoint === TailwindBreakpoint.LG;
  const isBelowXXL = isBelowXL || breakpoint === TailwindBreakpoint.XL;

  return { breakpoint, isBelowSM, isBelowMD, isBelowLG, isBelowXL, isBelowXXL };
}
