import { ICellRendererParams } from 'ag-grid-community';
import { FunctionComponent } from 'react';
import { GridLabel } from '../GridLabel';
import { AssignedLabelDTO } from '@/modules/labels/api/label.contracts';
import { Tooltip } from '@mui/material';

export interface LabelCellRendererParams {
  labels?: AssignedLabelDTO[];
}

interface LabelCellRendererProps extends ICellRendererParams<unknown>, LabelCellRendererParams {}

const LabelCellRenderer: FunctionComponent<LabelCellRendererProps> = (props: LabelCellRendererProps) => {
  const { labels } = props;

  return (
    <div className="flex items-center gap-x-1">
      {labels?.map((label, i) => (
        <Tooltip key={i} title={label.name}>
          <span>
            <GridLabel key={i} value={label.value.value} color={label.color} />
          </span>
        </Tooltip>
      ))}
    </div>
  );
};

export default LabelCellRenderer;
