import { useFormatting } from '@/hooks/useFormatting';
import { useTranslation } from '@/lib';
import { PagePaginationResultDto } from '@/lib/api/pagination.page.dto';
import { FunctionComponent } from 'react';

interface PagedResultDataTextProps {
  data?: PagePaginationResultDto<unknown>;
  name: string;
}

const PagedResultDataText: FunctionComponent<PagedResultDataTextProps> = ({ data, name }) => {
  const { formatNumber } = useFormatting();
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  return <div className="">{t('pagination_result', { total: formatNumber(data?.totalElements), entity: name })}</div>;
};

export default PagedResultDataText;
