import { Autocomplete, TextField } from '@mui/material';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { assetService } from '../api/assets/asset.service';
import { AssetModel } from '../types/AssetModel';
import { PageSortOrder } from '@/lib/api/pagination.page.dto';

interface AssetSelectProps {
  onSelected: (selectedAsset: AssetModel | null) => void;
  exludedAssetIds?: number[];
  clearSelectionOnSelect?: boolean;
  label?: string;
  helperText?: string;
  selectedAssetId?: number;
}

export const AssetSelect: FunctionComponent<AssetSelectProps> = ({
  onSelected,
  exludedAssetIds,
  clearSelectionOnSelect,
  helperText,
  selectedAssetId,
  label,
}) => {
  const [selectedOption, setSelectedOption] = useState<AssetModel | null>(null);
  const [inputValue, setInputValue] = useState('');
  const exludedAssetIdsMemoed = useMemo(() => exludedAssetIds?.toString(), [exludedAssetIds]);

  const handleOptionChange = (event: unknown, value: AssetModel | null) => {
    if (!clearSelectionOnSelect) {
      setSelectedOption(value);
    } else {
      setInputValue('');
      loadOptions('');
      setSelectedOption(null);
    }
    onSelected(value);
  };

  const [options, setOptions] = useState<AssetModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadOptions = async (query: string) => {
    setIsLoading(true);
    try {
      const response = await assetService.get({ searchText: query }, { page: 1, limit: 1000, order: PageSortOrder.ASC });
      if (response.isSuccess) {
        let assets = response.payload.data;
        if (exludedAssetIds) {
          assets = assets.filter((t) => !exludedAssetIds.includes(t.id));
        }
        setOptions(assets);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedAssetId) {
      setSelectedOption(null);
      return;
    }

    const loadAssetById = async () => {
      const assetResponse = await assetService.getById(selectedAssetId);
      if (assetResponse.isSuccess) {
        setSelectedOption(assetResponse.payload);
      }
    };
    loadAssetById();
  }, [selectedAssetId]);

  useEffect(() => {
    console.log('loading exludedAssetIds', { exludedAssetIds });

    loadOptions('');
  }, [exludedAssetIdsMemoed]);

  return (
    <Autocomplete
      value={selectedOption}
      inputValue={inputValue}
      className="w-full"
      options={options}
      onOpen={() => loadOptions('')}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.code} (${option.assetType.name})`}
      onInputChange={(event, newInputValue) => {
        loadOptions(newInputValue);
        setInputValue(newInputValue);
      }}
      filterOptions={(x) => x}
      onChange={(_, value) => {
        handleOptionChange(_, value);
      }}
      loading={isLoading}
      renderInput={(params) => <TextField {...params} label={label} helperText={helperText} InputLabelProps={{ shrink: true }} />}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.code} ({option.assetType.name})
          </li>
        );
      }}
    />
  );
};
