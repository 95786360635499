import { useLayoutActions } from '@/providers/layout-actions-provider/LayoutActionsProvider';
import { PropsWithChildren, FunctionComponent, ReactNode } from 'react';

interface TabbedLayoutProps extends PropsWithChildren {
  topBar: ReactNode;
}

export const TabbedLayout: FunctionComponent<TabbedLayoutProps> = ({ children, topBar }) => {
  const { topBar: contextTopBar } = useLayoutActions();
  return (
    <div className="flex h-full  flex-grow flex-col">
      <div className="  z-10 flex w-full flex-col items-center justify-between bg-gray-50 bg-opacity-80   backdrop-blur-sm dark:bg-gray-900 dark:bg-opacity-80">
        {contextTopBar ?? topBar}
      </div>

      <div className=" flex flex-grow">{children}</div>
    </div>
  );
};
