import { FunctionComponent } from 'react';
import { useParams } from 'react-router';
// import EventFormApi from '../components/event-form/EventFormApi';

interface EventDetailPageProps {
  isCreate?: boolean;
}
export const EventDetailPage: FunctionComponent<EventDetailPageProps> = ({ isCreate }) => {
  const { eventId } = useParams();
  return <div>Not implemented yet.</div>;
  // return <EventFormApi isCreate={isCreate} eventId={eventId ? Number.parseInt(eventId) : undefined} />;
};
