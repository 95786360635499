import React, { FunctionComponent } from 'react';
import Tooltip from '@mui/material/Tooltip';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTranslation } from '@/lib';

interface PeriodToggleButtonProps {
  onChange: (event: React.MouseEvent<HTMLElement>, newPeriodInDays: number) => void;
  periodInDays: number;
}

const PeriodToggleButton: FunctionComponent<PeriodToggleButtonProps> = ({ onChange, periodInDays }) => {
  const handleChange = (event: React.MouseEvent<HTMLElement>, newPeriodInDays: number) => {
    if (newPeriodInDays) onChange(event, newPeriodInDays);
  };

  const { t } = useTranslation();

  return (
    <div className="flex items-center">
      <Tooltip title={t('group_balance_over_time')}>
        <ToggleButtonGroup size="small" value={periodInDays} exclusive onChange={handleChange} aria-label="group balances over time">
          <ToggleButton value={14} aria-label="group by hour">
            <div className="text-xs">{t('number_of_days', { day: 14 })}</div>
          </ToggleButton>
          <ToggleButton value={30} aria-label="group by day">
            <div className="text-xs">{t('number_of_days', { day: 30 })}</div>
          </ToggleButton>
          <ToggleButton value={90} aria-label="group by week">
            <div className="text-xs">{t('number_of_days', { day: 90 })}</div>
          </ToggleButton>
        </ToggleButtonGroup>
      </Tooltip>
    </div>
  );
};

export default PeriodToggleButton;
