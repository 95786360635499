import { Checkbox, FormControlLabel, TextField, Tooltip } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { MapWidgetConfiguration } from './MapWidgetConfiguration';
import { useDashboardContext } from '@/modules/dashboards/contexts/DashboardContextProvider';
import AssetTypeSelect from '@/modules/asset-types/components/AssetTypeSelect';
import { TopLocationsWidgetConfiguration } from '../top-locations-widget/TopLocationsWidgetConfiguration';
import { InfoOutlined } from '@mui/icons-material';

interface MapWidgetConfigurationFormProps {
  configuration: MapWidgetConfiguration;
  id: string;
}

const MapWidgetConfigurationForm: FunctionComponent<MapWidgetConfigurationFormProps> = ({ configuration, id }) => {
  const { updateWidget, updateWidgetConfiguration } = useDashboardContext();

  const [includeEmptyLocations, setIncludeEmptyLocations] = useState(configuration.includeEmptyLocations);
  const [includeInTransitAssets, setIcludeInTransitAssets] = useState(configuration.includeInTransitAssets);

  return (
    <div className="flex flex-col">
      <TextField
        label="Name"
        value={configuration.title}
        onChange={(e) => {
          updateWidgetConfiguration<MapWidgetConfiguration>(id, {
            title: e.currentTarget.value,
          });
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={includeEmptyLocations}
            inputProps={{ 'aria-label': 'controlled' }}
            onChange={(e) => {
              setIncludeEmptyLocations(e.target.checked);
              updateWidget<MapWidgetConfiguration>(id, {
                configuration: {
                  title: configuration.title,
                  includeEmptyLocations: e.target.checked,
                  includeInTransitAssets: configuration.includeInTransitAssets,
                },
              });
            }}
          />
        }
        label="Include Locations with 0 Assets"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={includeInTransitAssets}
            inputProps={{ 'aria-label': 'controlled' }}
            onChange={(e) => {
              setIcludeInTransitAssets(e.target.checked);
              updateWidget<MapWidgetConfiguration>(id, {
                configuration: {
                  title: configuration.title,
                  includeEmptyLocations: configuration.includeEmptyLocations,
                  includeInTransitAssets: e.target.checked,
                },
              });
            }}
          />
        }
        label={
          <div className="flex items-center">
            Include In Transit Assets
            <div className="pl-1 flex items-center">
              <Tooltip title="Only Assets where the most recent Event has a GPS location, are displayed.">
                <InfoOutlined fontSize={'small'} />
              </Tooltip>
            </div>
          </div>
        }
      />

      <div className="mt-3 mb-1">Filter on Asset Type</div>
      <AssetTypeSelect
        label={'Asset Type'}
        selectedAssetTypeId={configuration.assetTypeId}
        onChange={(assetType) => {
          updateWidgetConfiguration<TopLocationsWidgetConfiguration>(id, {
            assetTypeId: assetType?.id,
          });
        }}
      />
    </div>
  );
};

export default MapWidgetConfigurationForm;
