import { IDashboardWidgetCatalogItem } from '../../../types/IDashboardWidgetCatalogItem';
import NumbersRounded from '@mui/icons-material/NumbersRounded';
import { CountWidgetConfiguration } from './CountWidgetConfiguration';
import { DashboardWidgetType } from '@/modules/dashboards/types/DashboardWidgetType';
import { v4 } from 'uuid';
import { TFunction } from 'i18next';
import { DashboardWidgetCatalogItemImageColorType } from '../../widget-catalog/DashboardWidgetCatalogItemImage';
import { Layout } from 'react-grid-layout';
export class CountWidgetCatalogItem implements IDashboardWidgetCatalogItem<CountWidgetConfiguration> {
  title: string;
  description: string;
  icon: JSX.Element;
  iconColor: DashboardWidgetCatalogItemImageColorType;
  id: string;
  type: DashboardWidgetType;
  configuration: CountWidgetConfiguration;
  layout: Layout;

  constructor(t: TFunction) {
    this.title = t('dashboard.countWidget.title');
    this.description = t('dashboard.countWidget.description');
    this.icon = <NumbersRounded />;
    this.iconColor = 'green';
    this.id = v4();
    this.type = DashboardWidgetType.CountWidget;
    this.configuration = new CountWidgetConfiguration(t);
    this.layout = {
      h: 1,
      w: 1,
      i: v4(),
      x: 0,
      y: 0,
      maxH: 2,
      maxW: 2,
      minH: 1,
      minW: 1,
    };
  }

  localize(t: TFunction) {
    this.title = t(this.title);
    this.description = t(this.description);
  }
}
